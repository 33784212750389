import React from 'react';
import { Col, Container, Row } from 'reactstrap';

// Components
import SkillStatus from '../../about/concepts/SkillStatus';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Train.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>Self-Directed Training</h1>
          <h4 className="padding-top-15">
            Deliberate training is the gold standard in training. CueMate’s assessment and training
            features enable you to deliberately train focusing on your needs.
          </h4>
          <h4 className="padding-top-15">
            The following describes CueMate’s features and tools for training by yourself. CueMate
            also has the Rating-based training, which automatically generates training goals based
            on your skill rating.
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

function TrainingGoalManagement() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <h2 className="blueGrey">Training Goals Management</h2>
        <p>
          Self-directed training starts with understanding CueMate’s Training features, including
          the Skill Assessment, and how to use these to define Training Goals, and manage the
          Training process during a session.
        </p>

        <h3>Setting Training Goals</h3>
        <p>
          Deliberate training involves setting training goals. The typical flow for self-directed
          training is:
          <ol>
            <li>
              Start with Review, inspect the outcomes of your core strokes (sweet spot, spin, and
              speed)
            </li>
            <li>Identify the stroke class with the deficient outcome</li>
            <li>Select the stroke class</li>
            <li>View the metrics in the class</li>
            <li>
              Look for the deficient compared to the reference values (see, e.g., radar chart).
            </li>
            <li>Go the trend plot to see how you have been doing for this metric over time.</li>
            <li>If the trend shows systematic low value, create a training goal</li>
          </ol>
          The Trends screen shows the graph of any metrics over selected time periods. On this
          screen, you can add the training element to the training list which appears under the
          Train in the side menu.
        </p>
      </Container>
    </Container>
  );
}

function TrainingList() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row>
        <Col>
          <h3>Training List</h3>
          <p>
            The CueMate Training List shows all the active training goals that were generated by
            CueMate as well as the ones you created.
          </p>
          <p>
            The Training List organizes the training goals based on their respective learning stage.
          </p>
          <p>
            You can pin training goals that you are actively training so you can better focus and
            track progress during training sessions. Pinned training goals are shown in the Session
            Report and when you tag session.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function TrainingGoalStatus() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h3>Training Goal Status</h3>
            <p>
              The training goal status screen provides detailed information about the activity and
              progress on a training goal.
            </p>
            <p>
              The screen also allows you to configure the feedback cueing and training tips to help
              you achieve the training goal.
            </p>
            <p>
              {' '}
              CueMate tracks your progress using batches of strokes. You must succeed (e.g., produce
              spin at or above a training goal) repeatedly in a batch of 100 strokes for three out
              of five batches to attain the training goal.
            </p>
            <p>(in development)</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function SelfDirectedTraining() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <TrainingGoalManagement />
      <TrainingList />
      <TrainingGoalStatus />

      <SkillStatus />
      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

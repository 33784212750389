const dev = {
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'cuemate-tennis',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_y99UQMCZP',
    APP_CLIENT_ID: '5c2tghfifa0qfnfa0hq4vnodd7',
    IDENTITY_POOL_ID: 'us-west-2:4c11de2b-6755-4bfe-8492-27f88b4d51af',
  },
  apiGateway: {
    REGION: 'us-west-2',
    ADMIN_URL: 'https://api.cuemate.com/admin',
    URL: 'https://api.cuemate.com/tennis',
  },
  api: {
    ENDPOINT: 'https://api.stage.cuemate.com/api/v1',
  },
};

const prod = {
  s3: {
    REGION: 'us-west-2',
    BUCKET: 'cuemate-tennis',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_y99UQMCZP',
    APP_CLIENT_ID: '5c2tghfifa0qfnfa0hq4vnodd7',
    IDENTITY_POOL_ID: 'us-west-2:4c11de2b-6755-4bfe-8492-27f88b4d51af',
  },
  apiGateway: {
    REGION: 'us-west-2',
    ADMIN_URL: 'https://api.cuemate.com/admin',
    URL: 'https://api.cuemate.com/tennis',
  },
  api: {
    ENDPOINT: 'https://api.prod.cuemate.com/api/v1',
  },
};

let config;
if (process.env && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'prod') {
  config = prod;
} else {
  config = dev;
}

console.debug('Using:', config.api.ENDPOINT);

export default {
  // Add common config values here
  ...config,
};

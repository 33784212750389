import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level3/on-court/advanced-metrics/AdvancedMetrics-1.png';
import view2 from '../../../../images/cuemate101/level3/on-court/advanced-metrics/AdvancedMetrics-2.png';
import view3 from '../../../../images/cuemate101/level3/on-court/advanced-metrics/AdvancedMetrics-3.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Advanced Metrics</h1>
          <h4>
            Technique metrics and coefficients help you focus on the efficiency of your stroke
            mechanics that improves your skills.
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">TECHNIQUE METRICS</h3>
          <ul>
            <li>Smoothness</li>
            <li>Impact Timing</li>
            <li>Head Speed</li>
          </ul>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Advanced Metrics 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Advanced Metrics 2" />
          </Col>
          <Col sm={5}>
            <h3 className="padding-top-25 blue">PERFORMANCE METRICS</h3>
            <ul>
              <li>Energy</li>
              <li>Power</li>
            </ul>
          </Col>
        </Row>
        <br />
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row className="padding-top-25">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">COEFFICIENTS</h3>
          <p>
            Coefficients measure the efficiency of the technique in producing the outcomes. They are
            excellent indicators for optimizing stroke technique.
          </p>
          <ul>
            <li>
              Spin cE: The amount of spin you generate for every Joule of energy you exert. A low
              value is typically due to fluid windshield wiper motion.
            </li>
            <li>
              Speed cE: The amount of ball speed you generate for every Joule of energy you exert. A
              low value is typically due to not exploiting the kinetic chain (starting with a unit
              turn and generating stroke acceleration from your legs and hips, ending with whipping
              motion).
            </li>
            <li>
              Spin-Speed cE: Your ability to simultaneously produce spin and ball speed. This is
              critical for producing deep powerful shots.
            </li>
          </ul>
        </Col>
        <Col sm={7}>
          <img src={view3} className="d-block mx-auto img-fluid" alt="Advanced Metrics 3" />
        </Col>
      </Row>
    </Container>
  );
}

export default function AdvancedMetrics() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />
      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Features.css';

export default function ClassEnsembleMetrics() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm={12}>
          <h1>Class Ensemble Metrics</h1>
          <h4>
            The collection of strokes that form a specific stroke class provide information about
            the class.{' '}
          </h4>

          <h3 className="padding-top-10">Stroke Class Quality Score</h3>
          <p>
            {' '}
            The metrics composite view combines the key metrics in a so-called radar chart. This
            screen makes it possible to get a picture of the overall stroke characteristics for a
            class in a single glance. Similar as for the metrics screen, the results are overlaid on
            the reference values.
          </p>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

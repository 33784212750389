import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';

import home from '../../../../images/screens/Home-StartSession.PNG';
import playerProfile from '../../../../images/screens/PlayerProfile.PNG';
import playmap from '../../../../images/screens/Play-StrokeMap.PNG';
import trainmap from '../../../../images/screens/TrainingLists.PNG';
import reportssession from '../../../../images/screens/SessionStrokeReport.PNG';
import review from '../../../../images/screens/Review-StrokeMap.PNG';

import metricoutcomes from '../../../../images/screens/ClassReport-Outcomes.PNG';
import trends from '../../../../images/screens/ClassReport-Trends.PNG';
import statusconnected from '../../../../images/screens/CueMateStatus.PNG';

import settings from '../../../../images/screens/Settings.PNG';

export default function AppFeatures() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Row>
        <Col sm="12">
          <h1>CueMate for iOS</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h4>
            The features within the CueMate app bring CueMate&apos;s
            <Link to="/key-concepts"> concepts</Link> to life. These features are categorized to be
            used during different times in play as follows:
          </h4>
          <ul>
            <li>
              <Link smooth to="/cuemate-ios#before-play">
                Before Play
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios#starting-play">
                Starting Play
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios#during-play">
                During Play
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios#after-play">
                After Play
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios#preferences">
                Preferences
              </Link>
            </li>
          </ul>
          <h4> Choose an app screen to learn how to make the most of the CueMate ecosystem.</h4>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 blueGrey" id="before-play">
        <Col sm={4}>
          <h2>Before Play:</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Link to="/cuemate-ios/home-screen">
            <img
              src={home}
              className="d-block mx-auto img-fluid img-border"
              alt="App home screen"
            />
          </Link>
          <h4 className="tracking padding-top-25">HOME SCREEN</h4>
          <p>Connect your sensor</p>
          <p>See your past sessions</p>
          <p>Start your session</p>
        </Col>

        <Col sm="4">
          <Link to="/cuemate-ios/player-profile">
            <img
              src={playerProfile}
              className="d-block mx-auto img-fluid img-border"
              alt="Player Profile"
            />
          </Link>
          <h4 className="tracking padding-top-25">PLAYER PROFILE</h4>
          <p>Get an overview of your sessions</p>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 blueGrey" id="starting-play">
        <Col sm={4}>
          <h2>Starting Play:</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Link to="/cuemate-ios/tagging">
            <img
              src={home}
              className="d-block mx-auto img-fluid img-border"
              alt="App home screen"
            />
          </Link>
          <h4 className="tracking padding-top-25">TAGGING</h4>
          <p>Add contextual information to your sessions and sets</p>
        </Col>
        <Col sm="4">
          <Link to="/cuemate-ios/train">
            <img src={trainmap} className="d-block mx-auto img-fluid img-border" alt="Train map" />
          </Link>
          <h4 className="tracking padding-top-25">TRAIN</h4>
          <p> Identify training goal progress as you play </p>
          <p> Organize and track training progress </p>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 blueGrey" id="during-play">
        <Col sm={4}>
          <h2>During Play:</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Link to="/cuemate-ios/play-screen">
            <img
              src={playmap}
              className="d-block mx-auto img-fluid img-border"
              alt="Stroke map feature"
            />
          </Link>
          <h4 className="tracking padding-top-25">PLAY SCREEN</h4>
          <p> Identify stroke improvement and decline as you play </p>
        </Col>
        <Col sm="4">
          <Link to="/cuemate-ios/metrics-screen">
            <img
              src={metricoutcomes}
              className="d-block mx-auto img-fluid img-border"
              alt="Metrics overview feature"
            />
          </Link>
          <h4 className="tracking padding-top-25">METRICS SCREEN</h4>
          <p>Assess outcome, technique and performance</p>
          <p>Customize your metrics</p>
        </Col>
        <Col sm="4">
          <Link to="/cuemate-ios/trends-screen">
            <img src={trends} className="d-block mx-auto img-fluid img-border" alt="Play map" />
          </Link>
          <h4 className="tracking padding-top-25">TRENDS SCREEN</h4>
          <p>Review training element trends</p>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 blueGrey" id="after-play">
        <Col sm={4}>
          <h2>After Play:</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Link to="/cuemate-ios/reports-screen">
            <img
              src={reportssession}
              className="d-block mx-auto img-fluid img-border"
              alt="Training list feature"
            />
          </Link>
          <h4 className="tracking padding-top-25">SESSION REPORTS SCREEN</h4>
          <p>Assess the stroke and training report of your sessions</p>
        </Col>
        <Col sm="4">
          <Link to="/cuemate-ios/review-screen">
            <img
              src={review}
              className="d-block mx-auto img-fluid img-border"
              alt="Live mode feature"
            />
          </Link>
          <h4 className="tracking padding-top-25">REVIEW SCREEN</h4>
          <p>Review and assess stroke performance</p>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 blueGrey" id="preferences">
        <Col sm={4}>
          <h2>Preferences:</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Link to="/cuemate-ios/status-screen">
            <img
              src={statusconnected}
              className="d-block mx-auto img-fluid img-border"
              alt="Trainig element feature"
            />
          </Link>
          <h4 className="tracking padding-top-25">STATUS SCREEN</h4>
          <p>Peek under the hood</p>
        </Col>
        <Col sm="4">
          <Link to="/cuemate-ios/settings">
            <img
              src={settings}
              className="d-block mx-auto img-fluid img-border"
              alt="Metrics overview feature"
            />
          </Link>
          <h4 className="tracking padding-top-25">SETTINGS SCREEN</h4>
          <p>Handle units, notifications and sounds</p>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { cuemateGET, cuematePOST } from '../../../../libs/dataAccess';
import onError from '../../../../libs/errorLib';

const getTennisStringBrands = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET('/gear/strings/brands', currentSession.accessToken.jwtToken);
  if (!response) {
    return [];
  }
  const { data } = response;
  return data;
};

const getTennisStringsByBrand = async (brand) => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET(
    `/gear/strings?brand=${brand}`,
    currentSession.accessToken.jwtToken
  );
  if (!response) {
    return [];
  }
  const { data } = response;
  return data;
};

const createUserStrings = async (racketId, strings) => {
  const currentSession = await Auth.currentSession();
  let response = {};
  try {
    response = await cuematePOST(
      `/users/gear/rackets/${racketId}/strings`,
      currentSession.accessToken.jwtToken,
      strings
    );
  } catch (error) {
    onError(error, true);
  }
  return response;
};

export default function AddStrings({ racketId, stringsAdded, onStringsAdded }) {
  const [modal, setModal] = useState(false);
  const [tennisStringsBrands, setTennisStringsBrands] = useState([]);
  const [tennisMainStringsByBrand, setTennisMainStringsByBrand] = useState([]);
  const [selectedMainsStringsBrand, setSelectedMainsStringsBrand] = useState('');
  const [selectedMainsStringsModel, setSelectedMainsStringsModel] = useState('');
  const [selectedMainsStringsTension, setSelectedMainsStringsTension] = useState('');
  const [isHybrid, setIsHybrid] = useState(false);
  const [tennisCrossesStringsByBrand, setTennisCrossesStringsByBrand] = useState([]);
  const [selectedCrossesStringsBrand, setSelectedCrossesStringsBrand] = useState('');
  const [selectedCrossesStringsModel, setSelectedCrossesStringsModel] = useState('');
  const [selectedCrossesStringsTension, setSelectedCrossesStringsTension] = useState('');
  const [selectedStringsNotes, setSelectedStringsNotes] = useState('');

  const toggle = async () => {
    if (!modal) {
      setTennisStringsBrands(await getTennisStringBrands());
    }
    setModal(!modal);
  };
  useEffect(() => {
    async function onLoad() {
      try {
        const tennisStringsByBrandsResponse = await getTennisStringsByBrand(
          selectedMainsStringsBrand
        );
        setTennisMainStringsByBrand(tennisStringsByBrandsResponse);
      } catch (error) {
        onError(error, true);
      }
    }
    if (selectedMainsStringsBrand.length > 0 && selectedMainsStringsBrand !== '-1') {
      onLoad();
    } else {
      setTennisMainStringsByBrand([]);
    }
  }, [selectedMainsStringsBrand]);

  useEffect(() => {
    async function onLoad() {
      try {
        const tennisStringsByBrandsResponse = await getTennisStringsByBrand(
          selectedCrossesStringsBrand
        );
        setTennisCrossesStringsByBrand(tennisStringsByBrandsResponse);
      } catch (error) {
        onError(error, true);
      }
    }
    if (selectedCrossesStringsBrand.length > 0 && selectedCrossesStringsBrand !== '-1') {
      onLoad();
    } else {
      setTennisCrossesStringsByBrand([]);
    }
  }, [selectedCrossesStringsBrand]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let strings = { isHybrid, notes: selectedStringsNotes };
      let mains = {
        brand: selectedMainsStringsBrand,
        model: selectedMainsStringsModel,
        tension: +selectedMainsStringsTension,
      };
      mains = Object.fromEntries(Object.entries(mains).filter(([, v]) => v !== '' && v !== '-1'));
      strings.mains = mains;
      if (isHybrid) {
        let crosses = {
          brand: selectedCrossesStringsBrand,
          model: selectedCrossesStringsModel,
          tension: +selectedCrossesStringsTension,
        };
        crosses = Object.fromEntries(
          Object.entries(crosses).filter(([, v]) => v !== '' && v !== '-1')
        );
        strings.crosses = crosses;
      }
      strings = Object.fromEntries(
        Object.entries(strings).filter(([, v]) => v !== '' && v !== '-1')
      );
      if (Object.keys(strings).length > 0) {
        strings.remoteId = Date.now() * 1000;
        const response = await createUserStrings(racketId, strings);
        if (Object.keys(response).length > 0) onStringsAdded(stringsAdded + 1);
      }
    } catch (error) {
      onError(error, true);
    }
    toggle();
  };

  const hybridStringingButton = (
    <FormGroup switch>
      <Input
        type="switch"
        checked={isHybrid}
        onChange={() => {
          setIsHybrid(!isHybrid);
        }}
      />
      <Label check>Hybrid Stringing</Label>
    </FormGroup>
  );

  const mainsBrandInput = (
    <FormGroup>
      <Label for="brand">Brand</Label>
      <Input
        id="brand"
        name="brand"
        type="select"
        onChange={(e) => setSelectedMainsStringsBrand(e.target.value)}
      >
        <option value="-1">Select an option</option>
        {tennisStringsBrands.length > 0
          ? tennisStringsBrands.map((option) => (
              // eslint-disable-next-line react/no-array-index-key
              <option key={option.key} value={option.value}>
                {option.value}
              </option>
            ))
          : null}
      </Input>
    </FormGroup>
  );

  const mainsModelInput = (
    <FormGroup>
      <Label for="model">Model</Label>
      <Input
        id="model"
        name="model"
        type="select"
        onChange={(e) => setSelectedMainsStringsModel(e.target.value)}
      >
        <option value="-1">Select an option</option>
        {tennisMainStringsByBrand.length > 0
          ? tennisMainStringsByBrand.map((option) => (
              <option key={option.key} value={option.value}>
                {option.value}
              </option>
            ))
          : null}
      </Input>
    </FormGroup>
  );

  const mainsTensionInput = (
    <FormGroup>
      <Label for="tension">Tension (lbs)</Label>
      <Input
        id="tension"
        name="tension"
        type="number"
        min={40}
        max={70}
        onChange={(e) => setSelectedMainsStringsTension(e.target.value)}
      />
    </FormGroup>
  );

  const crossesBrandInput = (
    <FormGroup>
      <Label for="brand">Brand</Label>
      <Input
        id="brand"
        name="brand"
        type="select"
        onChange={(e) => setSelectedCrossesStringsBrand(e.target.value)}
      >
        <option value="-1">Select an option</option>
        {tennisStringsBrands.length > 0
          ? tennisStringsBrands.map((option) => (
              // eslint-disable-next-line react/no-array-index-key
              <option key={option.key} value={option.value}>
                {option.value}
              </option>
            ))
          : null}
      </Input>
    </FormGroup>
  );

  const crossesModelInput = (
    <FormGroup>
      <Label for="model">Model</Label>
      <Input
        id="model"
        name="model"
        type="select"
        onChange={(e) => setSelectedCrossesStringsModel(e.target.value)}
      >
        <option value="-1">Select an option</option>
        {tennisCrossesStringsByBrand.length > 0
          ? tennisCrossesStringsByBrand.map((option) => (
              <option key={option.key} value={option.value}>
                {option.value}
              </option>
            ))
          : null}
      </Input>
    </FormGroup>
  );

  const crossesTensionInput = (
    <FormGroup>
      <Label for="tension">Tension (lbs)</Label>
      <Input
        id="tension"
        name="tension"
        type="number"
        onChange={(e) => setSelectedCrossesStringsTension(e.target.value)}
      />
    </FormGroup>
  );

  const stringNotesInput = (
    <FormGroup>
      <Label for="notes">Notes</Label>
      <Input
        id="notes"
        name="notes"
        type="textarea"
        onChange={(e) => setSelectedStringsNotes(e.target.value)}
      />
    </FormGroup>
  );

  return (
    <div>
      <Button
        color="primary"
        size="sm"
        outline
        onClick={() => {
          toggle();
        }}
      >
        Add Strings
      </Button>
      <Modal
        isOpen={modal}
        toggle={() => {
          toggle();
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          Add Strings
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>{hybridStringingButton}</Col>
            </Row>
            <hr />
            <Row>
              <legend>Mains</legend>
            </Row>
            <Row>
              <Col sm="4">{mainsBrandInput}</Col>
              <Col sm="4">{mainsModelInput}</Col>
              <Col sm="4">{mainsTensionInput}</Col>
            </Row>

            {isHybrid ? (
              <>
                <Row>
                  <legend>Crosses</legend>
                </Row>
                <Row>
                  <Col sm="4">{crossesBrandInput}</Col>
                  <Col sm="4">{crossesModelInput}</Col>
                  <Col sm="4">{crossesTensionInput}</Col>
                </Row>
              </>
            ) : null}

            <Row>
              <Col>{stringNotesInput}</Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={
              selectedMainsStringsBrand === '' ||
              selectedMainsStringsBrand === '-1' ||
              (isHybrid &&
                (selectedCrossesStringsBrand === '' || selectedCrossesStringsBrand === '-1'))
            }
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            Add Strings
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

AddStrings.propTypes = {
  racketId: PropTypes.string,
  stringsAdded: PropTypes.number,
  onStringsAdded: PropTypes.func,
};

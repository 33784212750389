import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level1/on-court/session-report/SessionStrokeReport-1.png';
import view2 from '../../../../images/cuemate101/level1/on-court/session-report/SessionStrokeReport-2.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Session Reports</h1>
          <p>
            CueMate Session Reports give players an at-a-glance view of their most important session
            data. There are two Report views – Session Training Report and Session Stroke Report.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">SESSION SUMMARY</h3>
          <p>
            Players can view an overall summary of the selected session with information such as
            strokes, duration, stroke quality, average sweet spot % and movement.
          </p>
          <p>
            The time scale dropdown enables to compare the metrics of the selected session with four
            different options from (previous session to previous 10 sessions)
          </p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Session Reports 1" />
        </Col>
      </Row>
      <br />
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Session Reports 2" />
          </Col>
          <Col sm={5}>
            <h3 className="padding-top-25 blue">CORE STROKES</h3>
            <p>
              The Session Stroke report helps you recognize your core strokes in the selected
              session. These core strokes determine to a large extent your game and are your
              strengths on the court.
            </p>
            <p>
              You can view usage data and three outcome metrics (Spin, Pace, Sweet Spot %) for them
              as well as averages for all forehand and all backhand strokes.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function SessionReports() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />

      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

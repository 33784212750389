import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Row } from 'reactstrap';

function Level1Description() {
  return (
    <Row>
      <Col sm="12">
        Learn how to use and benefit from CueMate’s basic features over your first 2-3 court
        sessions
        <ul>
          <li>Start & stop sessions</li>
          <li>Tag your sessions</li>
          <li>Learn to read the stroke map</li>
          <li>Learn the basic stroke metrics</li>
          <li>Learn how to interpret the stroke metrics</li>
          <li>Get a first baseline to track your progress</li>
        </ul>
      </Col>
    </Row>
  );
}

function Level1KeyConcepts() {
  return (
    <Row>
      <Col>
        <b>Key Concepts</b>
        <ul>
          <li>
            <Link to="/key-concepts/strokes-and-shots">Strokes and Shots</Link>
          </li>
          <li>
            <Link to="/key-concepts/stroke-map">Stroke Map</Link>
          </li>
          <li>
            <Link to="/key-concepts/stroke-metrics">Stroke Metrics</Link>
          </li>
          <li>
            <Link to="/key-concepts/sessions-and-sets">Sessions, Sets and Tagging</Link>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

function Level1Features() {
  return (
    <Row>
      <Col>
        <b>Features</b>
      </Col>
    </Row>
  );
}

function Level1Survey() {
  return (
    <li>
      <b>Post Session</b>
      <ul>
        <li>
          <Link
            to={{
              pathname: 'https://cuemate-tennis.typeform.com/to/LoKpNpS2',
            }}
            target="_blank"
          >
            Level 1 Survey
          </Link>
        </li>
      </ul>
    </li>
  );
}

export { Level1Description, Level1KeyConcepts, Level1Features, Level1Survey };

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import stroke from '../../../../images/concepts/stroke.png';
import shot from '../../../../images/concepts/shot.png';
import strokeAndShots from '../../../../images/concepts/stroke-and-shots-01.png';
import strokeOutcomes from '../../../../images/concepts/stroke-outcomes.png';

import './Concepts.css';

export default function StrokesAndShots() {
  return (
    <Container className="padding-bottom-25 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-bottom-50">
        <Col sm="12">
          {' '}
          <h1>Strokes and Shots</h1>
          <h3>
            The CueMate sensor measures the 3D racket motion. This data is used to analyze your
            strokes. Therefore, First, you need to distinguish between strokes and shots and their
            respective outcome, which are illustrated and described below.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          {' '}
          <h2>Overview</h2>
        </Col>
      </Row>
      <Row className="padding-bottom-50">
        <Col sm="6">
          {' '}
          <img src={stroke} className="d-block mx-auto img-fluid" alt="Stroke model" />{' '}
        </Col>
        <Col sm="6">
          {' '}
          <img src={shot} className="d-block mx-auto img-fluid" alt="Shot model" />{' '}
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <ul className="with-spacing">
            <li>
              Strokes are the movement made with your body and racket to strike the ball, and and
              return it to the opponent. Strokes are measured relative to the ball strike.{' '}
            </li>
            <li>
              Shots are the trajectory of the ball that result from the strike. Shots are measured
              relative to the court and opponent.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="padding-bottom-50">
        <Col sm={{ offset: 2, size: 8 }}>
          {' '}
          <img
            src={strokeAndShots}
            className="d-block mx-auto img-fluid"
            alt="Strokes and shots"
          />{' '}
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm="12">
          <h2 className="mb-4">Strokes</h2>
          <ul className="with-spacing">
            <li>
              Strokes are defined by the path of your racket, created by your arm and body.
              Depending how the racket strikes the ball, and the incoming ball motion, creates
              specific impact conditions that result in two primary outcomes on the ball as it
              leaves the strings: it changes the spin and speed of the ball. These outcomes, in turn
              then determine the ball trajectory.
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 3, size: 6 }}>
          <img src={strokeOutcomes} className="d-block mx-auto img-fluid" alt="Strokes outcomes" />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm="12">
          <h3 className="mb-4">Strokes Classes</h3>
          <p>
            CueMate breaks down tennis ground strokes into 18 unique stroke classes. Each stroke
            class is categorized by Backhand/Forehand, Topspin/Flat/Slice, and Light/Med/Hard.
            Stroke class categorization enables you to see what areas of your repertoire need
            improvement and why. The full picture of all 18 stroke classes is known as the Stroke
            Map. Stroke classes and the Stroke Map will be described in more details under their own
            sections.
          </p>
          <h3 className="mb-4">Strokes Metrics</h3>
          <p>
            The stroke classes represent the family of strokes that have similar technique,
            outcomes, and conditions. Combining strokes into classes makes it possible to analyze
            your skills and performance more precisely and in a way that reflects how the brain
            organizes the movement performance.{' '}
          </p>
          <p>
            CueMate computes <Link to="cuemate-ios/metrics">metrics</Link> for each stroke outcome
            on the ball (spin and ball, speed), the strike quality (sweet spot percentage, impact
            precision, impact timing), as well as the stroke technique (timing, consistency, etc.),
            and physical performance (energy, power).
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm={{ offset: 3, size: 6 }}>
          <img src={shot} className="d-block mx-auto img-fluid" alt="Shots" />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col sm="12">
          <h2 className="mb-4">Shots</h2>
          <p>
            {' '}
            The shot characteristics are determined by the imparted pace and spin on the ball during
            the strike, and the player’s location and orientation relative to the court.
          </p>
          <p>
            The shot has its own outcomes, which can be described by the impact location on the
            court and its bounce conditions that will depend on the remaining pace of the ball and
            spin as it hit the ground.
          </p>
          <p>
            CueMate currently doesn’t track the ball relative to the court. iCueMotion has
            camera-based tools that are used to analyze and develop the CueMate algorithms. Some of
            these advanced features will be available in the future for users who want more
            comprehensive capabilities.
          </p>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

export const ClubType = {
  INVITE_ONLY: 'invite-only',
  OPEN: 'open',
};

export const GroupStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const GroupType = {
  COACHING: 'admin',
  CLUBS: 'club',
  REF_RANGE: 'refRanges',
};

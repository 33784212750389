import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import icon1 from '../../../../images/concepts/icons/strokesandshots.png';
import icon2 from '../../../../images/concepts/icons/setsandsessions.png';
import icon3 from '../../../../images/concepts/icons/assessmentlevels.png';

// import metricstiming from '../../../../images/concepts/metrics-timing.png';
// import strokephasesoverview from '../../../../images/concepts/stroke-phases-overview.png';
// import backlooptype from '../../../../images/concepts/backloop-type.png';
// import arclength from '../../../../images/concepts/arc-length.png';

import '../Features.css';

export default function MetricsDetailed() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Row>
        <Col sm="12">
          <h1>CueMate Metrics</h1>
          <h3>
            CueMate Metrics are grouped into three major categories. Click on a category to learn
            more about it.
          </h3>
        </Col>
      </Row>

      <Row className="padding-top-10">
        <p>Learn more about:</p>
        <Col sm="1">
          <Link to="stroke-class">
            <img
              src={icon1}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Stroke Class Metrics"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> #1 </h4>
          <Link to="stroke-class">
            <h3>Stroke Class Metrics</h3>
          </Link>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="1">
          <Link to="repertoire">
            <img
              src={icon2}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Repertoire Metrics"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> #2 </h4>
          <Link to="repertoire">
            <h3>Repertoire Metrics</h3>
          </Link>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="1">
          <Link to="class-ensemble">
            <img
              src={icon3}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Class Ensemble Metrics"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> #3 </h4>
          <Link to="class-ensemble">
            <h3>Class Ensemble Metrics</h3>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import startSet from '../../../../images/screens/StartSet.PNG';
import startSetCoach from '../../../../images/screens/StartSet-Coach.PNG';
import startSetPartner from '../../../../images/screens/StartSet-Partner.PNG';

import generalCues from '../../../../images/screens/StartSet-GeneralCues.PNG';
import trainingCues from '../../../../images/screens/StartSet-TrainingCues.PNG';
import trainingCuesPinnedGoals from '../../../../images/screens/StartSet-TrainingCues-PinnedGoals.PNG';
import richTagging from '../../../../images/screens/StartSet-RichTagging.PNG';

import Breadcrumbs from '../../../../components/Breadcrumbs';

import './AppFeatures.css';

export default function Tagging() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm={6}>
          <h2 className="blueGrey">Tagging</h2>
          <p>
            {' '}
            Before starting play it is possible to tag an activity. Tagging enables to create rich
            contextual information about the session and sets. This information can be useful later
            when reviewing data.{' '}
          </p>
          <p>
            Tagging works through successive screens, each focusing on a particular aspect of the
            activity.{' '}
          </p>
          <ul>
            <li>Session Partner Type</li>
            <li>Session Partner Name (if Coach or Partner)</li>
            <li>Set Play Type</li>
            <li>Set Cue Sound</li>
            <li>Set Cue Type</li>
            <li>Rich Tagging</li>
          </ul>
        </Col>
        <Col sm={6}>
          <img
            src={startSet}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Set Tagging"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="padding-top-50">
          <img src={startSetCoach} className="d-block mx-auto img-fluid img-border" alt="Coach" />
          <h4 className="tracking padding-top-25">Coach</h4>
        </Col>
        <Col sm="6" className="padding-top-50">
          <img
            src={startSetPartner}
            className="d-block mx-auto img-fluid img-border"
            alt="Partner"
          />
          <h4 className="tracking padding-top-25">Partner</h4>
        </Col>
      </Row>

      <Row>
        <Col sm="6" className="padding-top-50">
          <img
            src={generalCues}
            className="d-block mx-auto img-fluid img-border"
            alt="General Cues"
          />
          <h4 className="tracking padding-top-25">General Cues</h4>
        </Col>
      </Row>

      <Row>
        <Col sm="6" className="padding-top-50">
          <img
            src={trainingCues}
            className="d-block mx-auto img-fluid img-border"
            alt="Training Cues"
          />
          <h4 className="tracking padding-top-25">Training Cues</h4>
        </Col>
        <Col sm="6" className="padding-top-50">
          <img
            src={trainingCuesPinnedGoals}
            className="d-block mx-auto img-fluid img-border"
            alt="Training Cues with Pinned Training Goals"
          />
          <h4 className="tracking padding-top-25">Training Cues - Pinned Goals</h4>
        </Col>
      </Row>

      <Row>
        <Col sm="6" className="padding-top-50">
          <img
            src={richTagging}
            className="d-block mx-auto img-fluid img-border"
            alt="Rich Tagging"
          />
          <h4 className="tracking padding-top-25">Rich Tagging</h4>
        </Col>
      </Row>

      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

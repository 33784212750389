import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import repertoireAssessment from '../../../../images/concepts/Repertoire_assessment_functions_v2.png';
import strokeMapAssessments from '../../../../images/concepts/stroke_map_assessments.png';

// CSS
import '../Train.css';

function HeaderContent() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1 className="blueGrey">Hierarchical Assessment Overview</h1>
          <h4 className="mb-3">
            CueMate computes a wide range of metrics to enable a detailed and comprehensive
            assessment.
          </h4>
          <p>
            Since movement behavior in activities like tennis are complex, the assessment and
            diagnostics are best organized hierarchically.
          </p>
          <p>
            Metrics covers a wide range of aspects of stroke performance—from specific features of
            the stroke classes—to the more wholistic characteristics of the stroke repertoire.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={repertoireAssessment}
            className="d-block mx-auto img-fluid img-border"
            alt="Repertoire Assessment Functions "
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="padding-top-10 blueGrey">Stroke Class Metrics</h3>
          <p>
            Stroke Class Metrics describe how well you are doing within each stroke class across a
            range of characteristics ranging from consistency to various aspect of performance.
          </p>
          <p>
            Stroke Class Quality Score provides a quality assessment at the level of each stroke
            class. The score is a composite metric that combines many attributes. It makes it
            possible to understand how the metrics contribute and which one represents a key
            indicator in the class overall performance.{' '}
          </p>
          <p>
            A good way to understand the composite nature of the Class Quality Score is the radar
            plot shown here. As you can see it combines multiple metrics to provide an assessment of
            the overall quality of the stroke.{' '}
          </p>
          <h3 className="padding-top-10 blueGrey">Stroke Map Metrics</h3>
          <p>
            The Stroke Map is a representation of the repertoire or stroke vocabulary. Stroke Map
            Metrics provide a more holistic assessment.{' '}
          </p>
          <p>
            Your strokes distribution across the repertoire and some of their key characteristics
            such as success rate spin.
          </p>
          <p>These distribution reflects your abilities and also identify your style of play.</p>
        </Col>
      </Row>
    </Container>
  );
}

function Content() {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h2 className="padding-top-10 blueGrey">Stroke Class Assessment</h2>
          <p>
            The stroke class assessment is best performed leveraging the various scoring functions
            since these scores combine the metrics in associated groups that make their
            interpretation easier.
          </p>
          <p>
            Recall that CueMate also incorporates data from other players to determine reference
            ranges that help interpret and identify your specific weaknesses, as well as set
            realistic expectations for skill levels attainable through deliberate training.
          </p>
          <h3 className="blueGrey">Composite Scores</h3>
          <p>
            Composite scores refer to any stroke class score that combines several metrics for that
            stroke class, and therefore gives an idea of the overall quality of that stroke class.
          </p>
          <p>
            Combining the metrics to determine overall rating that diagnostics and training goals.
          </p>
          <h2 className="padding-top-10 blueGrey">Stroke Map Assessment</h2>
          <p>
            The repertoire describes the vocabulary of strokes used in your game. The composition of
            the stroke map already provides key insights that are useful for training and game
            strategy.
          </p>
          <p>
            CueMate takes performance assessments a significant step forward by accounting for your
            stroke repertoire. The stroke repertoire is like a vocabulary, to be versatile you need
            a wide range of stroke types. These enable to support the various conditions and shot
            types to control the ball on the court.
          </p>
          <p>
            The most fundamental aspect of the assessment, therefore, are obtained from the
            repertoire overall characteristics, including how strokes distribute across the range of
            intensities and spin types, and how reliably you can perform each class.
          </p>
          <p>CueMate provides the following metrics for the repertoire level assessments.</p>
          <ul className="with-spacing">
            <li>Forehand/backhand ratio</li>
            <li>Spin Differentiation</li>
            <li>Mean Intensity Level</li>
          </ul>
          <p>
            These metrics basically help you identify areas of strengths and weaknesses in your
            stroke vocabulary, including.{' '}
          </p>
          <ul className="with-spacing">
            <li>What strokes am I using the most (see Core Strokes)</li>
            <li>Where are my gaps in the repertoire (in terms of class usage, outcomes, etc.)</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={strokeMapAssessments}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Map Assessments"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="padding-top-10 blueGrey">Forehand/Backhand Ratio</h3>
          <p>(in development)</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="padding-top-10 blueGrey">Spin Differentiation</h3>
          <p>(in development)</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="padding-top-10 blueGrey">Mean Intensity level</h3>
          <p>(in development)</p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="padding-top-10 blueGrey">Core Strokes</h3>
          <p>
            To help deal with the large amount of information, the concept of Core Strokes (link)
            provides a great way to focus on the essentials.
          </p>
          <p>When reviewing your core strokes:</p>
          <ul className="with-spacing">
            <li>
              First check the consistency of their performance, in particular the Sweetspot
              percentage and Strike Quality scores. If one or more of your core strokes are not
              reliable, start by focusing on improving their consistency before focusing on their
              outcomes (ball speed and spin).
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h2 className="padding-top-10 blueGrey">Stroke Class Assessments</h2>
          <h3 className="padding-top-10 blueGrey">Ensemble Metrics</h3>
          <p>
            The collection of strokes that form a specific stroke class provide information about
            the class.
          </p>
          <p>Combining strokes into an ensemble makes it possible to determine patterns.</p>
          <h3 className="padding-top-10 blueGrey">Stroke Class Quality Score</h3>
          <p>
            The metrics composite view combines the key metrics in a so-called radar chart. This
            screen makes it possible to get a picture of the overall stroke characteristics for a
            class in a single glance.
          </p>
          <p>
            Similar as for the metrics screen, the results are overlaid on the reference values.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default function StrokeMetrics() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <HeaderContent />
      <Content />

      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

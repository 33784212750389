import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import './GatedMainHome.css';

export default function GatedWelcomeHero() {
  return (
    <Container fluid className="padding-bottom-50 animated fadeIn underNav">
      <Row className="padding-top-50 padding-bottom-100 humanaug">
        <Col sm={7} />
        <Col sm={4}>
          <h1 className="padding-top-25 text-left">
            {' '}
            Improve Your Tennis Skills with Augmented Training{' '}
          </h1>
          <h3 className="padding-top-50 blueGrey padding-bottom-50 text-left linespacing black">
            CueMate training system is designed to harness your brain and body&apos;s natural
            learning mechanisms to help you improve your skills faster and more efficiently.
          </h3>
          <Row>
            <Col sm={6}>
              <Link to="/cuemate101" className="btn btn-primary btn-block">
                {' '}
                Start using CueMate{' '}
              </Link>
            </Col>
            <Col sm={6}>
              <Link to="/download-app/XD" className="btn btn-primary btn-block">
                {' '}
                Download the App{' '}
              </Link>
            </Col>
          </Row>
        </Col>
        <Col sm={1} />
      </Row>
    </Container>
  );
}

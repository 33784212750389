import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import warmup5 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-5.png';

import baseline1 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-1.png';
import baseline4 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-4.png';

import bhrally1 from '../../../../images/cuemate101/level2/set5/bh-rally-1.png';
import bhfhrally1 from '../../../../images/cuemate101/level2/set6/bhfh-rally-1.png';

import '../AppFeatures.css';

export default function L2S7Progress() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SET 7: PROGRESS EVALUATION</h2>
          <h3>Repeat Baseline Set to Measure Performance Gains</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="4">
          <ol>
            <li>Press + to start a new Set</li>
            <img
              src={baseline1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="2">
            <li>Enter play conditions</li>
            <span className="blue">Technique, No Cue, Tags ON</span>
            <img
              src={bhrally1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">Rallying, Intensity Level 2, Middle, Baseline</span>
          <img
            src={bhfhrally1}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="3">
            <li>Begin Set</li>
            <p className="blue">
              Rally down +-50 balls the middle from the baseline
              <br />
              Focus on hitting with only Forehand Topspin and Backhand Topspin strokes
              <br />
              While generating topspin, try to maintain pace to and stroke success to keep shots in
              shots in play
            </p>
          </ol>
        </Col>
        <Col sm="5">
          <br />
          <img src={baseline4} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="4">
            <li>Press Pause when finished</li>
            <img
              src={warmup5}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/review-stroke-map">
            <h3>
              Next: Review: Stroke Maps <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React, { Component } from 'react';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { API, Auth } from 'aws-amplify';
import LoaderButton from '../../../components/LoaderButton';

export default class SignupFriend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      shouldShowThankNote: false,
    };

    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { firstName, lastName, email } = this.state;
    const submitParams = {
      first_name: firstName,
      last_name: lastName,
      email,
    };
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      submitParams.accessToken = currentUser.signInUserSession.accessToken.jwtToken;
      await API.post('cuemate-tennis', '/referral', {
        body: submitParams,
      });
      this.setState({ shouldShowThankNote: true });
    } catch (error) {
      console.log(error);
    }
  }

  validateForm() {
    const { firstName, lastName, email } = this.state;
    return firstName.length > 0 && lastName.length > 0 && email.length > 0;
  }

  renderThankNote() {
    const { currentUser } = this.state;
    return (
      <Container className="padding-bottom-25 animated fadeIn">
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
            <h1>Thanks {currentUser}!</h1>
            <hr className="colorgraph" />
            <h3 className="padding-top-50">
              Your friend&apos;s information has been forwarded to the team.
            </h3>
          </Col>
        </Row>
      </Container>
    );
  }

  renderSubmitFriendForm() {
    const { firstName, lastName, email, isLoading } = this.state;
    return (
      <Container>
        <Row className="padding-bottom-25 animated fadeIn">
          <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} xs="12">
            <Form onSubmit={this.handleSubmit}>
              <h2>Refer-a-Friend</h2>
              <hr className="colorgraph" />
              <Row>
                <Col xs="12" sm="6" md="6">
                  <FormGroup>
                    <Input
                      type="text"
                      name="first_name"
                      id="firstName"
                      className="form-control input-lg"
                      placeholder="First Name"
                      autoFocus
                      required
                      value={firstName}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="6">
                  <FormGroup>
                    <Input
                      type="text"
                      name="last_name"
                      id="lastName"
                      className="form-control input-lg"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control input-lg"
                  placeholder="Email Address"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <hr className="colorgraph" />
              <Row>
                <Col xs="12" md="12" className="padding-bottom-25">
                  <LoaderButton
                    type="submit"
                    text="Submit"
                    color="primary"
                    size="lg"
                    block
                    loadingText="Submitting information..."
                    isLoading={isLoading}
                    disabled={!this.validateForm()}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { shouldShowThankNote } = this.state;
    return shouldShowThankNote ? this.renderThankNote() : this.renderSubmitFriendForm();
  }
}

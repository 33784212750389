import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Logo from '../../images/landingpage/Cue_Mate_Tennis_Logo.svg';
import iCueMotionLogo from '../../images/landingpage/IcueMotion_Logo.svg';
import Pdf from '../ungated/signup/CueMate-Terms-and-Conditions.pdf';
import './GatedFooter.css';

// eslint-disable-next-line react/display-name
export default function GatedFooter() {
  return (
    <footer className="black-bg footer">
      <Container fluid className="black-bg">
        <Row className="small-font">
          <Col sm={{ size: 2 }} className="text-center my-auto">
            <img alt="CueMate Logo" src={Logo} />
          </Col>
          <Col sm={{ size: 2 }} className="text-center my-auto">
            <a href="https://icuemotion.com/" target="_blank" rel="noopener noreferrer">
              <img alt="iCueMotion Logo" src={iCueMotionLogo} />
            </a>
          </Col>
          <Col sm={{ size: 2, offset: 2 }} className="text-left">
            <ul className="list-unstyled">
              <li className="blue-font font-VR-DemiBold">CUEMATE BETA</li>
              <li className="text-white">
                <Link to="/refer-a-friend" className="text-white font-VR-Regular">
                  Refer a Friend
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={{ size: 2 }} className="text-left">
            <ul className="list-unstyled">
              <li className="blue-font font-VR-DemiBold">CUEMATE RESOURCES</li>
              <li className="text-white">
                <Link to="/sitemap" className="text-white font-VR-Regular">
                  Sitemap
                </Link>
              </li>
            </ul>
          </Col>
          <Col sm={{ size: 2 }} className="text-left">
            <ul className="list-unstyled">
              <li className="blue-font font-VR-DemiBold">CONTACT</li>
              <li className="text-white">
                <Link to="/contact" className="text-white font-VR-Regular">
                  Contact Us
                </Link>
              </li>
              <li className="text-white">
                <Link to="/privacy" className="text-white font-VR-Regular">
                  Privacy
                </Link>
              </li>
              <li className="text-white">
                <a
                  href={Pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white font-VR-Regular"
                >
                  Terms
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12" className="text-center text-white font-VR-Regular">
            <p>
              {' '}
              CONFIDENTIAL &copy; {`${new Date().getFullYear()} `} iCueMotion LLC. Patent Pending
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

/* eslint-env browser */
import React, { Component } from 'react';
import { Alert, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ListMyProfile from './UserInfo';
import UserNotificationGroups from './UserNotificationGroups';
import MyActivityProfile from './MyActivityProfile';

const USER_PROFILE_TABS = {
  MY_PROFILE: 'myProfile',
  MOBILE_NOTIFICATIONS: 'mobileNotifications',
  MY_ACTIVITY_PROFILE: 'myActivityProfile',
  MY_TRAINING: 'myTraining',
};

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ableToGoBack: false,
      activeTab: USER_PROFILE_TABS.MY_PROFILE,
    };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    try {
      const { location } = window;
      const query = new URLSearchParams(location.search);
      this.setState({ ableToGoBack: query.has('goback') });
    } catch (error) {
      console.error(error);
    }
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { ableToGoBack, activeTab } = this.state;
    return (
      <Container className="animated fadeIn">
        {ableToGoBack && (
          <Row>
            <Col xs="12" sm="12" md="12">
              <Alert color="warning">
                {`We'd love to get to know you better! Please update your profile now, esp. handedness and activity profile.`}
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm="3">
            <Nav tabs vertical pills>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === USER_PROFILE_TABS.MY_PROFILE,
                  })}
                  onClick={() => {
                    this.toggle(USER_PROFILE_TABS.MY_PROFILE);
                  }}
                >
                  My Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === USER_PROFILE_TABS.MY_TRAINING,
                  })}
                  onClick={() => {
                    this.toggle(USER_PROFILE_TABS.MY_TRAINING);
                  }}
                >
                  My Training Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === USER_PROFILE_TABS.MOBILE_NOTIFICATIONS,
                  })}
                  onClick={() => {
                    this.toggle(USER_PROFILE_TABS.MOBILE_NOTIFICATIONS);
                  }}
                >
                  Mobile Notifications
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="9">
            <TabContent activeTab={activeTab}>
              <TabPane tabId={USER_PROFILE_TABS.MY_PROFILE}>
                <ListMyProfile />
              </TabPane>
              <TabPane tabId={USER_PROFILE_TABS.MOBILE_NOTIFICATIONS}>
                <UserNotificationGroups />
              </TabPane>
              <TabPane tabId={USER_PROFILE_TABS.MY_TRAINING}>
                <MyActivityProfile />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    );
  }
}

import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import './AppFeatures.css';

import trendsoverview from '../../../../images/screens/trends/ClassReport-Trends-ABC.png';
import trendsA from '../../../../images/screens/trends/ClassReport-Trends-A.png';
import trendsB from '../../../../images/screens/trends/ClassReport-Trends-B.png';
import trendsC from '../../../../images/screens/trends/ClassReport-Trends-C.png';
import trends from '../../../../images/screens/ClassReport-Trends.PNG';
import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';
import labelC from '../../../../images/screens/labels/C.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';

export default function TrendsScreen() {
  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm="12">
          <h1>Trends</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="padding-top-50">
          <img
            src={trendsoverview}
            className="d-block mx-auto img-fluid"
            alt="Trends overview screen"
          />
        </Col>
        <Col sm="4" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/trends-screen#time-scale">
                  Time Scale
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/trends-screen#trend-lines">
                  Trend Line, Averages, <br />
                  Training Goal
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/trends-screen#training-goal">
                  Training Goal Addition
                </Link>
              </h3>
            </Media>
          </Media>
          <p className="padding-top-25">
            The Trends screen provides a comprehensive view of a given training element (stroke +
            metric) over time.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100" id="time-scale">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-header-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h2>Time Scale</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Similar to the <Link to="/cuemate-ios/metrics-screen#time-scale">Time Filter</Link>, the
            Time Scale enables you to review metric results within 5 different time scales.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={trendsA} className="d-block mx-auto img-fluid" alt="Trends timescale screen" />
        </Col>
      </Row>

      <Row className="padding-top-100" id="trend-lines">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-header-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h2>Trend Line, Averages, Training Goal</h2>
            </Media>
          </Media>
          <h3 className="padding-top-50">Trend Line and Trend Averages</h3>
          <p className="">
            The trend line helps you evaluate improvement and decline over time. Y-axis is the
            metric unit (e.g., MPH for Pace); X-axis is stroke count. The time range adjusts based
            on the time scale selected.
          </p>
          <p>
            In the below example, the user has selected the last 5 sessions for FTH Ball Spin. The
            average spin for each session is shown as a thin grey horizontal lines. The black line
            represents spin outcomes over all five sessions.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={trendsB} className="d-block mx-auto img-fluid" alt="Trends" />
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25">
          <figure className="figure">
            <img
              src={trends}
              className="d-block mx-auto img-fluid img-border"
              alt="Trends screen"
            />
            <figcaption className="figure-caption">
              The Trends screen provides a comprehensive view of a given training element (stroke +
              metric) over time.
            </figcaption>
          </figure>
        </Col>
      </Row>

      <Row>
        <Col sm={12} className="padding-top-25">
          <h3 className="padding-top-50">Training Goal</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="">
          <p className="">
            The blue horizontal line is your current goal. This will only appear if you have an
            active relevant training goal.
          </p>
        </Col>
        <Col sm={6} className="">
          <p className="">
            Targets appear along the trend line where training goals have been achieved.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50 padding-bottom-100" id="training-goal">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-header-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h2>Training Goal Addition</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Select ADD TRAINING GOAL to add the relevant training element (e.g., FTH Pace) to your
            training list. Once selected, a pop-up will appear to confirm the goal value to
            benchmark against.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={trendsC} className="d-block mx-auto img-fluid" alt="Training Goal Activation" />
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review1 from '../../../../images/cuemate101/level1/fundamentals/review/review-1.png';
import review2 from '../../../../images/cuemate101/level1/fundamentals/review/review-2.png';
import review3 from '../../../../images/cuemate101/level1/fundamentals/review/review-3.png';

import '../AppFeatures.css';

export default function ReviewStrokeMap() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>REVIEW: STROKE MAP</h2>
          <h3>SESSION STROKE MAP vs SET STROKE MAPS</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={{ size: 8 }}>
          <ol>
            <li>Review your Stroke Map</li>
            <img src={review1} className="d-block mx-auto img-fluid" alt="Review Stroke Map" />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <img
            src={review2}
            className="d-block mx-auto img-fluid img-border"
            alt="Freeplay, No Cue, Tags ON"
          />
        </Col>
        <Col sm="6">
          <p className="blue">
            Most frequent stroke classes: <br />
            Forehand Topspin Medium (124) <br />
            Backhand Flat Light (63) <br />
            Backhand Topspin Hard (42) <br />… etc. <br />
            Total Strokes: 360 <br />
            Total Time: 37 minutes 42 sec <br />
            Impact Success Rate: 89%
          </p>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="8">
          <ol start="2">
            <li>‘Browse’ to view Stroke Maps for each set</li>

            <img
              src={review3}
              className="d-block mx-auto img-fluid img-border"
              alt="‘Browse’ to view Stroke Maps for each set"
            />
          </ol>
        </Col>
        <Col sm="3">
          <br />
          <p className="blue">Before and After Cues:</p>
          <p className="blue">
            Compare the ‘Baseline’ Stroke Map (Set 2) with the ‘Post-Cue’ Stroke Map (Set 6) to see
            if there was any difference in the distribution of stroke classes.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate101/level1/court-exercise/review-outcome-metrics">
            <h3>
              Next: Review Outcome Metrics <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import leaderboard from '../../../../images/web-features/leaderboard.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import './WebFeatures.css';

export default function WebLeaderboard() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Leaderboard</h2>
        </Col>
      </Row>

      <Row>
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={leaderboard}
            className="d-block mx-auto
          img-fluid img-fluid img-border"
            alt="Leaderboard "
          />
          <figcaption className="figure-caption">Leaderboard</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-web">
            <h3>
              Back to: CueMate for Web <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

/* eslint-env browser */
function onError(error, showAlert = false) {
  let errorInfo = {};
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }
  if (showAlert) {
    alert(message);
  }
}

export default onError;

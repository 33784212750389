/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Auth, Storage } from 'aws-amplify';
import Avatar from 'react-avatar';
import AvatarEditor from 'react-avatar-editor';

import { cuematePUT } from '../../../../libs/dataAccess';

import './UserAvatar.css';

const converterDataURItoBlob = (dataURI) => {
  let byteString;
  let mimeString;
  let ia;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = encodeURI(dataURI.split(',')[1]);
  }
  // separate out the mime component
  // eslint-disable-next-line prefer-destructuring, prefer-const
  mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  // eslint-disable-next-line prefer-const
  ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // eslint-disable-next-line no-undef
  return new Blob([ia], { type: mimeString });
};

export default function UserAvatar({ src, name }) {
  const editorRef = useRef(null);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: src,
  });
  const [loading, setLoading] = useState(false);
  const [saveError, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSlider = (event) => {
    setPicture({
      ...picture,
      zoom: parseFloat(event.target.value),
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
    setLoading(false);
    setError('');
    setSuccess(false);
  };

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      if (editorRef.current) {
        const fileKey = 'avatar/avatar.jpg';
        const canvasScaled = editorRef.current.getImageScaledToCanvas();
        const croppedImg = canvasScaled.toDataURL();

        const currentSession = await Auth.currentSession();
        const { accessToken, idToken } = currentSession;

        await cuematePUT('/userprofile', accessToken.jwtToken, {
          avatar: `private/${idToken.payload.preferred_username}/${fileKey}`,
        });
        await Storage.vault.put(fileKey, converterDataURItoBlob(croppedImg), {
          contentType: 'image/jpg',
        });

        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
          croppedImg,
        });
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
    } catch (error) {
      setError('Error saving avatar. Please try again later.');
      setLoading(false);
      setTimeout(() => {
        setError('');
      }, 3000);
    }
  }, [editorRef]);

  const handleFileChange = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };

  return (
    <div>
      {success && <Alert>Avatar saved successfully!</Alert>}
      <Avatar size="200px" name={name} src={picture.croppedImg} className="mb-2" />
      <br />
      <label htmlFor="avatar" className="custom-file-upload">
        Update Avatar
      </label>
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/*"
        onChange={handleFileChange}
        onClick={(event) => {
          // eslint-disable-next-line no-param-reassign
          event.target.value = null;
        }}
      />
      {picture.cropperOpen && (
        <Modal isOpen={picture.cropperOpen}>
          <ModalHeader>Update avatar</ModalHeader>
          <ModalBody>
            {saveError && <Alert color="danger">{saveError}</Alert>}
            <div
              style={{
                width: '100%',
                height: '100%',
                background: 'rgba(200,200,200,.8)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <AvatarEditor
                ref={editorRef}
                image={picture.img}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                rotate={0}
                scale={picture.zoom}
              />
              <label htmlFor="zoom" />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <label
                  style={{
                    marginRight: 10,
                  }}
                >
                  Zoom
                </label>
                <input
                  id="zoom"
                  min={1}
                  max={10}
                  onChange={handleSlider}
                  step={0.1}
                  type="range"
                  value={picture.zoom}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleCancel} disabled={loading}>
              Cancel
            </Button>
            <Button color="primary" disabled={loading} onClick={handleSave}>
              {loading ? (
                <div>
                  <Spinner size="sm" />
                  <span> Saving</span>
                </div>
              ) : (
                'Save'
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
}

UserAvatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
};

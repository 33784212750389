import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import { Level1Description, Level1KeyConcepts, Level1Features } from './L1ToC';

export default function Level1() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm="12">
          <h2>1. Essentials on the Court</h2>
        </Col>
      </Row>
      <Level1Description />
      <Level1KeyConcepts />
      <Level1Features />
    </Container>
  );
}

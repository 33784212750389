import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Form,
  Button,
  FormGroup,
  Input,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import LoaderButton from '../../../../components/LoaderButton';
import { cuematePUT } from '../../../../libs/dataAccess';
import { FriendshipType, Relationship } from '../SocialConstants';
import UserSearch from '../UserSearch';
import './AddFriendForm.css';

const SEARCH_URL = '/users/social/search';

function AddFriendForm({ onManageFriendsClick, friendshipType = 'FRIEND' }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isSendingFriendRequest, setIsSendingFriendRequest] = useState(false);
  const [shouldShowFriendRequestSent, setShouldShowFriendRequestSent] = useState(false);
  const [friendRequestError, setFriendRequestError] = useState(false);

  const toggleModal = () => setModalOpen((prevState) => !prevState);

  const handleSendRequest = async (friendUsername) => {
    setIsSendingFriendRequest(true);
    const url = '/users/social/friendships';
    try {
      const currentSession = await Auth.currentSession();
      const data = {
        friendUsername,
        status: 1,
        type: FriendshipType[friendshipType],
      };

      const response = await cuematePUT(url, currentSession.accessToken.jwtToken, data);
      if (response.code) {
        setFriendRequestError(true);
        // Hide the error message after 2 seconds
        setTimeout(() => {
          setFriendRequestError(false);
        }, 2000);
      } else {
        setShouldShowFriendRequestSent(true);
        // Hide the "Friend request sent" message after 2 seconds
        setTimeout(() => {
          setShouldShowFriendRequestSent(false);
        }, 2000);
      }
      setIsSendingFriendRequest(false);
      toggleModal();
    } catch (err) {
      console.error(err);
    }
  };

  const modalFriendRequest = (
    <Modal isOpen={modalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{`Add as a ${Relationship[friendshipType]}`}</ModalHeader>
      <ModalBody>{`Do you want to add ${selectedUser} as a ${Relationship[friendshipType]}?`}</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => handleSendRequest(selectedUser)}
          disabled={isSendingFriendRequest}
        >
          {isSendingFriendRequest ? <Spinner size="sm" /> : 'Send Request'}
        </Button>{' '}
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  const handleUserClick = (username) => {
    setSelectedUser(username);
    toggleModal();
  };

  return (
    <>
      {modalFriendRequest}
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormGroup row>
          <Col sm={12}>
            <Input placeholder="EMAIL" bsSize="sm" disabled />
          </Col>
        </FormGroup>
        <Label>
          <small>OR</small>
        </Label>
        <UserSearch searchURL={SEARCH_URL} onUserClick={handleUserClick} />
        {friendRequestError && <Alert color="danger">Error Sending Request</Alert>}
        {shouldShowFriendRequestSent && (
          <Alert color="success">{`${Relationship[friendshipType]} Request Sent!`}</Alert>
        )}
        {onManageFriendsClick !== undefined && (
          <FormGroup row>
            <Col sm={12}>
              <LoaderButton
                onClick={onManageFriendsClick}
                text={`MANAGE ${Relationship[friendshipType]}S`}
                color="primary"
                size="sm"
                block
                outline
              />
            </Col>
          </FormGroup>
        )}
      </Form>
    </>
  );
}

AddFriendForm.propTypes = {
  onManageFriendsClick: PropTypes.func,
  friendshipType: PropTypes.string,
};

export default AddFriendForm;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level1/on-court/session-review/SessionReview-1.png';
import view2 from '../../../../images/cuemate101/level1/on-court/session-review/SessionReview-2.png';
import view3 from '../../../../images/cuemate101/level1/on-court/session-review/SessionReview-3.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Session Review</h1>
          <p>
            You can review your completed sessions in the CueMate App using the Review feature in
            the sidebar.
          </p>
          <p>
            The Review feature shows you the Stroke Map of the selected session enhanced by time,
            repertoire, and assessment filters
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">SESSION OVERVIEW</h3>
          <p>
            The left panel shows high-level session information including total stroke counts, total
            play time, and sweet spot %. You can see how your session compares to your chosen time
            scale filter.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Session Review 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={8}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Session Review 2" />
          </Col>
          <Col sm={4}>
            <h3 className="blue">PERFORMANCE HIGHLIGHTS</h3>
            <p>
              While in the ’Stroke Quality’ view, you can see those stroke metrics that changed the
              most (represented by stroke metric icons in each stroke class).
            </p>
            <p>
              This view is important in learning which are your core strokes and what your
              repertoire consists of. Using the highlighted stroke classes (either in green or red),
              you can further investigate which strokes work for you or don’t.
            </p>
            <p>
              <ul>
                <li>
                  The icons in the stroke class which metrics have improved the most via your stroke
                  quality.
                </li>
                <li>
                  Explore the metrics and trends of these classes to find out how your performance
                  changed during different sets in the past.
                </li>
              </ul>
            </p>
            <p>You can also view the most frequent stroke classes as shown by the stroke counts.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row>
        <Col sm={4}>
          <h3 className="padding-top-25 blue">SKILL STATUS</h3>
        </Col>
        <Col sm={8}>
          <img src={view3} className="d-block mx-auto img-fluid" alt="Session Review 3" />
        </Col>
      </Row>
    </Container>
  );
}

export default function SessionReview() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />

      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import strokesandshots from '../../../../images/concepts/icons/strokesandshots.png';
import setsandsessions from '../../../../images/concepts/icons/setsandsessions.png';
import assessmentlevels from '../../../../images/concepts/icons/assessmentlevels.png';
import repertoire from '../../../../images/concepts/icons/repertoire.png';
import trainingloop from '../../../../images/concepts/icons/trainingloop.png';

import './Concepts.css';

export default function CuemateConcepts() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Row>
        <Col sm="12">
          <h1>Concepts</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h3>
            CueMate is a data-driven training ecosystem designed to help you understand your game
            and train more efficiently.
          </h3>
          <h3>Movement activities like tennis have many dimensions and layers.</h3>
          <h3>
            To fully take advantage of the capabilities, the following describes the key Concepts
            that form the language of the CueMate augmented training ecosystem.
          </h3>
          <h3>
            Click on a concept bellow to learn more about it. These should give you enough
            understanding to get going with CueMate.
          </h3>
          <h3>
            To learn more about the CueMate philosophy and approach to data-driven training, check
            out the <Link to="/background">background</Link> section and the{' '}
            <Link to="/cuemate101">Training with CueMate sections</Link>.{' '}
          </h3>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/strokes-and-shots">
            <img
              src={strokesandshots}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Strokes and Shots"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #1 </h4>
          <Link to="/key-concepts/strokes-and-shots">
            <h3>Strokes and Shots</h3>
          </Link>
          <p>
            To fully take advantage of CueMate you will need to understand how it organizes and
            processes the data. First, you need to distinguish between strokes and shots.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/stroke-map">
            <img
              src={repertoire}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Stroke Map"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #2 </h4>
          <Link to="/key-concepts/stroke-map">
            <h3>Stroke Map</h3>
          </Link>
          <p>
            CueMate tracks and organizes your repertoire of strokes table or matrix structure called
            the Stroke Map. The Stroke Map depicts your stroke vocabulary and is a central concept
            for navigating your strokes and to assess your skills and manage training.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/stroke-metrics">
            <img
              src={repertoire}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Stroke Metrics"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #3 </h4>
          <Link to="/key-concepts/stroke-metrics">
            <h3>Stroke Metrics</h3>
          </Link>
          <p>
            CueMate computes metrics that describe a wide range of attributes of your strokes.
            Metrics cover different aspects of the movement performance including their outcomes
            (ball speed and spin) and various aspects of movement technique and physical
            performance. These metrics provide key skill assessments.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/reference-ranges">
            <img
              src={repertoire}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Reference Ranges"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #4 </h4>
          <Link to="/key-concepts/reference-ranges">
            <h3>Reference Ranges</h3>
          </Link>
          <p>
            Metrics by themselves are not very easy to interpretate. CueMate computes reference
            ranges to help you understand where you stand relative to other players across the wide
            range of characteristic defining your skills.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/reference-ranges">
            <img
              src={repertoire}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Reference Ranges"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #5 </h4>
          <Link to="/key-concepts/skill-assesments">
            <h3>Skill Assessments</h3>
          </Link>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/stroke-class-scores">
            <img
              src={repertoire}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Stroke Class Scores"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #6 </h4>
          <Link to="/key-concepts/stroke-class-scores">
            <h3>Stroke Class Scores</h3>
          </Link>
          <p>
            The metrics are used combined to give you of scores for each stroke class covering
            aspects of performance such as the quality of the ball strike or the quality of the
            stroke outcomes. The scores help provide an intuitive assessment of your skill.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/sessions-and-sets">
            <img
              src={trainingloop}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Sets and Sessions"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #7 </h4>
          <Link to="/key-concepts/sessions-and-sets">
            <h3>Sessions, Sets and Tagging</h3>
          </Link>
          <p>
            Sets and sessions enable you to track and organize your activity. A useful feature for
            organizing your session is tagging. Tagging let’s the app know if you are playing games,
            freeplay, or training. This information is important to interpretate your data since you
            may play differently when you are under pressure. Understand what changes under
            different play conditions is also important for your training, including the fitness and
            mental dimensions.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/training-goals">
            <img
              src={repertoire}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Training Goals"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #8 </h4>
          <Link to="/key-concepts/training-goals">
            <h3>Training Goals</h3>
          </Link>
          <p>
            CueMate enables you to focus on specific aspects of your technique using training goals.
            The main purpose of the training goal is to easily track a small set of characteristics
            that are currently critical for your skill development.
          </p>
          <p>Two types of training goals are available:</p>
          <ul className="with-spacing">
            <li>
              Automated training goals that are CueMate generatesd based on your skill rating and
              activity profiles.{' '}
            </li>
            <li>
              Custom training goals that you can determine manually focusing on one of the many
              metrics used to assess your strokes.
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/feedback-cueing">
            <img
              src={trainingloop}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Feedback Cueing"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #9 </h4>
          <Link to="/key-concepts/feedback-cueing">
            <h3>Feedback Cueing</h3>
          </Link>
          <p>
            As already described in the overview, immediate feedback is key to effective learning.
            CueMate can generate feedback on a nearly unlimited set of movement features helping you
            become more aware of your strokes outcome levels, as well as technical aspects of your
            strokes such as movement phase properties, such as the back loop type.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/skill-rating">
            <img
              src={assessmentlevels}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Skill Rating"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #10 </h4>
          <Link to="/key-concepts/skill-rating">
            <h3>Skill Rating and Game Style</h3>
          </Link>
          <p>
            Everyone’s stroke repertoire is different. It reflects their skill level and game style.
            CueMate uses information from your repertoire to determines your individual rating
            across stroke categories (groundstrokes, serves, and volleys.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="2">
          <Link to="/key-concepts/rating-based-training">
            <img
              src={setsandsessions}
              className="d-block mx-auto img-fluid concept-icon"
              alt="Rating-Based Training"
            />
          </Link>
        </Col>
        <Col sm="6">
          <h4 className="tracking"> CONCEPT #11 </h4>
          <Link to="/key-concepts/rating-based-training">
            <h3>Rating-Based Training</h3>
          </Link>
          <p>
            As already described under training goals, CueMate computes rating goals. These goals
            are auto generated and provide insights into what aspects of your skills you have to
            focus on to advance your rating across the different stroke categories.{' '}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

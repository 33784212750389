import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';
import labelC from '../../../../images/screens/labels/C.png';
import labelD from '../../../../images/screens/labels/D.png';

import reviewoverview from '../../../../images/screens/review/Review-ABCD.gif';
import reviewA from '../../../../images/screens/review/Review-A.png';
import reviewB from '../../../../images/screens/review/Review-B.png';
import reviewC from '../../../../images/screens/review/Review-C.png';
import reviewD from '../../../../images/screens/review/Review-D.png';
import './AppFeatures.css';

export default function ReviewScreen() {
  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <Row>
        <Col sm="12">
          <h1>Review Screen</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="padding-top-50">
          <img
            src={reviewoverview}
            className="d-block mx-auto img-fluid"
            alt="Review overview Screen"
          />
        </Col>
        <Col sm="4" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/review-mode#statistics-panel">
                  Statistics Panel
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/review-mode#assessment-filters">
                  Assessment Filters
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/review-mode#stroke-map">
                  Stroke Map
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/review-mode#learning-stage">
                  Learning Stage
                </Link>
              </h3>
            </Media>
          </Media>
        </Col>
      </Row>

      <Row className="padding-top-100" id="statistics-panel">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-header-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h2>Statistics Panel</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The statistics panel on the left side of the screen shows key information. The top shows
            the date of the last session that is being compared against the skill filters, described
            below.{' '}
          </p>
          <p>
            The statistics panel populates with total strokes, time elapsed, and sweet spot for the
            item selected in the time filter, described below.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img
            src={reviewA}
            className="d-block mx-auto img-fluid"
            alt="Review time details screen"
          />
        </Col>
      </Row>

      <Row className="padding-top-100" id="assessment-filters">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-header-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h2>Assessment Filter</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Put your last session’s performance into context using skill filters. <br />
            CueMate’s three skill filters are outlined below:
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img
            src={reviewB}
            className="d-block mx-auto img-fluid"
            alt="Review assessment filters screen"
          />
        </Col>
      </Row>

      <Row>
        <Col sm="4" className="padding-top-50">
          <h3>Time Filter</h3>
          <p>
            {' '}
            {
              "The time filter enables you to compare your last session's metrics to your metrics across the following time periods:"
            }{' '}
          </p>
          <p>
            {' '}
            <span className="bold">Previous Session</span> <br />
            Shows metric averages from previous session{' '}
          </p>
          <p>
            {' '}
            <span className="bold">Previous 3 Sessions</span> <br />
            Shows metric averages from previous three sessions{' '}
          </p>
          <p>
            {' '}
            <span className="bold">Previous 5 Sessions</span> <br />
            Shows metric averages from previous five sessions{' '}
          </p>
          <p>
            {' '}
            <span className="bold">Previous 10 Sessions</span> <br />
            Shows metric averages from previous ten sessions{' '}
          </p>
        </Col>
        <Col sm="4" className="padding-top-50">
          <h3>Repertoire Filter</h3>
          <p>
            {' '}
            The repertoire filter enables you to filter different collections of stroke types to
            conveniently perform repertoire-level assessments.{' '}
          </p>
          <p> Note that the repertoire filter does not apply to the Training List view. </p>
          <p> There are three types of repertoire filters used in the Review Map view: </p>
          <p>
            {' '}
            <span className="bold">All Strokes</span> <br />
            Shows all strokes{' '}
          </p>
          <p>
            {' '}
            <span className="bold">Core Strokes</span> <br />
            Shows core strokes — <br />
            FTM, FSM, BTM, BSM{' '}
          </p>
          <p>
            {' '}
            <span className="bold">Skill Status</span> <br />
          </p>
        </Col>
        <Col sm="4" className="padding-top-50">
          <h3>Assessment Filter</h3>
          <p>
            {' '}
            The assessment filter enables you to view stroke metrics through various lenses, and
            primarily affects the alerts presented in the top left and right corners of each stroke
            map cell.{' '}
          </p>
          <p> There are three types of assessment filters: </p>
          <p>
            <span className="bold">Stroke Quality</span>
            <br />
            Displays the change in the stroke quality metric score relative to the selected time
            scale.
          </p>
          <p>
            <span className="bold">Sweet Spot</span>
            <br />
            Displays the change in sweet spot relative to the selected time scale.
          </p>
          <p>
            <span className="bold">Usage</span>
            <br />
            Displays the changes in usage relative to the selected time scale. Unlike the other
            assessment filters, Usage presents stroke usage in the form of percentage of repertoire
            as opposed to stroke count.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100" id="stroke-map">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-header-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h2>Review Map</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            CueMate tracks and organizes your repertoire of groundstrokes, serves and volleys onto a
            3x6 table called the Stroke Map. You can reference the Stroke Map to discern shot
            quality for each stroke type.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={reviewC} className="d-block mx-auto img-fluid" alt="Review map screen" />
        </Col>
      </Row>

      <Row className="padding-top-100" id="learning-stage">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-header-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h2>Learning Stage</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The Learning Stage table displays every stroke class in one of the following stages:
            Empty, Formation, Consolidation, Optimization and Optimal, computed from the stroke
            metrics.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img
            src={reviewD}
            className="d-block mx-auto img-fluid"
            alt="Review assessment filters screen"
          />
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import './ContactUs.css';

export default function ContactUs() {
  return (
    <Container className="ContactUs padding-top-25 animated fadeIn">
      <Row>
        <Col sm="12">
          <h2>Contact Us</h2>
        </Col>
      </Row>
      <Row className="padding-top-100 padding-bottom-100">
        <Col sm="4">
          <a
            href="tel:415-993-1283"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-icon"
          >
            <div className="text-center">
              <h3>
                <i className="fa fa-mobile triad blue" /> Phone
              </h3>
              <p>
                415-993-1CUE
                <br />
                (415-993-1283)
              </p>
            </div>
          </a>
        </Col>
        <Col sm="4">
          <a
            href="mailto:info@icuemotion.com"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-icon"
          >
            <div className="text-center">
              <h3>
                <i className="fa fa-envelope-o env blue" /> Email
              </h3>
              <p>info@icuemotion.com</p>
            </div>
          </a>
        </Col>
        <Col sm="4">
          <a
            href="https://goo.gl/maps/WQ8X7SAGwVB9yxaz6"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-icon"
          >
            <div className="text-center">
              <h3 className="p-t-1 serif">
                <i className="fa fa-map-marker location blue" /> Mailing Address
              </h3>
              <p>
                584 Castro Street PMB 412
                <br />
                San Francisco, CA 94114-2594
              </p>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

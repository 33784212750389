export const ADD_LEADERBOARD = 'ADD_LEADERBOARD';
export const ADD_USERNAME = 'ADD_USERNAME';
export const ADD_USER_GROUPS = 'ADD_USER_GROUPS';
export const ADD_REPORT_TYPE = 'ADD_REPORT_TYPE';
export const ADD_ACTIVITY_DATA = 'ADD_ACTIVITY_DATA';
export const ADD_ACTIVE_SETS = 'ADD_ACTIVE_SETS';
export const ADD_REALM_CLIENT = 'ADD_REALM_CLIENT';
export const ADD_ELEMENTS = 'ADD_ELEMENTS';
export const ADD_REF_RANGES = 'ADD_REF_RANGES';
export const ADD_CLASS_STATS = 'ADD_CLASS_STATS';
export const ADD_SESSION_DATA = 'ADD_SESSION_DATA';
export const ADD_CLASS_DATA = 'ADD_CLASS_DATA';
export const ADD_VISION_DATA = 'ADD_VISION_DATA';
export const ADD_CONFIG = 'ADD_CONFIG';
export const UPDATE_ACTIVE_CONTENT = 'UPDATE_ACTIVE_CONTENT';
export const UPDATE_MODES = 'UPDATE_MODES';
export const UPDATE_UNITS = 'UPDATE_UNITS';
export const ADD_RATING = 'ADD_RATING';
export const ADD_USER_CLASS_PROFILES = 'ADD_USER_CLASS_PROFILES';
export const ADD_CMV_THRESHOLDS = 'ADD_CMV_THRESHOLDS';

export const addLeaderboard = (payload) => ({
  type: ADD_LEADERBOARD,
  payload,
});

export const addUsername = (username) => ({
  type: ADD_USERNAME,
  username,
});

export const addUserGroups = (payload) => ({
  type: ADD_USER_GROUPS,
  payload,
});

export const addReportType = (reportType) => ({
  type: ADD_REPORT_TYPE,
  reportType,
});

export const addActivityData = (payload) => ({
  type: ADD_ACTIVITY_DATA,
  payload,
});

export const addActiveSets = (payload) => ({
  type: ADD_ACTIVE_SETS,
  payload,
});

export const addRealmClient = (payload) => ({
  type: ADD_REALM_CLIENT,
  payload,
});

export const addElements = (payload) => ({
  type: ADD_ELEMENTS,
  payload,
});

export const addRefRanges = (payload) => ({
  type: ADD_REF_RANGES,
  payload,
});

export const addClassStats = (payload) => ({
  type: ADD_CLASS_STATS,
  payload,
});

export const addSessionData = (payload) => ({
  type: ADD_SESSION_DATA,
  payload,
});

export const addClassData = (payload) => ({
  type: ADD_CLASS_DATA,
  payload,
});

export const addVisionData = (payload) => ({
  type: ADD_VISION_DATA,
  payload,
});

export const addConfig = (payload) => ({
  type: ADD_CONFIG,
  payload,
});

export const updateActiveContent = (content) => ({
  type: UPDATE_ACTIVE_CONTENT,
  content,
});

export const updateModes = (modes) => ({
  type: UPDATE_MODES,
  modes,
});

export const updateUnits = (payload) => ({
  type: UPDATE_UNITS,
  payload,
});

export const addRating = (payload) => ({
  type: ADD_RATING,
  payload,
});

export const addUserClassProfiles = (payload) => ({
  type: ADD_USER_CLASS_PROFILES,
  payload,
});

export const addCmvThresholds = (payload) => ({
  type: ADD_CMV_THRESHOLDS,
  payload,
});

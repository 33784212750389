import React from 'react';
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

// Ungated Home
import MainHome from './containers/ungated/home/MainHome';
import PublicSetUp from './containers/ungated/Setup';
import FAQs from './containers/ungated/faqs/FAQs';
import ContactUs from './containers/ungated/contact/ContactUs';
import Privacy from './containers/ungated/privacy/Privacy';
import Login from './containers/ungated/login/Login';
import ResetPassword from './containers/ungated/ResetPassword';
import UserInfoForm from './containers/gated/home/usermgmnt/UserProfile';

// Gated Home
import GatedMainHome from './containers/gated/overview/GatedMainHome';
import SignupFriend from './containers/gated/home/SignupFriend';
import Settings from './containers/gated/home/usermgmnt/Settings';
import UserGear from './containers/gated/home/usermgmnt/UserGear';
import SiteMap from './containers/gated/home/SiteMap';
import UploadManual from './containers/gated/upload/UploadManual';

// Background
import Background from './containers/gated/background/Background';
import TechnologyMovementTraining from './containers/gated/background/TechnologyMovementTraining';
import HierarchicalMovementModel from './containers/gated/background/HierarchicalMovementModel';
import BackgroundLearningModel from './containers/gated/background/LearningModel';
import HierarchicalSkillAssessmentTraining from './containers/gated/background/HierarchicalSkillAssessmentTraining';
import CueMateEcosystem from './containers/gated/background/CueMateEcosystem';

// Concepts
import CuemateConcepts from './containers/gated/about/concepts/CuemateConcepts';
import StrokesAndShots from './containers/gated/about/concepts/StrokesAndShots';
import StrokeMap from './containers/gated/about/concepts/StrokeMap';
import SessionsAndSets from './containers/gated/about/concepts/SessionsAndSets';
import StrokeClassScores from './containers/gated/about/concepts/StrokeClassScores';
import FeedbackCueing from './containers/gated/about/concepts/FeedbackCueing';
import ConceptsRatingBasedTraining from './containers/gated/about/concepts/RatingBasedTraining';
import LearningModel from './containers/gated/about/concepts/LearningModel';
import ReferenceRanges from './containers/gated/about/concepts/ReferenceRanges';
import SkillAssessments from './containers/gated/about/concepts/SkillAssessments';

import GameTypes from './containers/gated/about/concepts/GameTypes';

// Get Started
import InstallApp from './containers/gated/getstarted/InstallApp';
import SetupSensor from './containers/gated/getstarted/SetupSensor';

// CueMate 101
import CueMate101 from './containers/gated/cuemate101/CueMate101';

// Level 1
import Level1 from './containers/gated/cuemate101/level1/Level1';
import StrokeMetrics from './containers/gated/cuemate101/level1/StrokeMetrics';
import StrokeMap101 from './containers/gated/cuemate101/level1/StrokeMap';
import Cueing from './containers/gated/cuemate101/level1/Cueing';
// Level 1: Court Session
import CourtSession1 from './containers/gated/cuemate101/level1/CourtSession1';
// Level 1: On Court
import RecordingSessions from './containers/gated/cuemate101/level1/RecordingSessions';
import StrokeMapReview from './containers/gated/cuemate101/level1/StrokeMapReview';
import SessionReports from './containers/gated/cuemate101/level1/SessionReports';
import ClassReports from './containers/gated/cuemate101/level1/ClassReport';
import SessionReview from './containers/gated/cuemate101/level1/SessionReview';

import L1S1Warmup from './containers/gated/cuemate101/level1/L1S1Warmup';
import L1S2Baseline from './containers/gated/cuemate101/level1/L1S2Baseline';
import L1S3ISR from './containers/gated/cuemate101/level1/L1S3ISR';
import L1S4Spin from './containers/gated/cuemate101/level1/L1S4Spin';
import L1S5Pace from './containers/gated/cuemate101/level1/L1S5Pace';
import L1S6PostCue from './containers/gated/cuemate101/level1/L1S6PostCue';
import L1ReviewStrokeMap from './containers/gated/cuemate101/level1/L1ReviewStrokeMap';
import L1ReviewOutcomeMetrics from './containers/gated/cuemate101/level1/L1ReviewOutcomeMetrics';
import FinishEndSession from './containers/gated/cuemate101/level1/FinishEndSession';

// Level 2
import Level2 from './containers/gated/cuemate101/level2/Level2';
import CoreStrokes from './containers/gated/cuemate101/level2/CoreStrokes';
import TrainingGoals from './containers/gated/cuemate101/level2/TrainingGoals';
import TrainingCues from './containers/gated/cuemate101/level2/TrainingCues';
// Level 2: On Court
import RatingSystem from './containers/gated/cuemate101/level2/RatingSystem';
import TrainingGoalStatus from './containers/gated/cuemate101/level2/TrainingGoalStatus';
import TrainingReport from './containers/gated/cuemate101/level2/TrainingReport';

// Level 2: Court Session
import CourtSession2 from './containers/gated/cuemate101/level2/CourtSession2';
import L2S1Warmup from './containers/gated/cuemate101/level2/L2S1Warmup';
import L2S2PerformanceBaseline from './containers/gated/cuemate101/level2/L2S2PerformanceBaseline';
import L2S2Review from './containers/gated/cuemate101/level2/L2S2Review';
import L2S3BHWarmupCue from './containers/gated/cuemate101/level2/L2S3BHWarmupCue';
import L2S4BHRallyCue from './containers/gated/cuemate101/level2/L2S4BHRallyCue';
import L2S5BHRally from './containers/gated/cuemate101/level2/L2S5BHRally';
import L2S6BHFHRallyCue from './containers/gated/cuemate101/level2/L2S6BHFHRallyCue';
import L2S7Progress from './containers/gated/cuemate101/level2/L2S7Progress';
import L2ReviewStrokeMap from './containers/gated/cuemate101/level2/L2ReviewStrokeMap';
import L2ReviewOutcomeMetrics from './containers/gated/cuemate101/level2/L2ReviewOutcomeMetrics';

// Level 3
import Level3 from './containers/gated/cuemate101/level3/Level3';
import ReviewFeatures from './containers/gated/cuemate101/level3/ReviewFeatures';
import BrowseFeatures from './containers/gated/cuemate101/level3/BrowseFeatures';
import Reports from './containers/gated/cuemate101/level3/Reports';
import MetricViews from './containers/gated/cuemate101/level3/MetricViews';
// Level 3: On Court
import AdvancedMetrics from './containers/gated/cuemate101/level3/AdvancedMetrics';
import CustomTrainingGoals from './containers/gated/cuemate101/level3/CustomTrainingGoals';

// Features
import Features from './containers/gated/features/Features';
import UCTrain from './containers/gated/features/Train';
import SkillAssessment from './containers/gated/features/training/SkillAssessment';
import TrainingStrokeMetrics from './containers/gated/features/training/StrokeMetrics';
import RatingBasedTraining from './containers/gated/features/training/RatingBasedTraining';
import SelfDirectedTraining from './containers/gated/features/training/SelfDirectedTraining';
import TrainingPrograms from './containers/gated/features/training/TrainingPrograms';
import MetricsDetailed from './containers/gated/features/metrics/MetricsDetailed';
import StrokeClassMetrics from './containers/gated/features/metrics/StrokeClassMetrics';
import RepertoireMetrics from './containers/gated/features/metrics/RepertoireMetrics';
import ClassEnsembleMetrics from './containers/gated/features/metrics/ClassEnsembleMetrics';
import Social from './containers/gated/features/social/Social';
import LearningProcessAssessmentAndSkillRating from './containers/gated/features/training/LearningProcessAssessmentAndSkillRating';

// iOS Features
import AppFeaturesScreen from './containers/gated/home/appfeatures/AppFeaturesScreen';
import HomeScreen from './containers/gated/home/appfeatures/HomeScreen';
import PlayerProfile from './containers/gated/home/appfeatures/PlayerProfile';
import Tagging from './containers/gated/home/appfeatures/Tagging';
import PlayMode from './containers/gated/home/appfeatures/PlayMode';
import TrainMode from './containers/gated/home/appfeatures/TrainMode';
import TrendsScreen from './containers/gated/home/appfeatures/TrendsScreen';
import Metrics from './containers/gated/home/appfeatures/Metrics';
import ReportsScreen from './containers/gated/home/appfeatures/ReportsScreen';
import ReviewMode from './containers/gated/home/appfeatures/ReviewMode';
import StatusScreen from './containers/gated/home/appfeatures/StatusScreen';
import SettingsScreen from './containers/gated/home/appfeatures/SettingsScreen';

// Web Features
import WebFeatures from './containers/gated/home/cuemate-web/WebFeatures';
import WebHome from './containers/gated/home/cuemate-web/WebHome';
import WebSessionReports from './containers/gated/home/cuemate-web/WebSessionReports';
import WebPlayerReports from './containers/gated/home/cuemate-web/WebPlayerReports';
import WebStrokeClassReports from './containers/gated/home/cuemate-web/WebStrokeClassReports';
import WebTraining from './containers/gated/home/cuemate-web/WebTraining';
import WebLeaderboard from './containers/gated/home/cuemate-web/WebLeaderboard';

// Reports
import TennisReport from './containers/gated/home/reports/TennisReport';
import ComputerVisionReport from './containers/gated/home/reports/components/ComputerVisionReport';

// Routes
import AuthenticatedRoute from './components/AuthenticatedRoute';
import NotFound from './containers/NotFound';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import LoadingStateComponent from './components/LoadingStateComponent';
import HowCueMateWorks from './containers/gated/background/HowCueMateWorks';

import PdfLink from './containers/gated/home/guides/PdfLink';

function SurveyRedirect() {
  // eslint-disable-next-line no-undef
  window.location.href = 'https://cuemate-tennis.typeform.com/to/LNSqoawF';
  return <LoadingStateComponent loadingText="Redirecting ..." />;
}

export default function Routes() {
  return (
    <ReactRouterRoutes>
      <Route
        path="/"
        element={
          <UnauthenticatedRoute>
            <MainHome />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path="/privacy"
        element={
          <UnauthenticatedRoute>
            <Privacy />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path="/faqs"
        element={
          <AuthenticatedRoute>
            <FAQs />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/contact"
        element={
          <AuthenticatedRoute>
            <ContactUs />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/public-setup"
        element={
          <UnauthenticatedRoute>
            <PublicSetUp />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path="/login"
        element={
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path="/password-reset"
        element={
          <UnauthenticatedRoute>
            <ResetPassword />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path="/survey"
        element={
          <UnauthenticatedRoute>
            <SurveyRedirect />
          </UnauthenticatedRoute>
        }
      />

      <Route
        path="/overview"
        element={
          <AuthenticatedRoute>
            <GatedMainHome />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="basic" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/basic"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="basic" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/basic/:tabName"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="basic" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/experiment"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="experiment" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/experiment/:tabName"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="experiment" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/advanced"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="advanced" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/advanced/:tabName"
        element={
          <AuthenticatedRoute>
            <TennisReport reportType="advanced" />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/dashboard/computer-vision"
        element={
          <AuthenticatedRoute>
            <ComputerVisionReport />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/my-account"
        element={
          <AuthenticatedRoute>
            <Settings />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/my-settings"
        element={
          <AuthenticatedRoute>
            <UserInfoForm />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/my-gear"
        element={
          <AuthenticatedRoute>
            <UserGear />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/upload"
        element={
          <AuthenticatedRoute>
            <UploadManual />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/refer-a-friend"
        element={
          <AuthenticatedRoute>
            <SignupFriend />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background"
        element={
          <AuthenticatedRoute>
            <Background />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background/how-cuemate-works"
        element={
          <AuthenticatedRoute>
            <HowCueMateWorks />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background/technology-and-movement-training"
        element={
          <AuthenticatedRoute>
            <TechnologyMovementTraining />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background/hierarchical-movement-skill-model"
        element={
          <AuthenticatedRoute>
            <HierarchicalMovementModel />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background/learning-model"
        element={
          <AuthenticatedRoute>
            <BackgroundLearningModel />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background/hierarchical-skill-assessment-and-training"
        element={
          <AuthenticatedRoute>
            <HierarchicalSkillAssessmentTraining />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/background/cuemate-ecosystem"
        element={
          <AuthenticatedRoute>
            <CueMateEcosystem />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts"
        element={
          <AuthenticatedRoute>
            <CuemateConcepts />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/strokes-and-shots"
        element={
          <AuthenticatedRoute>
            <StrokesAndShots />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/stroke-map"
        element={
          <AuthenticatedRoute>
            <StrokeMap />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/stroke-metrics"
        element={
          <AuthenticatedRoute>
            <StrokeMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/reference-ranges"
        element={
          <AuthenticatedRoute>
            <ReferenceRanges />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/skill-assesments"
        element={
          <AuthenticatedRoute>
            <SkillAssessments />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/stroke-class-scores"
        element={
          <AuthenticatedRoute>
            <StrokeClassScores />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/sessions-and-sets"
        element={
          <AuthenticatedRoute>
            <SessionsAndSets />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/training-goals"
        element={
          <AuthenticatedRoute>
            <TrainingGoals />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/feedback-cueing"
        element={
          <AuthenticatedRoute>
            <FeedbackCueing />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/skill-rating"
        element={
          <AuthenticatedRoute>
            <GameTypes />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/rating-based-training"
        element={
          <AuthenticatedRoute>
            <ConceptsRatingBasedTraining />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/key-concepts/learning-model"
        element={
          <AuthenticatedRoute>
            <LearningModel />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/install-app"
        element={
          <AuthenticatedRoute>
            <InstallApp />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/setup-sensor"
        element={
          <AuthenticatedRoute>
            <SetupSensor />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101"
        element={
          <AuthenticatedRoute>
            <CueMate101 />
          </AuthenticatedRoute>
        }
      />

      {/* Level 1 */}
      <Route
        path="/cuemate101/level1"
        element={
          <AuthenticatedRoute>
            <Level1 />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/stroke-metrics"
        element={
          <AuthenticatedRoute>
            <StrokeMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/stroke-map"
        element={
          <AuthenticatedRoute>
            <StrokeMap101 />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/cueing"
        element={
          <AuthenticatedRoute>
            <Cueing />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/on-court/recording-sessions"
        element={
          <AuthenticatedRoute>
            <RecordingSessions />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/on-court/stroke-map"
        element={
          <AuthenticatedRoute>
            <StrokeMapReview />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/on-court/session-reports"
        element={
          <AuthenticatedRoute>
            <SessionReports />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/on-court/class-reports"
        element={
          <AuthenticatedRoute>
            <ClassReports />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/on-court/session-review"
        element={
          <AuthenticatedRoute>
            <SessionReview />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise"
        element={
          <AuthenticatedRoute>
            <CourtSession1 />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/set-1"
        element={
          <AuthenticatedRoute>
            <L1S1Warmup />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/set-2"
        element={
          <AuthenticatedRoute>
            <L1S2Baseline />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/set-3"
        element={
          <AuthenticatedRoute>
            <L1S3ISR />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/set-4"
        element={
          <AuthenticatedRoute>
            <L1S4Spin />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/set-5"
        element={
          <AuthenticatedRoute>
            <L1S5Pace />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/set-6"
        element={
          <AuthenticatedRoute>
            <L1S6PostCue />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/review-stroke-map"
        element={
          <AuthenticatedRoute>
            <L1ReviewStrokeMap />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/review-outcome-metrics"
        element={
          <AuthenticatedRoute>
            <L1ReviewOutcomeMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level1/court-exercise/finish"
        element={
          <AuthenticatedRoute>
            <FinishEndSession />
          </AuthenticatedRoute>
        }
      />

      {/* Level 2 */}

      <Route
        path="/cuemate101/level2"
        element={
          <AuthenticatedRoute>
            <Level2 />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/core-strokes"
        element={
          <AuthenticatedRoute>
            <CoreStrokes />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/training-goals"
        element={
          <AuthenticatedRoute>
            <TrainingGoals />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/training-cues"
        element={
          <AuthenticatedRoute>
            <TrainingCues />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise"
        element={
          <AuthenticatedRoute>
            <CourtSession2 />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-1"
        element={
          <AuthenticatedRoute>
            <L2S1Warmup />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-2"
        element={
          <AuthenticatedRoute>
            <L2S2PerformanceBaseline />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-2/review"
        element={
          <AuthenticatedRoute>
            <L2S2Review />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-3"
        element={
          <AuthenticatedRoute>
            <L2S3BHWarmupCue />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-4"
        element={
          <AuthenticatedRoute>
            <L2S4BHRallyCue />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-5"
        element={
          <AuthenticatedRoute>
            <L2S5BHRally />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-6"
        element={
          <AuthenticatedRoute>
            <L2S6BHFHRallyCue />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/set-7"
        element={
          <AuthenticatedRoute>
            <L2S7Progress />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/review-stroke-map"
        element={
          <AuthenticatedRoute>
            <L2ReviewStrokeMap />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/review-outcome-metrics"
        element={
          <AuthenticatedRoute>
            <L2ReviewOutcomeMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/court-exercise/finish"
        element={
          <AuthenticatedRoute>
            <FinishEndSession />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/on-court/rating-system"
        element={
          <AuthenticatedRoute>
            <RatingSystem />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/on-court/training-goal-status"
        element={
          <AuthenticatedRoute>
            <TrainingGoalStatus />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level2/on-court/training-report"
        element={
          <AuthenticatedRoute>
            <TrainingReport />
          </AuthenticatedRoute>
        }
      />

      {/* Level 3 */}

      <Route
        path="/cuemate101/level3"
        element={
          <AuthenticatedRoute>
            <Level3 />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level3/review"
        element={
          <AuthenticatedRoute>
            <ReviewFeatures />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level3/browse"
        element={
          <AuthenticatedRoute>
            <BrowseFeatures />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/cuemate101/level3/reports"
        element={
          <AuthenticatedRoute>
            <Reports />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level3/metric-views"
        element={
          <AuthenticatedRoute>
            <MetricViews />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level3/on-court/advanced-metrics"
        element={
          <AuthenticatedRoute>
            <AdvancedMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate101/level3/on-court/custom-training-goals"
        element={
          <AuthenticatedRoute>
            <CustomTrainingGoals />
          </AuthenticatedRoute>
        }
      />

      {/* Features */}
      <Route
        path="/features"
        element={
          <AuthenticatedRoute>
            <Features />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/metrics"
        element={
          <AuthenticatedRoute>
            <MetricsDetailed />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/metrics/stroke-class"
        element={
          <AuthenticatedRoute>
            <StrokeClassMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/metrics/class-ensemble"
        element={
          <AuthenticatedRoute>
            <ClassEnsembleMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/metrics/repertoire"
        element={
          <AuthenticatedRoute>
            <RepertoireMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training"
        element={
          <AuthenticatedRoute>
            <UCTrain />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training/skill-assessment"
        element={
          <AuthenticatedRoute>
            <SkillAssessment />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training/stroke-metrics"
        element={
          <AuthenticatedRoute>
            <TrainingStrokeMetrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training/learning-process-assessment"
        element={
          <AuthenticatedRoute>
            <LearningProcessAssessmentAndSkillRating />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training/rating"
        element={
          <AuthenticatedRoute>
            <RatingBasedTraining />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training/self-directed"
        element={
          <AuthenticatedRoute>
            <SelfDirectedTraining />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/training/programs"
        element={
          <AuthenticatedRoute>
            <TrainingPrograms />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/social"
        element={
          <AuthenticatedRoute>
            <Social />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios"
        element={
          <AuthenticatedRoute>
            <AppFeaturesScreen />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/home-screen"
        element={
          <AuthenticatedRoute>
            <HomeScreen />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/player-profile"
        element={
          <AuthenticatedRoute>
            <PlayerProfile />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/tagging"
        element={
          <AuthenticatedRoute>
            <Tagging />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/train"
        element={
          <AuthenticatedRoute>
            <TrainMode />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/play-screen"
        element={
          <AuthenticatedRoute>
            <PlayMode />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/metrics-screen"
        element={
          <AuthenticatedRoute>
            <Metrics />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/trends-screen"
        element={
          <AuthenticatedRoute>
            <TrendsScreen />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/reports-screen"
        element={
          <AuthenticatedRoute>
            <ReportsScreen />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/review-screen"
        element={
          <AuthenticatedRoute>
            <ReviewMode />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/status-screen"
        element={
          <AuthenticatedRoute>
            <StatusScreen />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-ios/settings"
        element={
          <AuthenticatedRoute>
            <SettingsScreen />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web"
        element={
          <AuthenticatedRoute>
            <WebFeatures />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web/home-screen"
        element={
          <AuthenticatedRoute>
            <WebHome />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web/session-reports"
        element={
          <AuthenticatedRoute>
            <WebSessionReports />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web/player-reports"
        element={
          <AuthenticatedRoute>
            <WebPlayerReports />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web/stroke-class-reports"
        element={
          <AuthenticatedRoute>
            <WebStrokeClassReports />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web/leaderboard"
        element={
          <AuthenticatedRoute>
            <WebLeaderboard />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/cuemate-web/training"
        element={
          <AuthenticatedRoute>
            <WebTraining />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/sitemap"
        element={
          <AuthenticatedRoute>
            <SiteMap />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/instructions/sensor-setup"
        element={
          <AuthenticatedRoute>
            <PdfLink pdfURL="docs/CueMateSetUp.pdf" linkText="CueMate Set-up" />
          </AuthenticatedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </ReactRouterRoutes>
  );
}

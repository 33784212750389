import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import strokeMap from '../../../../images/screens/Play-StrokeMap.PNG';
import tagging from '../../../../images/screens/StartSet-GeneralCues.PNG';
import extendedTagging from '../../../../images/cuemate101/thumbnails/extending-tagging.png';

export default function SessionsAndSets() {
  return (
    <Container className="padding-bottom-25 animated fadeIn">
      <Breadcrumbs />

      <Row>
        <Col sm="12">
          {' '}
          <h1>Sessions, Sets and Tagging</h1>{' '}
          <h4>
            Activities like tennis typically follow a specific structure. CueMate provides tools to
            organize your session and record contextual information that can be beneficial for later
            review.{' '}
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="12">
          <h2 className="grey ">Session Structure</h2>
          <p>
            Tennis sessions typically involve continuous periods of play, that we call sets,
            interrupted by pauses. The first set is often used to warm up. ifferent sessions have
            different degree of organization. In free play, players usually rally and take breaks to
            rest. In training sessions, sets are often used to focus on specific aspects of their
            performance. In match point, the set structure is determined by the game.
          </p>
          <p>
            A typical session lasts between 60 and 120 minutes and a typical stroke count per hour
            is from 250 to 600 depending on the intensity. During play, the app populates the Stroke
            Map with data from the current set. During breaks you can review your performance using
            trend indicators on the stroke map. This information can be used to make decisions about
            your next set.
          </p>
        </Col>
      </Row>

      <Row>
        <Col sm={{ offset: 2, size: 8 }}>
          <img src={strokeMap} className="d-block mx-auto img-fluid" alt="Session set" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="12">
          <h2 className="grey">Tagging</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <p>
            CueMate provides tools to tag your sets and sessions. This information is useful when
            you review your sessions and also helps CueMate get contextual information about your
            performance.
          </p>
          <p>Tagging is divided into general tagging and extended tagging.</p>
          <p>General tagging includes the following categories:</p>
          <ul>
            <li>Partner Type: Coach, Partner, Self</li>
            <li>Partner Name</li>
            <li>Play Type: Warmup, Free play, Train, Game</li>
            <li>Cue Type: Tone and Voice</li>
          </ul>
        </Col>
        <Col className="d-flex align-items-center">
          <img src={tagging} className="d-block mx-auto img-fluid img-border" alt="Tagging" />{' '}
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <p>
            Extended tagging covers details that are useful to track your training performance,
            including shot types, court positions, exchange patterns.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 2, size: 8 }}>
          <img src={extendedTagging} className="d-block mx-auto img-fluid" alt="Session set" />
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

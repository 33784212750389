import React from 'react';
import { Col, Container, Row } from 'reactstrap';

// CSS
import './Social.css';

export default function Social() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Row>
        <Col sm={12}>
          <h1>Social</h1>
          <p>(in development)</p>
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import browse1 from '../../../../images/cuemate101/level3/key-concepts/browse/browse-1.png';
import browse2 from '../../../../images/cuemate101/level3/key-concepts/browse/browse-2.png';
import browse3 from '../../../../images/cuemate101/level3/key-concepts/browse/browse-3.png';
import browse4 from '../../../../images/cuemate101/level3/key-concepts/browse/browse-4.png';

import '../AppFeatures.css';

export default function BrowseFeatures() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h2>Browse Features</h2>
          <p>
            The CueMate Browse feature allows you to access your complete session history. It has
            multiple filter views, which give coaches and players the flexibility to look at
            progress in select areas or under specific play conditions.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={browse1}
            className="d-block mx-auto img-fluid img-border"
            alt="Review Features"
          />
        </Col>
      </Row>

      <h3 className="padding-top-25 blue">Session List</h3>
      <Row>
        <Col sm={4}>
          All previous sessions are listed, and the ‘+’ will show the sets for each session. Any
          Session or Set that is selected and in grey, is then being represented in the Stroke Map
          results. The metrics in the map are represented by the Stroke Metric Icons:
          <img src={browse2} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
        <Col sm={8}>
          <img src={browse3} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={8}>
          <img src={browse4} className="d-block mx-auto img-fluid" alt="Review: Skills" />
        </Col>
        <Col sm={4}>
          <h3 className="blue">Example Filter View</h3>
          <p>
            This player has used the Modality filter to select “Rallying”, and compile data from
            only rallying sets.
          </p>
          <p>
            The Session List on the left shows all the included sets (27). The stoke class counts
            reflect the totals for all sets, and give a great view of the core strokes and stroke
            distribution under the selected conditions.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level3/reports">
            <h3>
              Next: Reports <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

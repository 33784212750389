import React from 'react';

import { Col, Container, Row } from 'reactstrap';
// import images

import step1 from '../../images/getstarted/step1.PNG';
import step2 from '../../images/getstarted/step2.PNG';
import step3 from '../../images/getstarted/step3.PNG';
import step4 from '../../images/getstarted/step4.PNG';
import step5 from '../../images/getstarted/step5.PNG';
import step6 from '../../images/getstarted/step6.PNG';
import step7 from '../../images/getstarted/step7.PNG';
import step8 from '../../images/getstarted/step8.PNG';
import step9 from '../../images/getstarted/step9.PNG';
import step10 from '../../images/getstarted/step10.PNG';
import step11 from '../../images/getstarted/step11.PNG';

export default function Setup() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Row>
        <Col sm={8} className="padding-top-50">
          <h2>Download the beta app</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h4>
            The first time you install CueMate, you have to follow steps 5 to 10 to authorize your
            phone to trust the app.
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 1</h3>
          <img src={step1} className="d-block mx-auto img-fluid img-border" alt="step 1" />
          <p className="padding-top-25">
            If you are not already accessing this website (beta.icuemotion.com) on your{' '}
            <span className="bold">iPhone*</span> (with <span className="bold">WiFi</span>), do so
            now. Select <span className="bold">APPLY FOR CUEMATE BETA</span>.
          </p>
          <p className="padding-top-25 grey">* Most recent iOS version is required</p>
        </Col>
        <Col xs={3}>
          <h3 className="padding-top-25">Step 2</h3>
          <img src={step2} className="d-block mx-auto img-fluid img-border" alt="step 2" />
          <p className="padding-top-25">
            Enter your name and email address, then select <span className="bold">NEXT</span>
          </p>
        </Col>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 3</h3>
          <img src={step3} className="d-block mx-auto img-fluid img-border" alt="step 3" />
          <p className="padding-top-25">
            Fill out the rest of your information and select <span className="bold">REGISTER</span>
          </p>
        </Col>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 4</h3>
          <img src={step4} className="d-block mx-auto img-fluid img-border" alt="step 4" />
          <p className="padding-top-25">
            Select <span className="bold">LOGIN NOW</span> – login to access CueMate gated site
            (moving forward, you can access the gated version of www.beta.cuemate.com from your
            desktop computer as well)
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 5</h3>
          <img src={step5} className="d-block mx-auto img-fluid img-border" alt="step 5" />
          <p className="padding-top-25">
            After entering login credentials, you’ll be taken to the home screen – select{' '}
            <span className="bold">START USING CUEMATE</span>
          </p>
        </Col>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 6</h3>
          <img src={step6} className="d-block mx-auto img-fluid img-border" alt="step 6" />
          <p className="padding-top-25">
            From the Get Started page, select{' '}
            <span className="bold">SET UP YOUR CUEMATE SENSOR</span>
          </p>
        </Col>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 7</h3>
          <img src={step7} className="d-block mx-auto img-fluid img-border" alt="step 7" />
          <p className="padding-top-25">
            From the Sensor Setup page, scroll down and select{' '}
            <span className="bold">DOWNLOAD CUEMATE BETA APP</span>
          </p>
        </Col>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 8</h3>
          <img src={step8} className="d-block mx-auto img-fluid img-border" alt="step 8" />
          <p className="padding-top-25">
            Select <span className="bold">INSTALL</span> from the AWS popup
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 9</h3>
          <img src={step9} className="d-block mx-auto img-fluid img-border" alt="step 9" />
          <p className="padding-top-25">
            Now navigate to <span className="bold">SETTINGS</span> &gt;{' '}
            <span className="bold">GENERAL</span> &gt;{' '}
            <span className="bold">DEVICE MANAGEMENT *</span> &gt;{' '}
            <span className="bold">iCueMotion LLC</span> &gt; <span className="bold">TRUST</span>
          </p>
          <p className="padding-top-25 grey">
            * DEVICE MANAGEMENT may appear as PROFILES & DEVICE MANAGEMENT, or simply PROFILES,
            depending on which phone you have
          </p>
        </Col>

        <Col xs={6} sm={3}>
          <h3 className="padding-top-25">Step 10</h3>
          <img src={step10} className="d-block mx-auto img-fluid img-border" alt="step 10" />
          <p className="padding-top-25">
            Go back to the home screen on your phone – you should see the CueMate app on your phone
          </p>
        </Col>

        <Col xs={12} sm={6}>
          <h3 className="padding-top-25">Step 11</h3>
          <img src={step11} className="d-block mx-auto img-fluid img-border" alt="step 11" />
          <p className="padding-top-25">
            Log in with your username and password (note: press RETURN on the keyboard to hide it) –
            your account and CueMate app are all set up!
          </p>
        </Col>
      </Row>
    </Container>
  );
}

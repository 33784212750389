import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import warmup5 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-5.png';

import baseline1 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-1.png';
import baseline4 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-4.png';

import bhrally1 from '../../../../images/cuemate101/level2/set5/bh-rally-1.png';
import bhrally2 from '../../../../images/cuemate101/level2/set4/bh-rally-1.png';

import '../AppFeatures.css';

export default function LS5BHRally() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SET 5: BACKHAND RALLY – NO CUE</h2>
          <h3>Repeat Same Rally without Cues to Assess Learning &amp; Retention</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="4">
          <ol>
            <li>Press + to start a new Set</li>
            <img
              src={baseline1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="2">
            <li>Enter play conditions</li>
            <span className="blue">Technique, No Cue, Tags ON</span>
            <img
              src={bhrally1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">
            Rallying, Intensity Level 2, Middle, Ad Side, Baseline, Cross Court
          </span>
          <img
            src={bhrally2}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="3">
            <li>Begin Set</li>
            <p className="blue">
              Rally down +-50 balls the middle from the baseline, Ad side
              <br />
              Return again using only Backhand Topspin strokes, while retaining spin without cue
              feedback
              <br />
              Adjust intensity to achieve high spin while maintaining clean strokes
            </p>
          </ol>
        </Col>
        <Col sm="5">
          <br />
          <img src={baseline4} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="4">
            <li>Press Pause when finished</li>
            <img
              src={warmup5}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/set-6">
            <h3>
              Next: Set 6 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

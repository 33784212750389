import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table } from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumbs';

import hierarchicalDiagnostic from '../../../images/background/hierarchical-diagnostic.png';
import tennisBehaviorHierarchy2 from '../../../images/background/tennis-behavior-hierarchy-02.png';
import tennisBehaviorHierarchy3 from '../../../images/background/tennis-behavior-hierarchy-03.png';
import tennisBehaviorHierarchy4 from '../../../images/background/tennis-behavior-hierarchy-04.png';
import './Background.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>Skill Assessment and Training</h1>
          <h4 className="padding-top-10">
            The <Link to="/background/hierarchical-movement-skill-model">movement skill model</Link>{' '}
            describes how the various movement characteristics combine to form the players’ game and
            ultimately their skills.
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col>
          <h2 className="blueGrey">Skill Assessment and Training Goals</h2>
          <p>
            CueMate movement and learning model enables detailed skill assessment and diagnosis of
            skills, and ultimately the generation of training goals.{' '}
          </p>
          <p>The assessment and diagnosis follow the hierarchical skill model. </p>
          <ol className="with-spacing">
            <li>
              At the level of the <b>repertoire</b>, the assessment focuses on:
              <ol type="a" className="with-spacing">
                <li>what stroke classes you are using and</li>
                <li>the stroke classes&apos; learning stage.</li>
              </ol>
            </li>

            <li>
              At the level of each <b>stroke class</b>,
              <ol type="a" className="with-spacing">
                <li>the level of outcomes, in particular spin and ball speed.</li>
                <li>the specifics of the technique.</li>
              </ol>
            </li>
          </ol>
          <p>This process can be described in the following diagram:</p>
        </Col>
      </Row>
      <Row className="justify-content-center padding-15">
        <Col sm="6">
          <img
            src={hierarchicalDiagnostic}
            className="d-block mx-auto img-fluid img-border"
            alt="Hierarchical Diagnostic"
          />
        </Col>
      </Row>

      <Row>
        <p>
          The diagnostics uses the data from other players in your skill level or higher to provide
          reference values that make it possible to determine the expected range for all these
          parameters.
        </p>
      </Row>
    </Container>
  );
}

function CueMateScope() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row className="padding-bottom-15">
          <Col>
            <h2 className="blueGrey">CueMate Scope</h2>
            <h4>
              CueMate helps you build a versatile and effective stroke vocabulary so that you can
              focus on the tennis game.
            </h4>
            <p>
              Sports like tennis require more than great strokes and shots. To understand the scope
              of CueMate’s system, it is helpful to consider the complete information hierarchy.{' '}
            </p>
            <h3>Tennis Behavior Levels</h3>
            As shown in the illustration below, tennis has the following levels of behavior.
            Starting from the bottom:
            <ul className="with-spacing">
              <li>The strokes performance, which corresponds to the basic actions in tennis.</li>
              <li>
                Next we can consider how strokes are used in the context of a shot (level of
                ball-shot interaction).{' '}
              </li>
              <li>Then, how strokes and shots are combined in a sequence of exchanges.</li>
              <li>
                And ultimately how these exchanges are used in the context of points, games, and the
                match.
              </li>
            </ul>
          </Col>
        </Row>

        <Table bordered size="sm" striped>
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>Levels</th>
              <th>Elements</th>
              <th>Assessment/Diagnostic Components</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="vertical-column">
                <span>Game Performance</span>
              </th>
              <td className="vertical-column-img">
                Building games with their stroke/shot repertoire
                <p>6-3, 7-5, 3-6, 6-2</p>
              </td>
              <td>
                <p>
                  <b>Outcomes</b>: Sets, games, points
                </p>
                <p>
                  <b>Functional</b>: Combination of strategy, decision makng, and task-level
                  dynamics
                </p>
                <p>
                  <b>Perceptual</b>: Perception of opponent behavior across levels and game strategy
                </p>
                <p>
                  <b>Memory/Learning</b>: Use of the shot repertoire to achieve optimal game
                  performance
                </p>
              </td>
            </tr>
            <tr>
              <th className="vertical-column">
                <span>Exchange Performance</span>
              </th>
              <td className="vertical-column-img">
                Shot Exchange
                <img
                  src={tennisBehaviorHierarchy3}
                  className="d-block mx-auto img-fluid concept-icon"
                  alt="Shot Exchange"
                />
              </td>
              <td>
                <p>
                  <b>Outcomes</b>: Range of stroke/shot repertoire that achieves optimal
                  discretization task environment for a given skill level
                </p>
                <p>
                  <b>Functional</b>: Conditions to create desired outcomes, positioning on court
                </p>
                <p>
                  <b>Perceptual</b>: Environment elements, anticipation of shot conditions
                </p>
                <p>
                  <b>Memory/Learning</b>: Range of task environment elements
                </p>
              </td>
            </tr>
            <tr>
              <th className="vertical-column">
                <span>Shot Performance</span>
              </th>
              <td className="vertical-column-img">
                Stroke and Shot Trajectory
                <img
                  src={tennisBehaviorHierarchy2}
                  className="d-block mx-auto img-fluid concept-icon"
                  alt="Stroke and Shot Trajectory"
                />
              </td>
              <td>
                <p>
                  <b>Outcomes</b>: Ball strike characteristics, shot trajectory, and shot placement,
                  adaptation to conditions
                </p>
                <p>
                  <b>Functional</b>: Movement pattern, environment and task elements and cues
                </p>
                <p>
                  <b>Perceptual</b>: Ball anticipation and interception, extraction of cues
                </p>
                <p>
                  <b>Memory/Learning</b>: Movement pattern and ball motion in task environment
                </p>
              </td>
            </tr>
            <tr>
              <th className="vertical-column">
                <span>Stroke Performance</span>
              </th>
              <td className="vertical-column-img">
                Body and Racket Movement
                <img
                  src={tennisBehaviorHierarchy4}
                  className="d-block mx-auto img-fluid concept-icon"
                  alt="Body and Racket Movement"
                />
              </td>
              <td>
                <p>
                  <b>Outcomes</b>: Movement technique for efficient/optimal use of body segments
                </p>
                <p>
                  <b>Functional</b>: Coordination of body segments and muscle synergies
                </p>
                <p>
                  <b>Perceptual</b>: Proprioception, movement phase synchronization with task
                  elements
                </p>
                <p>
                  <b>Memory/Learning</b>: Movement patterns across body segments
                </p>
              </td>
            </tr>
          </tbody>
        </Table>

        <Row className="padding-top-15">
          <Col>
            Therefore, strokes are necessary but not sufficient to be a great player. So more
            generally, learning to become a proficient tennis player entails learning a complex
            hierarchy of behaviors from body movements, movement-environment interactions, and game
            strategy.
          </Col>
        </Row>

        <Row className="padding-top-25">
          <Col>
            <h3>Extended Analysis</h3>
            <p>
              CueMate uses cameras and computer vision techniques to capture the broader levels of
              behavior, in particular the ball-shot interactions.
            </p>
            <p>
              These capabilities are currently used for experiments and algorithm development but
              will eventually be available for users to analyze and train the broader scope of court
              movement behaviors and performance.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function HierarchicalSkillAssessmentTraining() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <CueMateScope />
      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Card, CardTitle, CardText, Button, CardBody, CardSubtitle, ButtonGroup } from 'reactstrap';
import { ClubType } from './GroupConstants';
import LoaderButton from '../../../../components/LoaderButton';
import { cuematePOST } from '../../../../libs/dataAccess';

export const ClubTabs = {
  GROUPS: 'GROUPS',
  CONTENT: 'CONTENT',
};

export default function ClubCard({
  name,
  description,
  type,
  clubId,
  isUserInGroup,
  isPendingRequest,
  onClubSelected,
  onJoinClub,
  onLeaveClub,
}) {
  const [isUserInClub, setIsUserInClub] = useState(isUserInGroup);
  const [isUserPendingRequest, setIsUserPendingRequest] = useState(isPendingRequest);
  const [isJoiningLeavingClub, setIsJoiningLeavingClub] = useState(false);
  const [isCancelingRequest, setIsCancelingRequest] = useState(false);

  const joinLeaveClub = async (event) => {
    event.preventDefault();
    setIsJoiningLeavingClub(true);
    try {
      const currentSession = await Auth.currentSession();
      if (!isUserInClub) {
        const url = `/groups/clubs/${type}/${clubId}/join`;
        const response = await cuematePOST(url, currentSession.accessToken.jwtToken);
        if (response.id !== undefined && response.id.length > 0) setIsUserInClub(true);
        if (type === ClubType.INVITE_ONLY) setIsUserPendingRequest(true);
        if (onJoinClub !== undefined) {
          onJoinClub();
        }
      } else {
        const url = `/groups/clubs/${type}/${clubId}/leave`;
        const response = await cuematePOST(url, currentSession.accessToken.jwtToken);
        if (response.id !== undefined && response.id.length > 0) setIsUserInClub(false);
        if (type === ClubType.INVITE_ONLY) setIsUserPendingRequest(false);
        if (onLeaveClub !== undefined) {
          onLeaveClub();
        }
      }
      setIsJoiningLeavingClub(false);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelRequestToJoinAClub = async (event) => {
    event.preventDefault();
    setIsCancelingRequest(true);
    try {
      const currentSession = await Auth.currentSession();
      const url = `/groups/clubs/${type}/${clubId}/join/cancel`;
      const response = await cuematePOST(url, currentSession.accessToken.jwtToken);
      if (response.id !== undefined && response.id.length > 0) setIsUserInClub(false);
      if (type === ClubType.INVITE_ONLY) setIsUserPendingRequest(false);
      if (onJoinClub !== undefined) {
        onJoinClub();
      }
      setIsCancelingRequest(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onVisitClick = () => {
    onClubSelected(clubId, type);
  };

  const renderInviteOnlyButton = useCallback(() => {
    if (isUserPendingRequest) {
      return (
        <LoaderButton
          isLoading={isCancelingRequest}
          color="primary"
          outline
          text="Cancel request to join"
          onClick={cancelRequestToJoinAClub}
        />
      );
    }
    if (isUserInClub) {
      return (
        <>
          <Button color="primary" outline onClick={onVisitClick}>
            Visit
          </Button>
          <LoaderButton
            isLoading={isJoiningLeavingClub}
            color="primary"
            outline
            text="Leave"
            onClick={joinLeaveClub}
          />
        </>
      );
    }
    return (
      <LoaderButton
        isLoading={isJoiningLeavingClub}
        color="primary"
        outline
        text="Request to join"
        onClick={joinLeaveClub}
      />
    );
  }, [isUserPendingRequest, isUserInClub]);

  return (
    <div className="col">
      <Card className="h-100">
        <CardBody className="d-flex flex-column">
          <CardTitle tag="h5">{name}</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {type}
          </CardSubtitle>
          <CardText>{description}</CardText>
          <ButtonGroup className="mt-auto" size="sm">
            {type === ClubType.INVITE_ONLY ? (
              renderInviteOnlyButton()
            ) : (
              <>
                <Button color="primary" outline onClick={onVisitClick}>
                  Visit
                </Button>
                <LoaderButton
                  isLoading={isJoiningLeavingClub}
                  color="primary"
                  outline
                  text={isUserInClub ? 'Leave' : 'Join'}
                  onClick={joinLeaveClub}
                />
              </>
            )}
          </ButtonGroup>
        </CardBody>
      </Card>
    </div>
  );
}

ClubCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  clubId: PropTypes.string,
  isUserInGroup: PropTypes.bool,
  isPendingRequest: PropTypes.bool,
  onClubSelected: PropTypes.func,
  onJoinClub: PropTypes.func,
  onLeaveClub: PropTypes.func,
};

import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { Container, Col, Row } from 'reactstrap';
import LoadingStateComponent from '../../../components/LoadingStateComponent';
import LoaderButton from '../../../components/LoaderButton';
import StrokeMap from './data/StrokeMap';
import Feed from './Feed';
import SessionData from './data/SessionStats';
import { cuemateGET } from '../../../libs/dataAccess';
import { FeedsType } from './SocialConstants';

const processSessionsData = (username, data) => {
  const reducer = (accumulator, currentValue) => ({
    [currentValue.name]: currentValue.value,
    ...accumulator,
  });
  let tennisSessions = data.filter(
    (session) => session.profilesOfClasses !== undefined && session.profilesOfClasses.length > 0
  );
  tennisSessions = tennisSessions.map((session) => ({
    id: session.id,
    user: session.userAlias || session.user,
    startTS: session.startTS,
    endTS: session.endTS,
    location: session.location,
    strokes: session.numberMotionUnits,
    duration: session.duration,
    successRate: session.successRate,
    score: session.sessionScoreTechnique,
    profilesOfClasses: session.profilesOfClasses.map((classData) => ({
      id: classData.id,
      user: classData.user,
      classification: classData.classification,
      count: classData.count,
      ...classData.metrics.reduce(reducer, {}),
      ...(classData.trends && classData.trends.reduce(reducer, {})),
    })),
    commentsCount: session.commentsCount || 0,
    reactions: session.reactions || [],
    isLikedByUser: session.reactions.findIndex((el) => el.username === username) >= 0,
  }));

  tennisSessions.sort((session1, session2) =>
    dayjs(session1.endTS).isAfter(dayjs(session2.endTS)) ? -1 : 1
  );

  return tennisSessions;
};

function FeedsHandler({ typeOfFeeds, clubType = undefined, currentGroupId = undefined }) {
  const LIMIT = 10;

  const renderFeeds = (sessions) => {
    const feedsComponent = [];

    sessions.forEach((session) => {
      const { profilesOfClasses, ...restOfSession } = session;
      const data = {
        session: false,
        classKeys: ['classification'],
        elements: profilesOfClasses || [],
      };

      feedsComponent.push(
        <Feed
          key={session.id}
          user={{ displayName: restOfSession.user, data: restOfSession }}
          mainContent={<StrokeMap {...data} />}
          subcontent={<SessionData {...restOfSession} />}
          socialData={{
            commentsCount: session.commentsCount,
            reactions: session.reactions,
            sessionId: session.id,
            isLikedByUser: session.isLikedByUser,
          }}
        />
      );
    });
    return feedsComponent;
  };

  const [isLoadingMoreFeeds, setIsLoadingMoreFeeds] = useState(false);
  const [displayedFeeds, setDisplayedFeeds] = useState([]);
  const [hasMoreFeeds, setHasMoreFeeds] = useState(false);
  const [minModified, setMinModified] = useState(Number.MAX_VALUE);
  const [isLoadingInitialFeeds, setIsLoadingInitialFeeds] = useState(true);

  const fetchFeedsData = useCallback(
    // eslint-disable-next-line no-shadow
    async (type, limit, currentGroupId = undefined, minModified = undefined) => {
      let url = `/users/social/feeds/${type}?type=tennis&limit=${limit}`;

      if (type === FeedsType.GROUPS && currentGroupId !== undefined && currentGroupId.length > 0) {
        url = `/groups/clubs/${clubType}/${currentGroupId}/data?limit=${limit}`;
      }

      if (minModified !== undefined) {
        url += `&modifiedBefore=${minModified}`;
      }

      try {
        const currentSession = await Auth.currentSession();
        const response = await cuemateGET(url, currentSession.accessToken.jwtToken);
        response.username = currentSession.accessToken.payload.username;

        return response;
      } catch (error) {
        console.error('Error fetching sessions', error);
      }
      return {};
    },
    [minModified, currentGroupId]
  );

  const fetchMoreFeeds = useCallback(() => {
    async function fetch() {
      try {
        setIsLoadingMoreFeeds(true);
        const response = await fetchFeedsData(typeOfFeeds, LIMIT, currentGroupId, minModified);
        const newFeeds = processSessionsData(response.username, response.data);
        setDisplayedFeeds([...displayedFeeds, ...newFeeds]);
        setHasMoreFeeds(response.hasMore);
        setMinModified(response.minModified);
        setIsLoadingMoreFeeds(false);
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [minModified, displayedFeeds, currentGroupId]);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await fetchFeedsData(typeOfFeeds, LIMIT, currentGroupId);
        setDisplayedFeeds(processSessionsData(response.username, response.data));
        setHasMoreFeeds(response.hasMore);
        setMinModified(response.minModified);
        setIsLoadingInitialFeeds(false);
      } catch (err) {
        console.error(err);
      }
    }
    if (
      typeOfFeeds === FeedsType.GROUPS &&
      (currentGroupId === undefined || currentGroupId.length === 0)
    ) {
      return;
    }
    fetch();
  }, [minModified, currentGroupId]);

  if (
    typeOfFeeds === FeedsType.GROUPS &&
    (currentGroupId === undefined || currentGroupId.length === 0)
  ) {
    return null;
  }

  if (isLoadingInitialFeeds) return <LoadingStateComponent />;

  return (
    <Container className="feeds-container animated fadeIn">
      {displayedFeeds.length === 0 ? (
        <>
          <hr className="colorgraph" />
          <h3 className="padding-top-25 text-center">No available feeds yet. Add some friends!</h3>
        </>
      ) : (
        <>
          {renderFeeds(displayedFeeds)}
          <Row className="d-flex justify-content-center">
            <Col sm="3" md="3">
              <LoaderButton
                onClick={fetchMoreFeeds}
                text={hasMoreFeeds ? 'Load more' : 'No more feeds'}
                color="primary"
                size="sm"
                loadingText="Loading..."
                isLoading={isLoadingMoreFeeds}
                disabled={isLoadingMoreFeeds || !hasMoreFeeds}
                block
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

FeedsHandler.propTypes = {
  typeOfFeeds: PropTypes.string,
  clubType: PropTypes.string,
  currentGroupId: PropTypes.string,
};

export default FeedsHandler;

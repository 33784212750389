import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Col, Row, Table, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as d3 from 'd3';
import { cuemateGET, useAsyncV2 } from '../../../../../libs/dataAccess';
import LoadingStateComponent from '../../../../../components/LoadingStateComponent';
import processLeaderboardData from '../util/utils';
import LeaderboardType from '../../../social/leaderboard/LeaderboardConstants';

function LeaderboardOverviewRow({ leaderboardData, rowClickHandler, groupId }) {
  if (
    leaderboardData === undefined ||
    leaderboardData === null ||
    Object.keys(leaderboardData).length === 0
  )
    return null;
  const scores = leaderboardData.score;
  const keys = Object.keys(leaderboardData.user);
  let leaderboardLeadPosition = keys[0];
  const leaderboardLeadValue = scores[keys[0]];
  for (let i = 0; i < keys.length; i += 1) {
    if (scores[keys[i]] > leaderboardLeadValue) {
      leaderboardLeadPosition = keys[i];
    }
  }
  const leaderboardLeadUser =
    (leaderboardData.user && leaderboardData.user[leaderboardLeadPosition]) || '-';
  const leaderboardScore =
    (leaderboardData.score && leaderboardData.score[leaderboardLeadPosition]) || '-';
  const leaderboardClassEntropy =
    (leaderboardData['class entropy'] &&
      leaderboardData['class entropy'][leaderboardLeadPosition]) ||
    '-';
  const leaderboardImpactSuccess =
    (leaderboardData['impact success'] &&
      leaderboardData['impact success'][leaderboardLeadPosition]) ||
    '-';

  const tabToCall = `${groupId}+LEADERBOARD`;
  return (
    <>
      <Row className="black">
        <Col sm="12">
          <h4 className="padding-top-50 tracking">GROUP LEADERBOARD</h4>
        </Col>
      </Row>
      <a
        href={`#${tabToCall}`}
        onClick={() => {
          rowClickHandler(tabToCall);
        }}
      >
        <Row className="black grey-bg padding-top-25">
          <Col sm="4">
            <h4> {leaderboardLeadUser} </h4>
            <p className="grey tracking">LEAD USER FROM GROUP</p>
          </Col>
          <Col sm="2">
            <h4>
              {' '}
              {
                // eslint-disable-next-line no-restricted-globals
                isNaN(leaderboardScore) ? leaderboardScore : (leaderboardScore * 100).toFixed(1)
              }{' '}
            </h4>
            <p className="grey tracking">GLOBAL SCORE</p>
          </Col>
          <Col sm="2">
            <h4>
              {' '}
              {
                // eslint-disable-next-line no-restricted-globals
                isNaN(leaderboardClassEntropy)
                  ? leaderboardClassEntropy
                  : leaderboardClassEntropy.toFixed(2)
              }{' '}
            </h4>
            <p className="grey tracking">VERSATILITY</p>
          </Col>
          <Col sm="3">
            <h4>
              {' '}
              {
                // eslint-disable-next-line no-restricted-globals
                isNaN(leaderboardImpactSuccess)
                  ? leaderboardImpactSuccess
                  : `${(leaderboardImpactSuccess * 100).toFixed(2)}%`
              }{' '}
            </h4>
            <p className="grey tracking">IMPACT SUCCESS RATE</p>
          </Col>
          <Col sm="1">
            <h4 className="text-right pull-right">
              <i className="fa fa-chevron-right blue" aria-hidden="true" />
            </h4>
          </Col>
        </Row>
      </a>
    </>
  );
}

LeaderboardOverviewRow.propTypes = {
  leaderboardData: PropTypes.any,
  groupId: PropTypes.string,
  rowClickHandler: PropTypes.func,
};

async function getLeaderboard(
  leaderboardType = LeaderboardType.GLOBAL,
  groupId = undefined,
  clubType = undefined
) {
  if (
    leaderboardType === LeaderboardType.GROUP &&
    (groupId === undefined || groupId.length === 0) &&
    (clubType === undefined || clubType.length === 0)
  ) {
    return [];
  }

  let url = '/users/social/leaderboard';
  if (leaderboardType === LeaderboardType.FRIENDS) {
    url = '/users/social/leaderboard/friendships';
  } else if (leaderboardType === LeaderboardType.GROUP) {
    url = `/groups/clubs/${clubType}/${groupId}/leaderboard`;
  }
  const currentSession = await Auth.currentSession();

  const leaderboardData = await cuemateGET(url, currentSession.accessToken.jwtToken);
  return processLeaderboardData(leaderboardData.data);
}

export function Leaderboard({
  leaderboardType,
  username,
  showLeaderboardOverview = false,
  groupId = undefined,
  clubType = undefined,
  onLeaderboardContentChange = undefined,
}) {
  const [currentGroupId, toggleGroup] = useState(0);
  const [showLeaderboard, setShowLeaderboard] = useState(!showLeaderboardOverview);

  const onClickToOpenTab = () => {
    setShowLeaderboard(true);
    if (onLeaderboardContentChange !== undefined) onLeaderboardContentChange();
  };

  useEffect(() => {
    setShowLeaderboard(!showLeaderboardOverview);
  }, [showLeaderboardOverview]);

  const {
    loading: isLoadingLeaderboardData,
    error: loadingLeaderboardDataError,
    value: leaderboardData,
  } = useAsyncV2(getLeaderboard, [leaderboardType, groupId, clubType]);

  const config = [
    { name: 'id', label: 'Name', fix: true, style: 'bold' },
    {
      name: 'score',
      label: 'Global Score',
      format: (d) => d3.format('.1f')(d * 100),
      style: 'brandBlue',
    },
    { name: 'groundstroke score', label: 'GS Score', format: (d) => d3.format('.1f')(d * 100) },
    { name: 'serve score', label: 'SV Score', format: (d) => d3.format('.1f')(d * 100) },
    { name: 'volley score', label: 'VL Score', format: (d) => d3.format('.1f')(d * 100) },
    { name: 'class entropy', label: 'Versatility', format: (d) => d3.format('.2f')(d) },
    { name: 'impact success', label: 'Sweet Spot', format: (d) => `${d3.format('.1f')(d * 100)}%` },
    { name: 'impact precision', label: 'Impact Precision', format: (d) => d3.format('.2f')(d) },
    // { name: 'stroke consistency', label: 'Stroke Consistency', format: (d) => d3.format('.2f')(d) },
    {
      name: 'max swing',
      label: 'Max Head Speed',
      unit: 'rad/s',
      format: (d) => d3.format('.1f')(d),
    },
    { name: 'max pace', label: 'Max Pace', unit: 'm/s', format: (d) => d3.format('.1f')(d) },
    { name: 'max spin', label: 'Max Spin', unit: 'rpm', format: (d) => d3.format('.1f')(d) },
    // { name: 'max envelope', label: 'Envelope', format: (d) => d3.format('.1f')(d) },
  ];

  const [sortId, toggleSort] = useState(config.find((d) => !d.fix).name);

  if (isLoadingLeaderboardData) return <LoadingStateComponent />;

  const leaderboards = [leaderboardData];
  const leaderboard = leaderboards[currentGroupId];

  if (leaderboards[currentGroupId].user === undefined) return null;

  const userPositions = Object.keys(leaderboard.user);
  leaderboard.id = {};
  userPositions.forEach((userPosition) => {
    const name = leaderboard.user[userPosition];
    leaderboard.id[userPosition] = name;
  });

  const getSortedPositions = (name) => {
    const object = userPositions.map((p) => ({ key: p, value: leaderboard[name][p] }));
    object.sort((a, b) => b.value - a.value);
    return object.map((d) => d.key);
  };
  const sortedPositions = getSortedPositions(sortId);

  const renderNav = () => {
    if (leaderboards.length <= 1) return null;
    return (
      <Nav tabs className="d-flex text-center row">
        {
          // eslint-disable-next-line no-shadow
          leaderboards.map((leaderboard, k) => (
            <NavItem key={leaderboard.group} style={{ flex: 1 }}>
              <NavLink
                active={currentGroupId === k}
                style={{ backgroundColor: currentGroupId === k ? '#EEE' : 'white' }}
                onClick={() => toggleGroup(k)}
              >
                {leaderboard.group.toUpperCase()}
              </NavLink>
            </NavItem>
          ))
        }
      </Nav>
    );
  };

  const renderHeader = () => (
    <thead>
      <tr>
        {config.map((item) => (
          <th
            key={`header-${item.name}`}
            className={item.style ? 'bold' : 'grey'}
            onClick={item.fix ? () => {} : () => toggleSort(item.name)}
          >
            {item.label + (item.unit ? ` (${item.unit})` : '')}
            {item.name === sortId && <i className="fa fa-caret-down" aria-hidden="true" />}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderRow = (userPosition) => (
    <tr
      key={`row${userPosition}`}
      className={classnames({
        'bg-highlight': leaderboard.user[userPosition] === username,
      })}
    >
      {config.map((item) => (
        <td key={`row-${item.name}`} className={item.style || 'grey'}>
          {item.format
            ? item.format(leaderboard[item.name][userPosition])
            : leaderboard[item.name][userPosition]}
        </td>
      ))}
    </tr>
  );

  if (loadingLeaderboardDataError) {
    console.error(loadingLeaderboardDataError);
    return <LoadingStateComponent />;
  }

  if (!showLeaderboard) {
    return (
      <LeaderboardOverviewRow
        leaderboardData={leaderboardData}
        groupId={groupId}
        rowClickHandler={onClickToOpenTab}
      />
    );
  }
  return (
    <>
      {renderNav()}
      <Row className="black">
        <Col sm="12">
          <Table striped hover responsive>
            {renderHeader()}
            <tbody>{sortedPositions.map((userPosition) => renderRow(userPosition))}</tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

Leaderboard.propTypes = {
  leaderboardType: PropTypes.string,
  username: PropTypes.string,
  showLeaderboardOverview: PropTypes.bool,
  groupId: PropTypes.string,
  clubType: PropTypes.string,
  onLeaderboardContentChange: PropTypes.func,
};

export default connect((state) => ({
  leaderboardType: state.leaderboardType,
  username: state.username,
}))(Leaderboard);

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import strokesandshots from '../../../../images/processes/strokesandshots.png';
import tracking from '../../../../images/processes/tracking.png';

import review from '../../../../images/screens/Review-StrokeMap.PNG';
import ReviewScreen from './ReviewScreen';

import './AppFeatures.css';

export default function ReviewMode() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-25">
        <Col sm="12">
          <h1>Review Mode</h1>
        </Col>
      </Row>
      <Row className="padding-top-100">
        <Col sm="6">
          <figure className="figure">
            <img
              src={strokesandshots}
              className="d-block mx-auto img-fluid"
              alt="Strokes, shots and outcomes"
            />
            <figcaption className="figure-caption text-right">
              Strokes, shots and outcomes
            </figcaption>
          </figure>
        </Col>
        <Col sm="6">
          <figure className="figure">
            <img
              src={tracking}
              className="d-block mx-auto img-fluid"
              alt="Determining stroke names based on outcomes"
            />
            <figcaption className="figure-caption text-right">
              Determining stroke names based on outcomes
            </figcaption>
          </figure>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h4 className="padding-top-25">
            CueMate’s Review mode is the assessment and diagnostic component of deliberate training.
            The primary goal here is to identify areas of potential for improvements with the aim of
            obtaining actionable insights that can be turned into interventions.
          </h4>
          <h4 className="padding-top-25">
            After or during play, the player or their coach can use the enhanced Stroke Map to
            identify their strengths and deficiencies at different levels of the movement
            performance (movement technique, outcomes, repertoire, and game).
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={6}>
          <h2 className="blueGrey">Enhanced Stroke Map</h2>
          <p>
            As seen in Play mode, CueMate records and organizes strokes on the Stroke Map which is
            enhanced by color highlights to help quickly identify the overall quality of the stroke
            types. A player can review each stroke’s outcomes — as well as their performance and
            technique — by tapping on a stroke type and reviewing its metrics data.
          </p>
          <p>
            At the top of the Review Screen, players can filter by various time periods (i.e.,
            previous session up to the last 10 sessions); repertoire (all strokes, core strokes,
            skill status); and repertoire level assessment (count, ball speed, ball spin, sweet
            spot).
          </p>
          <p>
            The Review mode is augmented by the stroke quality function to help players or coaches
            diagnose across the overall repertoire and identify gaps or weaknesses. Use this
            function to proceed top-down to identify weakest elements in the repertoire, then select
            the metrics view to gain insights about the deficiencies’ causes.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={review}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Forehand flat hard"
          />
        </Col>
      </Row>
      <hr />

      <ReviewScreen />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

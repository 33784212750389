import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import warmup4 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-4.png';
import warmup5 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-5.png';

import baseline1 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-1.png';
import baseline4 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-4.png';

import isr1 from '../../../../images/cuemate101/level1/fundamentals/set3/isr-1.png';
import isr2 from '../../../../images/cuemate101/level1/fundamentals/set3/isr-2.png';

import '../AppFeatures.css';

export default function L1S3ISR() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SET 3: ISR CUE</h2>
          <h3>Freeplay, ISR Cue</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="4">
          <ol>
            <li>Press + to start a new Set</li>
            <img
              src={baseline1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="2">
            <li>Enter play conditions</li>
            <span className="blue">Freeplay, W. Cue, Tags ON</span>
            <img
              src={isr1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">Cueing ISR, Middle, Baseline</span>
          <img src={isr2} className="d-block mx-auto img-fluid img-border" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="3">
            <li>Begin Set</li>
            <span className="blue">
              Once the stroke map is displayed, set phone on bench or nearby bench or table
            </span>
            <img
              src={warmup4}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <p className="blue">
            Rally down +-50 balls the middle from the baseline
            <br />
            Adjust the effort intensity to achieve best ball impact success rate
            <br />
            You will hear a positive ISR cue for clean hits where the ball contacts the racket’s
            “sweet spot”
          </p>
          <img src={baseline4} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="4">
            <li>Press Pause when finished</li>
            <img
              src={warmup5}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate101/level1/court-exercise/set-4">
            <h3>
              Next: Set 4 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Train.css';

export default function SkillAssessment() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm={12}>
          <h1>Skill Assessment Overview</h1>
          <h4>
            Skill Assessment is at the core of the CueMate training system. A precise and complete
            assessment provides the basis for effective training (so called deliberate training).
          </h4>
          <h4>
            After 2-3 sessions of using CueMate, there should be sufficient strokes to perform an
            initial assessment. You will get a first idea of your stroke repertoire, and for each
            stroke class, metrics provide insights about a range of specific stroke attributes.
          </h4>
          <p>
            The following sections provide some guidelines to assessing your skills across the
            various metrics and scores, etc. that are computed by CueMate.
          </p>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React, { useState } from 'react';
import { TabContent, TabPane, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import FeedsHandler from '../FeedsHandler';
import { FeedsType } from '../SocialConstants';
import { Leaderboard } from '../../home/reports/components/Leaderboard';
import LeaderboardType from '../leaderboard/LeaderboardConstants';
import ClubCard, { ClubTabs as Tabs } from './ClubCard';
import ListOfClubMembers from './ListOfClubMembers';
import './ClubsDeck.css';

export default function MyClubsDeck({ userGroups, onLeaveClub }) {
  if (userGroups.length === 0) return null;

  const [activeTab, setActiveTab] = useState(Tabs.GROUPS);
  const [currentGroupId, setCurrentGroupId] = useState('');
  const [currentClubType, setCurrentClubType] = useState('');
  const [showFeeds, setShowFeeds] = useState(true);
  const [showLeaderboardOverview, setShowLeaderboardOverview] = useState(true);

  const onClubSelected = (clubId, clubType) => {
    setCurrentGroupId(clubId);
    setCurrentClubType(clubType);
    setActiveTab(Tabs.CONTENT);
  };

  const onLeaderboardContentChangeHandler = () => {
    setShowFeeds(false);
    setShowLeaderboardOverview(false);
  };

  const getCurrentGroupName = () => {
    const groupIndex = userGroups.findIndex((club) => club.id === currentGroupId);
    if (groupIndex < 0) return 'empty';
    return userGroups[groupIndex].name;
  };

  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId={Tabs.GROUPS}>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {userGroups.map((club) => (
            <ClubCard
              key={club.id}
              name={club.name}
              description={club.description}
              type={club.clubType}
              clubId={club.id}
              isUserInGroup
              onClubSelected={onClubSelected}
              onLeaveClub={onLeaveClub}
            />
          ))}
        </div>
      </TabPane>
      <TabPane tabId={Tabs.CONTENT}>
        <Row className="mt-2">
          <Col sm="12">
            <Breadcrumb tag="nav">
              <BreadcrumbItem
                tag="a"
                href="#group-data"
                className="tracking"
                onClick={() => {
                  setActiveTab(Tabs.GROUPS);
                }}
              >
                GROUPS
              </BreadcrumbItem>
              {activeTab === Tabs.CONTENT && (
                <BreadcrumbItem
                  tag="a"
                  href="#group-data"
                  className="tracking"
                  onClick={() => {
                    setShowFeeds(true);
                    setShowLeaderboardOverview(true);
                  }}
                >
                  {getCurrentGroupName()}
                </BreadcrumbItem>
              )}
              {activeTab === Tabs.CONTENT && !showFeeds && (
                <BreadcrumbItem tag="a" href="#group" className="tracking" onClick={() => {}}>
                  LEADERBOARD
                </BreadcrumbItem>
              )}
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <ListOfClubMembers clubType={currentClubType} clubId={currentGroupId} />
          </Col>
        </Row>
        <Row className="black">
          <Col sm="12">
            <Leaderboard
              leaderboardType={LeaderboardType.GROUP}
              groupId={currentGroupId}
              clubType={currentClubType}
              onLeaderboardContentChange={onLeaderboardContentChangeHandler}
              showLeaderboardOverview={showLeaderboardOverview}
            />
          </Col>
        </Row>
        {showFeeds && (
          <>
            <Row className="black mb-3">
              <Col sm="12">
                <h4 className="padding-top-50 tracking">GROUP FEEDS</h4>
              </Col>
            </Row>
            <Row className="black">
              <Col sm="12">
                <FeedsHandler
                  typeOfFeeds={FeedsType.GROUPS}
                  currentGroupId={currentGroupId}
                  clubType={currentClubType}
                />
              </Col>
            </Row>
          </>
        )}
      </TabPane>
    </TabContent>
  );
}

MyClubsDeck.propTypes = {
  userGroups: PropTypes.array,
  onLeaveClub: PropTypes.func,
};

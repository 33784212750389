import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export default function LoaderButton({
  isLoading,
  text,
  loadingText,
  className = '',
  disabled = false,
  tag = 'button',
  showSpinner = true,
  ...props
}) {
  return (
    <Button className={className} disabled={disabled || isLoading} tag={tag} {...props}>
      {isLoading && showSpinner && <i className="fa fa-spinner fa-pulse fa-fw" />}
      {!isLoading ? text : loadingText}
    </Button>
  );
}

LoaderButton.propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tag: PropTypes.string,
  showSpinner: PropTypes.bool,
};

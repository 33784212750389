import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../../images/landingpage/Cue_Mate_Tennis_Logo.svg';
import iCueMotionLogo from '../../images/landingpage/IcueMotion_Logo.svg';
import Pdf from './signup/CueMate-Terms-and-Conditions.pdf';
import './Footer.css';

// eslint-disable-next-line react/display-name
export default function Gated() {
  return (
    <footer className="black-bg footer">
      <Container fluid className="black-bg">
        <Row className="small-font">
          <Col sm={{ size: 2 }} className="text-center my-auto font-VR-Regular">
            <img alt="CueMate Logo" src={Logo} />
          </Col>
          <Col sm={{ size: 2 }} className="text-center my-auto font-VR-Regular">
            <a href="https://icuemotion.com/" target="_blank" rel="noopener noreferrer">
              <img alt="iCueMotion Logo" src={iCueMotionLogo} />
            </a>
          </Col>
          <Col sm={{ size: 2, offset: 2 }} className="text-left font-VR-Regular">
            <ul className="list-unstyled">
              <li className="blue-font font-VR-DemiBold">CONTACT</li>
              <li className="text-white">info@icuemotion.com</li>
              <li className="text-white">415-93-1CUE</li>
            </ul>
          </Col>
          <Col sm={{ size: 2 }} className="text-left font-VR-Regular">
            <ul className="list-unstyled">
              <li className="blue-font font-VR-DemiBold">MAILING ADDRESS</li>
              <li className="text-white">584 Castro Street PMB 412</li>
              <li className="text-white">San Francisco, CA 94114-2594</li>
            </ul>
          </Col>
          <Col sm={{ size: 2 }} className="text-left blue-font">
            <ul className="list-unstyled">
              <li className="blue-font font-VR-DemiBold">
                <Link to="/privacy" className="blue-font text-decoration-none">
                  PRIVACY
                </Link>
              </li>
              <li className="blue-font font-VR-DemiBold">
                <a
                  href={Pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="blue-font text-decoration-none"
                >
                  TERMS
                </a>
              </li>
            </ul>
            <ul className="list-unstyled footer-icon-font mt-auto">
              <li className="list-horizontal no-margin-left">
                <i className="fa fa-linkedin" aria-hidden="true" />
              </li>
              <li className="list-horizontal">
                <i className="fa fa-twitter" aria-hidden="true" />
              </li>
              <li className="list-horizontal">
                <i className="fa fa-instagram" aria-hidden="true" />
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

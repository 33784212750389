import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level1/on-court/recording-sessions/RecordingSessions-1.png';
import view2 from '../../../../images/cuemate101/level1/on-court/recording-sessions/RecordingSessions-2.png';
import view3 from '../../../../images/cuemate101/level1/on-court/recording-sessions/RecordingSessions-3.png';
import view4 from '../../../../images/cuemate101/level1/on-court/recording-sessions/RecordingSessions-4.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Recording Sessions</h1>
          <p>
            CueMate captures stroke data during each session and keeps a record of your performance
            over time.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">SENSOR CONNECTIONS</h3>
          <p>Once sensor is connected, hit Start Session to start recording strokes.</p>
          <p>Check the session timer to confirm that the session is recording.</p>
          <p>Tips</p>
          <ul>
            <li>Check that sensor is charged and is connected via Bluetooth</li>
            <li>Keep phone close to playing area</li>
          </ul>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid " alt="Recording Sessions 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row>
          <Col sm={7} className="padding-top-50">
            <img src={view2} className="d-block mx-auto img-fluid " alt="Recording Sessions 2" />
          </Col>
          <Col sm={5}>
            <h3 className="padding-top-25 blue">SESSION AND SET TAGS</h3>
            <p>
              Tags are used to document your playing conditions. Later, you can use this information
              to compare and analyze your performance data across these different conditions.
            </p>
            <h3 className="blue">PLAY TYPES</h3>
            <ul>
              <li>Warmup: Use for warmup</li>
              <li>Freeplay: Use for rallying</li>
              <li>Train: Use while training specific stroke elements</li>
              <li>Game: Use while playing games, sets and matches</li>
            </ul>
            <h3 className="blue">CUES</h3>
            <ul>
              <li>No cue</li>
              <li>Tone: Use musical sound for cues</li>
              <li>Voice: Use a human voice for cues</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row>
        <Col sm={5}>
          <h3 className="padding-top-25 blue">RICH TAGGING</h3>
        </Col>
        <Col sm={7}>
          <img src={view3} className="d-block mx-auto img-fluid " alt="Recording Sessions 3" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen4() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={7}>
            <img src={view4} className="d-block mx-auto img-fluid " alt="Recording Sessions 4" />
          </Col>
          <Col sm={5}>
            <h3 className="blue padding-top-25">PLAY CONTROLLER</h3>
            <p>
              Once the session is started, use the session controller to:
              <ul>
                <li>Edit Set Tags</li>
                <li>Pause Set: To pause recording during sets</li>
                <li>Start New Set</li>
                <li>
                  End Session: When finished playing, tap the red Stop button to stop recording and
                  upload your session data to the CueMate database.
                </li>
              </ul>
              You can view your session reports in app or a detailed report on the website
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function RecordingSessions() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />
      <Screen4 />
      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React, { useState } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useAsyncV2, cuemateGET } from '../../../../libs/dataAccess';
import onError from '../../../../libs/errorLib';
import LoadingStateComponent from '../../../../components/LoadingStateComponent';
import MyClubsDeck from './MyClubsDeck';
import { GroupType } from './GroupConstants';

export default function MyClubs({ refreshGroups, onLeaveClub }) {
  const [shouldRefreshData, setShouldRefreshData] = useState(refreshGroups);
  const listClubs = async () => {
    const currentSession = await Auth.currentSession();
    let userGroups = [];
    try {
      userGroups = await cuemateGET(
        `/users/social/groups?groupType=${GroupType.CLUBS}`,
        currentSession.accessToken.jwtToken
      );
    } catch (error) {
      onError(error);
    }

    return userGroups;
  };

  const onLeaveClubHandler = () => {
    setShouldRefreshData(!shouldRefreshData);
    if (onLeaveClub !== undefined) onLeaveClub();
  };

  const { value: data, error, loading } = useAsyncV2(listClubs, [refreshGroups, shouldRefreshData]);

  if (loading) {
    return <LoadingStateComponent loadingText="Loading clubs.." />;
  }

  const userGroups = data || [];

  if (error) {
    onError(error);
    return <LoadingStateComponent loadingText="Error loading clubs. Refresh to try again." />;
  }

  return (
    <Container className="animated fadeIn padding-bottom-50">
      <MyClubsDeck userGroups={userGroups} onLeaveClub={onLeaveClubHandler} />
    </Container>
  );
}

MyClubs.propTypes = {
  refreshGroups: PropTypes.bool,
  onLeaveClub: PropTypes.func,
};

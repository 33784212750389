import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level3/on-court/custom-training-goals/CustomTrainingGoal-1.png';
import view2 from '../../../../images/cuemate101/level3/on-court/custom-training-goals/CustomTrainingGoal-2.png';
import view3 from '../../../../images/cuemate101/level3/on-court/custom-training-goals/CustomTrainingGoal-3.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Custom Training Goals</h1>
          <p>
            Creating your own training goals will take your tennis training and performance tracking
            to the next level. CueMate. CueMate makes it possible to set Training Goals on all the
            available stroke metrics so that players can train with very specific stroke class and
            metrics.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">SETTING A CUSTOM TRAINING GOAL</h3>
          <p>
            To set a custom training goal, go to the trend plot for the stroke class and metric of
            interest.
          </p>
          <p>Select the Add Training Goal button to the bottom left of the screen.</p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid " alt="Custom Training Goals 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row>
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Custom Training Goals 2" />
          </Col>
          <Col sm={5} className="padding-top-25">
            <p>
              An Add Training Goal modal will pop-up with a predetermined yet editable goal value.
            </p>
            <p>
              {' '}
              Selecting ‘Confirm’ will create the training goal for that training element. You can
              then track the progress of these training goals.
            </p>
          </Col>
        </Row>
        <br />
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row>
        <Col sm={5} className="padding-top-25">
          <p>
            Adding a Training Goal allows you to track progress and use Training Cues for this
            Training Element. You can choose between Tones, Verbal, or Instructions.
          </p>
          <p>Tones and Verbal cues work similarly as for the general cues.</p>
          <p>
            {' '}
            Instructions provides verbal cues based on the training tips to remind you of what to
            focus on to achieve the specific goal. You can use this in addition to the verbal and
            tone cues.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view3} className="d-block mx-auto img-fluid " alt="Custom Training Goals 3" />
        </Col>
      </Row>
    </Container>
  );
}

export default function CustomTrainingGoals() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />
      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

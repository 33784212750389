import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import Breadcrumbs from '../../../../components/Breadcrumbs';

import metricstiming from '../../../../images/concepts/metrics-timing.png';
import strokephasesoverview from '../../../../images/concepts/stroke-phases-overview.png';
import backlooptype from '../../../../images/concepts/backloop-type.png';
import arclength from '../../../../images/concepts/arc-length.png';
import strokePatternWPhases from '../../../../images/concepts/stroke_pattern_w_phases_v1.png';
import techniqueMetrics from '../../../../images/screens/metrics/ClassReport-Outcomes-D.png';
import coefMetrics from '../../../../images/screens/metrics/ClassReport-Outcomes-F.png';

// CSS
import '../Features.css';

function Overview() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm="12">
          <h1>Stroke Metrics Technical Description</h1>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Metrics List</h2>
          <p>Please use the links bellow to see the corresponding definitions.</p>
        </Col>
      </Row>
      <Row className="padding-top-15">
        <Col sm={4}>
          <h3>Stroke Primary Outcomes Metrics</h3>
          <p>The primary results produced by strokes.</p>
          <ul>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#speed&spin">
                Ball Speed
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#speed&spin">
                Ball Spin
              </Link>
            </li>
          </ul>
        </Col>

        <Col sm={4}>
          <h3>Stroke Strike Metrics </h3>
          <p>Focus on the stroke ball strike characteristics.</p>
          <ul>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#strike-metrics">
                Sweet Spot %
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#strike-metrics">
                Impact precision
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#strike-metrics">
                Impact Timing
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#strike-metrics">
                Impact Timing %
              </Link>
            </li>
          </ul>
        </Col>
        <Col sm={4}>
          <h3>Movement Technique Metrics</h3>
          <p>
            Stroke technique features to help you understand specific characteristics of your
            movement technique.{' '}
          </p>
          <ul>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#smoothness">
                Smoothness
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#head-speed">
                Head Speed
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#forward-swing-type">
                Forward-swing Type
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#racket-drop-arc-length">
                Racket Drop
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#racket-drop-arc-length">
                Arc Length
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#forward-swing-lag">
                Forward-swing Lag
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#backloop-openness">
                Backloop Openness
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm={4}>
          <h3>Physical Performance</h3>
          <p>The stroke’s physical performance can be used to improve movement efficiency.</p>
          <ul>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#physical-performance">
                Energy
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics/stroke-class#physical-performance">
                Power
              </Link>
            </li>
          </ul>
        </Col>
        <Col sm={4}>
          <h3>Coefficients</h3>
          <p>
            The coefficients (cE) are designed to capture efficiency of the stroke in producing
            outcomes:
          </p>
          <ul>
            <li>
              <Link smooth to="/cuemate-ios/metrics#coefficients">
                Spin cE
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics#coefficients">
                Speed cE
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-ios/metrics#coefficients">
                Spin-Speed cE
              </Link>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

function StrokePhases() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={12}>
            <h2 className="mb-4 blueGrey">Stroke Architecture Overview</h2>
            <h4>
              The stroke is organized in distinct phases. Understanding the phases is useful because
              some of the stroke metrics focus on specific aspect of a stroke phase, such as the
              racket face openness at the end of the back swing.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 4, offset: 4 }} className="padding-top-25">
            <figure className="figure">
              <img
                src={strokephasesoverview}
                className="mx-auto d-block img-fluid img-border"
                alt="Metric Technique"
              />
              <figcaption className="figure-caption">Stroke Phases: Overview</figcaption>
            </figure>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 4, offset: 4 }} className="padding-top-25">
            <figure className="figure">
              <img
                src={strokePatternWPhases}
                className="mx-auto d-block img-fluid img-border"
                alt="Stroke Pattern With Phases"
              />
              <figcaption className="figure-caption">Stroke Pattern With Phases</figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm={12}>
            <p>
              The figure illustrates the phases extracted by the CueMate motion processing. The
              phases are defined as follows:
            </p>
            <ul className="with-spacing">
              <li>
                Ready: The racket is ready for the next stroke. It is the phase before initiating
                the backswing
              </li>
              <li>Backswing: Movement used to take the racket back.</li>
              <li>
                Back loop: Transition phase between the backswing and forward swing. This is a
                critical phase since it sets up the racket for the forward swing.
              </li>
              <li>
                Forward Swing: The racket is accelerating toward the ball. Key phase since it
                creates the impact conditions for the stroke outcomes.
              </li>
              <li>
                Impact: Time period during which the racket is in contact with the ball. During the
                impact, the racket imparts part of its momentum to the ball and creates the
                outcomes.
              </li>
              <li>
                Followthrough: The racket is slowing down after impact. This phase provides
                information about the stroke technique
              </li>
              <li>Recovery: Once the racket has slowed down it is taken to the ready position.</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function PrimaryOutcome() {
  return (
    <Container className="padding-top-25 padding-bottom-25" id="speed&spin">
      <Row>
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Primary Outcome Metric</h2>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm="12">
          <h3>Ball Speed</h3>
          <h4 className="padding-bottom-25">{`Pace represents the linear
          speed of the ball as it leaves the racket. Pace is measured in
          meters per second (m/s) and is divided into High, Medium, and Light`}</h4>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm="12">
          <h3>Ball Spin</h3>
          <h4 className="padding-bottom-25">{`Spin represents the rotational speed of the ball as it leaves the
          racket. Spin is measured in rotations per minute (rpm). Spin levels
          are divided into three regions: Topspin, Slice, and Flat.`}</h4>
        </Col>
      </Row>
    </Container>
  );
}

function Technique1() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25" id="strike-metrics">
      <Container>
        <Row>
          <Col sm={12}>
            <h2 className="blueGrey">Strike Metrics</h2>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Sweet Spot % </h3>
            <h4 className="padding-bottom-25">
              Sweet Spot % is the percentage of strokes that have an impact location within the
              sweet spot. Sweet Spot % = number of impacts in sweet spot / total number of impacts.{' '}
            </h4>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Impact Precision</h3>
            <h4 className="padding-bottom-25">
              The variation in the point of impact on the racket.
              <br /> A high impact precision corresponds to a concentrated impact distribution.{' '}
              <br />
              While a low impact precision corresponds to a scattered distribution, and is often
              associated with a lower sweet spot percentage.{' '}
            </h4>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Impact Timing</h3>
            <h4 className="padding-bottom-25">
              Impact Timing refers to the time difference between the impact and the instant when
              the racket reaches its peak speed. It allows to determine if you accelerate your
              racket through the impact or if you slowed down before the impact.
            </h4>
            <h4 className="padding-bottom-25">
              Ideally, impact timing should be small, indicating a preparation and timing of the
              stroke acceleration toward the incoming ball. Large impact timing (both the positive
              or negative values) suggest poor preparation and coordination of the stroke with the
              incoming ball.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 5, offset: 4 }}>
            <figure className="figure">
              <img
                src={metricstiming}
                className="d-block mx-auto img-fluid img-border"
                alt="Metric Technique"
              />
              <figcaption className="figure-caption">Technique: Timing</figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Impact Timing %</h3>
            <h4 className="padding-bottom-25">
              The impact timing % is the analog to the sweet spot % but in the temporal dimension.
              <br /> Ideally, you want to keep your impact timing within a band around the strike
              time.
              <br /> Together, the sweet spot area and the target impact timing describes an
              ellipsoid spatiotemporal region for a successful ball strike.
            </h4>
            <h4 className="padding-bottom-25">
              Ideally, impact timing should be small, indicating a preparation and timing of the
              stroke acceleration toward the incoming ball. Large impact timing (both the positive
              or negative values) suggest poor preparation and coordination of the stroke with the
              incoming ball.
            </h4>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function StrokeTechniqueMetrics() {
  return (
    <Container className="padding-top-25 padding-bottom-25" id="smoothness">
      <Row>
        <Col sm="12">
          <h3>Stroke Technique Metrics </h3>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25 padding-bottom-25">
          <figure className="figure">
            <img
              src={techniqueMetrics}
              className="d-block mx-auto img-fluid img-border"
              alt="Stroke Technique Metrics"
            />
            <figcaption className="figure-caption">Stroke Technique Metrics</figcaption>
          </figure>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h3>Smoothness</h3>
          <h4 className="padding-bottom-25">
            Smoothness measures the continuity of the stroke movement acceleration profile. A more
            continuous movement, i.e., less jerk, is associated with higher movement skills.
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

function BackloopType() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row id="backloop-type">
        <Col sm="12">
          <h3>Backloop Type</h3>
          <h4 className="padding-bottom-25">
            The backloop refers to the transition from the backswing to the forward swing (see
            stroke architecture illustration). The style of backloop is an important feature of the
            stroke technique.
          </h4>
          <h5>Type 1: Linear transition from backswing to forward</h5>
          <h5 className="padding-bottom-25">{`Most of the momentum of the
          forward swing is created from scratch. Therefore it is physically
          more demanding and can put stress on the shoulder.`}</h5>
          <h5>Type 2: Circular transition</h5>
          <h5 className="padding-bottom-25">{`Advantage of the conservation
          of the movement from the backswing to the forward swing.`}</h5>
          <h5>Type 3: Circular transition with added lag</h5>
          <h5 className="padding-bottom-25">{`Builds on the Type 2 but the
          stroke goes through an additional loop before the forward swing.`}</h5>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25 padding-bottom-25">
          <figure className="figure">
            <img
              src={backlooptype}
              className="d-block mx-auto img-fluid img-border"
              alt="Metric Technique"
            />
            <figcaption className="figure-caption">Backloop Type</figcaption>
          </figure>
        </Col>
      </Row>
    </Container>
  );
}

function Technique2() {
  return (
    <Container
      fluid
      className="light-grey-bg padding-top-25 padding-bottom-25"
      id="racket-drop-arc-length"
    >
      <Container>
        <Row>
          <Col sm="12">
            <h3>Racket Drop</h3>
            <h4 className="padding-bottom-25">
              The Racket Drop refers to the angle of the racket path relative to the horizontal just
              before the ball strike. The deeper the racket drop the more vertical racket movement
              before the strike, helping to generate top spin.
            </h4>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Arc Length</h3>
            <h4 className="padding-bottom-25">{`The arc length refers to the
          length of the forward swing segment. It is basically a measure of
          how large the backswing is. The further back you bring the racket
          before the strike, the longer the forward swing path. Modern stroke
          technique uses much more compact strokes and shorter backswing and
          forward swing than the classic technique.`}</h4>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 5, offset: 4 }} className="padding-top-50">
            <figure className="figure">
              <img
                src={arclength}
                className="d-block mx-auto img-fluid img-border"
                alt="Metric Technique"
              />
              <figcaption className="figure-caption">Arc Length</figcaption>
            </figure>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function PhysicalPerformance() {
  return (
    <Container className="padding-top-25 padding-bottom-25" id="physical-performance">
      <Row>
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Physical Performance Metrics</h2>
          <h4 className="padding-bottom-25">{}</h4>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm="12">
          <h3>Energy</h3>
          <h4 className="padding-bottom-25">
            Energy is the total amount of work performed by your muscles in a stroke, i.e., how much
            force is applied over the stroke duration. <br /> Energy is measured in calories.
          </h4>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm="12">
          <h3>Power</h3>
          <h4 className="padding-bottom-25">
            Power is the average time rate of energy exerted during the forward swing. It is a
            measurement of the explosiveness of your stroke, i.e., how fast you release the energy
            into the stroke. Power is measured in watts.{' '}
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

function Coefficients() {
  return (
    <Container fluid className="light-grey-bg padding-bottom-25 padding-top-25" id="coefficients">
      <Container>
        <Row>
          <Col sm={12}>
            <h2 className="blueGrey">Coefficients</h2>
            <h4 className="padding-bottom-25">
              The coefficients (cE) are designed to capture efficiency of the stroke in producing
              outcomes.
            </h4>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Spin cE</h3>
            <h4 className="padding-bottom-25">
              Describes the efficiency with which the stroke technique produces spin. The higher the
              spin cE the less energy is required to produce a given amount of topspin.
            </h4>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Speed cE</h3>
            <h4 className="padding-bottom-25">
              Describes the efficiency with which the stroke technique produces speed. The higher
              the speed cE the less energy is required to produce a given amount of speed.
            </h4>
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm="12">
            <h3>Spin-Speed cE</h3>
            <h4 className="padding-bottom-25">
              Describes the efficiency with which the stroke simultaneously produces spin and speed.
              Spin and speed are difficult to produce simultaneously because they rely on what can
              be considered orthogonal movements (the swinging motion for speed and the racket
              rolling motion for spin).
            </h4>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 5, offset: 4 }}>
            <figure className="figure">
              <img
                src={coefMetrics}
                className="d-block mx-auto img-fluid img-border"
                alt="Coefficients Metrics"
              />
              <figcaption className="figure-caption">Coefficients Metrics</figcaption>
            </figure>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </Container>
  );
}

export default function StrokeClassMetrics() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Overview />
      <StrokePhases />
      <PrimaryOutcome />
      <Technique1 />
      <StrokeTechniqueMetrics />
      <BackloopType />
      <Technique2 />
      <PhysicalPerformance />
      <Coefficients />
    </>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level2/on-court/rating-system/RatingSystem-1.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Rating System</h1>
          <p>
            The Stroke Rating is an assessment of your skill level on a scale from 1 (novice) to 5
            (expert).
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5} className="padding-top-25">
          <p>
            The rating system looks at your overall stroke repertoire and generates training goals
            for the player to work on so that they can fix gaps in their repertoire as well as
            improve their stroke quality.
          </p>
          <p>
            It shows where you currently stand as a player in terms of your strokes and skills in
            the repertoire.
          </p>
          <p>
            {' '}
            The progress ring shows you how close you are to the next level. It motivates you to
            track your progress in improving stroke quality.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Rating System 1" />
        </Col>
      </Row>
    </Container>
  );
}

export default function RatingSystem() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />

      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

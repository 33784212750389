export const FriendshipType = {
  PERSONAL: 1,
  BUDDY: 2,
  FRIEND: 3,
  COACH: 4,
  STUDENT: 5,
};

export const Relationship = {
  PERSONAL: 'PERSONAL',
  BUDDY: 'BUDDY',
  FRIEND: 'FRIEND',
  COACH: 'STUDENT',
  STUDENT: 'COACH',
};

export const FriendshipStatus = {
  SENT: 1,
  REJECTED: 2,
  ACCEPTED: 3,
  DELETED: 4,
  PENDING: 5,
};

export const FeedsType = {
  PUBLIC: 'public',
  FRIENDSHIPS: 'friendships',
  GROUPS: 'groups',
};

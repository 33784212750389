import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import './AppFeatures.css';

import metricoverviewGif from '../../../../images/screens/metrics/ClassReport-ABCDE.gif';
import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';
import labelC from '../../../../images/screens/labels/C.png';
import labelD from '../../../../images/screens/labels/D.png';
import labelE from '../../../../images/screens/labels/E.png';

import metricsA from '../../../../images/screens/metrics/ClassReport-A.png';
import metricsB from '../../../../images/screens/metrics/ClassReport-B.png';
import pacecropped from '../../../../images/screens/pace-cropped.png';
import metricsC from '../../../../images/screens/metrics/ClassReport-C.png';
import metricsD from '../../../../images/screens/metrics/ClassReport-D.png';
import metricsE from '../../../../images/screens/metrics/ClassReport-E.png';

import paceIcon from '../../../../images/icons/pace.svg';
import spinIcon from '../../../../images/icons/spin.svg';
import successrateIcon from '../../../../images/icons/successRate.svg';
import smoothnessIcon from '../../../../images/icons/smoothness.svg';
import timingIcon from '../../../../images/icons/timing.svg';
import consistencyIcon from '../../../../images/icons/consistency.svg';
import powerIcon from '../../../../images/icons/power.svg';
import energyIcon from '../../../../images/icons/energy.svg';

export default function Metrics() {
  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <Row>
        <Col sm="12">
          <h1>Metrics Screen</h1>
        </Col>
      </Row>

      <Row>
        <Col sm="8" className="padding-top-50">
          <img src={metricoverviewGif} className="d-block mx-auto img-fluid" alt="Metrics" />
        </Col>
        <Col sm="4" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/metrics-screen#time-scale">
                  Time Scale
                </Link>
              </h3>
            </Media>
          </Media>

          <Media>
            <Media left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/metrics-screen#edit-metrics">
                  Edit Metrics
                </Link>
              </h3>
            </Media>
          </Media>

          <Media>
            <Media left>
              <img src={labelC} className="label-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/metrics-screen#key">
                  Key
                </Link>
              </h3>
            </Media>
          </Media>

          <Media>
            <Media left>
              <img src={labelD} className="label-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/metrics-screen#metric-list">
                  Metric List
                </Link>
              </h3>
            </Media>
          </Media>

          <Media>
            <Media left>
              <img src={labelE} className="label-max-height" alt="Label E" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/metrics-screen#edit-metrics-list">
                  Edit Metric List
                </Link>
              </h3>
            </Media>
          </Media>
        </Col>
      </Row>

      <Row className="padding-top-100" id="time-scale">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media left>
              <img src={labelA} className="label-header-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h2>Time Scale</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            {' '}
            Similar to the <Link to="/cuemate-ios/play-screen#assessment-filter">Time Filter</Link>,
            the Time Scale enables you to review metric results within 5 different time scales.{' '}
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={metricsA} className="d-block mx-auto img-fluid" alt="Time Filter" />
        </Col>
      </Row>

      <Row className="padding-top-100" id="time-scale">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media left>
              <img src={labelB} className="label-header-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h2>Edit Metrics</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Click on the Edit Metrics button to short-list and order the metrics from all the list
            of metrics
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={metricsB} className="d-block mx-auto img-fluid" alt="Time Filter" />
        </Col>
      </Row>

      <Row className="padding-top-100" id="key">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media left tag="span">
              <img src={labelC} className="label-header-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h2>Key</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            {' '}
            It is important to reference the key to effectively understand the metrics screen.{' '}
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={metricsC} className="d-block mx-auto img-fluid" alt="Metrics Key" />
        </Col>
      </Row>

      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-100">
          <figure className="figure">
            <img src={pacecropped} className="d-block mx-auto img-fluid" alt="Pace bar" />
            <figcaption className="figure-caption">
              Example of metric icons used to interpret Pace.
            </figcaption>
          </figure>
        </Col>
      </Row>

      <Row>
        <Col sm="6" className="padding-top-50">
          <h3>Black Dot and Bars</h3>
          <p>
            T he black dot indicates the average value (e.g., 71) and the black bars indicate the
            statistical range of values attained for a given class (standard deviation σ = ±14).
            Smaller error bars indicate more consistent strokes.{' '}
          </p>
        </Col>
        <Col sm="6" className="padding-top-50">
          <h3>
            <span className="badRed">Red</span> and <span className="goodGreen">Green</span> Carets
          </h3>
          <p>
            The red/green carets represent your current average and help you compare your present
            self to historic self. A green caret means your current set average is better than your
            historical average (the black dot), determined by the selected time filter. A red caret
            means the current set average is worse than your historical average (the black dot),
            determined by the selected time filter.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="padding-top-50">
          <h3>
            <span className="brandBlue">Blue</span> Caret
          </h3>
          <p>
            The blue caret represents your training goal. The blue caret only appears if you have a
            relevant training goal for the stroke metric.
          </p>
        </Col>
        <Col sm="6" className="padding-top-50">
          <h3>
            <span className="brandBlue">Blue</span> Bands
          </h3>
          <p>
            The blue bands help you compare yourself to other similar players. Each metric average
            is displayed within a broader blue gradient scale. You can see how your metrics compare
            to those of similar players by referencing the range your averages fall within. In this
            example, the player’s pace falls within the MED (medium) skill level range.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100" id="outcome-metrics">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-header-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h2>Metric List</h2>
            </Media>
          </Media>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img
            src={metricsD}
            className="d-block mx-auto img-fluid"
            alt="Outcome Measurement metrics"
          />
        </Col>
      </Row>
      <Row>
        Outcomes are the results produced by your strokes. CueMate estimates Ball Speed, Ball Spin
        and Sweet Spot.
      </Row>

      <Row>
        <Col sm="4" className="padding-top-50">
          <h3>
            <img src={paceIcon} className="metricIcon" alt="Ball Speed icon" />
            Ball Speed
          </h3>
          <p>
            {' '}
            Ball Speed represents the linear speed of the ball as it leaves the racket. It is
            measured in meters per second (m/s) and is divided into High, Medium, and Light.{' '}
          </p>
        </Col>
        <Col sm="4" className="padding-top-50">
          <h3>
            <img src={spinIcon} className="metricIcon" alt="Spin icon" />
            Ball Spin
          </h3>
          <p>
            {' '}
            Ball Spin represents the rotational speed of the ball as it leaves the racket. It is
            measured in rotations per second (rps). Spin levels are divided into three levels:
            Topspin, Slice, and Flat. You can learn more about spin <a href="#here">here</a>.{' '}
          </p>
        </Col>
        <Col sm="4" className="padding-top-50">
          <h3>
            <img src={successrateIcon} className="metricIcon" alt="Success rate icon" />
            Sweet Spot
          </h3>
          <p>
            {' '}
            Sweet Spot is the percentage of strokes that have an impact location within the sweet
            spot.{' '}
          </p>
          <p> Sweet Spot = number of impacts in sweet spot / total number of impacts. </p>
        </Col>
      </Row>
      <Row>
        <p className="padding-top-50">
          {' '}
          Movement technique measures different characteristics of your stroke form. These metrics
          are designed to help understand specific deficiencies in your stroke technique and provide
          actionable knowledge to help you improve.{' '}
        </p>
        <p>
          {' '}
          CueMate also provides movement technique metrics to help you increase positive outcomes
          created by your strokes and reduce strain on your body.{' '}
        </p>
      </Row>
      <Row>
        <Col sm="4" className="padding-top-50">
          <h3>
            <img src={smoothnessIcon} className="metricIcon" alt="Smoothness Icon" />
            Smoothness
          </h3>
          <p>
            {' '}
            Smoothness measures the continuity of your stroke motion. It is determined from the
            amount of jerk present in the strokes trajectory. Smoothness is presented as an integer
            value between 1 and 10, with 10 as the best.{' '}
          </p>
          <p>
            {' '}
            Smoothness is also an indicator of the strain produced on your body. This measurement
            can help you prevent injury and premature fatigue.{' '}
          </p>
          <p className="text-right">
            {' '}
            <a href="#learn-more">Learn more</a>{' '}
          </p>
        </Col>
        <Col sm="4" className="padding-top-50">
          <h3>
            <img src={timingIcon} className="metricIcon" alt="Timing Icon" />
            Impact Timing
          </h3>
          <p>
            {' '}
            Good timing is a result of finding rhythm with the ball in order to produce clean impact
            at the highest speed. Friction and instability in the arm results from mistiming, which
            leads to reduced spin and speed, and potential injury.{' '}
          </p>
          <p>
            {' '}
            A positive value for timing means that the peak speed (or angular rate) of the swing
            happened after impact; a negative value for timing means that the peak happened before
            impact. Timing is measured in milliseconds (ms). The goal is to be within 5ms of the
            impact.{' '}
          </p>
          <p className="text-right">
            {' '}
            <a href="#learn-more">Learn more</a>{' '}
          </p>
        </Col>
        <Col sm="4" className="padding-top-50">
          <h3>
            <img src={consistencyIcon} className="metricIcon" alt="Consistency Icon" />
            Consistency
          </h3>
          <p>
            {' '}
            The stroke technique for each class should follow a well-defined pattern. Consistency
            measures the variability in your stroke pattern.{' '}
          </p>
          <p> Consistency = number of outliers / number of strokes in core pattern </p>
          <p className="text-xs-right">
            {' '}
            <a href="#learn-more">Learn more</a>{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <p className="padding-top-50">
          {' '}
          Physical performance metrics measure the physical performance of strokes before impact.
          They are used to improve efficiency.{' '}
        </p>
        <p>
          {' '}
          Physical performance is determined by technique, and is a measure of strain on your body.
          Poor technique can result in inefficient strokes.{' '}
        </p>
      </Row>
      <Row>
        <Col sm="6" className="padding-top-25">
          <h3>
            <img src={energyIcon} className="metricIcon" alt="Energy Icon" />
            Energy
          </h3>
          <p>
            {' '}
            Energy is the total amount of work performed by your muscles in a stroke. Energy is
            measured in calories.{' '}
          </p>
          <p className="text-right">
            {' '}
            <a href="#learn-more">Learn more</a>{' '}
          </p>
        </Col>
        <Col sm="6" className="padding-top-25">
          <h3>
            <img src={powerIcon} className="metricIcon" alt="Power Icon" />
            Power
          </h3>
          <p>
            {' '}
            Power is the average time rate of energy exerted during the forward swing. It is a
            measurement of the explosiveness of your stroke. Power is measured in watts.{' '}
          </p>
          <p className="text-right">
            {' '}
            <a href="#learn-more">Learn more</a>{' '}
          </p>
        </Col>
      </Row>

      <Row id="performance-metrics">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media left>
              <img src={labelE} className="label-header-max-height" alt="Label E" />
            </Media>
            <Media body>
              <h2>Edit Metrics List</h2>
            </Media>
          </Media>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={metricsE} className="d-block mx-auto img-fluid" alt="Edit Metrics screen" />
        </Col>
      </Row>
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { BasicLayout, tennisUtilities } from 'cuemate-charts';
import utilityRepertoire from './utilityRepertoire';

const {
  getTypes,
  getClassScore,
  getImpactPrecision,
  getRepertoireStats,
  getSkillStatus,
  getConsistency,
} = tennisUtilities;

export default function StrokeMap({
  elements,
  references,
  classKeys = ['classification', 'advancedUtilityClassification'],
}) {
  const sessions = d3
    .nest()
    .key((d) => d.date)
    .rollup((sessionElements) => sessionElements)
    .entries(elements)
    .sort((a, b) => new Date(b.key) - new Date(a.key));

  // Dropdowns
  const sessionOptions = [{ name: 'all', label: 'All SESSIONS', value: 0 }];
  [1, 3, 5, 10, 15].forEach((n) => {
    if (n <= sessions.length) {
      sessionOptions.push({
        name: `previous ${n}`,
        label: `prev ${n} sessions`.toUpperCase(),
        value: n,
      });
    }
  });

  const metricOptions = [
    {
      name: 'count',
      label: 'COUNT',
      value: 0,
      format: d3.format('.0f'),
    },
    {
      name: 'useFrequency',
      label: 'USE FREQUENCY',
      value: 1,
      format: (d) => `${d3.format('.1f')(100 * d)}%`,
    },
    {
      name: 'impact_success',
      label: 'SWEET SPOT',
      value: 2,
      format: (d) => `${d3.format('.1f')(100 * d)}%`,
    },
    {
      name: 'score',
      label: 'SCORE',
      value: 3,
      format: (d) => `${d3.format('.1f')(100 * d)}`,
    },
  ];

  const allClassificationOptions = [
    {
      name: 'classification',
      label: 'Normal',
    },
    {
      name: 'basicUtilityClassification',
      label: 'Basic',
    },
    {
      name: 'advancedUtilityClassification',
      label: 'Advanced',
    },
  ];

  const updatedReferences = classKeys.map(
    (_, i) =>
      references &&
      references[i] &&
      references[i].map((d) => ({
        ...d,
        impactSuccess: d.impact_success.mean,
        score: getClassScore(getImpactPrecision(d.impact_variability.mean), d.impact_success.mean),
      }))
  );

  const classificationOptions = classKeys.map((key, i) => {
    const option = allClassificationOptions.find((d) => d.name === key);
    return {
      ...option,
      value: i,
      types: getTypes(key).slice(1),
      reference: updatedReferences[i],
    };
  });

  const [sessionOption] = useState(sessionOptions[0]);
  const [metricOption] = useState(metricOptions[0]);
  const [classificationOption, setClassificationOption] = useState(classificationOptions[0]);
  const [digit, toggleDigit] = useState(true);

  useEffect(() => {
    const index = classificationOptions.findIndex((d) => d.name === classificationOption.name);
    setClassificationOption(classificationOptions[index < 0 ? 0 : index]);
  }, [classKeys]);

  // Get selected elements
  const selectedElements =
    sessionOption.value === 0
      ? elements
      : [].concat(...sessions.slice(0, sessionOption.value).map((s) => s.value));

  // Get stats for classes

  const totalCount = d3.sum(selectedElements, (cls) => cls.count);

  const classStats = selectedElements.map((cls) => ({
    ...cls,
    useFrequency: cls.count / totalCount,
    score: getClassScore(getImpactPrecision(cls.impact_var), cls.impact_success),
    skillStatus: getSkillStatus(
      cls.impact_success,
      cls.impact_var,
      cls.count,
      getConsistency(cls.azimuth_std, cls.elevation_std),
      cls.timing_std
    ).skillStatus,
  }));

  const getValue = (d, metric) => (d !== undefined && metric in d ? d[metric] : 0);
  const getValues = (values, groups, metric) =>
    groups[0].map((g1) =>
      groups[1].map((g2) =>
        groups[2].map((g3) => {
          const cls = values.find((d) => d.classification.includes(`${g1} ${g2} ${g3}`));
          const value = getValue(cls, metric);
          return typeof value === 'object' ? value.mean : value;
        })
      )
    );

  const getDensity = (groups, groupValues) => {
    const groupMeans = [];
    const groupStds = [];
    groupValues.forEach((g1) => {
      const groupSum = d3.sum(g1.map((g2) => d3.sum(g2)));
      if (groupSum === 0) {
        groupMeans.push([0, 0]);
        groupStds.push([0, 0]);
      } else {
        const xValues = g1.map((g2) => d3.sum(g2));
        const yValues = d3.range(groups[2].length).map((i3) => d3.sum(g1.map((g2) => g2[i3])));
        const groupMean = [
          d3.sum(xValues.map((v, i) => v * i)) / groupSum / (xValues.length - 1),
          d3.sum(yValues.map((v, i) => v * i)) / groupSum / (yValues.length - 1),
        ];
        const groupStd = [
          Math.sqrt(
            d3.sum(xValues.map((v, i) => v * (i / (xValues.length - 1) - groupMean[0]) ** 2)) /
              groupSum
          ),
          Math.sqrt(
            d3.sum(yValues.map((v, i) => v * (i / (yValues.length - 1) - groupMean[1]) ** 2)) /
              groupSum
          ),
        ];
        groupMeans.push(groupMean);
        groupStds.push(groupStd);
      }
    });

    return { groupMeans, groupStds };
  };

  const groups = classificationOption.types;
  const groupValues = getValues(classStats, groups, metricOption.name);
  const groupStats = getRepertoireStats(classStats, classificationOption.name)[1];

  const groupData = {
    digit: true,
    groups,
    groupValues,
    groupStats,
  };
  if (metricOption.value < 2) {
    const density = getDensity(groups, groupValues);
    groupData.groupMeans = density.groupMeans;
    groupData.groupStds = density.groupStds;
  }
  groupData.groupSkillStatus = getValues(classStats, groups, 'skillStatus');

  if (classificationOption.reference && metricOption.name in classificationOption.reference[0]) {
    groupData.refValues = getValues(classificationOption.reference, groups, metricOption.name);
  }

  // Visualizations

  const items = [
    // [{ content: dropdowns }],
    [
      {
        chart: utilityRepertoire,
        ...groupData,
        digit,
        toggleDigit,
        format: metricOption.format,
        metricName: 'skillStatus',
        fixed: false,
      },
    ],
  ];

  return <BasicLayout items={items} />;
}

StrokeMap.propTypes = {
  elements: PropTypes.array,
  references: PropTypes.array,
  classKeys: PropTypes.array,
};

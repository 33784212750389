import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Container, Col, Row, TabContent, TabPane } from 'reactstrap';
import { Sidebar } from 'cuemate-charts';
import FeedsHandler from './FeedsHandler';
import AddFriendForm from './management/AddFriendForm';
import ManageFriendships, {
  Tabs as ManagementTabs,
  RowFriendRequest,
} from './management/ManageFriendships';
import { cuemateGET } from '../../../libs/dataAccess';
import Leaderboards from './leaderboard/Leaderboards';
import DiscoverClubs from './groups/DiscoverClubs';
import MyClubs from './groups/MyClubs';
import AddFriendsImg from '../../../images/social/add-friends@3x.png';
import { FeedsType, FriendshipStatus } from './SocialConstants';
import './SocialTab.css';
import useCheckMobileScreen from '../../../libs/mobileUtilities';
import { sortArrObjWithStringPropertyAsc } from '../../../libs/utilities';

const Tabs = {
  PUBLIC: 'PUBLIC',
  FRIENDS: 'FRIENDS',
  MANAGEMENT: 'ADMIN',
  LEADERBOARD: 'LEADERBOARD',
  DISCOVER: 'DISCOVER GROUPS',
  YOUR_GROUPS: 'YOUR GROUPS',
};

function SocialTab({ onFriendRequestsUpdate, friendRequests: initialFriendRequests = [] }) {
  const [activeTab, setActiveTab] = useState(Tabs.PUBLIC);
  const [shouldShowAddFriendForm, setShouldShowAddFriendForm] = useState(false);
  const [friendRequests, setFriendRequests] = useState(initialFriendRequests);
  const [refreshMyGroups, setRefreshMyGroups] = useState(false);
  const [shouldRefreshGlobalGroups, setShouldRefreshGlobalGroups] = useState(false);
  const isMobile = useCheckMobileScreen();

  const updateFriendRequests = async () => {
    try {
      const url = `/users/social/friendships`;
      const currentSession = await Auth.currentSession();
      const response = await cuemateGET(url, currentSession.accessToken.jwtToken);
      const friends = response.data;
      let requests = friends.filter((el) => el.status === FriendshipStatus.PENDING);
      requests = sortArrObjWithStringPropertyAsc(requests, 'username');

      if (onFriendRequestsUpdate !== undefined) onFriendRequestsUpdate(requests);
      setFriendRequests(requests);
    } catch (err) {
      console.error(err);
    }
  };

  const onTransition = (state) => {
    setActiveTab(state);
    setShouldShowAddFriendForm(state === Tabs.FRIENDS || state === Tabs.MANAGEMENT);
  };

  const onTabChange = (tabName) => {
    setShouldShowAddFriendForm(activeTab === Tabs.MANAGEMENT && tabName !== ManagementTabs.ADD);
  };

  const onManageFriendsClick = useCallback(() => {
    setActiveTab(Tabs.MANAGEMENT);
  }, []);

  const onJoinClubHandler = () => {
    setRefreshMyGroups(!refreshMyGroups);
  };

  const onLeaveClub = () => {
    setShouldRefreshGlobalGroups(!shouldRefreshGlobalGroups);
  };

  const sideBarItems = [
    { name: Tabs.PUBLIC, label: Tabs.PUBLIC },
    {
      name: Tabs.FRIENDS,
      label: Tabs.FRIENDS,
      count: friendRequests.length,
      countFromOne: true,
    },
    { name: Tabs.DISCOVER, label: Tabs.DISCOVER },
    { name: Tabs.YOUR_GROUPS, label: Tabs.YOUR_GROUPS },
    { name: Tabs.LEADERBOARD, label: Tabs.LEADERBOARD },
  ];

  return (
    <Container className="feeds-container animated fadeIn" fluid={isMobile}>
      <Row className="no-gutters responsive-font">
        <Col sm="2" md="3">
          <Sidebar items={sideBarItems} onTransition={onTransition} />
          {shouldShowAddFriendForm && (
            <Container className="form-container" fluid={isMobile}>
              <Row>
                <Col sm="12">
                  <p className="text-break float-left">
                    <small>
                      ADD FRIENDS
                      <img
                        alt="add-friend-bttn"
                        src={AddFriendsImg}
                        className="add-friend-bttn-img"
                      />
                    </small>
                  </p>
                </Col>
              </Row>
              <AddFriendForm onManageFriendsClick={onManageFriendsClick} />
              {friendRequests.length > 0 && (
                <>
                  <Row>
                    <Col>
                      <h3 className="friends-lst-header font-weight-light">REQUESTS</h3>
                    </Col>
                  </Row>
                  {friendRequests.map((user) => (
                    <RowFriendRequest
                      key={user.username}
                      data={user}
                      compactList
                      onRowUpdate={updateFriendRequests}
                    />
                  ))}
                </>
              )}
            </Container>
          )}
        </Col>
        <Col sm="10" md="9">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={Tabs.PUBLIC}>
              <Row>
                <Col sm="12">
                  <FeedsHandler typeOfFeeds={FeedsType.PUBLIC} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={Tabs.FRIENDS}>
              <Row>
                <Col sm="12">
                  <FeedsHandler typeOfFeeds={FeedsType.FRIENDSHIPS} />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={Tabs.MANAGEMENT}>
              <Row>
                <Col sm="12">
                  <ManageFriendships
                    onTabChange={onTabChange}
                    onFriendRequestsUpdate={updateFriendRequests}
                    friendRequests={friendRequests}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={Tabs.LEADERBOARD}>
              <Row>
                <Col sm="12">
                  <Leaderboards />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={Tabs.DISCOVER}>
              <Row>
                <Col sm="12">
                  <DiscoverClubs
                    onJoinClub={onJoinClubHandler}
                    shouldRefreshData={shouldRefreshGlobalGroups}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId={Tabs.YOUR_GROUPS}>
              <Row>
                <Col sm="12">
                  <MyClubs refreshGroups={refreshMyGroups} onLeaveClub={onLeaveClub} />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}

SocialTab.propTypes = {
  onFriendRequestsUpdate: PropTypes.func,
  friendRequests: PropTypes.array,
};

export default SocialTab;

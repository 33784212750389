import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { useAsyncV2, cuemateGET } from '../../../../libs/dataAccess';
import onError from '../../../../libs/errorLib';
import LoaderButton from '../../../../components/LoaderButton';

const listMembers = async (clubType, clubId) => {
  if (clubType === undefined || clubId === undefined || clubId.length === 0) return {};
  const url = `/groups/clubs/${clubType}/${clubId}/members?limit=100`;
  const currentSession = await Auth.currentSession();
  try {
    const members = await cuemateGET(url, currentSession.accessToken.jwtToken);
    return members;
  } catch (error) {
    onError(error);
  }

  return {};
};
export default function ListOfClubMembers({ clubType, clubId }) {
  const { value: data, error, loading } = useAsyncV2(listMembers, [clubType, clubId]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  if (error) {
    onError(error);
    return null;
  }
  if (!loading && data.data === undefined) {
    return null;
  }
  return (
    <>
      <i className="fa fa-users" style={{ color: '#0d6efd' }} aria-hidden="true" />
      <LoaderButton
        isLoading={loading}
        disabled={loading}
        loadingText="Loading.."
        color="link"
        text="Members"
        onClick={toggle}
      />
      <Modal isOpen={modal} toggle={toggle} size="sm">
        <ModalHeader toggle={toggle}>Members List</ModalHeader>
        <ModalBody>
          <Table className="text-center">
            <thead>
              <tr>
                <th>USERNAMES</th>
              </tr>
            </thead>
            <tbody>
              {!loading &&
                data.data.map((m) => (
                  <tr key={m.userId}>
                    <td>{m.username}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            OK
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>
  );
}

ListOfClubMembers.propTypes = {
  clubId: PropTypes.string,
  clubType: PropTypes.string,
};

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import warmup1 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-1.png';
import warmup2 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-2.png';
import warmup3 from '../../../../images/cuemate101/level2/set1/warmup-3.png';
import warmup4 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-4.png';
import warmup5 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-5.png';
import warmup6 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-6.png';

import '../AppFeatures.css';

export default function L2S1Warmup() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SET 1: WARMUP</h2>
          <h3>Warmup, No Cue</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="4">
          <ol>
            <li>Start a new Session</li>
            <img
              src={warmup1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="2">
            <li>Enter play conditions</li>
            <span className="blue">Coach, Warmup, No Cue, Tags ON</span>
            <img
              src={warmup2}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">Rallying, Intensity Level 1, Middle, Service Line</span>
          <img
            src={warmup3}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="3">
            <li>Begin Set</li>
            <span className="blue">
              Once the stroke map is displayed, set phone on bench or nearby bench or table
            </span>
            <img
              src={warmup4}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <p className="blue">
            Rally down +-50 balls the middle from the service line, using easy warmup intensity
            level 1<br />
            Focus on executing topspin strokes on both backhand and forehand side
            <br />
            Play lowest intensity to keep strokes clean and the ball in play
          </p>
          <img src={warmup6} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="4">
            <li>Press Pause when finished</li>
            <img
              src={warmup5}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/set-2">
            <h3>
              Next: Set 2 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

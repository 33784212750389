import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, UncontrolledCarousel } from 'reactstrap';
import Breadcrumbs from '../../../components/Breadcrumbs';

import allCourt from '../../../images/concepts/allcourt.png';
import netRusher from '../../../images/concepts/netrusher.png';
import strokeShotsConditions from '../../../images/background/stroke-and-shots-conditions.png';
import strokeShotsOutcome from '../../../images/background/stroke-and-shots-shot-outcome.png';
import strokeShotsTechnique from '../../../images/background/stroke-and-shots-technique.png';

function HowCueMateWorksMain() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>How CueMate Works: The Big Picture</h1>
          <h4 className="padding-top-10">
            When you perform a complex task, such as tennis, you use a wide range of movement types
            to produce a variety of outcomes needed to succeed at the task.
          </h4>
          <h2 className="padding-top-10 blueGrey">Tennis Strokes as Environment Interactions</h2>
          <p>
            To understand how CueMate works, it is helpful to understand the basic nature of tennis
            strokes and their environment.
          </p>
          <ul className="with-spacing">
            <li>
              Tennis is challenging because both the players and the ball are moving during an
              exchange.
            </li>
            <li>
              Because of the variety of incoming shots and court positions, tennis players must
              learn to produce different shot outcomes under a variety of conditions.
            </li>
          </ul>
          <h3>Stroke Types</h3>
          <p>
            The different strike conditions provide opportunities for creating a variety of shots
            but also require:
          </p>
          <ul className="with-spacing">
            <li>Anticipating the optimal strike point</li>
            <li>Being able to position oneself</li>
            <li>
              Using stroke patterns that are adapted to achieving the desired shot outcomes under
              those conditions.{' '}
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="padding-top-25 justify-content-center">
        <Col sm={6}>
          <img src={allCourt} className="d-block mx-auto img-fluid img-border" alt="All Court" />
        </Col>
        <Col sm={6}>
          <img src={netRusher} className="d-block mx-auto img-fluid img-border" alt="Net Rusher" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col>
          <h3>Strike Conditions</h3>
          <p>
            Next, let’s take a look at the four key aspects of tennis stroke: the strike conditions,
            the stroke technique and its immediate outcomes, and the shot outcomes.
          </p>
          <ul>
            <li>
              The strike conditions are determined by the incoming shot and the player’s movement
              and setup to create the desired strike conditions for the stroke execution.
            </li>
            <li>
              The stroke technique is the movement pattern used to strike the ball and generate the
              spin and pace on the ball to achieve the shot target.
            </li>
            <li>
              {' '}
              The spin and pace, together with the player’s position and direction of the shot,
              determine the shot outcomes.
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="padding-top-25 justify-content-center">
        <Col sm="6">
          <UncontrolledCarousel
            dark
            items={[
              {
                key: 1,
                src: strokeShotsConditions,
              },
              {
                key: 2,
                src: strokeShotsTechnique,
              },
              {
                key: 3,
                src: strokeShotsOutcome,
              },
            ]}
            className="img-border"
          />
          <p className="padding-top-10">
            Every stroke, therefore, is described by the strike conditions, the stroke pattern or
            technique, and its outcomes on the ball and its ultimate trajectory.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function ModelingOverview() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row>
        <h2 className="blueGrey">Tennis Learning Overview</h2>
        <Col sm={12}>
          <p>
            As just described, the main movement elements in tennis are the strokes, which span a
            wide variety of movement patterns depending on the strike conditions and desired
            outcomes.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function MovementLearning() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col>
            <h3 className="padding-top-10">Movement Learning</h3>
            <p>
              Understanding the range of interactions and strokes in tennis helps understand how
              becoming proficient in tennis, means learning a multitude of stroke types that enable
              to produce specific outcomes under a range of different conditions.
            </p>
            <p>
              Since there are an infinite combination of possible outcomes and conditions this is a
              very exhaustive process. The brain deals with this complexity by breaking up this
              space into stroke classes, each covering a range of conditions such as forehand volley
              slice, or backhand groundstroke top spin, etc. This collection of stroke classes
              represents a form of stroke vocabulary.
            </p>
            <p>
              The so-called motor memory is formed for families of strokes of the same type (same
              range of outcomes, conditions, etc. elicit the same behavior). This allows the brain
              to efficiently learn complex movements (and behaviors). Otherwise, it would have to
              learn an infinite number of strokes for all the possible combinations of outcomes and
              conditions.{' '}
            </p>
            <p>
              {' '}
              Your repertoire of strokes, and other supportive movements, is an indicator of your
              skills or proficiency:
            </p>
            <ul>
              <li>
                Beginner players have a more limited repertoire of strokes, and the stroke classes
                are not as reliable in producing their outcomes, and also strike conditions are not
                precisely controlled.
              </li>
              <li>
                Proficient players have a rich repertoire of strokes where each stroke class is
                optimized in producing their outcomes. This makes them versatile and effective
                players.
              </li>
            </ul>
            <p>
              These general properties are used by CueMate to assess players skills and drive the
              training process.
            </p>
            <Link to="/background/learning-model">
              See CueMate’s Learning Model for more details {'>'}
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function StrokeRepertoireModeling() {
  return (
    <Container fluid className="padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col>
            <h3 className="padding-top-10">Stroke Repertoire Modeling</h3>
            <p>
              Therefore, a central aspect of assessing tennis skills is modeling the repertoire of
              different stroke types acquired by a player to manage the wide range of outcomes and
              conditions.
            </p>
            <p>
              CueMate is unique in its ability to track and extracts the player&apos;s strokes which
              are then classified to form their stroke repertoire. The stroke within a class can
              then be modelled and analyzed in detail to assess and diagnose a player’s skills.
            </p>
            <p>
              In addition, the stroke repertoire is used to determine a skill rating—you are the sum
              of all your strokes! And ultimately this knowledge is used to drive the training
              process.
            </p>
            <Link to="/background/hierarchical-movement-skill-model">
              See CueMate’s Movement Skill Model for more details {'>'}
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function HowCueMateWorks() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>

      <HowCueMateWorksMain />
      <ModelingOverview />
      <MovementLearning />
      <StrokeRepertoireModeling />
      <Container>
        <Breadcrumbs />
      </Container>
    </>
  );
}

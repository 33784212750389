import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review4 from '../../../../images/cuemate101/level1/fundamentals/review/review-4.png';
import review5 from '../../../../images/cuemate101/level1/fundamentals/review/review-5.png';
import review6 from '../../../../images/cuemate101/level1/fundamentals/review/review-6.png';

import '../AppFeatures.css';

export default function ReviewOutcomeMetrics() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>REVIEW: OUTCOME METRICS</h2>
          <h3>PERFORMANCE BETWEEN STROKES &#38; SETS</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={{ size: 8 }}>
          <ol>
            <li>
              Navigate back to ‘Review’, and select one of the core stroke classes from the Session
              Stroke Map
            </li>
            <img src={review4} className="d-block mx-auto img-fluid" alt="Review Stroke Map" />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="8">
          <ol start="2">
            <li>View outcome metrics for that specific stroke class</li>

            <img
              src={review5}
              className="d-block mx-auto img-fluid img-border"
              alt="‘Browse’ to view Stroke Maps for each set"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="8">
          <ol start="3">
            <li>View outcome metrics for that specific stroke class</li>
            <img
              src={review6}
              className="d-block mx-auto img-fluid img-border"
              alt="‘Browse’ to view Stroke Maps for each set"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate101/level1/court-exercise/finish">
            <h3>
              Next: End Session <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import reports1 from '../../../../images/cuemate101/level3/key-concepts/reports/reports-1.png';
import reports2 from '../../../../images/cuemate101/level3/key-concepts/reports/reports-2.png';

import '../AppFeatures.css';

export default function Reports() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h2>Reports</h2>
          <p>
            CueMate Reports give players an at-a-glance view of their most important session data.
            There are two Report views – Session Training Report and Session Stroke Report.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={reports1}
            className="d-block mx-auto img-fluid img-border"
            alt="Review Features"
          />
        </Col>
      </Row>

      <h3 className="padding-top-25 blue">STROKE REPORT</h3>
      <Row>
        <Col sm={8}>
          <img src={reports2} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
        <Col sm={4}>
          <span className="bold">Focus: Core Strokes</span>
          <br />
          The Session Stroke report lets you view important information about your core strokes, all
          in a single list format. You can see usage data as well as metrics for all 3 stroke
          outcomes (Spin, Pace, ISR). It also gives a session score and average impact success rate
          for all your strokes.
        </Col>
      </Row>
      <br />

      <h3 className="padding-top-25 blue">TRAINING REPORT</h3>
      <Row>
        <Col sm={8}>
          <img src={reports2} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
        <Col sm={4}>
          <span className="bold">Focus: Training Goals</span>
          <br />
          This report gives progress information on active Training Goals.
          <br />
          For each Training Goal, you can see your metrics as well as a ranking of progress compared
          to the other active goals.
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level3/metric-views">
            <h3>
              Next: Metric Views <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

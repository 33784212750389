import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Breadcrumbs';

import './Background.css';

function Title() {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h1>Technology and Movement Training</h1>
        </Col>
      </Row>
    </Container>
  );
}

function CueMateTakesYourDatatotheNextLevel() {
  return (
    <Container fluid className="padding-bottom-25">
      <Container>
        <Row>
          <Col sm={12} className="padding-top-10">
            <h2 className="blueGrey">CueMate Takes Your Data to the Next Level</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="padding-top-10">
            <p>
              {' '}
              Progress in wearable and embedded sensors has led to a wide range of products in the
              sport and fitness space, from basic step counters to more specialized devices to track
              tennis or golf swings. These devices are producing large amounts of data, however for
              activities like tennis they have resulted in limited benefits to the user.
            </p>
            <p>
              This is primarily because generating data is not enough; users need so-called
              actionable information that can lead to meaningful inputs that can help them
              understand skills and ultimately help them train.
            </p>
            <p>
              {' '}
              CueMate addresses this shortcoming using a data-driven training ecosystem that
              integrates:
            </p>
            <ul className="with-spacing">
              <li>
                Movement performance and learning models to enable detailed and comprehensive skill
                assessments and training.{' '}
              </li>
              <li>
                Data-driven diagnostics, including training goals generation, that make it possible
                to operationalize training following concepts from <b>deliberate training.</b>
              </li>
              <li>
                Real-time feedback during performance, thereby maximizing the benefits of training.{' '}
              </li>
            </ul>

            <h3>Key to improving: Deliberate Training</h3>
            <p>
              To better understand the significance of these features, it is important to understand
              how deliberate training--the gold standard in training--achieves its results.
            </p>
            <p>
              Deliberate training is the result of extensive research in understanding the key
              factors that determine superior performance. The key ones are:
            </p>
            <ul className="with-spacing">
              <li>Being motivated to improve performance in the activity.</li>
              <li>Consider the players skills and knowledge.</li>
              <li>Basic understanding of movement technique and strategy.</li>
              <li>Explicit instructions about best method.</li>
              <li>Individual supervision and diagnosis of errors.</li>
              <li>Repetition with immediate feedback about results and performance.</li>
              <li>Remedial training focusing on key issues.</li>
              <li>Sequence of training tasks with appropriate level of challenge (curriculum).</li>
            </ul>
            <p>
              The effectiveness of deliberate training has been established in numerous domains in
              which complex skills play a critical role. A key limitation is that this type of
              systematic practice requires an experienced professional coach.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function AssistTraining() {
  return (
    <Container fluid className="padding-25 light-grey-bg">
      {' '}
      <Container>
        <Row>
          <Col>
            <h2 className="blueGrey">Why Isn’t There More Technology To Assist Training?</h2>
            <p>
              The primary reason why there aren’t many technologies designed to support deliberate
              training is the challenge of extracting the necessary information from movement data
              and turning it into training interventions that help the user.
            </p>
            <p>Doing this requires solving two fundamental problems: </p>
            <ol className="with-spacing">
              <li>
                how to <b>model movement performance</b> in complex tasks like tennis, and{' '}
              </li>
              <li>
                how to <b>model the biological learning process</b>
              </li>
            </ol>
            <p>
              Before describing CueMate’s movement and learning models, let’s first understand some
              of the key characteristics of sports like tennis in tennis.
            </p>
            <p>
              See the big picture of <Link to="how-cuemate-works">How CueMate Works &gt;</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function TechnologyMovementTraining() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Title />

      <CueMateTakesYourDatatotheNextLevel />
      <AssistTraining />

      <Container className="padding-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Col, Container, Form, FormGroup, Input, Row, FormText } from 'reactstrap';
import LoaderButton from '../../components/LoaderButton';

function ConfirmationNote() {
  return (
    <Container className="padding-bottom-25 animated fadeIn">
      <Row>
        <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
          <h2>Reset Password</h2>
          <hr className="colorgraph" />
          <p className="">Your password has been updated. Please login using your new password.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldRenderConfirmationNote: false,
      shouldRender2StepForm: false,
      isSubmitting: false,
      email: '',
      confirmationCode: '',
      password: '',
      confirmPassword: '',
    };

    this.validateForm = this.validateForm.bind(this);
    this.validateFormStep2 = this.validateFormStep2.bind(this);
    this.checkMatch = this.checkMatch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleConfirmationCodeSubmit = this.handleConfirmationCodeSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    const { email } = this.state;
    try {
      await Auth.forgotPassword(email.trim());
      this.setState({
        shouldRender2StepForm: true,
        isSubmitting: false,
      });
    } catch (e) {
      // eslint-disable-next-line no-undef
      alert(e.message);
      this.setState({ isSubmitting: false });
    }
  }

  async handleConfirmationCodeSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    const { email, confirmationCode, password } = this.state;
    try {
      await Auth.forgotPasswordSubmit(email.trim(), confirmationCode.trim(), password.trim());
      this.setState({
        shouldRender2StepForm: false,
        shouldRenderConfirmationNote: true,
      });
    } catch (e) {
      // eslint-disable-next-line no-undef
      alert(e.message);
      this.setState({ isSubmitting: false });
    }
  }

  checkMatch(event) {
    const { password } = this.state;
    if (event.target.value !== password) {
      event.target.setCustomValidity('Password does not match the confirm password.');
    } else {
      // input is valid -- reset the error message
      event.target.setCustomValidity('');
    }
  }

  validateFormStep2() {
    const { confirmationCode, password, confirmPassword } = this.state;
    return (
      confirmationCode.trim().length > 0 &&
      password.trim().length > 0 &&
      password === confirmPassword
    );
  }

  validateForm() {
    const { email } = this.state;
    return email.trim().length > 0;
  }

  renderResetPasswordForm() {
    const { email, isSubmitting } = this.state;
    return (
      <Container className="padding-bottom-25 animated fadeIn">
        <Row>
          <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} xs="12">
            <Form onSubmit={this.handleSubmit}>
              <h2>Reset Password</h2>
              <hr className="colorgraph" />
              <p className="">
                Enter the email address you used to signup and we will send you a confirmation code.
              </p>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control input-lg"
                  placeholder="Email Address"
                  value={email}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <Row>
                <Col xs="12" md="12" className="padding-bottom-25">
                  <LoaderButton
                    type="submit"
                    text="Submit"
                    color="primary"
                    size="lg"
                    block
                    loadingText="Submitting ..."
                    isLoading={isSubmitting}
                    disabled={!this.validateForm()}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }

  render2StepForm() {
    const { confirmationCode, password, confirmPassword, isSubmitting } = this.state;
    return (
      <Container className="padding-bottom-25 animated fadeIn">
        <Row>
          <Col sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }} xs="12">
            <Form onSubmit={this.handleConfirmationCodeSubmit}>
              <h2>Reset Password</h2>
              <hr className="colorgraph" />
              <p className="">Please check your email for the confirmation code.</p>
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormGroup>
                    <Input
                      type="tel"
                      name="confirmationCode"
                      id="confirmationCode"
                      className="form-control"
                      placeholder="Confirmation Code"
                      value={confirmationCode}
                      onChange={this.handleChange}
                      autoFocus
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={this.handleChange}
                      minLength="8"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormGroup>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={this.handleChange}
                      onInput={this.checkMatch}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormText>
                    Password must be at least 8 characters long, using letters and numbers only.
                  </FormText>
                </Col>
              </Row>
              <hr className="colorgraph" />
              <Row>
                <Col xs="12" md="12" className="padding-bottom-25">
                  <LoaderButton
                    type="submit"
                    text="Submit"
                    color="primary"
                    size="lg"
                    block
                    loadingText="Submitting ..."
                    isLoading={isSubmitting}
                    disabled={!this.validateFormStep2()}
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { shouldRender2StepForm, shouldRenderConfirmationNote } = this.state;
    return shouldRender2StepForm
      ? this.render2StepForm()
      : (shouldRenderConfirmationNote && <ConfirmationNote />) || this.renderResetPasswordForm();
  }
}

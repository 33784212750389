/* eslint-env browser */
import * as d3 from 'd3';
import { Storage } from 'aws-amplify';

export const DATA_TYPE = {
  CSV: 'CSV',
  JSON: 'JSON',
};

function getType(fileName) {
  if (fileName.endsWith('json')) {
    return DATA_TYPE.JSON;
  }
  if (fileName.endsWith('csv')) {
    return DATA_TYPE.CSV;
  }
  return null;
}

const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const convertBlobToBase64 = async (blob) => blobToBase64(blob);

// eslint-disable-next-line consistent-return
export async function listPublicReports(path = '') {
  try {
    const list = await Storage.list(`${path}`);
    return list.map((d) => d.key.split('/').pop()).filter((d) => !d.startsWith('.'));
  } catch (e) {
    console.error(e);
  }
}

// eslint-disable-next-line consistent-return
export async function getPublicReport(fileName, expectingDataType) {
  try {
    const data = await Storage.get(fileName, { download: true });
    const textData = await data.Body.text();
    const dataType = expectingDataType || getType(fileName);
    switch (dataType) {
      case DATA_TYPE.JSON:
        return JSON.parse(textData);
      case DATA_TYPE.CSV:
        return d3.csvParse(textData);
      default:
        return data;
    }
  } catch (e) {
    console.error(e);
  }
}

// it will always return the raw object please handle the Body property according to the type of data your expecting
// eslint-disable-next-line consistent-return
export async function getUserReport(fileName, expectingDataType, path) {
  // As long as there's one user signed in, Storage will handle credentials and uthenticantion
  // if (fileName) {
  try {
    // using vault is same as using the private path
    const data = await Storage.vault.get(`${path || 'report-data'}/${fileName}`, {
      download: true,
    });

    const textData = await data.Body.text();
    switch (expectingDataType) {
      case DATA_TYPE.JSON:
        return JSON.parse(textData);
      case DATA_TYPE.CSV:
        return d3.csvParse(textData);
      default:
        return textData;
    }
  } catch (e) {
    console.error(e);
  }
}

// eslint-disable-next-line consistent-return
export async function listUserReports(folderName, path) {
  try {
    const list = await Storage.vault.list(`${path || 'report-data'}/${folderName}`);
    return list.map((d) => d.key.split('/').pop()).filter((d) => !d.startsWith('.'));
  } catch (e) {
    console.error(e);
  }
}

// eslint-disable-next-line consistent-return
export async function getUserReportsInFolder(folderName, path) {
  try {
    const list = await Storage.vault.list(`${path || 'report-data'}/${folderName}`);
    const res = await Promise.all(
      list
        .map((d) => d.key.split('/').pop())
        .filter((d) => !d.startsWith('.'))
        .map((d) => getUserReport(`${folderName}${d}`, getType(d)))
    );
    return res;
  } catch (e) {
    console.error(e);
  }
}

// eslint-disable-next-line consistent-return
export async function getUserAvatar(fileName, path) {
  try {
    const data = await Storage.vault.get(`${path || 'avatar'}/${fileName || 'avatar.jpg'}`, {
      download: true,
    });
    return await convertBlobToBase64(data.Body);
  } catch (e) {
    console.error(e);
  }
}

export async function getVisionImage(strokeID, camera, event, type) {
  const path = strokeID.split('--');
  let data;
  if (type === '3D') {
    data = await Storage.vault.get(
      `vision-data/session-shots/${path[0]}/${path[1]}/${strokeID}.png`,
      { download: true }
    );
    return convertBlobToBase64(data.Body);
  }
  if (camera == null && event == null && type == null) {
    data = await Storage.vault.get(
      `vision-data/session-montages/${path[0]}/${path[1]}/${strokeID}.jpg`,
      { download: true }
    );
  } else if (type == null) {
    data = await Storage.vault.get(
      `vision-data/session-events/${path[0]}/${path[1]}/${camera}/${event}/${strokeID}.jpg`,
      { download: true }
    );
  }
  return convertBlobToBase64(data.Body);
}

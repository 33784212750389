import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../components/Breadcrumbs';
import './Setup.css';

export default function InstallApp() {
  const testflightURL = 'https://testflight.apple.com/join/LdTLeTey';
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm={8}>
          <h2>Install the CueMate App</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <h4>
            Install{' '}
            <a href={testflightURL} target="_blank" rel="noopener noreferrer">
              TestFlight&trade;&nbsp;
            </a>
            from Apple
          </h4>
          <ol>
            <li>
              Open the Apple App store and download the beta testing app -&nbsp;
              <a href={testflightURL} target="_blank" rel="noopener noreferrer">
                TestFlight&trade;
              </a>
            </li>
            <li>Once installed, open the TestFlight&trade; app.</li>
          </ol>
          <br />
          <h4>Install the CueMate App</h4>
          <ol>
            <li>Within TestFlight&trade; , hit ‘Continue’ to see your list of beta app invites.</li>
            <li>Select CueMate from the list.</li>
            <li>
              On the CueMate description screen, tap ‘Get‘ to download the CueMate app to your
              phone.
            </li>
          </ol>
          <br />
          <h4>Sign-in to CueMate</h4>
          <ol>
            <li>Once downloaded, open CueMate</li>
            <li>Select ‘Sign In‘.</li>
            <li>Enter your username and password.</li>
            <li>
              Once successfully signed in, you will be directed to the CueMate app home screen.
            </li>
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/setup-sensor">
            <h3>
              Next: Setup Sensor <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

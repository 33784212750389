import config from '../config';

export const SENSOR_NAME_REGEX = /\b^Bencic\w{2}\b/;

// const tennisClassName = {

//   FH_TOPSPIN_H : "Forehand Topspin H",
//   FH_TOPSPIN_M : "Forehand Topspin M",
//   FH_TOPSPIN_L : "Forehand Topspin L",

//   FH_FLAT_H : "Forehand Flat H",
//   FH_FLAT_M : "Forehand Flat M",
//   FH_FLAT_L : "Forehand Flat L",

//   FH_SLICE_H : "Forehand Slice H",
//   FH_SLICE_M : "Forehand Slice M",
//   FH_SLICE_L : "Forehand Slice L",

//   BH_TOPSPIN_H : "Backhand Topspin H",
//   BH_TOPSPIN_M : "Backhand Topspin M",
//   BH_TOPSPIN_L : "Backhand Topspin L",

//   BH_FLAT_H : "Backhand Flat H",
//   BH_FLAT_M : "Backhand Flat M",
//   BH_FLAT_L : "Backhand Flat L",

//   BH_SLICE_H : "Backhand Slice H",
//   BH_SLICE_M : "Backhand Slice M",
//   BH_SLICE_L : "Backhand Slice L"

// };

// Utilities
// https://www.safaribooksonline.com/library/view/regular-expressions-cookbook/9781449327453/ch05s02.html
// This function accepts an array of words to
// match. Any regex metacharacters within the accepted words are escaped
// with a backslash before searching.

// function matchWords (subject, words) {
//   var regexMetachars = /[(){[*+?.\\^$|]/g;

//   for (var i = 0; i < words.length; i++) {
//       words[i] = words[i].replace(regexMetachars, "\\$&");
//   }

//   var regex = new RegExp("\\b(?:" + words.join("|") + ")\\b", "gi");

//   return subject.match(regex) || [];
// }

/*
 Returns a more formatterd class name based on the class name received from the server
*/
// export function resolveClassName (classNameToResolve) {

//   classNameToResolve = classNameToResolve.toLowerCase();

//   // forehand
//   if(matchWords(classNameToResolve, ["forehand"]).length >= 1) {

//     if (matchWords(classNameToResolve, ["topspin","high"]).length >= 2) {

//       return tennisClassName.FH_TOPSPIN_H;

//     } else if (matchWords(classNameToResolve, ["topspin","medium"]).length >= 2) {

//     return tennisClassName.FH_TOPSPIN_M;

//     } else if (matchWords(classNameToResolve, ["topspin","low"]).length >= 2) {

//     return tennisClassName.FH_TOPSPIN_L;

//     } else if (matchWords(classNameToResolve, ["flat","high"]).length >= 2) {

//       return tennisClassName.FH_FLAT_H;

//     } else if (matchWords(classNameToResolve, ["flat","medium"]).length >= 2) {

//       return tennisClassName.FH_FLAT_M;

//     } else if (matchWords(classNameToResolve, ["flat","low"]).length >= 2) {

//       return tennisClassName.FH_FLAT_L;

//     } else if (matchWords(classNameToResolve, ["slice","high"]).length >= 2) {

//       return tennisClassName.FH_SLICE_H;

//     } else if (matchWords(classNameToResolve, ["slice","medium"]).length >= 2) {

//       return tennisClassName.FH_SLICE_M;

//     } else if (matchWords(classNameToResolve, ["slice","low"]).length >= 2) {

//       return tennisClassName.FH_SLICE_L;
//     }

//   } else if (matchWords(classNameToResolve, ["backhand"]).length >= 1) {

//     if (matchWords(classNameToResolve, ["topspin","high"]).length >= 2) {

//       return tennisClassName.BH_TOPSPIN_H;

//     } else if (matchWords(classNameToResolve, ["topspin","medium"]).length >= 2) {

//       return tennisClassName.BH_TOPSPIN_M;

//     } else if (matchWords(classNameToResolve, ["topspin","low"]).length >= 2) {

//       return tennisClassName.BH_TOPSPIN_L;

//     } else if (matchWords(classNameToResolve, ["flat","high"]).length >= 2) {

//       return tennisClassName.BH_FLAT_H;

//     } else if (matchWords(classNameToResolve, ["flat","medium"]).length >= 2) {

//       return tennisClassName.BH_FLAT_M;

//     } else if (matchWords(classNameToResolve, ["flat","low"]).length >= 2) {

//       return tennisClassName.BH_FLAT_L;

//     } else if (matchWords(classNameToResolve, ["slice","high"]).length >= 2) {

//       return tennisClassName.BH_SLICE_H;

//     } else if (matchWords(classNameToResolve, ["slice","medium"]).length >= 2) {

//       return tennisClassName.BH_SLICE_M;

//     } else if (matchWords(classNameToResolve, ["slice","low"]).length >= 2) {

//       return tennisClassName.BH_SLICE_L;

//     }

//   } else {
//     return classNameToResolve;
//   }
// }

export function resolveClassName(classNameToResolve) {
  const classNameToResolveTLC = classNameToResolve.toLowerCase();
  const words = classNameToResolveTLC.split(' ');
  if (words[0].includes('hand')) {
    words.unshift('groundstroke');
  }
  return [
    words[1][0].toUpperCase() + words[1].slice(1),
    words[2][0].toUpperCase() + words[2].slice(1),
  ]
    .concat(
      words.length > 3
        ? [
            words[3]
              .split('-')
              .map((w) => w[0].toUpperCase())
              .join(''),
          ]
        : []
    )
    .join(' ');
}

export function getClassId(name) {
  return `class${name[0]}${resolveClassName(name).replace(/\s/g, '')}`;
}

// it only adds an s if the length of the number in the parameter is more than 1
export function pluralizeWord(word, length) {
  return length > 1 ? `${word}s` : word;
}

export function formatDate(date) {
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate() + 1}`; // we add one because of the format comming from the server
  const year = `${date.getFullYear()}`;

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [month, day, year].join('/');
}

// Utilities
// get the dates diff en days (start date, end date)
// if end date undefined, it calculates based on the current date
// https://www.w3resource.com/javascript-exercises/javascript-date-exercise-8.php
// eslint-disable-next-line camelcase
export function date_diff_indays(date1, date2) {
  const dt1 = new Date(date1);
  let dt2;
  if (date2 === undefined) {
    dt2 = new Date();
  } else {
    dt2 = new Date(date2);
  }
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}

// utilities
// given seconds returns the hour and minutes
export function getHoursAndMinutes(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
}

export function toTitleCase(string) {
  return string
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');
}

export function tryCatchFixedNumberFunction(value) {
  try {
    return value.toFixed(2);
  } catch (error) {
    // console.log(`Either value(${value}) is null or not a number. Error: ${error}`);
    return '';
  }
}

// eslint-disable-next-line camelcase
export async function relation_to_group(method, username, group = config.DATA_DRIVEN_GROUP) {
  const groupURL = config.group.URL;

  const apiURL = `${groupURL}/${username}/${group}`;

  const params = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  };

  const res = await fetch(apiURL, params)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      console.log(`Bad response: ${JSON.stringify(response)}`);
      throw new Error('Network response was not ok.');
    })
    .catch((error) => {
      console.log(error.message);
      throw new Error(
        'Unable to check relation to group, please check your credentials and try again.'
      );
    });

  return res;
}

export function sortArrObjWithStringPropertyAsc(array, property) {
  const sortFunc = (a, b) => {
    const nameA = a[property].toUpperCase(); // ignore upper and lowercase
    const nameB = b[property].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  return array.sort(sortFunc);
}

export function isObjectEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

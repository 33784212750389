import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review1 from '../../../../images/cuemate101/level2/review/review-1.png';
import review2 from '../../../../images/cuemate101/level2/review/review-2.png';
import review3 from '../../../../images/cuemate101/level2/review/review-3.png';
import review4 from '../../../../images/cuemate101/level2/review/review-4.png';

import '../AppFeatures.css';

export default function L2ReviewStrokeMap() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SESSION REVIEW: STROKE MAPS</h2>
          <h3>SETS 2 - 7</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol>
            <li>View Session Stroke Map</li>
            <img src={review1} className="d-block mx-auto img-fluid" alt="View Composite" />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <img src={review2} className="d-block mx-auto img-fluid" alt="View Usage" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="9">
          <ol start="2">
            <li>Scroll to review individual Stroke Maps for Set 2 – Set 7</li>
            <p className="blue">
              Performance Baseline: Look at ratio of Forehand vs Backhand Strokes.
            </p>
            <img src={review4} className="d-block mx-auto img-fluid" alt="View Composite" />
            <p className="blue">
              Before and After Cues: <br />
              Compare the ‘Performance Baseline’ with ‘Progress Evaluation’.
              <br />
              Observe Forehand/Backhand ratio as well as unintentional flats.
            </p>
          </ol>
        </Col>
        <Col sm="3">
          <img src={review3} className="d-block mx-auto img-fluid" alt="View Usage" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/review-outcome-metrics">
            <h3>
              Next: Review Outcome Metrics <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

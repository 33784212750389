import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Row } from 'reactstrap';

function Level3Description() {
  return (
    <Row>
      <Col sm="12">
        Learn how to interpret your data
        <ul>
          <li>Review your stroke map</li>
          <li>Review your stroke classes</li>
          <li>Review your session report</li>
          <li>Review your overall skill and activity profile</li>
        </ul>
      </Col>
    </Row>
  );
}

function Level3KeyConcepts() {
  return (
    <Row>
      <Col>
        <b>Key Concepts</b>
        <ul>
          <li>
            <Link to="/key-concepts/training-goals">Training Goals</Link>
          </li>
          <li>
            <Link to="/key-concepts/feedback-cueing">Feedback Cueing</Link>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

function Level3Features() {
  return (
    <Row>
      <Col>
        Features
        <ul>
          <li>Session Reports</li>
          <li>Class Reports</li>
          <li>Session Review </li>
          <li>Player Profile</li>
        </ul>
      </Col>
    </Row>
  );
}

// function L3ToC() {
//   return (
//     <Row>
//       <Col sm="12">
//         <ul>
//           <li>
//             <b>Key Concepts</b>
//             <ul>
//               <li>
//                 <Link to="/key-concepts/skill-rating">Skill Rating</Link>
//               </li>
//               <li>
//                 <Link to="/key-concepts/rating-based-training">Rating Based Training</Link>
//               </li>
//             </ul>
//           </li>
//           <br />

//           <li>
//             <b>On the Court</b>
//             <ul>
//               <li>
//                 <Link to="/cuemate101/level3/on-court/advanced-metrics">Advanced Metrics</Link>
//               </li>
//               <li>
//                 <Link to="/cuemate101/level3/on-court/custom-training-goals">
//                   Custom Training Goals
//                 </Link>
//               </li>
//             </ul>
//           </li>
//           <br />

//           <b>TRAINING EXAMPLES</b>
//           <p>(in development)</p>
//         </ul>
//       </Col>
//     </Row>
//   );
// }

export { Level3Description, Level3KeyConcepts, Level3Features };

import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { getHoursAndMinutes } from '../../../../libs/utilities';
import './SessionStats.css';

export default function SessionData({ duration, strokes, successRate, score }) {
  return (
    <Row className="stats-header">
      <Col sm="3" md="3" className="my-auto text-center">
        <p>
          <small>DURATION</small>
        </p>
        <p>{getHoursAndMinutes(duration) || '-'}</p>
      </Col>
      <Col sm="3" md="3" className="my-auto text-center">
        <p>
          <small>STROKES</small>
        </p>
        <p>{strokes || '-'}</p>
      </Col>
      <Col sm="3" md="3" className="my-auto text-center">
        <p>
          <small>SUCCESS RATE</small>
        </p>
        <p>{(successRate && `${successRate.toFixed(2)}%`) || '-'}</p>
      </Col>
      <Col sm="3" md="3" className="my-auto text-center">
        <p>
          <small>SCORE</small>
        </p>
        <p>{(score && (10 * score).toFixed(1)) || '-'}</p>
      </Col>
    </Row>
  );
}

SessionData.propTypes = {
  duration: PropTypes.number,
  strokes: PropTypes.number,
  successRate: PropTypes.number,
  score: PropTypes.number,
};

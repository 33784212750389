import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import reportsoverview from '../../../../images/screens/session-report/SessionReport-ABC.gif';
import timeScale from '../../../../images/screens/session-report/SessionReport-A.png';
import strokesSummary from '../../../../images/screens/session-report/SessionReport-B.png';
import trainingSummary from '../../../../images/screens/session-report/SessionReport-C.png';
import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';
import labelC from '../../../../images/screens/labels/C.png';

import './AppFeatures.css';

export default function ReportsScreen() {
  const timeScaleContent = (
    <Row className="padding-top-100" id="time-scale">
      <Col sm="7">
        <Media>
          <Media left>
            <img src={labelA} className="label-header-max-height" alt="Label A" />
          </Media>
          <Media body>
            <h2>Time Scale</h2>
          </Media>
        </Media>
        <p className="padding-top-50">
          The Time Scale enables you to review the summary of the selected session in comparison
          with four different time scales in the dropdown.
        </p>
      </Col>
      <Col sm="5">
        <img src={timeScale} className="d-block mx-auto img-fluid" alt="Time Scale" />
      </Col>
    </Row>
  );

  const strokesSummaryContent = (
    <Row className="padding-top-100" id="strokes-summary">
      <Col sm="7">
        <Media>
          <Media tag="span" left>
            <img src={labelB} className="label-header-max-height" alt="Label B" />
          </Media>
          <Media body>
            <h2>Strokes Summary</h2>
          </Media>
        </Media>
        <p className="padding-top-25">
          The top panel in the Strokes Summary shows the total number of strokes, total duration,
          stroke quality, sweet spot and movement for the selected section.
        </p>
        <p>
          The second panel firstly lists the averages and trends in key metrics in all the forehand
          and all the backhand strokes followed by the top strokes by usage.
        </p>
        <p>
          The variations computed measure the difference between this specific session and your
          selected time scale. A green delta signifies improvement from your average to your
          session’s average; a red delta signifies decline
        </p>
      </Col>
      <Col sm="5">
        <img src={strokesSummary} className="d-block mx-auto img-fluid" alt="Stroke Summary" />
      </Col>
    </Row>
  );

  const trainingSummaryContent = (
    <Row className="padding-top-100" id="training-summary">
      <Col sm="7">
        <Media>
          <Media left>
            <img src={labelC} className="label-header-max-height" alt="Label C" />
          </Media>
          <Media body>
            <h2>Training Summary</h2>
          </Media>
        </Media>
        <p className="padding-top-50">
          The training summary lists your training goals, their value, progress and status as well
          as the session average for the particular training element. You can filter between pinned
          and all goals using the switch.
        </p>
      </Col>
      <Col sm="5">
        <img src={trainingSummary} className="d-block mx-auto img-fluid" alt="Training Summary" />
      </Col>
    </Row>
  );
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-25">
        <Col sm="12">
          <h1>Session Reports Screen</h1>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="8">
          <img
            src={reportsoverview}
            className="d-block mx-auto img-fluid"
            alt="Session Reports Screen"
          />
        </Col>
        <Col sm="4">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/reports-screen#time-scale">
                  Time Scale
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/reports-screen#strokes-summary">
                  Strokes Summary
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media left>
              <img src={labelC} className="label-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/reports-screen#training-summary">
                  Training Summary
                </Link>
              </h3>
            </Media>
          </Media>
          <p className="padding-top-50">
            CueMate provides reporting capabilities to track skill progression over longer periods
            of time and gather information about activities organized in a session. Use the Report
            data to plan training sets based on historic performance.
          </p>
        </Col>
      </Row>

      {timeScaleContent}
      {strokesSummaryContent}
      {trainingSummaryContent}

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

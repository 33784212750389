import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import metricIndicator from '../../../../images/concepts/metric-indicator-2.png';
import outcomeReferenceRangesGraph from '../../../../images/concepts/outcomes-reference-ranges-graph.png';
import outcomeReferenceRangesGraph2 from '../../../../images/concepts/outcomes-reference-ranges-graph2.png';

function ReferenceRangesContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="mb-3">
        {' '}
        <Col sm="12">
          <h1>Reference Ranges</h1>
          <h3>
            CueMate provides reference ranges to help users in the interpretation of their metrics.
            The reference ranges indicate the desirable values in a metrics. CueMate also accounts
            for different population subgroups (see below) to reflect the appropriate performance
            expectations.{' '}
          </h3>
        </Col>{' '}
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h2>Overview</h2>
          <p>
            The levels and are shown by five ranges (very low, low, medium, high, and very high)
            highlighted by a blue scale shown in the examples below.
          </p>
          <h3 className="blueGrey">Reference Ranges Computation</h3>
          <p>
            The reference ranges are computed based on the statistics in a respective metrics from
            the player population. The example here shows a distribution of spin for a group of
            players. Spin is typically distributed following a so-called normal distribution, that
            means, the mean value is close to the median and both the probability of low and high
            values of spin gets down as move away from the average spin. For example, the likelihood
            you can produce a very high spin (here above 2500rpm) is low. The five ranges from very
            low to very high are computed by dividing the distribution in regions of equal
            probability of 20%.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 3, size: 6 }}>
          <img
            src={outcomeReferenceRangesGraph}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Reference Ranges"
          />{' '}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <p>
            The figure bellow shows reference ranges for the general population group for spin, ball
            speed, and sweet spot percentage.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 3, size: 6 }}>
          <img
            src={outcomeReferenceRangesGraph2}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Reference Ranges"
          />{' '}
        </Col>
      </Row>
    </Container>
  );
}

function LevelIndicators() {
  return (
    <Container>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Level Indicators</h3>
          <p>
            On the CueMate app, each metric is displayed in terms of its statistics (mean and
            standard deviation). These quantities are superposed on a blue scale (low, medium, high,
            very high) that makes it possible to understand where the player’s attributes fall
            relative to the larger player population.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={metricIndicator}
            className="d-block mx-auto img-fluid img-border"
            alt="Metric Indicator"
          />{' '}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h3 className="blueGrey">Interpretation</h3>
          <ul className="with-spacing">
            <li>
              The average value indicates which level you achieve when averaged over many
              repetitions.
            </li>
            <li>The standard deviation shows how much variation around the average value.</li>
            <li>
              The range within the standard deviation represents values you achieve with high
              probability (68% assuming the distribution is normal).
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h2>Reference – Population Subgroups</h2>
          <p>
            The reference ranges are adapted to each class and metric; however, the values only make
            sense if they reflect a representative group of players (players that have similar level
            and individual factors as yours).
          </p>
          <p>
            CueMate computes reference values for different population subgroups. The more specific
            the subgroup, the more useful the reference values.
          </p>
          <p>The following subgroups let you compare your stroke class performance:</p>
          <ul className="with-spacing">
            <li>General population: all the players as a whole.</li>
            <li>
              Skill level: players with the same skill rating in a stroke category (groundstrokes,
              volleys, serves).
            </li>
            <li>
              Class Outcome Rating: players with similar outcome ranges in a class (low, medium,
              high).
            </li>
            <li>UTR Groups: players with similar UTR rating.</li>
            <li>Professional: players that are ranked (ATP or WTA 1000).</li>
            <li>
              Social groups: players that belong to your group of friend group, club, etc. (CueMate
              has various public and private groups you can join).
            </li>
          </ul>
          <h3 className="blueGrey">Skill and Stroke Characteristics</h3>
          <ul className="with-spacing">
            <li>
              The Class Outcome Level: Player groups based on the level of spin and speed outcomes
              of a stroke class.
            </li>
            <li>
              Stroke Styles: Different stroke techniques have different characteristic levels of
              outcomes and technique attributes. This type of reference is more specific than the
              outcome groups since it accounts for the stroke technique used.
            </li>
          </ul>
          <h3 className="blueGrey">Social Groups</h3>
          <p>
            CueMate also enables references groups based on social groups. Social Groups include
            such as clubs, leagues, or your own groups (friends). This makes it possible to compare
            yourself to other players you know. However, these values are typically less useful for
            training.
          </p>
          <h3 className="blueGrey">Outcome Rating</h3>
          <p>
            Outcome levels: The outcome levels for each stroke class are assessed and assigned an
            outcome level. Recall the main stroke outcomes are the ball spin and speed.
          </p>
          <p>
            If your forehand topspin high has a high outcome rating, that means your performance in
            this stroke class is on par with the group of players who can produce the highest level
            of outcomes.
          </p>
          <p>
            Every stroke class is assigned an outcome rating. This makes it possible to identify the
            classes for which your outcomes are deficient.
          </p>
          <p>
            Since the outcome ratings are based on our player population, the rating is expected to
            evolve as more players use CueMate, in particular high-performance players.
          </p>
          <h3 className="blueGrey">Using Reference Groups</h3>
          <ul className="with-spacing">
            <li>
              Different reference groups can be selected based on the skill level. This makes it
              possible to understand where you stand relative to a group of players of your level or
              bellow/above.
            </li>
            <li>
              CueMate assigns you to a reference group so you can get a fair comparison. You can use
              the next level of reference ranges to challenge yourself.
            </li>
          </ul>
          <h3 className="blueGrey">Time Periods</h3>
          <p>
            The statistics depend on the time period used for their computation. Metrics can be
            displayed for various time periods: last set, last session, last 5 sessions, last 3
            months, and last year. Changing the time will change the mean and standard deviation.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default function ReferenceRanges() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>

      <ReferenceRangesContent />
      <LevelIndicators />
      <Container className="padding-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import warmup5 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-5.png';

import baseline1 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-1.png';
import baseline4 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-4.png';

import bhwarmup1 from '../../../../images/cuemate101/level2/set3/bh-warmup-1.png';
import bhwarmup2 from '../../../../images/cuemate101/level2/set3/bh-warmup-2.png';
import bhwarmup3 from '../../../../images/cuemate101/level2/set3/bh-warmup-3.png';
import bhwarmup4 from '../../../../images/cuemate101/level2/set3/bh-warmup-4.png';
import bhwarmup5 from '../../../../images/cuemate101/level2/set3/bh-warmup-5.png';
import bhwarmup6 from '../../../../images/cuemate101/level2/set3/bh-warmup-6.png';
import bhwarmup7 from '../../../../images/cuemate101/level2/set3/bh-warmup-7.png';
import bhwarmup8 from '../../../../images/cuemate101/level2/set3/bh-warmup-8.png';
import bhwarmup9 from '../../../../images/cuemate101/level2/set3/bh-warmup-9.png';
import bhwarmup10 from '../../../../images/cuemate101/level2/set3/bh-warmup-10.png';

import '../AppFeatures.css';

export default function L2S3BHWarmupCue() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SET 3: BACKHAND WARMUP W CUE</h2>
          <h3>Isolate Core Stroke and Introduce Player to Custom Cue</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="4">
          <ol>
            <li>Press + to start a new Set</li>
            <img
              src={baseline1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="2">
            <li>Enter play conditions</li>
            <span className="blue">Technique, W Cue, Tags ON</span>
            <img
              src={bhwarmup1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">Racket Feed, Intensity Level 1, Service Line</span>
          <img
            src={bhwarmup2}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="3">
            <li>Turn on Specific Cues</li>
            <span className="blue">1. Swipe left to get to ‘Training List’</span>
            <img
              src={bhwarmup3}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">
            2. Select ‘Consolidation’, then tap on the Training Goal for the Core Backhand stroke
          </span>
          <img
            src={bhwarmup4}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <br />
          <span className="blue">3. On Training Goal Status screen, turn Cue ‘ON’</span>
          <img
            src={bhwarmup5}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">
            4. Once you see blue ‘Cue Active’ dots, swipe right to return to the Stroke Map
          </span>
          <img
            src={bhwarmup6}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="5">
          <ol start="4">
            <li>Begin Set</li>
            <p className="blue">
              Racket feed +-50 balls with player at the service line, middle
              <br />
              Focus on hitting core Backhand strokes with a lot of topspin
              <br />
              Spin metric = rotation speed of ball leaving the racket (rpm = revolutions per minute)
              <br />
              You will hear the Spin Cue as you generate different levels of Spin
              <br />
              Use cues to understand which stroke adjustments increase spin the most
            </p>
          </ol>
        </Col>
        <Col sm="7">
          <img src={baseline4} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
          <img src={bhwarmup7} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="8">
          <ol start="5">
            <li>Take a look at the live Stroke Map during play</li>
            <img src={bhwarmup8} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
            <br />
            <span className="blue">
              CUE ICONS
              <br />
              Blue stroke metric icons indicate a Custom Cue is active for that stroke class
            </span>
            <img src={bhwarmup10} className="d-block mx-auto img-fluid" alt="Cue Icons" />
          </ol>
        </Col>
        <Col sm="4">
          <br />
          <img src={bhwarmup9} className="d-block mx-auto img-fluid" alt="Performance Arrows" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="6">
            <li>Press Pause when finished</li>
            <img src={warmup5} className="d-block mx-auto img-fluid img-border" alt="Set Toolbar" />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/set-4">
            <h3>
              Next: Set 4 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

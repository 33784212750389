import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import strokemap from '../../../../images/cuemate101/level1/key-concepts/strokemap/stroke-map.png';
import strokemapelements from '../../../../images/cuemate101/level1/key-concepts/strokemap/stroke-map-elements.png';

import '../AppFeatures.css';

export default function StrokeMetrics() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h2>Stroke Map</h2>
          <p>
            CueMate tracks every stroke and displays the information in a grid of 18 Stroke Classes.
            This grid is the Stroke Map and it reveals a player’s ‘stroke repertoire’ during a set.
          </p>
          <p>
            The best players have a broad repertoire of strokes and can respond to and produce a
            variety of shots. The Stroke Map helps challenge players to become more versatile by
            pushing them to populate more classes on the Stroke Map.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={strokemap}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Stroke Metrics Key"
          />
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={{ size: 10, offset: 1 }}>
          <img
            src={strokemapelements}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate101/level1/cueing">
            <h3>
              Next: Cueing <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

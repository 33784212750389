import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';
import trainingloop from '../../../../images/concepts/trainingloop.png';

export default function TrainingLoop() {
  return (
    <Container className="animated fadeIn">
      <Row>
        <Col sm="12">
          <h2 className="blueGrey">Training Loop</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <p>
            {' '}
            Movement training is an incremental and iterative process. That means, you must improve
            various features of your movements in finite amounts over time—you cannot become a great
            player in just a few sessions. Typically, training extends over many months or years
            depending on your desired skill level.
          </p>
        </Col>
        <Col sm="6">
          <p>
            Training therefore involves successive iterations on various movement features. This
            process can be conceptualized by a Training Loop.
          </p>
        </Col>
      </Row>

      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <img
            src={trainingloop}
            className="d-block mx-auto figure-img img-fluid"
            alt="Training loop"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <p>
            The typical Training Loop involves:
            <ol>
              <li>Assess your skills: Identify which strokes and skill attributes to train</li>
              <li>Plan your session: Review and set training goals</li>
              <li>Train and monitor your stroke performance</li>
              <li>Review your overall progress, re-assess your training goal</li>
            </ol>
          </p>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <h3> Assess Skills </h3>
          <p>
            {' '}
            Assess outcomes, technique, and physical performance using CueMate’s stroke metrics.
          </p>
          <p>
            Assess improvements, declines, and plateaus during a training session or over multiple
            sessions.{' '}
          </p>
        </Col>
        <Col sm="6">
          <h3> Plan Session </h3>
          <p>
            {' '}
            Set and prioritize training goals, set feedback cues, organize your training list .{' '}
          </p>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <h3> Play and Monitor </h3>
          <p>
            {' '}
            CueMate tracks all of your <Link to="/key-concepts/stroke-map">strokes</Link> and stroke
            outcomes as you play. Receive real-time alerts, cues, and notifications.{' '}
          </p>
        </Col>
        <Col sm="6">
          <h3> Review Session </h3>
          <p>
            An important aspect of assessing learning is to trends such as improvements, declines,
            and plateaus during and after training sessions.
          </p>
          <p>
            The session reports in the mobile app provides information about key trends in your
            overall repertoire and trends that are specific to your training goals.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

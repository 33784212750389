import React, { useState } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import ModalVideoPlayer from '../../../components/ModalVideoPlayer';
import './MainHome.css';

export default function UngatedWelcomeHero() {
  const typeformURL = 'https://cuemate-tennis.typeform.com/to/LNSqoawF?utm_source=cuemate-tennis';
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  return (
    <Container fluid className="padding-bottom-50 animated fadeIn img-woman-tennis">
      <ModalVideoPlayer
        open={isOpen}
        toggleModal={toggle}
        videoLink="https://vimeo.com/719259403"
      />
      <Row className="padding-top-100">
        <Col sm="7" />
        <Col sm="5" className="padding-bottom-25 padding-4-2-rem">
          <h1 className="font-VR-ExtraLight text-white text-left">
            {' '}
            Experience Human Augmented Training
          </h1>
        </Col>
      </Row>
      <Row className="padding-bottom-100">
        <Col sm="7" />
        <Col sm="5" className="padding-bottom-25">
          <Button
            outline
            tag="a"
            className="rounded-button"
            target="_blank"
            rel="noopener noreferrer"
            onClick={toggle}
          >
            Watch the Video
          </Button>
          <Link
            smooth
            to="/#get-early-access"
            className="rounded-button btn btn-outline-secondary margin-left-10"
          >
            {' '}
            Requirements for Beta{' '}
          </Link>
          <Button
            outline
            tag="a"
            className="rounded-button margin-left-10"
            target="_blank"
            rel="noopener noreferrer"
            href={typeformURL}
          >
            {' '}
            Join the Beta{' '}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import rankedStrokes from '../../../../images/web-features/ranked-strokes.png';
import developmentPhase from '../../../../images/web-features/development-phase-trend.png';
import strokeHistoryUsageBrowser from '../../../../images/web-features/stroke-usage-history-browser.png';
import historyDurationNavigationBar from '../../../../images/web-features/history-duration-navigation-bar.png';
import classCompositeScore from '../../../../images/web-features/class-composite-score-chart.png';
import racketImpactDistribution from '../../../../images/web-features/racket-impact-distribution.png';
import attributeTrends from '../../../../images/web-features/attribute-trends.png';
import motionPhases from '../../../../images/web-features/motion-phases.png';
import strokeProfile from '../../../../images/web-features/stroke-profile.png';
import spinPaceCoefficient from '../../../../images/web-features/spin-pace-coefficient.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import './WebFeatures.css';

export default function WebStrokeClassReports() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Stroke Class Reports</h2>
          <h3>
            The stroke class reports, gives details about each stroke class based on their
            attributes, including the historical usage.
          </h3>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Ranked Strokes</h2>
          <p>
            Ranked Strokes provide a detailed analysis for each stroke class in the order of the
            class ranking.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={rankedStrokes}
            className="d-block mx-auto img-fluid img-border"
            alt="Ranked Strokes"
          />
          <figcaption className="figure-caption">Ranked Strokes</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Development Phase Trend</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={developmentPhase}
            className="d-block mx-auto img-fluid img-border"
            alt="Development Phase Trend"
          />
          <figcaption className="figure-caption">Development Phase Trend</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Usage History Browser</h2>
          <p>Class Use Frequency presents stroke count by session.</p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={strokeHistoryUsageBrowser}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke History Usage Browser"
          />
          <figcaption className="figure-caption">Stroke History Usage Browser</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">History Duration Navigation Bar</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={historyDurationNavigationBar}
            className="d-block mx-auto img-fluid img-border"
            alt="History Duration Navigation Bar"
          />
          <figcaption className="figure-caption">History Duration Navigation Bar</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Class Composite Score Chart</h2>
          <p>
            Class Stats shows the key metric values that determine the score of the stroke class.
            The shaded area implies the score of the class.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <img
            src={classCompositeScore}
            className="d-block mx-auto img-fluid img-border"
            alt="Class Composite Score Chart"
          />
          <figcaption className="figure-caption">Class Composite Score Chart</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Racket Impact Distribution</h2>
          <p>
            Impact Location Distribution shows the cumulative distribution of the stroke impact
            location on the racket over the recorded history. It provides a visual depiction of the
            ball impact location for the different stroke classes which convey two critical pieces
            of information: the center and the spread of the distribution relative to the racket
            frame.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <img
            src={racketImpactDistribution}
            className="d-block mx-auto img-fluid img-border"
            alt="Racket Impact Distribution"
          />
          <figcaption className="figure-caption">Racket Impact Distribution</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Attribute Trends</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <img
            src={attributeTrends}
            className="d-block mx-auto img-fluid img-border"
            alt="Attribue Trends"
          />
          <figcaption className="figure-caption">Attribute Trends</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Motion Phases</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <img
            src={motionPhases}
            className="d-block mx-auto img-fluid img-border"
            alt="Motion Phases"
          />
          <figcaption className="figure-caption">Motion Phases</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Profile</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <img
            src={strokeProfile}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Profile"
          />
          <figcaption className="figure-caption">Stroke Profile</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Spin-Pace Coefficient</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <img
            src={spinPaceCoefficient}
            className="d-block mx-auto img-fluid img-border"
            alt="Spin-Pace Coefficient"
          />
          <figcaption className="figure-caption">Spin-Pace Coefficient</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-web">
            <h3>
              Back to: CueMate for Web <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review1 from '../../../../images/cuemate101/level3/key-concepts/review/review-1.png';
import review2 from '../../../../images/cuemate101/level3/key-concepts/review/review-2.png';
import review3 from '../../../../images/cuemate101/level3/key-concepts/review/review-3.png';

import reviewicon1 from '../../../../images/cuemate101/level3/key-concepts/review/review-icon-1.png';
import reviewicon2 from '../../../../images/cuemate101/level3/key-concepts/review/review-icon-2.png';
import reviewicon3 from '../../../../images/cuemate101/level3/key-concepts/review/review-icon-3.png';
import '../AppFeatures.css';

export default function ReviewFeatures() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h2>Review Features</h2>
          <p>
            After ending your court session, you can review it in the CueMate App. The ‘Review’
            section shows you a Stroke Map overview of the session, as well as your current Skill
            Status.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={review1}
            className="d-block mx-auto img-fluid img-border"
            alt="Review Features"
          />
        </Col>
      </Row>

      <h3 className="padding-top-25 blue">STROKE MAP REVIEW</h3>
      <Row>
        <Col sm={7}>
          <img src={review2} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
        <Col sm={5}>
          <span className="bold">Session Overview</span>
          <br />
          The Stroke Map shows high-level session information including total stroke counts, impact
          success rate, and total play time.
          <br /> <br />
          <span className="bold">Performance Highlights</span>
          <br />
          While in the ’Composite’ view, you can see those stroke metrics that changed the most
          (represented by stroke metric icons in each stroke class)
        </Col>
      </Row>
      <hr />

      <h3 className="padding-top-25 blue">SKILL STATUS</h3>
      <Row>
        <Col sm={6}>
          CueMate analyzes all your stroke data, and sorts your stoke classes into categories that
          reflect current skill level. This view is helpful when making plans for future training
          sessions.
          <img src={review3} className="d-block mx-auto img-fluid" alt="Review: Skills" />
        </Col>
        <Col sm={6}>
          <Media>
            <Media tag="span" left>
              <img src={reviewicon1} className="label-max-height" alt="Formation icon" />
            </Media>
            <Media body>
              <Media heading className="blue">
                FORMATION
              </Media>
              Strokes that you either rarely do, or do poorly, listed in order of how well you
              perform each one (ranked by stroke element metrics relevant to that stroke class).
            </Media>
          </Media>
          <br />

          <Media>
            <Media tag="span" left>
              <img src={reviewicon2} className="label-max-height" alt="Consolidation icon" />
            </Media>

            <Media body>
              <Media heading className="blue">
                CONSOLIDATION
              </Media>
              Strokes that you execute properly often enough, such that you are ready to focus on
              improving them with targeted training.
            </Media>
          </Media>
          <br />

          <Media>
            <Media tag="span" left>
              <img src={reviewicon3} className="label-max-height" alt="Optimization icon" />
            </Media>
            <Media body>
              <Media heading className="blue">
                OPTIMIZATION
              </Media>
              Strokes you have mastered shot outcomes, but you could improve the efficiency of the
              stroke movement to conserve energy during a match or reduce your chances of developing
              an injury.
            </Media>
          </Media>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level3/browse">
            <h3>
              Next: Browse <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';

// Components
import TrainingLoop from '../about/concepts/TrainingLoop';

// CSS
import './Train.css';

function CueMateTraining() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>CueMate Training</h1>
          <h4 className="padding-top-15">
            Training is at the core of CueMate. CueMate’s training augmentation relies on detailed
            and comprehensive assessments of your tennis skills to generate training interventions.
          </h4>
          <h2 className="blueGrey padding-top-15">Training Modalities</h2>
          <h4>CueMate has three primary training modalities:</h4>
          <ul>
            <li>
              <b>Self-directed training</b> is designed to focus on specific issues identified by
              the user or coach using the data and tools. This modality requires a good
              understanding of the CueMate metrics and features.
            </li>
            <li>
              <b>Rating-based training</b> is designed to guide training to focus on increasing your
              overall rating. The rating system is used to automatically generate training goals and
              training tips. This mode is most beneficial for addressing stroke repertoire
              characteristics that determine your playing level and styles.
            </li>
            <li>
              <b>Training programs</b> (under development) is a library of programs designed to
              address a variety of specific issues, leveraging video instructions and CueMate
              training features.
            </li>
          </ul>
          <p>
            Before discussing the specifics of the training modalities, the following briefly
            describes the training loop and real-time cueing:{' '}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function TrainingLoopMain() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <TrainingLoop />
      </Container>
    </Container>
  );
}

function LearnMore() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <p>Learn more about: </p>
      <ul>
        <li>
          <Link to="skill-assessment">
            Skill Assessment Overview
            <i className="fa fa-angle-right" />
          </Link>
        </li>
        <li>
          <Link to="stroke-metrics">
            Stroke Metrics Assessment
            <i className="fa fa-angle-right" />
          </Link>
        </li>
        <li>
          <Link to="learning-process-assessment">
            Learning Process Assessment and Skill Rating
            <i className="fa fa-angle-right" />
          </Link>
        </li>
        <li>
          <Link to="rating">
            {'Rating-based Training '}
            <i className="fa fa-angle-right" />
          </Link>
        </li>
        <li>
          <Link to="self-directed">
            {'Self-directed Training '}
            <i className="fa fa-angle-right" />
          </Link>
        </li>
        <li>
          <Link to="programs">
            {'Training Programs '}
            <i className="fa fa-angle-right" />
          </Link>
        </li>
      </ul>
    </Container>
  );
}

export default function Train() {
  return (
    <>
      <CueMateTraining />
      <TrainingLoopMain />
      <LearnMore />
    </>
  );
}

import React, { Component } from 'react';
import { Container, Col, Collapse, Card, CardBody, CardHeader, Row } from 'reactstrap';
import './FAQs.css';

class Example extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: 0,
      cards: [
        {
          id: 1,
          title: 'What is CueMate?',
          content:
            'CueMate is a training ecosystem based on years of patented research on human movement skills, movement analysis, and wearable sensors. CueMate’s system components include an iPhone app and a sensor that attaches to one’s tennis racket.',
        },
        {
          id: 2,
          title: 'What is the time commitment?',
          content:
            'A minimum of 8 sessions (~16 hours) using CueMate is required in order to collect data. At least one session played per week is ideal.',
        },
        {
          id: 3,
          title: 'What happens if I lose or damage a sensor?',
          content:
            'All sensors are property of iCueMotion. Lost or damaged sensors should be reported immediately. Lost sensors require a police report.',
        },
        {
          id: 4,
          title: 'What happens after the CueMate Beta Program is complete?',
          content:
            'The data we collect from each trial participant will be used some a hopeful spin-off product with altruistic purpose and utility.',
        },
        {
          id: 5,
          title: 'Can my tennis buddy join as well?',
          content:
            'Referrals are individually considered at this stage in the program. Please enter your tennis friend’s name and email address for consideration.',
        },
      ],
    };
  }

  toggle(e) {
    const { event } = e.target.dataset;
    const { collapse } = this.state;
    this.setState({ collapse: collapse === Number(event) ? 0 : Number(event) });
  }

  render() {
    const { cards, collapse } = this.state;
    return (
      <Container className="FAQs padding-top-50 padding-bottom-100 animated fadeIn">
        <Row className="padding-bottom-50 text-center">
          <Col sm={12}>
            <h2 className="blueGrey">Still have questions?</h2>
          </Col>
        </Row>
        {cards.map((card) => (
          <Card key={card.id}>
            <CardHeader onClick={this.toggle} data-event={card.id}>
              {card.title}
            </CardHeader>
            <Collapse isOpen={collapse === card.id}>
              <CardBody>{card.content}</CardBody>
            </Collapse>
          </Card>
        ))}
      </Container>
    );
  }
}

export default Example;

import React, { useState, useEffect, useCallback } from 'react';
import { Navbar } from 'cuemate-charts';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  TabContent,
  TabPane,
  Row,
  Col,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import imgLikeProfile from '../../../../images/social/profile-light-blue@3x.png';
import imgCommentProfile from '../../../../images/social/profile-big@3x.png';
import LoadingStateComponent from '../../../../components/LoadingStateComponent';
import LoaderButton from '../../../../components/LoaderButton';
import { cuemateGET, cuematePOST } from '../../../../libs/dataAccess';
import LikeReactionButton from './LikeReactionButton';
import './ModalReactions.css';

export const ReactionTabs = {
  LIKES: 'LIKES',
  COMMENTS: 'COMMENTS',
};

function RowLikes({ username }) {
  return (
    <Row className="row-likes">
      <Col sm="2" md="2" className="my-auto">
        <img alt="profile" src={imgLikeProfile} className="profile-img" />
      </Col>
      <Col className="my-auto">
        <h4>{username}</h4>
      </Col>
    </Row>
  );
}

function ReactionsList({ items }) {
  const rows = [];
  items.forEach((reaction) => {
    rows.push(<RowLikes key={reaction.username} username={reaction.username} />);
  });
  return rows;
}

function RowComments({ username, comment }) {
  return (
    <Row className="row-comments">
      <Col sm="2" md="2" className="my-auto mx-auto">
        <img alt="profile" src={imgCommentProfile} className="profile-img" />
      </Col>
      <Col sm="10" md="10">
        <Row>
          <Col sm="12" md="12">
            <h4>{username}</h4>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12">
            <p>{comment}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const CommentsList = ({ items }) => {
  const rows = [];
  items.forEach((comments) => {
    rows.push(
      <RowComments key={comments.id} username={comments.username} comment={comments.comment} />
    );
  });
  return rows;
};

function ModalFooterLikes({ isLiked, activityId, initialCount, didCountUpdate }) {
  return (
    <ModalFooter className="social-buttons blue justify-content-start">
      <LikeReactionButton
        isLiked={isLiked}
        activityId={activityId}
        initialCount={initialCount}
        didCountUpdate={didCountUpdate}
      />
    </ModalFooter>
  );
}

function ModalFooterComments({ activityId, didPostComment }) {
  const [commentText, setCommentText] = useState('');
  const [isPostingComment, setIsPostingComment] = useState(false);

  const postComment = useCallback(() => {
    async function fetch() {
      try {
        if (commentText.length > 0) {
          setIsPostingComment(true);
          const currentSession = await Auth.currentSession();
          await cuematePOST(
            `/users/social/activities/${activityId}/comments`,
            currentSession.accessToken.jwtToken,
            { comment: commentText }
          );
          setIsPostingComment(false);
          if (didPostComment !== undefined) {
            didPostComment();
          }
        }
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [commentText]);
  return (
    <ModalFooter className="comments-footer">
      <Row>
        <Col sm="2" md="2">
          <img alt="profile" src={imgCommentProfile} className="profile-img" />
        </Col>
        <Col sm="8" md="8">
          <Input
            type="textarea"
            name="comment"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </Col>
        <Col sm="2" md="2">
          <LoaderButton
            onClick={postComment}
            text="Send"
            outline
            color="primary"
            size="sm"
            loadingText="Posting.."
            isLoading={isPostingComment}
            disabled={commentText.length < 0}
            block
          />
        </Col>
      </Row>
    </ModalFooter>
  );
}

function LoadingFooter() {
  return (
    <ModalFooter className="social-buttons blue justify-content-start">
      <i className="fa fa-spinner fa-pulse fa-2x fa-fw blue" aria-hidden="true" />
    </ModalFooter>
  );
}

function CustomModalFooter({
  activeTab,
  isLoadingReactionsList,
  isLoadingCommentsLists,
  activityId,
  reactionsList,
  username,
  didCountUpdate,
  didPostComment,
}) {
  switch (activeTab) {
    case ReactionTabs.LIKES:
      if (isLoadingReactionsList) {
        return <LoadingFooter />;
      }
      return (
        <ModalFooterLikes
          isLiked={reactionsList.findIndex((el) => el.username === username) >= 0}
          activityId={activityId}
          initialCount={reactionsList.length}
          didCountUpdate={didCountUpdate}
        />
      );
    case ReactionTabs.COMMENTS:
      if (isLoadingCommentsLists) {
        return <LoadingFooter />;
      }
      return <ModalFooterComments activityId={activityId} didPostComment={didPostComment} />;

    default:
      return null;
  }
}

function ModalReactions({
  toggle,
  isOpen,
  activeTab: defaultTab,
  activityId,
  isLikedByUserInit,
  onModalClosed,
}) {
  const sideBarItemsInit = [
    { name: ReactionTabs.LIKES, label: ReactionTabs.LIKES },
    { name: ReactionTabs.COMMENTS, label: ReactionTabs.COMMENTS },
  ];
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [reactionsList, setReactionsList] = useState([]);
  const [isLoadingReactionsList, setIsLoadingReactionsList] = useState(true);
  const [commentsList, setCommentsList] = useState([]);
  const [isLoadingCommentsLists, setIsLoadingCommentsList] = useState(true);
  const [currentUsername, setCurrentUsername] = useState('');
  const [sideBarItems, setSideBarItems] = useState(sideBarItemsInit);
  const [isLikedByUser, setIsLikedByUser] = useState(isLikedByUserInit);

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    setIsLikedByUser(isLikedByUser);
  }, [isLikedByUserInit]);

  const onTransition = (state) => {
    const tab = sideBarItems[state].label;
    setActiveTab(tab);
  };

  const reloadReactionsLists = useCallback(
    // eslint-disable-next-line no-shadow
    (isLikedByUser) => {
      async function fetch() {
        try {
          setIsLoadingReactionsList(true);
          const currentSession = await Auth.currentSession();
          setCurrentUsername(currentSession.accessToken.payload.username);

          cuemateGET(
            `/users/social/activities/${activityId}/reactions`,
            currentSession.accessToken.jwtToken
          ).then((response) => {
            if (response.reactions) {
              const newSideBarItems = [...sideBarItems];
              newSideBarItems[0].count = response.reactions.length;
              setReactionsList(response.reactions);
              setSideBarItems(newSideBarItems);
              setIsLoadingReactionsList(false);
              setIsLikedByUser(isLikedByUser);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
      fetch();
    },
    [activityId]
  );

  const onCommentPost = useCallback(() => {
    async function fetch() {
      try {
        setIsLoadingCommentsList(true);
        const currentSession = await Auth.currentSession();

        cuemateGET(
          `/users/social/activities/${activityId}/comments`,
          currentSession.accessToken.jwtToken
        ).then((response) => {
          if (response.comments) {
            const newSideBarItems = [...sideBarItems];
            newSideBarItems[1].count = response.comments.length;
            setCommentsList(response.comments);
            setSideBarItems(newSideBarItems);
            setIsLoadingCommentsList(false);
          }
        });
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [activityId]);

  useEffect(() => {
    async function fetch() {
      if (isOpen) {
        try {
          setIsLoadingReactionsList(true);
          setIsLoadingCommentsList(true);
          const currentSession = await Auth.currentSession();
          setCurrentUsername(currentSession.accessToken.payload.username);
          cuemateGET(
            `/users/social/activities/${activityId}/reactions`,
            currentSession.accessToken.jwtToken
          ).then((response) => {
            if (response.reactions) {
              const newSideBarItems = [...sideBarItems];
              newSideBarItems[0].count = response.reactions.length;
              setReactionsList(response.reactions);
              setSideBarItems(newSideBarItems);
              setIsLoadingReactionsList(false);
            }
          });

          cuemateGET(
            `/users/social/activities/${activityId}/comments`,
            currentSession.accessToken.jwtToken
          ).then((response) => {
            if (response.comments) {
              const newSideBarItems = [...sideBarItems];
              newSideBarItems[1].count = response.comments.length;
              setCommentsList(response.comments);
              setSideBarItems(newSideBarItems);
              setIsLoadingCommentsList(false);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
    }
    fetch();
  }, [isOpen, activityId]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(activeTab)}
      scrollable
      id="modal-reactions"
      onClosed={() => onModalClosed(isLikedByUser, reactionsList.length, commentsList.length)}
    >
      <ModalHeader toggle={() => toggle(activeTab)}>Reactions</ModalHeader>
      <ModalBody>
        <Navbar
          items={sideBarItems}
          toggle={onTransition}
          activeIndex={sideBarItems.findIndex((el) => el.label === activeTab)}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId={ReactionTabs.LIKES}>
            <Row>
              <Col sm="12" md="12">
                {isLoadingReactionsList ? (
                  <LoadingStateComponent />
                ) : (
                  <ReactionsList items={reactionsList} />
                )}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={ReactionTabs.COMMENTS}>
            <Row>
              <Col sm="12" md="12">
                {isLoadingCommentsLists ? (
                  <LoadingStateComponent />
                ) : (
                  <CommentsList items={commentsList} />
                )}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ModalBody>
      <CustomModalFooter
        activeTab={activeTab}
        isLoadingReactionsList={isLoadingReactionsList}
        isLoadingCommentsLists={isLoadingCommentsLists}
        reactionsList={reactionsList}
        username={currentUsername}
        activityId={activityId}
        didCountUpdate={reloadReactionsLists}
        didPostComment={onCommentPost}
      />
    </Modal>
  );
}

export default ModalReactions;

ModalReactions.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  activeTab: PropTypes.string,
  activityId: PropTypes.string,
  onModalClosed: PropTypes.func,
  isLikedByUserInit: PropTypes.bool,
};

ModalFooterLikes.propTypes = {
  isLiked: PropTypes.bool,
  activityId: PropTypes.string,
  initialCount: PropTypes.number,
  didCountUpdate: PropTypes.func,
};

ModalFooterComments.propTypes = {
  activityId: PropTypes.string,
  didPostComment: PropTypes.func,
};

ReactionsList.propTypes = {
  items: PropTypes.array,
};

RowLikes.propTypes = {
  username: PropTypes.string,
};

RowComments.propTypes = {
  username: PropTypes.string,
  comment: PropTypes.string,
};

CustomModalFooter.propTypes = {
  activeTab: PropTypes.string,
  isLoadingReactionsList: PropTypes.bool,
  isLoadingCommentsLists: PropTypes.bool,
  reactionsList: PropTypes.array,
  username: PropTypes.string,
  activityId: PropTypes.string,
  didCountUpdate: PropTypes.func,
  didPostComment: PropTypes.func,
};

import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import trainingGoalStatus from '../../../../images/screens/TrainingGoal-Status.PNG';
import trainingLoop from '../../../../images/concepts/trainingloop.png';

import '../AppFeatures.css';

function TrainingGoalsTitle() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm="12">
          <h1>Training Goals</h1>
          <h3>
            CueMate is designed to turn your data into training interventions that will help you
            elevate your game.
          </h3>
          <p>
            A strong stroke repertoire makes you more competitive in games and also makes it easier
            to fully enjoy recreational play.
          </p>
          <p>
            The gold standard in training is so-called deliberate training. CueMate is the only tool
            that is designed to enable deliberate training using movement data.
          </p>
          <p>
            {' '}
            Learn more at <Link to="/cuemate-ios/training">Training</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function DeliberateTraining() {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h2 className="blueGrey">Deliberate Training</h2>
          <p>
            Many players tend to gravitate toward their comfort zone. In tennis that means you will
            mostly practice what you already know. This is natural because your overall success rate
            is higher for those strokes you can already perform well. However, the result of this
            practice is that your skills plateau.
          </p>
          <p>
            This is where deliberate training and CueMate comes in. To improve you need to be
            deliberate about focusing on your weaknesses and then systematic about working on
            improving these weaknesses.
          </p>
          <h3 className="blueGrey">Challenges of good Training</h3>
          <p>But how do you go about this?</p>
          <p>
            First, it is difficult to improve something that you can’t objectively measure or
            assess. And, you cannot improve at once or set arbitrary goals. y goals. Training
            involves breaking up the process into stages Each stage has to have goals that are
            adapted to your level and isseues.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 3, size: 6 }}>
          <img
            src={trainingLoop}
            className="d-block mx-auto img-fluid img-border"
            alt="Training Loop"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className="blueGrey">CueMate Training Journey</h3>
          <ul className="with-spacing">
            <li>Training is a journey and you want to shortest path to your desired skills.</li>
            <li>
              CueMate’s skill model enables a comprehensive and detailed picture of your skills.
            </li>
            <li>
              The CueMate learning model acts like a GPS for your training, making it possible to
              guide your training in an incremental fashion, from your current level, putting you in
              a favorable position for the long-term progress in skills.{' '}
            </li>
            <li>
              Training goals make it possible to get objective, measurable assessment of your
              progress, so you can look back at your sessions and see where you get stuck and get
              encouraged by the progress.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

function TrainingTools() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row>
        <Col>
          <h2 className="blueGrey">Training Tools</h2>
          <p>
            If you have reviewed the detailed background, you will understand that training goals
            should:
          </p>
          <ul>
            <li>
              Focus on the. This aspect is primarily determined by your stroke outcomes across the
              repertoire.
            </li>
            <li>
              Create intermediate goals to help you progress efficiently. This aspect is primarily a
              function of building up stroke technique in a way that is consistent with your
              learning stage.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

function SkillAssessments() {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h2 className="blueGrey">Skill Assessments</h2>
          <p>
            CueMate accomplishes this through a range of analytics which focus on key aspects of
            your stroke technique:{' '}
          </p>
          <ul>
            <li>
              <b>Repertoire Scores</b> describe the skill attributes considering the entire
              repertoire of strokes.
            </li>
            <li>
              <b>Stroke Class Scores</b> describe different aspects of performance of the stroke
              classes (strike quality, outcome level, outcome efficiency).
            </li>
            <li>
              <b>Learning Stage</b> determines the learning stage of each of your stroke classes
              (formation, consolidation, optimization). The learning stage helps determine what
              aspect of the stroke performance to focus on during training.
            </li>
            <li>
              <b>Global Score</b> combines the class scores to provide a total score (stroke quality
              score, outcome level, outcome efficiency).{' '}
            </li>
            <li>
              <b>Skill Rating</b> which describes the overall skill based on properties of the
              stroke repertoire and learning stage.
            </li>
          </ul>

          <h3>Stroke Class Scores</h3>
          <p>
            {' '}
            Each stroke class is described by the dozens or hundreds of strokes that you have
            performed that satisfy the class definition. With this number of strokes, it is possible
            to reliably describe your performance across a range of characteristics.{' '}
          </p>
          <p>The stroke class scores are computed by combining the various stroke metrics.</p>
          <Table bordered size="sm" responsive>
            <thead>
              <tr>
                <th>
                  <b>Stroke Class Scores</b>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Strike Quality Score</td>
                <td>
                  <p>Shot anticipation</p>
                  <p>Stroke preparation</p>
                </td>
              </tr>
              <tr>
                <td>Stroke Outcome Level</td>
                <td>Stroke level of outcomes (spin and speed).</td>
              </tr>
              <tr>
                <td>Stroke Outcome Efficiency</td>
                <td>
                  Efficiency in achieving the outcomes. Low values indicate deficiencies in stroke
                  technique including use of the body (kinematic chain).
                </td>
              </tr>
              <tr>
                <td>Stroke Quality Score</td>
                <td>Combines all the stroke metrics to create a composite score.</td>
              </tr>
            </tbody>
          </Table>
          <p>
            The Stroke Quality Score can be used to determine which aspects that have the highest
            impact on your skills for a specific stroke class. This information is useful to
            determine what aspect to focus on to achieve the highest impact on stroke quality and
            performance.
          </p>

          <h3>Global Score</h3>
          <p>
            The global score is by combining all the classes scores. This information can be used to
            generate the leaderboard. You can access the leaderboard in the CueMate’s web app.
          </p>
          <p>
            Given the multi-dimensional nature of skills, it is difficult to reliably reduce your
            skills to a single score. Therefore, the ranking is indicative. In particular skills
            relies on the ability of building and winning points and other aspect of game strategies
            and mental game.
          </p>

          <h3>Skill Rating</h3>
          <p>
            CueMate’s Skill Rating was developed to address the difficulty of rating skills
            reliably. The Skill Rating considers the learning process, i.e., how you acquire the
            repertoire of strokes.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function TrainingGoalDetermination() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h2 className="blueGrey">Training Goal Determination</h2>
          <h3>Automatic Training Goals</h3>
          <p>
            CueMate computes a range of training goals automatically using the learning model that
            combines the user data. These features are expected to grow with the user base and the
            analysis of the user’s performance. The foundation of the automatic training goals is
            the rating system.
          </p>
          <p>
            See <Link to="/">Rating-based Training</Link> for more details.
          </p>
          <h3>Manual Training Goals</h3>
          <p>
            For players who are motivated to understand the details of the metrics and their
            interpretation, CueMate provides tools to generate and manage training goals manually.
          </p>
          <p>
            The training goals for complex skills like tennis are best in the context of the
            hierarchical movement and skill model.
          </p>
          <p>
            {' '}
            A hierarchical model can be understood both from the top-down, i.e., how requirements
            about the stroke repertoire set requirements for the strokes, and bottom up, i.e., how
            the performance of the strokes combine to create the player’s overall skills.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function TrainingAids() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col sm={6}>
            <h2 className="blueGrey">Training Aids</h2>
            <h3>Training Tips</h3>
            <p>In addition to the training goals, instructions to help you best attain them.</p>
            <p>
              CueMate has a database of training tips that get matched with the training goals. In
              addition, it includes so-called “cue words” to remind you of features to focus on when
              training a particular goal. These can be enabled with feedback cueing.
            </p>
            <h3>Training Videos</h3>
            <p>
              Most people are visual and learn best witch demonstrations. CueMate is building a
              library of videos to complement the training tips and help you achieve your training
              goals.{' '}
            </p>
            <p>(in development)</p>
          </Col>
          <Col sm={6}>
            <img
              src={trainingGoalStatus}
              className="d-block mx-auto img-fluid"
              alt="Training Goal Status Screen"
            />
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </Container>
  );
}

export default function TrainingGoals() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TrainingGoalsTitle />
      <DeliberateTraining />
      <TrainingTools />
      <SkillAssessments />
      <TrainingGoalDetermination />
      <TrainingAids />
    </>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Breadcrumbs';
import './Features.css';

export default function Features() {
  return (
    <Container className="padding-top-25 padding-bottom-50 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h1>CueMate Features</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h4>Click on a feature to learn more about it</h4>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="12">
          <h2>CueMate Training Agent</h2>
        </Col>
      </Row>
      <Col sm="6">
        <Link to="/cuemate-ios/training">
          <h3>Training</h3>
        </Link>
      </Col>
      <Row className="padding-top-50">
        <Col sm="12">
          <h2>CueMate Metrics</h2>
        </Col>
      </Row>
      <Col sm="6">
        <Link to="/cuemate-ios/metrics">
          <h3>Metrics</h3>
        </Link>
      </Col>
    </Container>
  );
}

import React, { Fragment, useState, useCallback } from 'react';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import ModalReactions, { ReactionTabs } from './reactions/ModalReactions';
import LikeReactionButton from './reactions/LikeReactionButton';
import PPPlaceHolder from '../../../images/social/avatar-placeholder.png';
import CommentIMG from '../../../images/social/comment@3x.png';
import './Feed.css';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

function FeedHeader({ name, data, img }) {
  return (
    <Row className="social-header">
      <Col sm="2" md="2">
        <a href="#profile">
          <img alt="img" src={img} />
        </a>
      </Col>
      <Col sm="10" md="10">
        <Row>
          <Col sm="12" md="12">
            <a href="#name" className="">
              {name}
            </a>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="12">
            <p className="text-muted">
              {' '}
              {`${dayjs(data.endTS).fromNow()} | ${dayjs(data.endTS).format('MMMM DD[,] YYYY')} | ${
                data.location
              }`}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default function Feed({ user, mainContent, subcontent, socialData }) {
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(ReactionTabs.LIKES);
  const [likesCount, setLikesCount] = useState(socialData.reactions.length);
  const [commentsCount, setCommentsCount] = useState(socialData.commentsCount);
  const [isLikedByUser, setIsLikedByUser] = useState(socialData.isLikedByUser);

  const onModalClosed = useCallback(
    // eslint-disable-next-line no-shadow
    (isLikedByUser, likesCount, commentsCount) => {
      setLikesCount(likesCount);
      setIsLikedByUser(isLikedByUser);
      setCommentsCount(commentsCount);
    },
    [likesCount, isLikedByUser, commentsCount]
  );

  const toggle = (tab) => {
    setActiveTab(tab);
    setShouldShowModal(!shouldShowModal);
  };

  return (
    <>
      <ModalReactions
        toggle={toggle}
        isOpen={shouldShowModal}
        activeTab={activeTab}
        activityId={socialData.sessionId}
        onModalClosed={onModalClosed}
        isLikedByUserInit={isLikedByUser}
      />
      <div className="social-feed-separated animated fadeIn">
        <div className="social-feed-box">
          <FeedHeader
            name={user.displayName}
            playType="Freeplay"
            data={user.data}
            img={PPPlaceHolder}
          />
          <div className="social-body">
            {subcontent}
            <Row>
              <Col
                sm={{ size: '6', offset: '3' }}
                md={{ size: '6', offset: '3' }}
                className="no-padding-left"
              >
                {mainContent}
              </Col>
            </Row>
            <Row>
              <Col sm="3" md="3" className="d-flex align-items-center no-padding-right">
                <ButtonGroup className="social-buttons">
                  <LikeReactionButton
                    isLiked={isLikedByUser}
                    activityId={socialData.sessionId}
                    onCountClick={() => toggle(ReactionTabs.LIKES)}
                    initialCount={likesCount}
                  />
                  <Button
                    color="white"
                    size="sm"
                    className="blue d-flex align-items-start"
                    onClick={() => toggle(ReactionTabs.COMMENTS)}
                  >
                    <img className="comment" alt="comment" src={CommentIMG} />
                  </Button>
                  <Button
                    className="p-0"
                    color="link"
                    onClick={() => toggle(ReactionTabs.COMMENTS)}
                  >
                    {commentsCount}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

Feed.propTypes = {
  user: PropTypes.object,
  mainContent: PropTypes.any,
  subcontent: PropTypes.any,
  socialData: PropTypes.object,
};

FeedHeader.propTypes = {
  name: PropTypes.string,
  data: PropTypes.any,
  img: PropTypes.string,
};

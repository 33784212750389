import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Train.css';

function Content() {
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <h1 className="mb-4">Learning Process Assessment and Skill Rating</h1>
          <h3 className="blueGrey">Stroke Learning Process Metrics</h3>
          <p>
            The biological learning process has some particular properties, one of the main one is
            that depends on biological changes such as neuroplasticity (the changes in the neural
            networks) and the development of strength and coordination. These changes don’t take
            place instantaneously and are stimulated by specific training conditions (and sleep).
          </p>
          <p>Assess how progressing.</p>
          <ul className="with-spacing">
            <li>
              Stroke Learning Stage describes how far along your brain and body are in the learning
              process.
            </li>
          </ul>
          <h3 className="blueGrey">Skill Rating</h3>
          <ul className="with-spacing">
            <li>
              Skill Rating uses all the above information to determine how the different strokes
              contribute to the user’s overall skills.
            </li>
          </ul>
        </Col>
        <Col sm={12}>
          <h2 className="mb-4 blueGrey">Stroke Learning Process Metrics</h2>
          <p>
            CueMate defines three key concepts: the skill status, skill profile, and skill rating.
          </p>
          <p>(In Development)</p>
          <h3 className="mb-4 blueGrey">Skill Status</h3>
          <p>
            Learning stage across the repertoire makes see which strokes are lagging in learning.
            The knowledge of learning stage is also helpful to determine the type of training
            intervention.
          </p>
          <p>(In Development)</p>
          <h3 className="blueGrey">Skill Profile</h3>
          <p>Overall repertoire composition and gaps.</p>
          <p>(In Development)</p>
        </Col>
      </Row>
    </Container>
  );
}

export default function LearningProcessAssessmentAndSkillRating() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Content />

      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

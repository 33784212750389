import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import { Storage } from 'aws-amplify';
import './Guides.css';
import document from '../../../../images/icons/document-icon.png';

export default class PdfLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfURL: undefined,
    };
  }

  async componentDidMount() {
    try {
      let { pdfURL } = this.props;
      pdfURL = await Storage.get(pdfURL);
      this.setState({
        pdfURL,
      });
    } catch (e) {
      // eslint-disable-next-line no-undef
      alert(e);
    }
  }

  render() {
    const { linkText, show } = this.props;
    const { pdfURL } = this.state;
    return (
      <a href={pdfURL} target="_blank" rel="noopener noreferrer">
        <Media>
          <Media left>
            {show && (
              <span>
                <img src={document} className="label-max-height" alt="Document label" />
                &nbsp;
              </span>
            )}
            {linkText}
          </Media>
        </Media>
      </a>
    );
  }
}

PdfLink.propTypes = {
  pdfURL: PropTypes.string,
  linkText: PropTypes.string,
  show: PropTypes.bool,
};

import React, { useState } from 'react';
import { Button, ButtonGroup, Container } from 'reactstrap';
import { Leaderboard } from '../../home/reports/components/Leaderboard';
import LeaderboardType from './LeaderboardConstants';

function Leaderboards() {
  const [currentLeaderboard, setCurrentLeaderboard] = useState(LeaderboardType.GLOBAL);

  return (
    <Container>
      <ButtonGroup>
        <Button
          color="primary"
          active={currentLeaderboard === LeaderboardType.GLOBAL}
          onClick={() => setCurrentLeaderboard(LeaderboardType.GLOBAL)}
        >
          {LeaderboardType.GLOBAL}
        </Button>
        <Button
          color="primary"
          active={currentLeaderboard === LeaderboardType.FRIENDS}
          onClick={() => setCurrentLeaderboard(LeaderboardType.FRIENDS)}
        >
          {LeaderboardType.FRIENDS}
        </Button>
      </ButtonGroup>

      <Leaderboard leaderboardType={currentLeaderboard} username="some username" />
    </Container>
  );
}

export default Leaderboards;

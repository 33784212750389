import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import { Navbar } from 'cuemate-charts';
import LoadingStateComponent from '../../../../components/LoadingStateComponent';
import imgProfile from '../../../../images/social/profile-green@3x.png';
import AddFriendForm from './AddFriendForm';
import { cuemateGET, cuematePOST, cuemateDELETE } from '../../../../libs/dataAccess';
import { FriendshipStatus } from '../SocialConstants';
import LoaderButton from '../../../../components/LoaderButton';
import { sortArrObjWithStringPropertyAsc } from '../../../../libs/utilities';
import './ManageFriendships.css';

export const Tabs = {
  ADD: 'ADD FRIENDS',
  MANAGE: 'MANAGE FRIENDS',
};

export function RowFriendRequest({ data: user, onRowUpdate, compactList = false }) {
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const acceptFriendRequest = useCallback(() => {
    async function request() {
      try {
        setIsSendingRequest(true);
        const url = `/users/social/friendships/${user.username}/accept`;
        const currentSession = await Auth.currentSession();

        await cuematePOST(url, currentSession.accessToken.jwtToken);
        setIsSendingRequest(false);
        if (onRowUpdate !== undefined) onRowUpdate();
      } catch (err) {
        console.error(err);
      }
    }
    request();
  }, []);

  const rejectFriendRequest = useCallback(() => {
    async function request() {
      try {
        setIsSendingRequest(true);
        const url = `/users/social/friendships/${user.username}/reject`;
        const currentSession = await Auth.currentSession();

        await cuematePOST(url, currentSession.accessToken.jwtToken);
        setIsSendingRequest(false);
        if (onRowUpdate !== undefined) onRowUpdate();
      } catch (err) {
        console.error(err);
      }
    }
    request();
  }, []);

  let mdPP = '1';
  let mdUsername = '2';
  let mdAcceptB = { size: '1', offset: '7' };
  let mdRejectB = '1';

  if (compactList) {
    mdPP = '2';
    mdUsername = '3';
    mdAcceptB = { size: '3', offset: '1' };
    mdRejectB = '3';
  }
  return (
    <Row className="row-friend">
      <Col sm="1" md={mdPP} className="my-auto">
        <img alt="profile" src={imgProfile} className="profile-img" />
      </Col>
      <Col sm="2" md={mdUsername} className="my-auto">
        <h4>{user.username}</h4>
      </Col>
      <Col sm={{ size: '1', offset: '7' }} md={mdAcceptB}>
        <LoaderButton
          outline
          onClick={acceptFriendRequest}
          text=""
          isLoading={isSendingRequest}
          color="primary"
          className="btn-circle btn-accept"
        />
      </Col>
      <Col sm="1" md={mdRejectB}>
        <LoaderButton
          outline
          onClick={rejectFriendRequest}
          text=""
          isLoading={isSendingRequest}
          color="danger"
          className="btn-circle btn-reject"
        />
      </Col>
    </Row>
  );
}

RowFriendRequest.propTypes = {
  data: PropTypes.object,
  onRowUpdate: PropTypes.func,
  compactList: PropTypes.bool,
};

function RowFriend({ data: user, onRowUpdate }) {
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const deleteFriendship = useCallback(() => {
    async function request() {
      try {
        setIsSendingRequest(true);
        const url = `/users/social/friendships/${user.username}`;
        const currentSession = await Auth.currentSession();

        await cuemateDELETE(url, currentSession.accessToken.jwtToken);
        setIsSendingRequest(false);
        if (onRowUpdate !== undefined) onRowUpdate();
      } catch (err) {
        console.error(err);
      }
    }
    request();
  }, []);

  return (
    <Row className="row-friend">
      <Col sm="1" md="1" className="my-auto">
        <img alt="profile" src={imgProfile} className="profile-img" />
      </Col>
      <Col sm="2" md="2" className="my-auto">
        <h4>{user.username}</h4>
      </Col>
      <Col sm={{ size: '1', offset: '7' }} md={{ size: '1', offset: '8' }}>
        <LoaderButton
          outline
          onClick={deleteFriendship}
          text=""
          isLoading={isSendingRequest}
          color="danger"
          className="btn-circle btn-reject"
        />
      </Col>
    </Row>
  );
}

RowFriend.propTypes = {
  data: PropTypes.object,
  onRowUpdate: PropTypes.func,
};

function FriendsList({ listType, header, items, onListUpdate }) {
  const rows = [];
  items.forEach((item) => {
    switch (listType) {
      case FriendshipStatus.PENDING:
        rows.push(<RowFriendRequest key={item.username} data={item} onRowUpdate={onListUpdate} />);
        break;
      case FriendshipStatus.ACCEPTED:
        rows.push(<RowFriend key={item.username} data={item} onRowUpdate={onListUpdate} />);
        break;
      default:
        break;
    }
  });

  return (
    <>
      <h3 className="friends-lst-header font-weight-light">{header}</h3>
      {rows}
    </>
  );
}

FriendsList.propTypes = {
  header: PropTypes.string,
  items: PropTypes.array,
  listType: PropTypes.number,
  onListUpdate: PropTypes.func,
};

function ManageFriendships({
  onTabChange,
  onFriendRequestsUpdate,
  friendRequests: initialFriendRequests = [],
}) {
  const barItems = [
    { name: Tabs.MANAGE, label: Tabs.MANAGE, count: 0 },
    { name: Tabs.ADD, label: Tabs.ADD },
  ];
  const [activeTab, setActiveTab] = useState(Tabs.MANAGE);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [friendRequests, setFriendRequests] = useState(initialFriendRequests);
  const [activeFriendsList, setActiveFriendList] = useState([]);
  const onToggle = (tabIndex) => {
    setActiveTab(barItems[tabIndex].name);
    if (onTabChange) onTabChange(barItems[tabIndex].name);
  };

  const updateList = async () => {
    try {
      setIsLoadingList(true);
      const url = `/users/social/friendships`;
      const currentSession = await Auth.currentSession();

      const response = await cuemateGET(url, currentSession.accessToken.jwtToken);

      const friends = sortArrObjWithStringPropertyAsc(response.data, 'username');

      const requestsList = friends.filter((el) => el.status === FriendshipStatus.PENDING);
      const activeList = friends.filter((el) => el.status === FriendshipStatus.ACCEPTED);

      barItems[0].count = activeList.length;
      if (onFriendRequestsUpdate !== undefined) onFriendRequestsUpdate(requestsList);
      setFriendRequests(requestsList);
      setActiveFriendList(activeList);
      setIsLoadingList(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setFriendRequests(initialFriendRequests);
  }, [initialFriendRequests]);

  useEffect(() => {
    updateList();
  }, []);

  if (isLoadingList) {
    return <LoadingStateComponent />;
  }

  return (
    <Container className="feeds-container animated fadeIn">
      <Navbar items={barItems} toggle={onToggle} />
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.MANAGE}>
          <Row>
            <Col sm="12" md="12" className="my-auto">
              <FriendsList
                header={`REQUESTS (${friendRequests.length})`}
                items={friendRequests}
                listType={FriendshipStatus.PENDING}
                onListUpdate={updateList}
              />
              <FriendsList
                header={`FRIENDS (${activeFriendsList.length})`}
                items={activeFriendsList}
                listType={FriendshipStatus.ACCEPTED}
                onListUpdate={updateList}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={Tabs.ADD}>
          <Row>
            <Col sm={{ size: 8, offset: 2 }} md={{ size: 8, offset: 2 }} className="mt-5">
              <AddFriendForm />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Container>
  );
}

ManageFriendships.propTypes = {
  onTabChange: PropTypes.func,
  onFriendRequestsUpdate: PropTypes.func,
  friendRequests: PropTypes.array,
};

export default ManageFriendships;

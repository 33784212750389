import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import PdfLink from './guides/PdfLink';
import Pdf from '../../ungated/signup/CueMate-Terms-and-Conditions.pdf';
import './SiteMap.css';

const setupPDFURL = 'docs/CueMateSetUp.pdf';
const setupLinkText = 'CueMate Set-up';

export default function SiteMap() {
  return (
    <Container className="padding-top-25 padding-bottom-50 animated fadeIn sitemap-container">
      <Row>
        <Col sm="12">
          <h1>Sitemap</h1>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm="6">
          <Link to="/home">
            {' '}
            <h2>Home</h2>{' '}
          </Link>
        </Col>
        <Col sm="6">
          <Link to="/background">
            <h2>Background</h2>
          </Link>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="6">
          <h2>Get Started</h2>
          <ul className="list-unstyled">
            <li>
              <Link to="/download-app/XD"> Download the App </Link>
            </li>
            <li>
              <PdfLink pdfURL={setupPDFURL} linkText={setupLinkText} />
            </li>
          </ul>
        </Col>
        <Col sm="6">
          <Link to="/key-concepts">
            <h2>Key Concepts</h2>
          </Link>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="6">
          <Link to="/cuemate-ios">
            <h2>CueMate for iOS</h2>
          </Link>
          <h3>Screens Overview</h3>
          <ul className="list-unstyled">
            <li>
              <Link to="/cuemate-ios/home-screen">Home</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/play-mode">Play</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/train-mode">Train</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/review-mode">Review</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/browse-mode">Browse</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/reports-screen">Reports</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/metrics-screen">Metrics</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/trends-screen">Trends</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/status-screen">Status</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/settings">Settings</Link>
            </li>
          </ul>

          <h3>Features</h3>
          <ul className="list-unstyled">
            <li>
              <Link to="/cuemate-ios/metrics">Metrics</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/training">Training</Link>
            </li>
            <li>
              <Link to="/cuemate-ios/social">Social</Link>
            </li>
          </ul>
        </Col>
        <Col sm="6">
          <Link to="/cuemate-web">
            <h2>CueMate for Web</h2>
          </Link>
          <ul className="list-unstyled">
            <li>
              <Link to="/cuemate-web/home-screen">Home</Link>
            </li>
            <li>
              <Link to="/cuemate-web/session-reports">Session Reports</Link>
            </li>
            <li>
              <Link to="/cuemate-web/player-reports">Player Reports</Link>
            </li>
            <li>
              <Link to="/cuemate-web/class-reports">Class Reports</Link>
            </li>
            <li>
              <Link to="/cuemate-web/training">Training</Link>
            </li>
            <li>
              <Link to="/cuemate-web/leaderboard">Leaderboard</Link>
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="12">
          <a href={Pdf} target="_blank" rel="noopener noreferrer">
            <h2>Terms</h2>
          </a>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="12">
          <Link to="/privacy">
            <h2>Privacy</h2>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

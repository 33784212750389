import React from 'react';
import { Col, Container, Row, Media } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import baseline1 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-1.png';
import baseline2 from '../../../../images/cuemate101/level2/set2/baseline-2.png';
import baseline3 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-3.png';
import baseline4 from '../../../../images/cuemate101/level1/fundamentals/set2/baseline-4.png';

import tip from '../../../../images/cuemate101/level2/set2/tip.png';
import warmup5 from '../../../../images/cuemate101/level1/fundamentals/set1/warmup-5.png';

import '../AppFeatures.css';

export default function L2S2PerformanceBaseline() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SET 2: PERFORMANCE BASELINE</h2>
          <h3>Record Player’s Stroke Repertoire and Performance Metrics</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="4">
          <ol>
            <li>Press + to start a new Set</li>
            <img
              src={baseline1}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="2">
            <li>Enter play conditions</li>
            <span className="blue">Freeplay, No Cue, Tags ON</span>
            <img
              src={baseline2}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <br />
          <span className="blue">Rallying, Intensity Level 2, Middle, Baseline</span>
          <img
            src={baseline3}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="3">
            <li>Begin Set</li>
            <p className="blue">
              Rally down +-50 balls the middle from the baseline
              <br />
              Focus on hitting with only Forehand Topspin and Backhand Topspin strokes
              <br />
              While generating topspin, try to maintain pace to keep shots in play
            </p>
          </ol>
        </Col>
        <Col sm="6">
          <img src={baseline4} className="d-block mx-auto img-fluid" alt="Outcome Metrics" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="4">
            <li>Press Pause when finished</li>
            <img
              src={warmup5}
              className="d-block mx-auto img-fluid img-border"
              alt="Outcome Metrics"
            />
          </ol>
        </Col>
        <Col sm="6">
          <Media>
            <Media tag="span" left>
              <img src={tip} className="label-max-height" alt="Coaching Tip" />
            </Media>
            <Media body>
              <Media heading className="blue">
                Coaching Tip
              </Media>
              Set-up a cone on the court to help player achieve consistent shot depth
            </Media>
          </Media>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/set-2/review">
            <h3>
              Next: Set 2: Review <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

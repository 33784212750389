import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row, UncontrolledAlert } from 'reactstrap';
import PropTypes from 'prop-types';
import { utilities } from 'cuemate-charts';
import LoaderButton from '../../../../components/LoaderButton';
import UserAvatar from './UserAvatar';

const { toTitleCase } = utilities;

const OPTIONS = {
  GENDER: [
    { label: 'Man', value: 'Man' },
    { label: 'Woman', value: 'Woman' },
    { label: 'Transgender Man', value: 'Transgender Man' },
    { label: 'Transgender Woman', value: 'Transgender Woman' },
    { label: 'Non-Binary', value: 'Non-Binary' },
    { label: "I don't identify with any gender", value: 'Agender' },
    { label: 'Prefer not to state', value: 'Prefer not to state' },
  ],
  HANDEDNESS: ['left', 'right'],
  BACKHAND_TYPE: ['One-handed', 'Two-handed'],
  UNITS: ['metric', 'imperial'],
  RACKET: [
    {
      BRAND: 'Wilson',
      CATEGORY: ['Pro Staff', 'Clash', 'Blade', 'Ultra', 'Burn'],
    },
    {
      BRAND: 'Babolat',
      CATEGORY: ['Pure Aero', 'Pure Drive', 'Pure Strike'],
    },
    {
      BRAND: 'Head',
      CATEGORY: ['Speed', 'Gravity', 'Prestige', 'Radical', 'Extreme', 'Instinct'],
    },
    {
      BRAND: 'Yonex',
      CATEGORY: ['V-Core Pro', 'V-Core', 'EZone', 'Astral'],
    },
  ],
  RACKET_SPECS: {
    GRIP_SIZE: ['#0', '#1 (4 1/8)', '#2 (4 1/4)', '#3 (4 3/8)', '#4 (4 1/2)', '#5+'],
  },
};

export default function MyProfile({
  avatar,
  disableSaveProfile,
  isSubmitting,
  onChange,
  onSubmit,
  settings,
  shouldRenderAlert,
}) {
  const {
    name,
    familyName,
    birthDay,
    gender,
    handedness,
    backhandType,
    height,
    weight,
    properties,
  } = settings || {};
  const { preferredSensor } = properties || {};
  const preferredSensorValue = preferredSensor || 'N/A';

  const { units } = properties || 'imperial';

  const genderHTMLOptions = OPTIONS.GENDER.map((option, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <option key={index + 1} value={option.value}>
      {option.label}
    </option>
  ));

  const handednessHTMLOptions = OPTIONS.HANDEDNESS.map((option, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <option key={index + 1} value={option}>
      {toTitleCase(option)}
    </option>
  ));

  const backhandTypeHTMLOptions = OPTIONS.BACKHAND_TYPE.map((option, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <option key={index + 1} value={option}>
      {option}
    </option>
  ));

  const unitsHTMLOptions = OPTIONS.UNITS.map((option, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <option key={index + 1} value={option}>
      {toTitleCase(option)}
    </option>
  ));

  return (
    <>
      {shouldRenderAlert && <UncontrolledAlert color="success">Profile Saved!</UncontrolledAlert>}

      <Form onSubmit={(event) => onSubmit(event)}>
        <h2 className="blue">My Profile</h2>
        <Row>
          <Col>
            <UserAvatar
              src={avatar}
              name={name && familyName ? name.concat(' ', familyName) : 'CueMate Tennis'}
            />
          </Col>
        </Row>
        <hr className="mb-4" />

        <Row>
          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="name">First Name</Label>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="First Name"
                value={name}
                onChange={(e) => onChange(e)}
              />
            </FormGroup>
          </Col>

          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="familyName">Last Name</Label>
              <Input
                type="text"
                name="familyName"
                id="familyName"
                placeholder="Last Name"
                value={familyName}
                onChange={(e) => onChange(e)}
              />
            </FormGroup>
          </Col>

          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="birthDay">Birthday</Label>
              <Input
                type="date"
                name="date"
                id="birthDay"
                placeholder="date placeholder"
                onChange={(e) => onChange(e)}
                value={birthDay}
              />
            </FormGroup>
          </Col>

          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="gender">Gender</Label>
              <Input
                type="select"
                name="gender"
                id="gender"
                value={gender}
                onChange={(e) => onChange(e)}
              >
                <option value="-1">Select an option</option>
                {genderHTMLOptions}
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="handedness">Handedness</Label>
              <Input
                type="select"
                name="handedness"
                id="handedness"
                value={handedness}
                onChange={(e) => onChange(e)}
              >
                <option value="-1">Select</option>
                {handednessHTMLOptions}
              </Input>
            </FormGroup>
          </Col>

          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="backhandType">Backhand Type</Label>
              <Input
                type="select"
                name="backhandType"
                id="backhandType"
                value={backhandType}
                onChange={(e) => onChange(e)}
              >
                <option value="-1">Select</option>
                {backhandTypeHTMLOptions}
              </Input>
            </FormGroup>
          </Col>

          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="height">Height (cm)</Label>
              <Input
                type="number"
                name="height"
                id="height"
                placeholder="Height"
                min="1"
                max="270"
                value={height}
                step="0.01"
                onChange={(e) => onChange(e)}
              />
            </FormGroup>
          </Col>

          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="weight">Weight (lbs)</Label>
              <Input
                type="number"
                name="weight"
                id="weight"
                placeholder="Weight"
                min="1"
                step="0.01"
                value={weight}
                onChange={(e) => onChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="3" sm="3" md="3">
            <FormGroup>
              <Label for="units">Unit System</Label>
              <Input
                type="select"
                name="units"
                id="properties-units"
                value={units}
                onChange={(e) => onChange(e)}
              >
                <option value="-1">Select</option>
                {unitsHTMLOptions}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <hr className="mb-4" />

        <h5 className="blue">My Sensor</h5>
        <Row>
          <Col xs="6" sm="6" md="6">
            Preferred Sensor: {preferredSensorValue}
          </Col>
        </Row>
        <hr className="mb-4" />
        <Row>
          <Col xs="6" sm="6" md="6" className="padding-bottom-25">
            <LoaderButton
              type="submit"
              text="Save Profile"
              color="primary"
              size="lg"
              loadingText="Saving..."
              isLoading={isSubmitting}
              block
              disabled={disableSaveProfile}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

MyProfile.propTypes = {
  disableSaveProfile: PropTypes.bool,
  settings: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  shouldRenderAlert: PropTypes.bool,
  avatar: PropTypes.string,
};

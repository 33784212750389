import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import UngatedWelcomeHero from './UngatedWelcomeHero';

import './MainHome.css';
import racketIcon from '../../../images/landingpage/Cue_Sensor_Icon.svg';
import cuemateApp from '../../../images/landingpage/Cue_App_Icon.svg';
import playerSequence from '../../../images/landingpage/Sequence_Web.png';
import strokeMap from '../../../images/landingpage/Stroke_repertoire.PNG';
import classReport from '../../../images/landingpage/Class_report.PNG';
import seamlessTraining from '../../../images/landingpage/Seamless_Training.svg';
import courtPhone from '../../../images/landingpage/Court_Phone.png';

function BetweenTitle() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row className=" text-center">
        <Col sm={12}>
          <h2 className="font-VR-Regular">See how the combination of AI and natural human</h2>
          <h2 className="font-VR-Regular">learning can take your training to a new level. </h2>
        </Col>
      </Row>
    </Container>
  );
}

function SensorAndCueMateApp() {
  return (
    <Container fluid className="padding-top-25 padding-bottom-25 light-grey-bg">
      <Row className=" text-center">
        <Col sm={{ size: 3, offset: 2 }}>
          <img
            src={racketIcon}
            className="d-block mx-auto img-fluid width-70  margin-bttm-25"
            alt="CueMate racket sensor"
          />
          <h3 className="font-VR-DemiBold">The CueMate Racket Sensor</h3>
          <p className="font-VR-Regular">
            Captures high-definition motion data for real-time stroke analysis and immediate audio
            feedback.
          </p>
        </Col>
        <Col sm={2} className="my-auto">
          <h1 className="font-VR-Regular large-font-size">+</h1>
        </Col>
        <Col sm={3}>
          <img
            src={cuemateApp}
            className="d-block mx-auto img-fluid width-70 margin-bttm-25"
            alt="CueMate App"
          />
          <h3 className="font-VR-DemiBold">The CueMate App</h3>
          <p className="font-VR-Regular">
            Makes data actionable and support deliberate training augmented by real-time feedback.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function BeyondFitnessTracking() {
  return (
    <Container fluid className="padding-top-50 padding-bottom-25 img-court custom-size">
      <Row className=" text-center">
        <Col sm={{ size: 12 }}>
          <h1 className="font-VR-Regular">Go Beyond Fitness Tracking</h1>
        </Col>
      </Row>
      <Row className=" text-center">
        <Col sm={{ size: 12 }}>
          <img src={courtPhone} className="d-block mx-auto img-fluid" alt="CueMate stroke map" />
        </Col>
      </Row>
    </Container>
  );
}

function FeedbackAfterEveryStroke() {
  return (
    <Container fluid className="padding-top-25 padding-bottom-25 black-bg">
      <Row>
        <Col sm={{ size: 7 }}>
          <img
            src={playerSequence}
            className="d-block mx-auto img-fluid"
            alt="CueMate racket sensor"
          />
        </Col>
        <Col sm={4} className="padding-4-2-rem">
          <h1 className="font-VR-ExtraLight text-white text-left"> Feedback After Every Stroke</h1>
          <p className="font-VR-Regular text-white text-left">
            The brain learns faster with immediate feedback. CueMate gives real-time audio “cues” to
            reinforce good technique during performance.
          </p>
          <p className="font-VR-Regular text-white text-left">
            Cues are based on individualized goals and encoded following musical scales to make them
            stimulating and easy to interpret.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function SeeYourPath() {
  return (
    <Container fluid className="padding-top-50 padding-bottom-100 light-grey-bg">
      <Row className=" text-center">
        <Col sm={{ size: 12 }}>
          <h1 className="font-VR-Regular">See Your Path to Becoming a Better Player</h1>
        </Col>
      </Row>
      <Row className="padding-top-50">
        <Col sm={{ size: 5, offset: 1 }}>
          <img src={strokeMap} className="d-block mx-auto img-fluid" alt="CueMate stroke map" />
        </Col>
        <Col sm={{ size: 4, offset: 1 }} className="header-margin">
          <h3 className="font-VR-DemiBold text-left margin-bttm-25">
            {' '}
            Understand Your Stroke Repertoire
          </h3>
          <p className="font-VR-Regular text-left margin-bttm-25">
            The stroke repertoire – or stroke map – gives you a picture of your stroke vocabulary.
          </p>
          <p className="font-VR-Regular text-left margin-bttm-25">
            It allows you to identify your core strokes and illuminate gaps.
          </p>
        </Col>
      </Row>
      <Row className="padding-top-50">
        <Col sm={{ size: 4, offset: 1 }}>
          <h3 className="font-VR-DemiBold text-left margin-bttm-25">
            {' '}
            Get Detailed Insights on Your Stroke Classes
          </h3>
          <p className="font-VR-Regular text-left margin-bttm-25">
            Find strengths and weaknesses of each stroke class using a wide range of metrics.
          </p>
        </Col>
        <Col sm={{ size: 5, offset: 1 }}>
          <img src={classReport} className="d-block mx-auto img-fluid" alt="CueMate stroke map" />
        </Col>
      </Row>
      <Row className="padding-top-100">
        <Col sm={{ size: 5, offset: 1 }}>
          <img src={strokeMap} className="d-block mx-auto img-fluid" alt="CueMate stroke map" />
        </Col>
        <Col sm={{ size: 4, offset: 1 }} className="header-margin">
          <h3 className="font-VR-DemiBold text-left margin-bttm-25">
            {' '}
            Use Individualized Training Goals and Track Your Progress
          </h3>
          <p className="font-VR-Regular text-left margin-bttm-25">
            CueMate calculates training goals using current skill level to stimulate your training.
          </p>
          <p className="font-VR-Regular text-left margin-bttm-25">
            Use Session Reports and Performance Trends to understand what exercises and drills are
            working best for you.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function MakeDeliberateTraining() {
  return (
    <Container fluid className="padding-top-50 padding-bottom-25 blue-bg">
      <Row>
        <Col sm={{ size: 4, offset: 1 }}>
          <img
            src={seamlessTraining}
            className="d-block mx-auto img-fluid width-80"
            alt="CueMate racket sensor"
          />
        </Col>
        <Col sm={{ size: 5 }} className="padding-4-2-rem">
          <h1 className="font-VR-ExtraLight text-left display-5">
            {' '}
            Make Deliberate Training Part of Your Routine
          </h1>
          <p className="font-VR-Regular text-left display-7">
            CueMate integrates seamlessly with your practice to accelerate the natural skill
            development process.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function GetEarlyAccess() {
  const typeformURL = 'https://cuemate-tennis.typeform.com/to/LNSqoawF?utm_source=cuemate-tennis';
  return (
    <Container
      fluid
      className="padding-bottom-50 animated fadeIn img-man-tennis"
      id="get-early-access"
    >
      <Row className="padding-top-100 padding-bottom-100">
        <Col sm={{ size: 5, offset: 1 }} className="padding-bottom-25 padding-4-2-rem">
          <h1 className="font-VR-ExtraLight text-white text-left margin-bottom-50">
            {' '}
            Get Early Access
          </h1>
          <p className="font-VR-Regular text-left text-white display-7 margin-bottom-50">
            Be part of our Beta Program and join the team of early-adopters helping to shape the
            future of training. Participation is free and open to both coaches and individual
            players.
          </p>
          <p className="font-VR-Regular text-left text-white display-7 margin-bottom-50">
            To participate you need iPhone 11 or greater with iOS greater than iOS 13.5, and a
            Wilson, Head, Babolat, or Yonex racket.
          </p>
          <Button
            outline
            tag="a"
            className="rounded-button margin-bottom-50"
            target="_blank"
            rel="noopener noreferrer"
            href={typeformURL}
          >
            {' '}
            Join the Beta{' '}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default function MainHome() {
  return (
    <div className="MainHome">
      <UngatedWelcomeHero />
      <BetweenTitle />
      <SensorAndCueMateApp />
      <BeyondFitnessTracking />
      <FeedbackAfterEveryStroke />
      <SeeYourPath />
      <MakeDeliberateTraining />
      <GetEarlyAccess />
    </div>
  );
}

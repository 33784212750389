import React from 'react';
import GatedWelcome from './GatedWelcome';
import GatedWelcomeHero from './GatedWelcomeHero';

import './GatedMainHome.css';

export default function GatedMainHome() {
  return (
    <div className="GatedMainHome">
      <GatedWelcomeHero />
      <GatedWelcome />
    </div>
  );
}

import React from 'react';
import { Container, Col, Row } from 'reactstrap';

export default function Privacy() {
  return (
    <Container className="padding-top-25 animated fadeIn font-VR-Regular">
      <h2>Privacy Policy</h2>
      <Row className="padding-top-25 padding-bottom-25">
        <Col sm="12">
          <h3>Effective Date: January 1, 2023</h3>
          <p>
            This Privacy Policy describes how ICUEMOTION LLC, a Delaware limited liability company
            and its affiliates (collectively, “iCueMotion”, “we”, “us”, or “our”) collects, uses,
            stores and shares your personal information and other data we obtain from you, including
            data collected through your use of our Technology (as defined below).{' '}
            <span className="font-VR-DemiBold">
              By using the Technology, you agree to be bound the terms and conditions of this
              Privacy Policy and our Terms of Use available at tennis.cuemate.com/terms, which may
              be updated by us at any time. If you do not agree, please do not use or access the
              Technology.
            </span>
          </p>
          <h3>Background on iCueMotion</h3>
          <p>
            iCueMotion&apos;s mission is to help people achieve better movement skills by using the
            data collected from users through the use of our Technology. We combine measurement data
            from user sessions and apply machine learning (AI) and other mathematical techniques to
            design training tools, including movement assessments, diagnostics, training algorithms,
            and feedback cueing algorithms.
          </p>
          <p>
            Our goal is to deliver value to our users from the use of our Technology. To achieve
            this, we design skill models and analytical tools using user information, including the
            measurement data generated by the CueMate sensor during sessions. The skill models
            combine the user&apos;s data with data from our population of other users.
          </p>
          <p>
            As used herein, “Technology” means the CueMate racket sensor, any other sensor, camera,
            device, accessory, hardware or equipment provided or made available by iCueMotion, the
            CueMate software application (including any prerelease or beta versions of the App), our
            web interface and software made available as a service through the web interface, our
            website and any information or functionality made available through our website, our
            hardware system (including any cameras or devices), system integration information, any
            predecessor versions and any updates, new releases or new versions of any of the
            foregoing, and any other software, hardware or technology provided or made available to
            you by iCueMotion at any time.
          </p>
          <h3>Information Collected</h3>
          <p>
            This Privacy Policy applies to information we collect through your use of the Technology
            and other information we collect when you undertake interactions with us.
          </p>
          <p>The types and categories of information that we collect and maintain include:</p>
          <ul>
            <li>
              <span className="font-VR-Medium">CueMate Sensor.</span> We collect data from our
              CueMate sensor, which measures the racket&apos;s acceleration and angular velocities.
            </li>
            <li>
              <span className="font-VR-Medium ">
                Information Collected Through Other Technology.
              </span>{' '}
              We may collect information about you through other technology, including video data
              (e.g., data related to ball movement and player-racket pose), heart rate data
              (optional) that may be correlated with the movement intensity determined from the
              racket sensor, information about location when you start and save a session
              (optional), and other information collected during active sessions to produce session
              records.
            </li>
            <li>
              <span className="font-VR-Medium ">Information You Provide.</span> We may collect
              nonpublic personal information about you when you register to use our Technology or
              when you provide information by visiting our store, through our website or through
              other written communications and during telephone and in person meetings, which
              information may include: (1) your name, username and password; (2) contact
              information, such as your personal or work postal address, email address and telephone
              number; (3) physical characteristics (e.g., age, gender, body height, weight); (4)
              racket data (e.g., brand, model, string type); and (5) other information we need to
              provide or market our products or services to you.
            </li>
            <li>
              <span className="font-VR-Medium ">Information From Third Parties.</span> We may
              receive personal information from third parties, including information from
              third-party apps that you elect to connect to our Technology, such as third-party apps
              that may provide us with your heart rate data (e.g., Apple HealthKit) and GPS location
              data during active sessions.
            </li>
          </ul>
          <h3>Use of Your Personal Information</h3>
          <p>
            We use the information we collect to provide our services and to improve our Technology:
          </p>
          <ul>
            <li>
              <span className="font-VR-Medium">
                Use of information collected through Technology.
              </span>{' '}
              The data collected through your use of the CueMate Sensor or other Technology is used
              to design skill models, analytical tools, training tools, algorithms, and improvements
              to our Technology so that we can enhance the quality of our analytics and the services
              provided by the CueMate system. Heart rate data is used to correlate with the movement
              intensity determined from the racket sensor, which information is used to enhance the
              session records. Information about location and start and stop times is also used to
              augment the session records.
            </li>
            <li>
              <span className="font-VR-Medium">Use of other information you provide.</span> The
              information you provide about your physical characteristics is used to calibrate our
              algorithms so that we can improve the level of accuracy and offer features that are
              tailored to particular users. The information you provide about your racket is used to
              determine the racket physical characteristics (weight, mass distribution, size) and
              used to calibrate the algorithms so that accuracy can be improved. In the future, we
              may use the information we collect to optimize your training and selection of
              equipment.
            </li>
            <li>
              <span className="font-VR-Medium">Anonymous and aggregated data.</span> We anonymize
              the data collected from each user and aggregate such data by combining it with other
              users&apos; data. We use the aggregated data to perform population analysis, which
              enables us to determine how individual factors may influence training and performance.
              For example, by comparing your data with other users&apos; data we can generate skill
              ratings and reference ranges for the metrics that are individualized to your skill
              level. We may also use aggregated data to determine how individual factors may
              influence training and performance. We also use anonymized or aggregated data in other
              ways to improve and market our Technology to deliver value to our users from the use
              of our Technology.
            </li>
          </ul>
          <p>
            We use your personal information for the following limited business purposes and as
            otherwise described in this Privacy Policy:
          </p>
          <ul>
            <li>
              <span className="font-VR-Medium">To provide services. </span>
              We may use your personal information to fulfill or meet the reason you provided such
              information. This includes our use of your personal information to send you the
              CueMate Sensor or other equipment, to provide, operate and improve the services
              provided to you, as to understand your interests and personalize your experience when
              using the Technology. We may use your information to verify your identity or to
              communicate with you in accordance with your preferences in relation to our services,
              which may include sending announcements, updates, security alerts, and support and
              administrative messages. We will provide you with options so that you can elect which
              communications you would like to receive.
            </li>
            <li>
              <span className="font-VR-Medium">For marketing and promotional communications. </span>
              If, as permitted by law, we send marketing or promotional information, we may use your
              personal information for such marketing, research and other business purposes. You can
              opt out of our marketing and promotional communications, as your consent is required
              to receive such communications.
            </li>
            <li>
              <span className="font-VR-Medium">To comply with law. </span>
              We use your personal information as we believe necessary or appropriate to comply with
              applicable laws, lawful requests, and legal process, such as to respond to subpoenas,
              court orders, governmental regulations or requests from government authorities
              conducting an investigation. In addition, we may use your personal information in the
              event that we have a reason to believe that someone is threatening to cause injury to,
              or interfere with, your or our rights or property.
            </li>
            <li>
              <span className="font-VR-Medium">With your consent. </span>
              On other occasions, we may specifically ask for your consent to use your personal
              information for the purpose(s) that we explain at that time.
            </li>
          </ul>
          <h3>Information Sharing Policy</h3>
          <p>
            It is our policy to not disclose or share your personal information, except in the
            following circumstances or as otherwise described in this Privacy Policy:
          </p>
          <ul>
            <li>
              <span className="font-VR-Medium">With your consent. </span>
              Limited information about your skill level and activities may be shared on a private
              or public feed with your consent. On occasion, we may specifically ask for your
              consent to share your personal information for the purpose(s) that we explain at that
              time. We also offer social features that will allow you to share your personal
              information if you choose to do so.
            </li>
            <li>
              <span className="font-VR-Medium">With affiliates. </span>
              We may share your personal information with our affiliates for our and our affiliates’
              everyday business purposes or purposes consistent with this Privacy Policy.
            </li>
            <li>
              <span className="font-VR-Medium">
                With service providers and other third parties.
              </span>{' '}
              We may share or provide access to your personal information with third-party companies
              and individuals that provide services on our behalf or help us operate the services
              (such as customer support, hosting, analytics, email delivery, database management and
              marketing). These third parties may use your personal information only as authorized
              by their contracts with us.
            </li>
            <li>
              <span className="font-VR-Medium">With professional advisors. </span>
              We may also disclose your personal information to professional advisors, such as
              lawyers, bankers, auditors, and insurers, where necessary in the course of the
              professional services that they render to us.
            </li>
            <li>
              <span className="font-VR-Medium">As required or permitted by law. </span>
              We may share your personal information for compliance, fraud prevention and safety
              purposes. We may preserve or disclose your information if we believe that it is
              reasonably necessary to comply with a law, regulation, or legal request or to protect
              our and your safety, property, or rights. However, nothing in this policy is intended
              to limit any legal defenses or objections that you may have to a third party or
              government request to disclose your information.
            </li>
          </ul>
          <p>
            In addition to the above, we may share anonymous or de-identified data and aggregate
            data with third parties(e.g., manufacturers of rackets or other equipment and retail
            stores), but such data does not include your personal information. We may also share
            de-identified users&apos; skill ratings and activities on a public feed that is
            accessible to other users.
          </p>
          <h3>Information Security</h3>
          <p>
            It is our goal to protect your personally identifiable information. We maintain
            physical, electronic, and procedural safeguards to guard your personal information and
            the integrity and security of our databases, including firewalls. We have procedures in
            place for the appropriate disposal and protection against unauthorized access or use of
            your information. Except as described above, access to non-public personal information
            is restricted to our employees who need to know such information. If we share your
            personal information, it is made available for limited purposes and under controlled
            circumstances. We require third parties to comply with our standards for security and
            confidentiality.
          </p>
          <h3>Children</h3>
          <p>
            Children are not eligible to use the Technology without their parents&apos; or
            guardian&apos;s consent. The services are not directed to, and we do not knowingly
            collect personal information from anyone under the age of 13 without their parents&apos;
            or guardian&apos;s consent. If we learn that we have collected personal information of a
            child without the consent of the child&apos;s parent or guardian, we will delete it.
          </p>
          <h3>International Use</h3>
          <p>
            We make no claims that the Technology may be used in locations outside the United
            States. Access to the Technology from countries or territories where such access is
            illegal is prohibited. If you use the Technology outside of the United States, you do so
            at your own risk.
          </p>
          <h3>Changes to this Privacy Policy</h3>
          <p>
            We may amend this Privacy Policy at our discretion and at any time and will indicate the
            effective date of the amended version. We will notify you of any changes that may affect
            your rights under this Privacy Policy.
          </p>
          <h3>Contact Details</h3>
          <p>
            Please contact us if you want to exercise your rights or if you are disabled and need a
            copy of this notice prepared for you in a manner that is accessible. Any such request
            may be made via phone at +1 (650) 345-9727 or via e-mail at support@cuemate.com.
          </p>
          <h3>For California Residents:</h3>
          <h3>California Consumer Privacy Act</h3>
          <p>
            If you are a California resident, you may have certain rights granted to you if your
            personal information is protected by the California Consumer Privacy Act of 2018
            (“CCPA”). Under the CCPA, a California consumer has the following rights, subject to
            certain exceptions and limitations:
          </p>
          <ol>
            <li>
              <span className="font-VR-Medium">Right to Know. </span>
              You have the right to request (a) the categories and specific pieces of personal
              information we collect and disclose; (b) the categories of sources from which we have
              collected your personal information; (c) the business purposes for such disclosure of
              your personal information.
            </li>
            <li>
              <span className="font-VR-Medium">Right to Request. </span>
              You have the right to request a list of the personal information (if any) disclosed to
              third parties for direct marketing purposes in the prior 12 months.
            </li>
            <li>
              <span className="font-VR-Medium">Right to Delete. </span>
              You have the right to access and then delete your personal information that we have
              collected from you or maintained about you.
            </li>
          </ol>
          <p>
            California residents also have the right to not be discriminated against for exercising
            any of the rights described above.
          </p>
          <p>
            You may only make a request to access and delete your personal information twice within
            a 12-month period. Such request must be described with enough detail that allows us to
            properly understand, evaluate and respond to it. To exercise these rights, please
            contact us at +1 (650) 345-9727 or email us at support@cuemate.com with “CCPA Rights” in
            the subject line and in the body of your message. There are circumstances where we are
            not required to comply with consumer requests and we will let you know if one of those
            situations arises.
          </p>
          <h3>Sale</h3>
          <p>We do not sell your personal information.</p>
          <h3>Verification</h3>
          <p>
            To help protect your privacy and maintain security, we will verify your identity before
            granting access to your personal information or complying with your request. If you
            request to access or delete your personal information, we will verify your identity by
            comparing information we have on file against information you may provide us. If you
            designate an authorized agent to make a request to access or delete your personal
            information on your behalf, we may require you to (1) provide the authorized agent
            written permission to do so, and (2) verify your own identity directly with us (as
            described above).
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import React, { Component } from 'react';
import {
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import LoaderButton from '../../../../components/LoaderButton';

export default class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordErrorsMsg: '',
      newPasswordErrorMsg: '',
      shouldShowConfirmationNote: false,
      isPasswordInvalid: false,
      isNewPasswordInvalid: false,
      isSubmitting: false,
    };

    this.validateForm = this.validateForm.bind(this);
    this.checkMatch = this.checkMatch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let { oldPassword, newPassword } = this.state;
    this.setState({
      isPasswordInvalid: false,
      isNewPasswordInvalid: false,
      isSubmitting: true,
    });

    oldPassword = oldPassword.trim();
    newPassword = newPassword.trim();

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      this.setState({
        shouldShowConfirmationNote: true,
      });
    } catch (e) {
      console.log(e);
      switch (e.code) {
        case 'NotAuthorizedException':
          this.setState({
            isPasswordInvalid: true,
            passwordErrorsMsg: 'Invalid password.',
            isSubmitting: false,
          });
          break;
        case 'InvalidPasswordException':
          this.setState({
            isNewPasswordInvalid: true,
            newPasswordErrorMsg: 'Invalid new password.',
            isSubmitting: false,
          });
          break;
        default:
          // eslint-disable-next-line no-undef
          alert(e.message);
      }
    }
  }

  checkMatch(event) {
    const { newPassword } = this.state;
    if (event.target.value !== newPassword) {
      event.target.setCustomValidity('Password does not match the confirm password.');
    } else {
      // input is valid -- reset the error message
      event.target.setCustomValidity('');
    }
  }

  validateForm() {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    return (
      oldPassword.trim().length >= 1 &&
      newPassword.trim().length >= 8 &&
      confirmPassword.trim().length >= 8
    );
  }

  renderConfirmationNote() {
    return (
      <Container {...this.props}>
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
            <h3 className="">Password update was successfully processed.</h3>
          </Col>
        </Row>
      </Container>
    );
  }

  renderForm() {
    const {
      oldPassword,
      isPasswordInvalid,
      passwordErrorsMsg,
      newPassword,
      isNewPasswordInvalid,
      newPasswordErrorMsg,
      confirmPassword,
      isSubmitting,
    } = this.state;
    return (
      <Container {...this.props}>
        <h3 className="subtitle-grey">Change Password</h3>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup row>
            <Label for="oldPassword" sm={4}>
              Old password
            </Label>
            <Col sm={8}>
              <Input
                type="password"
                name="password"
                id="oldPassword"
                value={oldPassword}
                onChange={this.handleChange}
                invalid={isPasswordInvalid}
                required
              />
              <FormFeedback>{passwordErrorsMsg}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="newPassword" sm={4}>
              New password
            </Label>
            <Col sm={8}>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                minLength="8"
                value={newPassword}
                onChange={this.handleChange}
                invalid={isNewPasswordInvalid}
                required
              />
              <FormFeedback>{newPasswordErrorMsg}</FormFeedback>
              <FormText>
                Password must be at least 8 characters long, using letters and numbers only.
              </FormText>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="confirmPassword" sm={4}>
              Confirm new password
            </Label>
            <Col sm={8}>
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                value={confirmPassword}
                onChange={this.handleChange}
                onInput={this.checkMatch}
                required
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm="4">
              <LoaderButton
                type="submit"
                text="Update Password"
                color="primary"
                size="sm"
                loadingText="Submitting information..."
                isLoading={isSubmitting}
                disabled={!this.validateForm()}
                block
              />
            </Col>
            <Col sm="8">
              <p>
                <Link to="/password-reset">Forgot your password?</Link>
              </p>
            </Col>
          </FormGroup>
        </Form>
      </Container>
    );
  }

  render() {
    const { shouldShowConfirmationNote } = this.state;
    return shouldShowConfirmationNote ? this.renderConfirmationNote() : this.renderForm();
  }
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import stroke from '../../../../images/concepts/stroke-9.21.2018.png';
import metricoutcomes from '../../../../images/screens/ClassReport-Outcomes.PNG';
import reportssession from '../../../../images/screens/SessionTrainingReport.PNG';
import trainmap from '../../../../images/screens/Play-StrokeMap.PNG';
import trainlist from '../../../../images/screens/TrainingLists.PNG';
import trends from '../../../../images/screens/ClassReport-Trends.PNG';
import traininggoalstatus from '../../../../images/screens/TrainingGoal-Status.PNG';
import traininggoalstatusedit from '../../../../images/screens/TrainingGoal-Edit.PNG';

import './AppFeatures.css';

export default function TrainMode() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-bottom-25 padding-top-25">
        <Col sm="12">
          <h1>Train</h1>
        </Col>
      </Row>

      <Row>
        <Col sm="6">
          <h4 className="padding-top-25 padding-bottom-25">
            CueMate’s Training Mode enables you to deliberately train, focus on improving deficient
            strokes, and help diversify your stroke repertoire.
          </h4>
          <p>
            CueMate Train also works for competitive players who focus their practice on training
            but lack data-driven insights to formulate training goals that tackle critical
            deficiencies in their skills, as well as a system to organize and track their training
            process.
          </p>
          <p>
            In Train mode, CueMate provides real-time cues that players can activate on selected
            training elements to reinforce good technique during stroke performance.
          </p>
          <p>
            The CueMate Train mode is organized building on the concepts of Training Elements,
            Training Goals and Training Lists.
          </p>
        </Col>
        <Col sm="6">
          <img
            src={stroke}
            className="d-block mx-auto img-fluid img-fluid padding-top-50"
            alt="Tennis Player with Racket Swing Path"
          />
        </Col>
      </Row>

      <hr />

      <Row className="padding-top-50 padding-bottom-25">
        <Col sm={12}>
          <h2 className="blueGrey">CueMate’s Training Agent Language</h2>
        </Col>
      </Row>

      <Row className="padding-bottom-25">
        <Col sm={6}>
          <h3>Training Element</h3>
          <p>A stroke type paired with stroke attributes.</p>
          <h3>Training Goal</h3>
          <p>
            A goal for each training element determined based on a player’s performance and a
            representative population sub-group.
          </p>
          <h3>Training List</h3>
          <p>A list that organizes and prioritizes training elements.</p>
        </Col>
        <Col sm={6}>
          <img
            src={trainlist}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Training List"
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <h3>Trends</h3>
          <p>
            Trends allow players to track progress of Training Elements over different time scales.
            Players can add a training element to their training list from the trends screen and
            track progress relative to Training Goals.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={trends}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Metric Trends"
          />
        </Col>
      </Row>

      <hr />

      <Row className="padding-top-50 padding-bottom-25">
        <Col sm={12}>
          <h2 className="blueGrey">Tracking Your Progress</h2>
          <h4>
            CueMate tracks your progress (or decline) towards becoming a more skilled player.
            Tracking can be used at different time scales, such as checking evolution of a specific
            metric or training element over a set or session, or determining how a given training
            method impacts your overall skill profile.
          </h4>
        </Col>
      </Row>

      <Row className="padding-bottom-25">
        <Col sm={6}>
          <h3 className="padding-bottom-25">Receive Alerts on the Stroke Map</h3>
          <img
            src={trainmap}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Stroke Map with Training Goal"
          />
          <p className="padding-top-25">
            Green indicates metric improvement. Red indicates metric decline.
          </p>
        </Col>
        <Col sm={6}>
          <h3 className="padding-bottom-25">View Changes on the Metrics Screen</h3>
          <img
            src={metricoutcomes}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Metric-Outcomes"
          />
          <p className="padding-top-25">
            The arrows indicate metric averages for the current session. Red arrows highlight a
            decline in comparison to your historical average; green arrows highlight an improvement
            in comparison to your historical average.
          </p>
        </Col>
      </Row>

      <Row className="padding-bottom-25">
        <Col sm={6}>
          <h3 className="padding-bottom-25">View Trends for Each Metric</h3>
          <img
            src={trends}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Forehand flat hard"
          />
          <p className="padding-top-25">
            You can review graphs of metric time histories by selecting the caret for each metric.
          </p>
          <p>
            Graphs show the evolution of a selected stroke metric over various time scales (current
            session, previous session, previous 3 sessions, previous 5 sessions and previous 10
            sessions).
          </p>
        </Col>
        <Col sm={6}>
          <h3 className="padding-bottom-25">View Session Training Reports</h3>
          <img
            src={reportssession}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Forehand flat hard"
          />
          <p className="padding-top-25">
            Review the progress and status of your training goals after each session with the
            average value for the training element during the session
          </p>
        </Col>
      </Row>

      <Row className="padding-bottom-25">
        <Col sm={6}>
          <h3 className="padding-bottom-25">View Training Goal Status for a Metric</h3>
          <img
            src={traininggoalstatus}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Forehand flat hard"
          />
          <p className="padding-top-25">
            You can view the training goal status and training tips for each metric.
          </p>
          <p>Pin the training goal from this screen to enable cueing.</p>
        </Col>
        <Col sm={6}>
          <h3 className="padding-bottom-25">Edit Training Goal </h3>
          <img
            src={traininggoalstatusedit}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Forehand flat hard"
          />
          <p className="padding-top-25">
            You can edit a training goal using the up and down arrows.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Train.css';

export default function RatingBasedTraining() {
  return (
    <Container className="animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm={12}>
          <h1>Rating-Based Training</h1>
          <h4 className="padding-top-15">
            Training to become a stronger player but with the many dimensions underlying your tennis
            performance it is often hard to determine what to focus on.
          </h4>
          <p>
            The skill rating is designed to capture the stroke characteristics underlying the
            progression in skills. Therefore, it can be used to determine what aspects of the
            technique needs to be trained to reach the next rating level.
          </p>
          <p>
            CueMate computes the gaps between your current and next rating level and uses this
            information to create a training list.
          </p>
          <p>(in development)</p>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import outcomemetrics from '../../../../images/cuemate101/level1/key-concepts/strokemetrics/outcome-metrics.png';

import '../AppFeatures.css';

export default function CourtSession1() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Court Session 1</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">Before heading to the court:</h4>
          <ol>
            <li>Attach the CueMate sensor and charge it for a minimum of 2 hours</li>
            <li>Download and sign into the CueMate App on your mobile device</li>
            <li>Pair the CueMate racket sensor to the CueMate mobile app</li>
          </ol>
          See Set-up Guide for details.
        </Col>
        <Col sm={{ size: 4, offset: 2 }}>
          View the video version of this guide:{' '}
          <Link to="/videos">https://tennis.cuemate.com/videos</Link>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm={12}>
          <h3>GENERAL CUES EXERCISE</h3>
        </Col>
      </Row>
      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={4}>
          <p>
            Perform Court Session 1 to practice the basic CueMate concepts, and record and asses
            your baseline stoke performance, and experience each of the three general cues. This
            exercise will allow you to compare your performance pre, during, and post cues.
          </p>
          <p>
            For more information about Cues, see <Link to="cuemate101/level1/cueing">Cueing</Link>{' '}
            or visit <Link to="/videos">https://tennis.cuemate.com/videos</Link>
          </p>
        </Col>
        <Col sm={8}>
          <img
            src={outcomemetrics}
            className="d-block mx-auto img-fluid img-border"
            alt="Outcome Metrics"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm={12}>
          <h3 className="blue">SESSION OVERVIEW</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped>
            <thead>
              <tr>
                <th>SET</th>
                <th>PLAY CONDITIONS</th>
                <th>PURPOSE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>
                  <span className="blue">WARM-UP</span>
                  <br />
                  Easy warm-up (+- 50 balls)
                </td>
                <td>To warm-up and get familiar with recording your sessions</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>
                  <span className="blue">BASELINE</span>
                  <br />
                  Freeplay w/o Cue (+-50 balls)
                </td>
                <td>
                  Capture data from natural play to determine your baseline stroke performance
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>
                  <span className="blue">ISR CUE</span>
                  <br />
                  Freeplay w/ISR Cue (+-50 balls)
                </td>
                <td>Play while hearing feedback relating to hitting the “sweetspot”</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>
                  <span className="blue">SPIN CUE</span>
                  <br />
                  Freeplay w/SPIN Cue (+-50 balls)
                </td>
                <td>Play while hearing feedback on ball spin</td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>
                  <span className="blue">PACE CUE</span>
                  <br />
                  Freeplay w/PACE Cue (+-50 balls)
                </td>
                <td>Play while hearing feedback on ball speed</td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>
                  <span className="blue">POST CUE</span>
                  <br />
                  Freeplay w/o Cue (+-50 balls)
                </td>
                <td>
                  Capture final set to determine if/how prior cue sets altered your stroke
                  performance
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate101/level1/court-exercise/set-1">
            <h3>
              Next: Set 1 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React, { PureComponent } from 'react';
import { Auth } from 'aws-amplify';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CVClassContent, Sidebar, tennisUtilities } from 'cuemate-charts';
import { cuemateGET } from '../../../../../libs/dataAccess';

/* Components */

/* Functions and Constants */
import { getVisionImage, getUserReport } from '../util/dataQuery';
import CVCarouselFeedback from './CVCarouselFeedback';

const { TYPES } = tennisUtilities;

const getUserFeedback = async (strokeId) => {
  try {
    const currentSession = await Auth.currentSession();
    const authToken = currentSession.accessToken.jwtToken;

    const feedbacks = await cuemateGET(`/cv/feedback/${strokeId}`, authToken);

    return feedbacks.data || {};
  } catch (error) {
    console.error('Error getting feedback', error);
  }

  return {};
};

class CVClassReports extends PureComponent {
  render() {
    const { activeContent, onTransition } = this.props;
    let { classData } = this.props;
    classData = classData.filter((c) => c.show).sort((a, b) => b.score - a.score);
    const sidebarItems = TYPES[0].map((type) => {
      const typeClassData = classData.filter((cls) => cls.name.startsWith(type));
      return {
        name: type,
        label: type.toUpperCase(),
        items: typeClassData.map((cls, i) => ({
          name: `/${cls.name}`,
          label: cls.abrName,
          index: i + 1,
        })),
      };
    });

    let activeClassId = classData.findIndex((d) => d.name === activeContent.split(',')[1]);
    if (activeClassId < 0) activeClassId = 0;
    const activeClass = classData[activeClassId];

    return (
      <Row>
        <Col sm="3" className="text-sm-left text-center d-flex flex-column">
          <Sidebar
            items={sidebarItems}
            sticky={100}
            activeContent={`/${activeClass.name}`}
            onTransition={onTransition}
          />
        </Col>
        <Col sm="9" className="text-sm-left text-center">
          <CVClassContent
            {...{
              ...this.props,
              classData,
              activeClassId,
              getImage: getVisionImage,
              getReport: getUserReport,
              feedbackSubmissionComponent: CVCarouselFeedback,
              getUserFeedbackCallback: getUserFeedback,
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default connect((state) => ({
  activeContent: state.activeContent,
  elements: state.elements,
  reportType: state.reportType,
  classData: state.classData,
  config: state.config,
}))(CVClassReports);

CVClassReports.propTypes = {
  activeContent: PropTypes.any,
  onTransition: PropTypes.any,
  classData: PropTypes.any,
};

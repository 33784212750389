import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level2/on-court/training-report/TrainingReport-1.png';
import view2 from '../../../../images/cuemate101/level2/on-court/training-report/TrainingReport-2.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Training Report</h1>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">TRAINING SUMMARY</h3>
          <p>
            The Training Report gives progress information on active Training Goals after each
            session.
          </p>
          <p>
            For each Training Goal, you can see your metrics as well as a ranking of progress
            compared to the other active goals.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Training Report 1" />
        </Col>
      </Row>
      <br />
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Training Report 2" />
          </Col>
          <Col sm={5}>
            <h3 className="padding-top-25 blue">TRAINING GOAL PROGRESS and STATUS</h3>
            For example: For FHTH Ball Spin, the user has hit 80 strokes far exceeding the target of
            1003 rpm. The triple arrow shows that the player has made great progress in achieving
            the goal.
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function TrainingReport() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review1 from '../../../../images/cuemate101/level2/set2/review-1.png';
import review2 from '../../../../images/cuemate101/level2/set2/review-2.png';
import review3 from '../../../../images/cuemate101/level2/set2/review-3.png';
import review4 from '../../../../images/cuemate101/level2/set2/review-4.png';

import '../AppFeatures.css';

export default function L2S2Review() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>REVIEW SET 2: CORE STROKES &amp; TRAINING GOALS</h2>
          <h3>Identify Player’s Core Strokes and add Training Goals</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={{ size: 7 }}>
          <ol>
            <li>Look at Stroke Counts for each Stroke Class</li>
            <img src={review1} className="d-block mx-auto img-fluid" alt="Review Stroke Map" />
          </ol>
        </Col>
        <Col sm="4">
          <br />
          <p>
            Stroke Classes with the highest Stroke Counts are considered Core Strokes, and can be
            assigned Training Goals and Training Cues.
          </p>
          <p>
            Identify player’s core strokes for Backhand Topspin and Forehand Topspin categories
            (Hard/Med/Light refers to stroke intensity)
          </p>
          <br />
          <br />
          <br />
          2. Tap on the Core Backhand Stroke to see ‘Stroke Metrics Overview’
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="10">
          <ol start="3">
            <li>Tap on ‘Metrics’ to see individual metrics</li>
            <img
              src={review2}
              className="d-block mx-auto img-fluid"
              alt="Freeplay, No Cue, Tags ON"
            />
          </ol>
        </Col>
        <Col sm="2">
          <br />
          <br />
          <br />
          <br />
          <br />
          4. Tap on the ‘Spin’ metric to go to ‘Metric Trends’
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol start="5">
            <li>Tap ‘Add Training Goal’ to generate blue goal line</li>
            <img
              src={review3}
              className="d-block mx-auto img-fluid"
              alt="‘Browse’ to view Stroke Maps for each set"
            />
            <p className="blue">
              Training Goals are automatically calculated based on player’s past performance with
              selected Core Stroke
            </p>
            <p className="blue">
              Once a Training Goal is added, Training Cues can be used while training to meet this
              goal
            </p>
          </ol>
        </Col>
        <Col sm="6" className="overlay">
          <ol start="6">
            <li>
              Navigate back to Stroke Map, Repeat above steps for the Core Forehand Topspin Stroke
            </li>
            <img
              src={review4}
              className="d-block mx-auto img-fluid"
              alt="‘Browse’ to view Stroke Maps for each set"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/set-3">
            <h3>
              Next: Set 3 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import * as d3 from 'd3';
import MyProfile from './MyProfile';
import LoadingStateComponent from '../../../../components/LoadingStateComponent';
import { cuemateGET, cuematePUT } from '../../../../libs/dataAccess';
import { getUserAvatar } from '../reports/util/dataQuery';

function poundsToKilograms(pounds) {
  return pounds * 0.453592;
}

function kilogramsToPounds(kilograms) {
  return kilograms * 2.20462;
}

function convertUserProfile(userProfile, direction = 'from') {
  const { birthDay, height, weight } = userProfile;
  const updates = {};

  if (direction === 'from') {
    const formatTime = d3.timeFormat('%Y-%m-%d');
    updates.birthDay = birthDay ? formatTime(d3.timeParse('%Y-%m-%dT%H:%M:%SZ')(birthDay)) : '';
    updates.height = height ? height.toString() : '';
    updates.weight = weight ? kilogramsToPounds(weight).toFixed(2) : '';
  } else {
    const parseTime = d3.timeParse('%Y-%m-%d');
    updates.birthDay = birthDay ? d3.timeFormat('%Y-%m-%dT%H:%M:%SZ')(parseTime(birthDay)) : '';
    updates.height = height ? parseFloat(height) : '';
    updates.weight = weight ? +poundsToKilograms(parseFloat(weight)).toFixed(2) : '';
  }

  const updatedUserProfile = { ...userProfile, ...updates };
  return updatedUserProfile;
}

const getActivityGoalProfiles = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET(
    '/goals/activity/profiles',
    currentSession.accessToken.jwtToken
  );
  if (!response) {
    return [];
  }
  const { data } = response;
  return data;
};

export default class ListMyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSubmitting: false,
      shouldRenderAlert: false,
      disableSaveProfile: true,
    };
    this.handleActivityProfileChange = this.handleActivityProfileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const currentSession = await Auth.currentSession();
      const userSettings = await cuemateGET('/userprofile', currentSession.accessToken.jwtToken);
      const userAvatar = await getUserAvatar();
      const activityGoalProfileOptions = await getActivityGoalProfiles();

      if (userSettings) {
        const userProfile = convertUserProfile(userSettings, 'from');
        const newState = {
          ...(userSettings.properties && userSettings.properties),
          userProfile,
          isLoading: false,
        };
        if (userAvatar) {
          newState.userAvatar = userAvatar;
        }
        if (activityGoalProfileOptions.length > 0) {
          newState.activityGoalProfileOptions = activityGoalProfileOptions;
        }
        this.setState(newState);
      } else {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleChange(event) {
    const { currentTarget } = event;
    const { id, value } = currentTarget;
    const { userProfile } = this.state;
    if (id.includes('properties')) {
      const { properties } = userProfile;
      const keys = id.split('-');
      properties[keys[1]] = value;
      this.setState({
        userProfile: { ...userProfile, properties },
        disableSaveProfile: false,
      });
    } else {
      this.setState({
        userProfile: { ...userProfile, ...{ [id]: value } },
        disableSaveProfile: false,
      });
    }
  }

  handleActivityProfileChange(option) {
    if (Object.keys(option).length !== 0) {
      const activityGoalProfileObject = {
        id: option.id,
        type: option.type,
      };
      const activityGoalProfile = [activityGoalProfileObject];
      const { userProfile } = this.state;
      const userProfileUpdate = { ...userProfile, activityGoalProfile };
      this.setState({
        userProfile: userProfileUpdate,
        disableSaveProfile: false,
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
      const { userProfile } = this.state;
      let userProfileObject = convertUserProfile(userProfile, 'to');
      userProfileObject = Object.fromEntries(
        Object.entries(userProfileObject).filter(([, v]) => v !== '')
      );

      if (Object.keys(userProfileObject).length > 0) {
        this.setState({ isSubmitting: true, shouldRenderAlert: false });
        const currentSession = await Auth.currentSession();
        await cuematePUT('/userprofile', currentSession.accessToken.jwtToken, userProfileObject);
        const currentUser = await Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });

        const attributes = {
          'custom:hasComplSettings': '1',
        };

        Auth.updateUserAttributes(currentUser, attributes);
        this.setState({ isSubmitting: false, shouldRenderAlert: true, disableSaveProfile: true });
        setTimeout(() => {
          this.setState({ shouldRenderAlert: false });
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      disableSaveProfile,
      isLoading,
      isSubmitting,
      shouldRenderAlert,
      userProfile,
      userAvatar,
    } = this.state;

    return isLoading ? (
      <LoadingStateComponent loadingText="Loading your Settings.." />
    ) : (
      <MyProfile
        avatar={userAvatar}
        disableSaveProfile={disableSaveProfile}
        isSubmitting={isSubmitting}
        onChange={this.handleChange}
        onSubmit={this.handleSubmit}
        settings={userProfile}
        shouldRenderAlert={shouldRenderAlert}
      />
    );
  }
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import sessionReports from '../../../../images/web-features/session-reports.png';
import sessionScoreHistory from '../../../../images/web-features/session-score-history.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import './WebFeatures.css';

export default function WebHome() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Home Screen</h2>
          <h4 className="padding-top-25">
            The home screen gives an overview of all the prior sessions.
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={4}>
          <h2 className="blueGrey">Session Reports</h2>
          <p>Session Reports provide a summation of play activities, including:</p>
          <ul>
            <li>Total number of strokes</li>
            <li>Total play duration</li>
            <li>Total days since last session</li>
            <li>List of key activity statistics by session</li>
          </ul>
          <p>Click on a session for more information.</p>
        </Col>
        <Col sm={8}>
          <img
            src={sessionReports}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Session Reports"
          />
          <figcaption className="figure-caption">Session Reports</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Session Score History</h2>
          <p>
            The session score history depicts the overall score achieved over the past sessions. It
            provides a quick assessment of the activities over the past history.
          </p>
          <p>The system distinguishes between four score components:</p>
          <ul>
            <li>Technique</li>
            <li>Activity</li>
            <li>Performance</li>
            <li>Versatility</li>
          </ul>
          <p>
            By default, the plot shows the technique and activity scores. You can toggle the various
            score dimensions, for example to see all of them, select the Performance and Versatility
            score.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={sessionScoreHistory}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Session Score History"
          />
          <figcaption className="figure-caption">Session Score History</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-web">
            <h3>
              Back to: CueMate for Web <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import './AppFeatures.css';

import statusoverview from '../../../../images/screens/status/CueMateStatus-AB.png';
import statusA from '../../../../images/screens/status/CueMateStatus-A.png';
import statusB from '../../../../images/screens/status/CueMateStatus-B.png';

import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';

export default function StatusScreen() {
  const deviceStatusContent = (
    <Row className="padding-top-100" id="device-status">
      <Col sm="7" className="padding-top-50">
        <Media>
          <Media tag="span" left>
            <img src={labelA} className="label-header-max-height" alt="Label A" />
          </Media>
          <Media body>
            <h2>Device Status</h2>
          </Media>
        </Media>
        <p className="padding-top-50">
          {' '}
          Connect and disconnect bluetooth connection between device and CueMate app. Check the
          battery level on your device. Determine is your network status is online or offline.{' '}
        </p>
      </Col>
      <Col sm="5" className="padding-top-50">
        <img src={statusA} className="d-block mx-auto img-fluid" alt="Device Status" />
      </Col>
    </Row>
  );

  const setUploadStatusContent = (
    <Row className="padding-top-100" id="set-upload-status">
      <Col sm="7" className="padding-top-50">
        <Media>
          <Media tag="span" left>
            <img src={labelB} className="label-header-max-height" alt="Label B" />
          </Media>
          <Media body>
            <h2>Set Upload Status</h2>
          </Media>
        </Media>
        <p className="margin-top-50">
          Determine the status of each set&apos;s data. If data has red indicators, there was an
          issue sending data to the CueMate database. Press UPLOAD SET DATA to trigger sending data
          to the database again.
        </p>
      </Col>
      <Col sm="5" className="padding-top-50">
        <img src={statusB} className="d-block mx-auto img-fluid" alt="Upload Sets" />
      </Col>
    </Row>
  );

  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm="12">
          <h1>Status</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="padding-top-50">
          <img src={statusoverview} className="d-block mx-auto img-fluid" alt="Status screen" />
        </Col>
        <Col sm="4" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/status-screen#device-status">
                  Device Status
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/status-screen#set-upload-status">
                  Set Upload Status
                </Link>
              </h3>
            </Media>
          </Media>
        </Col>
      </Row>

      {deviceStatusContent}
      {setUploadStatusContent}

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Train.css';

export default function TrainingPrograms() {
  return (
    <Container>
      <Breadcrumbs />
      <Row>
        <Col sm={12}>
          <h1>Training Programs</h1>
          <h4>
            The training programs are designed to create a complete training experience focusing on
            common problems, combining the training features.{' '}
          </h4>
          <p>(in development)</p>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import cueing1 from '../../../../images/cuemate101/level1/key-concepts/cueing/cueing-1.png';
import pacecueing from '../../../../images/cuemate101/level1/key-concepts/cueing/pace-cueing.png';
import spincueing from '../../../../images/cuemate101/level1/key-concepts/cueing/spin-cueing.png';
import isrcueing from '../../../../images/cuemate101/level1/key-concepts/cueing/isr-cueing.png';
import cueing2 from '../../../../images/cuemate101/level1/key-concepts/cueing/cueing-2.png';

import '../AppFeatures.css';

export default function Cueing() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={8}>
          <h2>Cueing</h2>
          <p>
            CueMate provides real-time audio Cues on the court during play. This instant feedback is
            a powerful way to improve performance.
          </p>
          <h3 className="blue padding-top-25">GENERAL CUES</h3>
          <p>
            Cues focus on one of three stroke outcomes: Pace, Spin, and ISR. General cues are active
            on all strokes, and are calibrated to average player performance. (Once a player records
            enough stoke data with CueMate, the more effective “stroke-specific” cues can be
            utilized.)
          </p>
        </Col>
        <Col sm={{ size: 4 }}>
          <img
            src={cueing1}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Stroke Metrics Key"
          />
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">PACE</h4>
          <p>Intensity of racket head acceleration during stroke</p>
        </Col>
        <Col sm={{ size: 6 }}>
          <figcaption className="figure-caption">Audio Cue Tone</figcaption>
          <img
            src={pacecueing}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Audio Cue Tone"
          />
          <figcaption className="figure-caption">
            Average Player Scale in N (Kg x m/sec^2)
          </figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">SPIN</h4>
          <p>Rotation speed of ball leaving the racket</p>
        </Col>
        <Col sm={{ size: 6 }}>
          <figcaption className="figure-caption">Audio Cue Tone</figcaption>
          <img
            src={spincueing}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Audio Cue Tone"
          />
          <figcaption className="figure-caption">Average Player Scale in rpm</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">ISR</h4>
          <p>Impact Success Rate - Ball contact with center of the racket</p>
        </Col>
        <Col sm={{ size: 6 }}>
          <figcaption className="figure-caption">Audio Cue Tone</figcaption>
          <img
            src={isrcueing}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Audio Cue Tone"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={{ size: 4 }}>
          <img
            src={cueing2}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Stroke Metrics Key"
          />
        </Col>
        <Col sm={8}>
          <h3 className="blue">STROKE-SPECIFIC CUES</h3>
          <p>
            CueMate allows players to set stroke-specific cues for more targeted feedback (as part
            of the advanced features covered in later sessions).
          </p>
          <p>
            Stroke-specific cues are calibrated to individual player performance metrics, and are
            active only during stroke classes the player is focusing on improving.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate101/level1">
            <h3>
              Back to: Level 1 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import trainingControls from '../../../../images/web-features/training-controls.png';
import trainingGoalTrends from '../../../../images/web-features/training-goal-trends.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import './WebFeatures.css';

export default function WebTraining() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Training</h2>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Training Controls</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 4, size: 6 }}>
          <img
            src={trainingControls}
            className="d-block mx-auto img-fluid img-border"
            alt="Training Controls"
          />
          <figcaption className="figure-caption">Training Controls</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Training Goal Trends</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 6, offset: 4 }}>
          <img
            src={trainingGoalTrends}
            className="d-block mx-auto img-fluid img-border"
            alt="Training Goal Trends"
          />
          <figcaption className="figure-caption">Training Goal Trends</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-web">
            <h3>
              Back to: CueMate for Web <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ChangeEmail from './ChangeEmail';
import UpdatePassword from './UpdatePassword';

/*
  Main class that renders the components that are part of the user settings
*/
export default function Settings() {
  return (
    <Container className="padding-top-25 animated fadeIn">
      <Row>
        <Col sm="12">
          <h2 className="subtitle-grey">My Account</h2>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <ChangeEmail className="padding-top-25 padding-bottom-25" />
          <hr />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <UpdatePassword className="padding-top-25 padding-bottom-25" />
        </Col>
        <hr />
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import playmappopulated from '../../../../images/screens/Play-StrokeMap.PNG';
import playlive from '../../../../images/screens/Play-Live.PNG';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import PlayScreen from './PlayScreen';

import './AppFeatures.css';

export default function PlayMode() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Play Mode</h2>
          <h4 className="padding-top-25">
            In Play mode, CueMate provides basic tracking capabilities over one’s periods of
            activity, including the recording of performance data and tools for reviewing
            performance.
          </h4>
          <h4>
            The focus of assessment under Play is on current and past performance, allowing players
            to take advantage of key metrics and analytics if desired, without interference in their
            activity.
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={6}>
          <h2 className="blueGrey">Stroke Map</h2>
          <p>
            {' '}
            The CueMate app displays stroke data and metrics from the current set on a Stroke Map.
            CueMate records and organizes strokes on the Stroke Map according to:{' '}
          </p>
          <ul>
            <li>stroke category (groundstrokes, serves, volleys)</li>
            <li>stroke class (forehand, backhand)</li>
            <li>stroke outcomes (topspin, slice and flat)</li>
            <li>stroke intensity (hard, medium, and light)</li>
          </ul>
          <p>
            The four elements describe a stroke using the following nomenclature on the Stroke Map:
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={playmappopulated}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Stroke Map"
          />
        </Col>
      </Row>
      <Row className="padding-top-50">
        <Col sm={6}>
          <Row>
            <Col sm={4}>
              <p>TYPE</p>
              <ul>
                <li>Forehand (FH)</li>
                <li>Backhand (BH)</li>
              </ul>
            </Col>
            <Col sm={4}>
              <p>OUTCOMES</p>
              <ul>
                <li>Topspin (TS)</li>
                <li>Flat (FL)</li>
                <li>Slice (SL)</li>
              </ul>
            </Col>
            <Col sm={4}>
              <p>INTENSITY</p>
              <ul>
                <li>Hard (H)</li>
                <li>Medium (M)</li>
                <li>Light (L)</li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <p>
            Stroke Map is enhanced by color highlights to help quickly identify the overall quality
            of the stroke types. A player can review each stroke’s outcomes — as well as their
            performance and technique — by tapping on a stroke type and reviewing its metrics data.
          </p>
        </Col>
      </Row>
      <Row className="padding-top-50">
        <Col sm={6}>
          <p>
            For example, The Forehand Topspin H (FHTS H) stroke is a Forehand stroke with topspin
            and hard stroke intensity (intensity is used in place of pace or speed).
          </p>
        </Col>
        <Col sm={6} />
      </Row>

      <hr />

      <Row className="padding-top-50">
        <Col sm={6}>
          <h2 className="blueGrey">Live View</h2>
          <p>
            During play, a coach or friend can view attributes of a player’s stroke — in real time —
            using CueMate’s Live View feature.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={playlive}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Live View"
          />
        </Col>
      </Row>
      <PlayScreen />

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

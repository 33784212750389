import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import { Level2Description, Level2KeyConcepts, Level2Features } from './L2ToC';

export default function Level2() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm="12">
          <h2>2: Review Your Sessions</h2>
        </Col>
      </Row>
      <Level2Description />
      <Level2KeyConcepts />
      <Level2Features />
    </Container>
  );
}

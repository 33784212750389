import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';

export default function WebFeatures() {
  return (
    <Container className="animated fadeIn">
      <Row>
        <Col sm="12">
          <h2>CueMate for Web</h2>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h4>The web app is designed to follow the general information architecture:</h4>
          <ul>
            <li>
              <Link smooth to="/cuemate-web/home-screen">
                Home
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-web/session-reports">
                Session Reports
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-web/player-reports">
                Player Reports
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-web/stroke-class-reports">
                Class Reports
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-web/training">
                Training
              </Link>
            </li>
            <li>
              <Link smooth to="/cuemate-web/leaderboard">
                Leaderboard
              </Link>
            </li>
          </ul>
          <h4> Choose a screen to learn how to make the most of the CueMate ecosystem.</h4>
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useAsyncV2, cuemateGET } from '../../../../libs/dataAccess';
import onError from '../../../../libs/errorLib';
import LoadingStateComponent from '../../../../components/LoadingStateComponent';
import DiscoverClubsDeck from './DiscoverClubsDeck';
import { GroupType } from './GroupConstants';

const CLUBS_LIMIT = 10;

export default function DiscoverClubs({ onJoinClub, shouldRefreshData }) {
  const listClubs = async () => {
    const currentSession = await Auth.currentSession();
    let allGroups = [];
    let userGroups = [];
    let pendingRequests = [];
    try {
      allGroups = await cuemateGET(
        `/groups/clubs?limit=${CLUBS_LIMIT}`,
        currentSession.accessToken.jwtToken
      );

      userGroups = await cuemateGET(
        `/users/social/groups?groupType=${GroupType.CLUBS}`,
        currentSession.accessToken.jwtToken
      );

      pendingRequests = await cuemateGET(
        `/groups/clubs/pending-requests/invite-only`,
        currentSession.accessToken.jwtToken
      );
    } catch (error) {
      onError(error);
    }

    return { allGroups, userGroups, pendingRequests };
  };

  const { value: data, error, loading } = useAsyncV2(listClubs, [shouldRefreshData]);

  if (loading) {
    return <LoadingStateComponent loadingText="Loading clubs.." />;
  }

  const clubs = data.allGroups.data || [];
  const userGroups = data.userGroups || [];
  const pendingRequests = data.pendingRequests || [];

  if (error) {
    onError(error);
    return <LoadingStateComponent loadingText="Error loading clubs. Refresh to try again." />;
  }

  return (
    <Container className="animated fadeIn padding-bottom-50">
      <DiscoverClubsDeck
        clubsData={clubs}
        userGroups={userGroups}
        onJoinClub={onJoinClub}
        pendingRequests={pendingRequests}
      />
    </Container>
  );
}

DiscoverClubs.propTypes = {
  onJoinClub: PropTypes.func,
  shouldRefreshData: PropTypes.bool,
};

import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Button,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useDebounce } from './useDebounce';
import LoaderButton from '../../../components/LoaderButton';
import { cuematePOST } from '../../../libs/dataAccess';

const SEARCH_LIMIT = 5;
const MODAL_SEARCH_LIMIT = 20;
const DEBOUNCE_DELAY = 300;

function UserSearch({ searchURL, onUserClick }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(true);

  const [modalSearchTerm, setModalSearchTerm] = useState('');
  const [modalResults, setModalResults] = useState([]);
  const [loadingModalSearch, setLoadingModalSearch] = useState(true);

  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleSearchModal = () => setSearchModalOpen((prevState) => !prevState);

  const defaultValues = {
    defaultValues: {
      username: '',
    },
  };

  const { handleSubmit } = useForm(defaultValues);

  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);
  const debouncedModalSearchTerm = useDebounce(modalSearchTerm, DEBOUNCE_DELAY);

  const handleSearch = useCallback(
    async (term, limit) => {
      setError(null);
      if (!term || term.length < 2) return [];
      try {
        const currentSession = await Auth.currentSession();
        setLoadingSearch(true);
        const response = await cuematePOST(searchURL, currentSession.accessToken.jwtToken, {
          username: term,
          limit,
        });
        if (response.code && response.message) {
          setError('An error occurred during search');
          return [];
        }
        return response.data;
      } catch (err) {
        console.error(err);
      }
      return [];
    },
    [searchURL]
  );

  const handleModalSearch = useCallback(async () => {
    setLoadingModalSearch(true);
    const data = await handleSearch(modalSearchTerm, MODAL_SEARCH_LIMIT);
    setModalResults(data);
    setLoadingModalSearch(false);
  }, [handleSearch, modalSearchTerm]);

  useEffect(() => {
    if (searchTerm.length >= 2) {
      (async () => {
        const data = await handleSearch(searchTerm, SEARCH_LIMIT);
        setResults(data);
        setLoadingSearch(false);
      })();
    } else {
      // Clear results when searchTerm is less than 2
      setResults([]);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    if (modalSearchTerm.length >= 2) {
      handleModalSearch();
    } else {
      setModalResults([]);
    }
  }, [debouncedModalSearchTerm, handleModalSearch]);

  const modalSearch = (
    <Modal isOpen={searchModalOpen} toggle={toggleSearchModal}>
      <ModalHeader toggle={toggleSearchModal}>Search More Users</ModalHeader>
      <ModalBody>
        <Input
          placeholder="Search users"
          value={modalSearchTerm}
          onChange={(e) => setModalSearchTerm(e.target.value)}
        />
        <ListGroup>
          {modalSearchTerm.length >= 2 && modalResults.length === 0 && !loadingModalSearch && (
            <ListGroupItem>No results found</ListGroupItem>
          )}
          {modalResults.map((user) => (
            <ListGroupItem
              key={user.username}
              tag="button"
              action
              onClick={() => {
                onUserClick(user.username);
                toggleSearchModal();
              }}
            >
              {user.username}
            </ListGroupItem>
          ))}
        </ListGroup>
      </ModalBody>
      <ModalFooter>
        {loadingModalSearch ? <Spinner size="sm" /> : null}
        <Button color="secondary" onClick={toggleSearchModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <>
      {modalSearch}

      <FormGroup row>
        <Col sm={12}>
          <Input
            placeholder="username"
            bsSize="sm"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
          <ListGroup>
            {searchTerm.length >= 2 && results.length === 0 && !loadingSearch && (
              <ListGroupItem>No results found</ListGroupItem>
            )}
            {results.map((user) => (
              <ListGroupItem
                key={user.username}
                tag="button"
                action
                onClick={() => {
                  onUserClick(user.username);
                }}
              >
                {user.username}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12}>
          <LoaderButton
            text="SEARCH"
            color="primary"
            size="sm"
            loadingText=""
            disabled={searchTerm.length < 2}
            block
            outline
            onClick={handleSubmit(() => {
              toggleSearchModal();
              setModalSearchTerm(searchTerm);
            })}
          />
        </Col>
      </FormGroup>
      {error && <div className="alert alert-danger">{error}</div>}
    </>
  );
}

UserSearch.propTypes = {
  searchURL: PropTypes.string,
  onUserClick: PropTypes.func,
};

export default memo(UserSearch);

import React, { useState, useCallback, Fragment, useEffect } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { cuemateGET, cuematePUT, cuemateDELETE } from '../../../../libs/dataAccess';
import LikeImgOff from '../../../../images/social/like@3x.png';
import LikeImgOn from '../../../../images/social/like@3x-filled.png';
import './LikeReactionButton.css';

export default function LikeReactionButton({
  isLiked,
  activityId,
  initialCount,
  onCountClick,
  didCountUpdate,
}) {
  const [isOn, setIsOn] = useState(isLiked);
  const [isBusy, setIsBusy] = useState(false);
  const [likesCount, setLikesCount] = useState(initialCount);

  useEffect(() => {
    setLikesCount(initialCount);
  }, [initialCount]);

  useEffect(() => {
    setIsOn(isLiked);
  }, [isLiked]);

  const likeUnlike = useCallback(() => {
    async function fetch() {
      try {
        setIsBusy(true);
        const currentSession = await Auth.currentSession();

        if (isOn) {
          await cuemateDELETE(
            `/users/social/activities/${activityId}/reactions`,
            currentSession.accessToken.jwtToken
          );
        } else {
          await cuematePUT(
            `/users/social/activities/${activityId}/reactions/1`,
            currentSession.accessToken.jwtToken
          );
        }
        const response = await cuemateGET(
          `/users/social/activities/${activityId}/reactions`,
          currentSession.accessToken.jwtToken
        );

        if (response.reactions) {
          setLikesCount(response.reactions.length);
          if (didCountUpdate !== undefined) {
            didCountUpdate(!isOn, response.reactions.length);
          }
        }
        setIsOn(!isOn);
        setIsBusy(false);
      } catch (err) {
        console.error(err);
      }
    }
    fetch();
  }, [isOn, isBusy]);

  return (
    <>
      <Button
        color="white"
        size="sm"
        className="blue d-flex align-items-start"
        onClick={likeUnlike}
      >
        {isBusy ? (
          <i className="fa fa-spinner fa-pulse fa-2x fa-fw blue" aria-hidden="true" />
        ) : (
          <img className="like" alt="like" src={isOn ? LikeImgOn : LikeImgOff} />
        )}
      </Button>
      <Button
        className="p-0"
        color="link"
        onClick={onCountClick !== undefined ? onCountClick : likeUnlike}
      >
        {likesCount}
      </Button>
    </>
  );
}

LikeReactionButton.propTypes = {
  isLiked: PropTypes.bool,
  activityId: PropTypes.string,
  onCountClick: PropTypes.func,
  initialCount: PropTypes.number,
  didCountUpdate: PropTypes.func,
};

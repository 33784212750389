import React from 'react';
import { BasicDataWrapper, CVSessionReport, LoadingState, NoReportMessage } from 'cuemate-charts';
import { getUserReport } from '../util/dataQuery';

export default function ComputerVisionReport() {
  const dataPromises = {
    sessions: getUserReport('session-list.csv', 'CSV', 'vision-data'),
  };
  return (
    dataPromises !== undefined && (
      <BasicDataWrapper dataPromises={dataPromises}>
        {(state) =>
          // eslint-disable-next-line no-nested-ternary
          state.isLoading ? (
            <LoadingState />
          ) : state.sessions ? (
            <div>
              <CVSessionReport sessions={state.sessions} getReport={getUserReport} />
            </div>
          ) : (
            <NoReportMessage />
          )
        }
      </BasicDataWrapper>
    )
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import * as d3 from 'd3';
import {
  Badge,
  UncontrolledAlert,
  Container,
  FormGroup,
  FormFeedback,
  Form,
  Input,
  Label,
  Col,
  Table,
} from 'reactstrap';
import LoaderButton from '../../../components/LoaderButton';
import { SENSOR_NAME_REGEX } from '../../../libs/utilities';
import { cuemateGET, cuematePOST, cuematePUT } from '../../../libs/dataAccess';
import onError from '../../../libs/errorLib';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function updateState(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

const readSessions = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET(
    '/users/sessions/upload?type=tennis',
    currentSession.accessToken.jwtToken
  );
  if (!response || response.data.length === 0) {
    return [];
  }
  return response.data;
};

const createSession = async (session) => {
  const currentSession = await Auth.currentSession();
  const response = await cuematePOST(
    '/users/sessions/upload',
    currentSession.accessToken.jwtToken,
    session
  );
  return !response ? {} : response;
};

const updateSession = async (session) => {
  const currentSession = await Auth.currentSession();
  const response = await cuematePUT(
    '/users/sessions/upload',
    currentSession.accessToken.jwtToken,
    session
  );
  return !response ? {} : response;
};

function TableRow(props, handleEndSessionClick, isEndingSession) {
  const { id, idx, sensorName, startTS, endTS } = props || {};
  const rowStyle = { textAlign: 'center' };

  return (
    <tr key={id} style={rowStyle}>
      <th scope="row">{idx + 1}</th>
      <td>{sensorName || '-'}</td>
      <td>{startTS || '-'}</td>
      <td>
        {endTS || (
          <LoaderButton
            type="submit"
            text="End Session"
            color="danger"
            size="sm"
            loadingText="Ending session..."
            onClick={handleEndSessionClick}
            value={idx}
            isLoading={isEndingSession}
          />
        )}
      </td>
    </tr>
  );
}

export default function UploadManual() {
  const [fields, handleFieldChange] = useFormFields({
    sensorName: 'Bencic',
  });
  const [isSessionActive, setSessionActive] = useState(false);
  const [currentSession, setCurrentSession] = useState({});
  const [manualSessions, setManualSessions] = useState([]);
  const [isStartingSession, setIsStartingSession] = useState(false);
  const [startedSession, setStartedSession] = useState(false);
  const [isEndingSession, setIsEndingSession] = useState(false);

  const isActive = (session) => session.endTS === undefined;

  useEffect(() => {
    async function onLoad() {
      try {
        const sessions = await readSessions();
        setManualSessions(sessions);
        setSessionActive(sessions.some(isActive));
      } catch (error) {
        onError(error, true);
      }
    }

    onLoad();
  }, [currentSession]);

  const isValidSensorName = !!fields.sensorName.match(SENSOR_NAME_REGEX);

  function validateUploadForm() {
    return fields.sensorName.length === 8;
  }

  const handleStartSessionClick = useCallback(async (event) => {
    event.preventDefault();
    setIsStartingSession(true);
    const date = d3.timeFormat('%Y-%m-%dT%H:%M:%S.%LZ')(Date.now());
    const session = {
      startTS: date,
      type: 'tennis',
      ...fields,
    };
    try {
      // eslint-disable-next-line no-shadow
      const currentSession = await createSession(session);
      setCurrentSession(currentSession);
      setStartedSession(true);
    } catch (error) {
      onError(error, true);
    }
    setIsStartingSession(false);
  });

  async function handleEndSessionClick(event) {
    event.preventDefault();
    setIsEndingSession(true);
    const date = d3.timeFormat('%Y-%m-%dT%H:%M:%S.%LZ')(Date.now());
    const selectedSession = manualSessions[event.target.value];
    const session = { ...selectedSession, endTS: date };
    try {
      // eslint-disable-next-line no-shadow
      const currentSession = await updateSession(session);
      setCurrentSession(currentSession);
    } catch (error) {
      onError(error, true);
    }
    setIsEndingSession(false);
  }

  function renderUpdateForm() {
    return (
      <Container>
        <h2 className="subtitle-black">Upload a Session </h2>
        <Form onSubmit={handleStartSessionClick}>
          <FormGroup row>
            <Label for="sensorName" sm={2}>
              Sensor Name
            </Label>
            <Col sm={10}>
              <Input
                id="sensorName"
                name="sensorName"
                onChange={handleFieldChange}
                type="text"
                placeholder="BencicXX"
                valid={isValidSensorName}
                invalid={!isValidSensorName}
                value={fields.sensorName}
              />
              <FormFeedback valid>Sweet! that name is valid</FormFeedback>
              <FormFeedback invalid={+!isValidSensorName}>
                Oh noes! That name is invalid
              </FormFeedback>
            </Col>
          </FormGroup>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {isSessionActive ? (
              <h3>
                <Badge color="warning">Active Session</Badge>
              </h3>
            ) : (
              <LoaderButton
                type="submit"
                text="Start Session"
                color="primary"
                size="sm"
                loadingText="Starting session..."
                isLoading={isStartingSession}
                disabled={!validateUploadForm() || isSessionActive}
              />
            )}
          </div>

          {startedSession ? (
            <Col sm="12">
              <UncontrolledAlert color="success">
                Your session was started successfully
              </UncontrolledAlert>
            </Col>
          ) : null}
        </Form>
      </Container>
    );
  }

  function renderActiveSessions() {
    return (
      <Container>
        <hr />
        <h2 className="subtitle-grey">All Sessions</h2>
        <Table striped hover responsive size="sm">
          <thead>
            <tr style={{ textAlign: 'center' }}>
              <th>#</th>
              <th>Sensor Name</th>
              <th>Start</th>
              <th>End</th>
            </tr>
          </thead>
          <tbody>
            {manualSessions.map((session, idx) =>
              TableRow({ idx, ...session }, handleEndSessionClick, isEndingSession)
            )}
          </tbody>
        </Table>
      </Container>
    );
  }

  const startSessionForm = renderUpdateForm();
  const activeSessions = renderActiveSessions();

  return (
    <div>
      {startSessionForm}
      {activeSessions}
    </div>
  );
}

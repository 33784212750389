import React, { useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import {
  UncontrolledAlert,
  Container,
  FormText,
  FormGroup,
  Form,
  Input,
  Label,
  Col,
} from 'reactstrap';
import LoaderButton from '../../../../components/LoaderButton';
import onError from '../../../../libs/errorLib';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function setVals(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
  ];
}

export default function ChangeEmail() {
  const [fields, handleFieldChange] = useFormFields({
    code: '',
    email: '',
  });
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  function validateEmailForm() {
    return fields.email.length > 0;
  }

  function validateConfirmForm() {
    return fields.code.length > 0;
  }

  const handleUpdateClick = useCallback(async (event) => {
    event.preventDefault();
    setIsSendingCode(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: fields.email });
      setCodeSent(true);
    } catch (error) {
      onError(error, true);
      setIsSendingCode(false);
    }
  }, []);

  const handleConfirmClick = useCallback(async (event) => {
    event.preventDefault();
    setIsConfirming(true);
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', fields.code);
      setIsConfirming(false);
      setIsEmailChanged(true);
      setCodeSent(false);
      setIsSendingCode(false);
    } catch (error) {
      onError(error, true);
      setIsConfirming(false);
    }
  }, []);

  function renderUpdateForm() {
    return (
      <Container>
        <h3 className="subtitle-grey">Change Email</h3>
        <Form onSubmit={handleUpdateClick}>
          <Col sm="12">
            <FormGroup>
              <Label for="email">New Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <LoaderButton
              type="submit"
              text="Update Email"
              color="primary"
              size="sm"
              loadingText="Sending code..."
              isLoading={isSendingCode}
              disabled={!validateEmailForm()}
              block
            />
          </Col>
          {isEmailChanged ? (
            <Col sm="12">
              <UncontrolledAlert color="success">
                Your email was changed successfully
              </UncontrolledAlert>
            </Col>
          ) : null}
        </Form>
      </Container>
    );
  }

  function renderConfirmationForm() {
    return (
      <Container>
        <Form onSubmit={handleConfirmClick}>
          <Col sm="12">
            <FormGroup>
              <Label for="code">{`Confirmation Code: `}</Label>
              <Input
                autoFocus
                type="tel"
                name="code"
                id="code"
                value={fields.code}
                onChange={handleFieldChange}
              />
              <FormText>
                Please check your email ({fields.email}) for the confirmation code.
              </FormText>
            </FormGroup>
          </Col>
          <Col sm="12">
            <LoaderButton
              type="submit"
              text="Confirm"
              color="primary"
              size="sm"
              loadingText="Confirming code..."
              isLoading={isConfirming}
              disabled={!validateConfirmForm()}
              block
            />
          </Col>
        </Form>
      </Container>
    );
  }

  let displayForm = renderUpdateForm();
  if (codeSent && !isEmailChanged) {
    displayForm = renderConfirmationForm();
  }

  return <div className="ChangeEmail">{displayForm}</div>;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function AddActivityGoalProfile({ profileName, profileOptions, handleChange }) {
  const [options] = useState(profileOptions || []);
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleOptionSelect = (option) => {
    handleChange(option);
    toggleModal();
  };

  const sortedOptions = [...options].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Button onClick={toggleModal}>Update</Button>
      <Modal isOpen={modal} toggle={toggleModal} style={{ maxWidth: '900px', width: '100%' }}>
        <ModalHeader toggle={toggleModal}>Select your Activity Profile</ModalHeader>
        <ModalBody>
          <div className="row">
            {sortedOptions.map((option) => (
              <div className="col mb-3" key={option.id}>
                <div className="d-flex flex-column">
                  <Button
                    block
                    outline
                    color="primary"
                    className="flex-grow-1 d-flex align-items-center justify-content-center"
                    style={{ height: '100px' }}
                    onClick={() => handleOptionSelect(option)}
                    active={option.name === profileName}
                  >
                    <h5>{option.name}</h5>
                  </Button>
                  {option.description && (
                    <div>
                      <hr />
                      <div className="flex-grow-0">{option.description}</div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

AddActivityGoalProfile.propTypes = {
  profileName: PropTypes.string,
  profileOptions: PropTypes.array,
  handleChange: PropTypes.func,
};

export default AddActivityGoalProfile;

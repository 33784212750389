import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import playerProfileOverview from '../../../../images/screens/player-profile/PlayerProfile.PNG';
import playerProfileActivity from '../../../../images/screens/player-profile/PlayerProfile-Activity.PNG';
import playerProfileStrokeMapOverview from '../../../../images/screens/player-profile/PlayerProfile-Strokemap-Overview.png';
import playerProfileStrokeMap from '../../../../images/screens/player-profile/PlayerProfile-Strokemap.PNG';
import playerProfileTraining from '../../../../images/screens/player-profile/PlayerProfile-Training.png';

import './AppFeatures.css';

export default function PlayerProfile() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-25">
        <Col sm="12">
          <h1>Player Profile</h1>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm="8">
          <img
            src={playerProfileOverview}
            className="d-block mx-auto img-fluid"
            alt="Player Profile Screen"
          />
        </Col>
        <Col sm="4">
          <p className="padding-top-50">
            The Player Profile screens provides a summary of your tennis activities and skills
          </p>
          <p>
            The top panel shows your average sweet spot %, best stroke, most used stroke and average
            groundstroke rating
          </p>
          <p>
            The Edit Profile button lets you change your profile information such as handedness,
            backhand type, height, weight, birthday and gender.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm="7">
          <Media>
            <Media body>
              <h2>Activity Bar</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The Activity Bar plot displays your weekly cumulative strokes played in the last three
            months.
          </p>
          <p>
            Click on the activity bar to see a summary of your personal statistics such as averages
            for the last for weeks, totals for the year and all-time totals{' '}
          </p>
        </Col>
        <Col sm="5">
          <img
            src={playerProfileOverview}
            className="d-block mx-auto img-fluid"
            alt="Activity Bar"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25">
          <figure className="figure">
            <img
              src={playerProfileActivity}
              className="d-block mx-auto img-fluid img-border"
              alt="Player Profile Activity Stats"
            />
            <figcaption className="figure-caption">Player Profile - Activity Stats</figcaption>
          </figure>
        </Col>
      </Row>

      <Row className="padding-top-100" id="begin-play">
        <Col sm="7">
          <Media>
            <Media body>
              <h2>Stroke Map</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The Stroke Map shows the total count for every stroke class highlighted by the color of
            their skill status stage and is based on the last ten sessions.
          </p>
          <p>
            Click on the Stroke Map to see a detailed view where you can see the distributions of
            intensity level, spin differentiation and sweet spot for your forehand and backhand.
          </p>
        </Col>
        <Col sm="5">
          <img
            src={playerProfileStrokeMapOverview}
            className="d-block mx-auto img-fluid"
            alt="Player Profile - Stroke Map"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25">
          <figure className="figure">
            <img
              src={playerProfileStrokeMap}
              className="d-block mx-auto img-fluid img-border"
              alt="Player Profile Activity Stats"
            />
            <figcaption className="figure-caption">Player Profile - Stroke Map</figcaption>
          </figure>
        </Col>
      </Row>

      <Row className="padding-top-100" id="begin-play">
        <Col sm="7">
          <Media>
            <Media body>
              <h2>Stroke Rating</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The Stroke Rating, as it implies, is an assessment of your skill level on a scale from 1
            (novice) to 5 (expert). CueMate rates each of your stroke categories and the forehand
            and backhand individually (groundstrokes, volleys, serves). In addition to the rating
            level, the progress ring to show you how close you are to the next level.
          </p>
        </Col>
        <Col sm="5">
          <img
            src={playerProfileTraining}
            className="d-block mx-auto img-fluid"
            alt="Player Profile - Training"
          />
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

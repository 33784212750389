import React from 'react';
import { Col, Container, Table, Row } from 'reactstrap';
import reviewSkills from '../../../../images/screens/review-skills.jpg';

export default function SkillStatus() {
  return (
    <Container className="padding-top-25 animated fadeIn" id="skill-status">
      <Row>
        <Col sm="12">
          <h1 className="blueGrey">Skill Status</h1>
          <p>
            The learning stage determines how to best train a stroke. As described [here], the brain
            goes through several learning stages. The stages are determined by the neuro-biological,
            and therefore, apply to every player.
          </p>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm="6">
          <p>
            CueMate’s Skill Status automatically categorizes the strokes in your repertoire
            according to the three stages of skill acquisition. It also uses gaps in the repertoire
            to identify the strokes to form.
          </p>
          <br />
          <Table size="sm" striped>
            <tbody>
              <tr>
                <th scope="row">Strokes to Form</th>
                <td>
                  Produce basic outcomes (impact success rate, pace and spin) under the range of
                  conditions to be start performing the game or activity.
                </td>
              </tr>
              <tr>
                <th scope="row">Strokes to Consolidate</th>
                <td>
                  Improve the stroke technique to increase the outcomes and consolidate in memory
                  (make the stroke performance automatic)
                </td>
              </tr>
              <tr>
                <th scope="row">Strokes to Optimize</th>
                <td>
                  Perfect the stroke technique to achieve even better outcomes under changing
                  conditions. Also, achieve the outcomes more efficiently to minimize effort and
                  injury.{' '}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col sm="6">
          <img
            src={reviewSkills}
            className="d-block mx-auto img-fluid img-border"
            alt="Review: Skills screen"
          />
        </Col>
      </Row>
    </Container>
  );
}

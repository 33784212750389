/* eslint no-undef: "error" */
/* eslint-env browser */
import React, { cloneElement } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAppContext } from '../libs/contextLib';

function querystring(name, url = window.location.href) {
  const parsedName = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${parsedName}(=([^&#]*)|&|#|$)`, 'i');
  const results = regex.exec(url);

  if (!results || !results[2]) {
    return false;
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function shouldRedirect(url = window.location.href) {
  const listToSkip = ['download-app', 'privacy'];
  for (let i = 0; i < listToSkip.length; i += 1) {
    if (url.includes(listToSkip[i])) {
      return false;
    }
  }
  return true;
}

export default function UnauthenticatedRoute(props) {
  const { isAuthenticated } = useAppContext();
  const { children } = props;
  const redirect = querystring('redirect');

  if (isAuthenticated) {
    if (shouldRedirect()) {
      return <Navigate to={redirect || '/dashboard'} />;
    }
  }

  return cloneElement(children, props);
}

UnauthenticatedRoute.propTypes = {
  children: PropTypes.any,
};

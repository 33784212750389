import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import allcourt from '../../../../images/concepts/allcourt.png';
import allcourtsm from '../../../../images/concepts/allcourt-sm.png';
import aggressivebaseliner from '../../../../images/concepts/aggressivebaseliner.png';
import aggressivebaselinersm from '../../../../images/concepts/aggressivebaseliner-SM.png';
import counterpuncher from '../../../../images/concepts/counterpuncher.png';
import counterpunchersm from '../../../../images/concepts/counterpuncher-SM.png';

import netrusher from '../../../../images/concepts/netrusher.png';
import netrushersm from '../../../../images/concepts/netrusher-SM.png';

function Title() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm="12">
          <h1>Skill Rating and Game Styles</h1>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <p>
            As described in detail in the background section, learning a skill like tennis entails
            the acquisition of a repertoire of movement elements, such as strokes in tennis.
          </p>
          <p>
            Your stroke repertoire—and more specifically the distribution of outcomes and learning
            stage--are determined by your skill level and game style.
          </p>
          <p>
            CueMate’s repertoire concept makes it possible to determine your skill level and game
            style based on the repertoire characteristics, such as the distribution of strokes
            across the repertoire classes.
          </p>
          <p>
            The skill rating, in turn, is also used to automatically generate training goals to get
            you on a path that will improve your skills{' '}
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={12}>
          <h2 className="blueGrey">Skill Rating</h2>
          <p>
            Novice and beginners will tend to have lower intensity strokes and more flats and top
            spin. As a player gains experience, he or she learns to produce top spin and this, in
            turn, enables more intensity and higher pace.
          </p>
          <p>
            CueMate uses the repertoire composition and learning stage of each stroke class to
            determine a rating level. The rating level has five levels: novice, beginner,
            intermediate, advances, and expert.{' '}
          </p>
          <p>
            The rating system is used to guide training by determining training goals that promote
            higher rating levels.{' '}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function GameStyles() {
  return (
    <Container fluid className="light-grey-bg">
      <Container>
        <Row className="padding-top-15 padding-bottom-25">
          <Col className="col-sm-12">
            <Row className="padding-top-15">
              <Col sm="12">
                <h2 className="blueGrey">Game Styles</h2>
              </Col>
            </Row>
            <p>
              The stroke repertoire also reflects game styles, therefore, CueMate can distinguish
              the games styles of players as part of their profile.
            </p>
            <p>The repertoire also reflects game style, such as:</p>
            <ul>
              <li>
                <Link smooth to="/about/concepts/game-types#all-court">
                  All Court Player
                </Link>
              </li>
              <li>
                <Link smooth to="/about/concepts/game-types#aggressive-baseliner">
                  Aggressive Baseliner
                </Link>
              </li>
              <li>
                <Link smooth to="/about/concepts/game-types#counter-puncher">
                  Counter Puncher
                </Link>
              </li>
              <li>
                <Link smooth to="/about/concepts/game-types#net-rusher">
                  Net Rusher
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function AllCourtPlayer() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row id="all-court">
        <Col sm="8">
          <h3>All Court Player</h3>
          <p>
            {' '}
            Uses widest variety of stroke types on the stroke map. Goal is to break opponent’s
            rhythm using creativity with stroke type selection.{' '}
          </p>
        </Col>
        <Col sm="4"> </Col>
      </Row>
      <Row className="padding-top-50 padding-bottom-50">
        <Col sm="7">
          <img src={allcourt} className="d-block mx-auto img-fluid" alt="All court game type" />
        </Col>
        <Col sm="5">
          <img src={allcourtsm} className="d-block mx-auto img-fluid" alt="All court sm" />
        </Col>
      </Row>
    </Container>
  );
}

function AggressiveBaseliner() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row id="aggressive-baseliner">
          <Col sm="8">
            <h3>Aggressive Baseliner</h3>
            <p>
              {' '}
              More likely to use pace over spin to control shots, and a narrower variety of stroke
              types. Goal is to put opponents under pressure and throw them off balance.{' '}
            </p>
          </Col>
          <Col sm="4" />
        </Row>
        <Row>
          <Col sm="7">
            <img
              src={aggressivebaseliner}
              className="d-block mx-auto img-fluid"
              alt="Aggressive baseliner game type"
            />
          </Col>
          <Col sm="5">
            <img
              src={aggressivebaselinersm}
              className="d-block mx-auto img-fluid"
              alt="Aggressive baseliner sm game type"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function CounterPuncher() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row id="counter-puncher">
        <Col sm="8">
          <h3>Counter Puncher</h3>
          <p>
            {' '}
            More likely to use spin over pace to control shots, and a narrower variety of stroke
            types. Goal is to get the ball back in play and force opponent errors by making them
            impatient.{' '}
          </p>
        </Col>
        <Col sm="4" />
      </Row>
      <Row>
        <Col sm="7">
          <img
            src={counterpuncher}
            className="d-block mx-auto img-fluid"
            alt="Counter puncher game type"
          />
        </Col>
        <Col sm="5">
          <img
            src={counterpunchersm}
            className="d-block mx-auto img-fluid"
            alt="Counter puncher sm game type"
          />
        </Col>
      </Row>
    </Container>
  );
}

function NetRusher() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row id="net-rusher">
          <Col sm="8">
            <h3>Net Rusher</h3>
            <p>
              {' '}
              Uses hard and light pace stroke types on the stroke map, and not likely to use medium
              pace strokes. Goal is to find next best opportunities to approach the net and execute
              points.{' '}
            </p>
          </Col>
          <Col sm="4" />
        </Row>
        <Row className="padding-bottom-25">
          <Col sm="7">
            <img src={netrusher} className="d-block mx-auto img-fluid" alt="Net rusher game type" />
          </Col>
          <Col sm="5">
            <img
              src={netrushersm}
              className="d-block mx-auto img-fluid"
              alt="Net rusher sm game type"
            />
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </Container>
  );
}

export default function GameTypes() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Title />
      <GameStyles />
      <AllCourtPlayer />
      <AggressiveBaseliner />
      <CounterPuncher />
      <NetRusher />
    </>
  );
}

/* eslint-env browser */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Alert, Table, Button, Container } from 'reactstrap';
import * as d3 from 'd3';
import { cuemateGET, cuemateDELETE } from '../../../../libs/dataAccess';
import AddRacket from './AddRacket';
import AddStrings from './AddStrings';
import onError from '../../../../libs/errorLib';

const readUserRackets = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const response = await cuemateGET('/users/gear/rackets', currentSession.accessToken.jwtToken);
    const { data } = response || [];
    return data;
  } catch (error) {
    onError(error, true);
  }
  return [];
};

const deleteUserRacket = async (racketId) => {
  let response = {};
  try {
    const currentSession = await Auth.currentSession();
    response = await cuemateDELETE(
      `/users/gear/rackets/${racketId}`,
      currentSession.accessToken.jwtToken
    );
  } catch (error) {
    onError(error, true);
  }
  return response;
};

export default function UserGear() {
  const [userRackets, setUserRackets] = useState([]);
  const [racketAdded, setRacketAdded] = useState(0);
  const [stringsAdded, setStringsAdded] = useState(0);

  useEffect(() => {
    async function onLoad() {
      try {
        const userRacketsResponse = await readUserRackets();
        setUserRackets(userRacketsResponse);
      } catch (error) {
        onError(error, true);
      }
    }

    onLoad();
  }, [racketAdded, stringsAdded]);

  const handleRacketDelete = async (event, racketId) => {
    event.preventDefault();
    try {
      const response = await deleteUserRacket(racketId);
      if ('message' in response && response.message === 'racket deleted') {
        setRacketAdded(racketAdded - 1);
      }
    } catch (error) {
      onError(error, true);
    }
  };

  function renderUserRacketsTable(rackets) {
    if (rackets.length > 0) {
      rackets.sort((a, b) => b.created - a.created);
      return (
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Racket</th>
              <th>Strings</th>
              <th>Added On</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {rackets.map((racket, i) => (
              <tr key={racket.id}>
                <td>{`${i + 1}`}</td>
                <td>{`${racket.brand} ${racket.model || ''}`}</td>
                <td>
                  {racket.strings ? (
                    racket.strings
                      .sort((a, b) => {
                        if (a.strungDate < b.strungDate) {
                          return 1;
                        }
                        if (a.strungDate > b.strungDate) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((string) => {
                        const { isHybrid, mains = {}, crosses = {} } = string;
                        const { brand: mainsBrand = '', model: mainsModel = '' } = mains;
                        const { brand: crossesBrand = '', model: crossesModel = '' } = crosses;
                        return (
                          <li key={string.id}>
                            {isHybrid
                              ? `${mainsBrand} ${mainsModel} / ${crossesBrand} ${crossesModel}`
                              : `${mainsBrand} ${mainsModel}`}
                          </li>
                        );
                      })
                  ) : (
                    <AddStrings
                      racketId={racket.id}
                      stringsAdded={stringsAdded}
                      onStringsAdded={setStringsAdded}
                    />
                  )}
                </td>
                <td>
                  {d3.timeFormat('%Y-%m-%dT%H:%M:%S.%LZ')(new Date(racket.created)).slice(0, 10)}
                </td>
                <td>
                  <Button size="sm" outline color="warning">
                    <i className="fa far fa-edit fa-fw" aria-hidden="true" />
                  </Button>{' '}
                  <Button
                    size="sm"
                    outline
                    onClick={(e) => handleRacketDelete(e, racket.id)}
                    color="danger"
                  >
                    <i className="fa far fa-trash fa-fw" aria-hidden="true" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }

    return null;
  }

  return (
    <Container className="animated fadeIn">
      <h2 className="blue">My Gear</h2>
      {userRackets.length === 0 ? (
        <Alert color="warning">You have not added any rackets!</Alert>
      ) : (
        renderUserRacketsTable(userRackets)
      )}
      <AddRacket racketAdded={racketAdded} onRacketAdded={setRacketAdded} />
    </Container>
  );
}

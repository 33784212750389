import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// CSS
import '../Features.css';

export default function RepertoireMetrics() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm={12}>
          <h1>Repertoire Metrics</h1>
          <h3>Spin Differentiation</h3>
          <p>(in development)</p>

          <h3>Intensity Levels</h3>
          <p>(in development)</p>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import mviews1 from '../../../../images/cuemate101/level3/key-concepts/metric-views/mviews-1.png';
import mviews2 from '../../../../images/cuemate101/level3/key-concepts/metric-views/mviews-2.png';
import mviews3 from '../../../../images/cuemate101/level3/key-concepts/metric-views/mviews-3.png';

import '../AppFeatures.css';

export default function MetricViews() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h2>Metric Views</h2>
          <p>
            CueMate™ stores individual stroke metrics for each Stroke Class. From any Stroke Map
            view, you can tap on a Stroke Class to see detailed performance metrics.
          </p>
          <p>
            The first screen is the <span className="blue">Metrics Overview</span> for that stroke.
            This composite diagram compares your stroke performance (red) to other players (blue).
          </p>
        </Col>
        <Col sm={6}>
          <img src={mviews1} className="d-block mx-auto img-fluid img-border" alt="Metric Views" />
        </Col>
      </Row>

      <h3 className="padding-top-25 blue">METRIC SCALES</h3>
      <Row>
        <Col sm={8}>
          <img src={mviews2} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
        <Col sm={4}>
          <p>View your performance on a scale based on average player data.</p>
          <p>
            The black dot represents your average for that selected time fame (previous session or
            multiple sessions). The error bars indicate the variability of the metric.
          </p>
          <p>
            <span className="blue">Coefficients</span> are excellent indicators for optimizing
            stroke outcomes.
          </p>
          <p>
            <span className="bold">Spin cE:</span> how much Spin you generate for every Joule of
            energy you exert.
          </p>
          <p>
            <span className="bold">Pace cE:</span> how much Spin you generate for every Joule of
            energy you exert.
          </p>
          <p>
            <span className="bold">Spin Pace cE:</span> how well you generate high spin and high
            pace simultaneously.
          </p>
        </Col>
      </Row>
      <br />

      <h3 className="padding-top-25 blue">METRIC GRAPHS</h3>
      <Row>
        <Col sm={8}>
          <img src={mviews3} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
        <Col sm={4}>
          Use the graphs to view your overall performance trends, as well as performance during
          single sets to evaluate effectiveness of drills and cueing.
          <br />
          <br />
          This is where you can add a <span className="blue">Training Goal</span>, and it will be
          represented by a blue line on the graph. (Red lines display the average performance for
          each Set or Session.)
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/">
            <h3>
              Back to: CueMate 101 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumbs';

import evolutionaryTree from '../../../images/concepts/evolutionary-tree.png';
import repertoireEvolution from '../../../images/concepts/repertoire-evolution.png';
import './Background.css';

function Title() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>CueMate&apos;s Learning Model</h1>
          <h4 className="padding-top-10">
            CueMate is the first movement training technology that accounts for the brain’s learning
            process. Learning tennis skills involves the acquisition of a repertoire of strokes and
            other movements needed to support the tennis game.
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

function LearningProcess() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h1 className="blueGrey">Learning Process</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="padding-top-10">
            <p>
              The stroke classes forming the repertoire are acquired through a gradual process shown
              as a form of evolutionary tree bellow for forehand groundstrokes. Most players will
              start with a couple of basic strokes. These are typically forehand slice or flat low
              intensity strokes. Over time, players learn to produce different spin types such as
              top spin, higher intensity, and broader impact conditions.
            </p>
            <p>
              New strokes are acquired either by learning a technique from scratch, or through
              modifying an existing stroke (so called stroke class differentiation), i.e., strokes
              undergo a type of evolution which is illustrated in the tree as new branches.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={7}>
            <img
              src={evolutionaryTree}
              className="d-block mx-auto img-fluid img-border"
              alt="Evolutonary Tree"
            />
          </Col>
        </Row>

        <Row className="padding-top-25">
          <Col sm={12}>
            <ul className="with-spacing">
              <li>Novice players typically start with flat and slice strokes.</li>
              <li>
                As they gain experience they acquire top spin, however, typically only at low
                intensity.
              </li>
              <li>
                Notice that each stroke class goes through the learning stages (formation,
                consolidation, optimization explained next).
              </li>
              <li>
                This means that a new stroke class will take repetition and some validation to get
                acquired at a stage where it can be performed automatically and reliably.
              </li>
            </ul>
            <p>
              As a result of this process, a player will require and be exposed to conditions that
              stimulate the formation of new stroke classes with higher intensity and spin type.
            </p>
            <p>
              As you can imagine, this explains why it can take many years for dedicated training to
              become proficient in tennis and other sports or activities involving movements in
              complex and dynamic environments.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function LearningStages() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1 className="blueGrey">Learning Stages</h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="padding-top-10">
          <p>
            The concept of learning stages is fundamental to biological learning. When we learn a
            new movement, the brain has to grow new neural networks, as well as muscle strength and
            coordination. This is an important contrast with machines that can be programmed.
          </p>
          <p>
            As a result, you cannot learn a new movement and become proficient in just a few days.
            Researchers have defined learning stages to describe how the brain and body progress. A
            common model is three primary learning stages:
          </p>
          <ul className="with-spacing">
            <li>
              <b>Formation</b>: the first phase during which the brain is literally forming the
              neural networks that control the muscles into a new movement pattern.
            </li>
            <li>
              <b>Consolidation</b>: refers to the consolidation in long-term memory. At this stage
              is your brain is consolidating the neural networks that control your muscles and you
              are also acquiring the necessary muscles strengths and coordination.{' '}
            </li>
            <li>
              <b>Optimization</b>: in this final phase, the neural circuits and muscle are capable
              enough and reliable enough that the movement can be fine-tuned to enhance its
              efficiency. For example, using a stroke technique that can achieve high levels of top
              spin without excessive force. During this phase the brain makes more detailed
              connections and fine tune its weights.
            </li>
          </ul>
          <p>
            The learning stage manifests in various aspects of the movement performance. For
            example,
          </p>
          <ul className="with-spacing">
            <li>
              Formation, the movements will tend to be more variable with inconsistencies even at
              the basic level such as striking the ball on the racket sweet spot.
            </li>
            <li>
              In consolidation, the movements will become more consistent, and you will be able to
              start to focus on the quality of their outcomes.
            </li>
            <li>
              In optimization, the movements are highly automated, and you will start to focus on
              achieving the highest level of movement efficiency and outcomes. The brain achieves
              this by learning to control the environment under which the stroke types or performed
              to reduces the source of external variation (such as strike zone) in the performance
            </li>
          </ul>
          <p>
            CueMate uses these movement characteristics to determine the learning stage of each
            stroke class.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function RepertoireRepresentation() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h1 className="blueGrey">Repertoire Progression</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="padding-top-10">
            <p>
              The learning process also implies that the stroke map will have patterns in the
              distribution of strokes and their learning stage (shown in different grays below),
              which can be used for skill assessment. The illustration below shows the learning
              stage of the strokes across the repertoire following an idealized acquisition sequence
              given by the tree.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={7}>
            <img
              src={repertoireEvolution}
              className="d-block mx-auto img-fluid img-border"
              alt="Repertoire Evoluton"
            />
          </Col>
        </Row>
        <Row className="padding-top-25">
          <Col sm={12}>
            <p>
              Note that the progression shown above is idealized and used here for illustration.
              Players need different amounts of time to acquire stroke classes and will emphasize
              different stroke classes depending on their level, styles of play, and type of
              training.
            </p>
            <p>For example:</p>
            <ul className="with-spacing">
              <li>
                Advanced players have a large repertoire of strokes allowing them to produce the
                desired outcomes needed to control the ball under a broad range of conditions.
              </li>
              <li>
                A defensive player will typically not acquire high-intensity top spin stroke
                (see&nbsp;
                <Link to="/key-concepts/skill-rating">game styles</Link>).
              </li>
            </ul>
            <p>
              This stroke map model, accounting the learning stages, can be used to assess a variety
              of player types and skill levels. The stroke repertoire distribution forms the basis
              for CueMate’s proprietary algorithms to rates skills and play type.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function LearningModel() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>

      <Title />
      <LearningProcess />
      <LearningStages />
      <RepertoireRepresentation />

      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

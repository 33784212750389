import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// import images
import playmappopulated from '../../../../images/screens/Play-StrokeMap.PNG';
import playList from '../../../../images/screens/Play-ListofStrokes.PNG';
import strokePattern from '../../../../images/concepts/stroke_pattern_repertoire_categories.png';
import strokePatternSpinClass from '../../../../images/concepts/stroke-pattern-spin-class.png';
import strokePatternIntensityClass from '../../../../images/concepts/stroke-pattern-intensity-class.png';
import strokeMapTrain from '../../../../images/concepts/stroke-map-train.png';

import './Concepts.css';

function StrokeMapTitle() {
  return (
    <Container>
      <Row>
        {' '}
        <Col sm="12">
          <h1>Stroke Map</h1>
          <h3>
            The stroke repertoire defines you as a player. It is the stroke vocabulary that your
            brain has learned and can use for games.
          </h3>
        </Col>{' '}
      </Row>

      <Row className="padding-25">
        <Col sm="6">
          <h2 className="blueGrey">Stroke Map Overview</h2>
          <p className="margin-top-25">
            CueMate tracks and organizes your repertoire of groundstrokes onto a 3x6 table called
            the <span className="bold">Stroke Map</span>.
          </p>
        </Col>
        <Col sm="6">
          <img
            src={playmappopulated}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Map"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <p>
            Each cell on the map represents a specific stroke class (example: Forehand Topspin
            Medium)
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <p>
            There are three primary Stroke Map views – Ground strokes, Volleys, and Serves
            (Overheads are currently captured under Serves).{' '}
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 3, size: 6 }}>
          <img
            src={strokePattern}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Pattern"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <p>
            The basic stroke map for ground stroke and volleys has 18 classes (3x3 matrix for each
            forehand and backhand):
          </p>
          <ul className="with-spacing">
            <li>Three spin types: top spin, flat, and slice</li>
            <li>Three levels of intensity: low, medium, and high</li>
          </ul>
          <p>The serve stroke map has 9 classes (3x3 matrix):</p>
          <ul className="with-spacing">
            <li>Three spin types: slice, flat, and quick</li>
            <li>Three levels of intensity: low, medium, and high</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <img
            src={strokePatternSpinClass}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Classes Spin Class"
          />
        </Col>
        <Col sm="6">
          <img
            src={strokePatternIntensityClass}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Classes Intensity Class"
          />
        </Col>
      </Row>
    </Container>
  );
}

function UsingTheStrokeMap() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row>
          <Col sm="6">
            <h2 className="blueGrey">Using the Stroke Map</h2>
            <p className="margin-top-25">
              The Stroke Map helps identify gaps and highlight how your stroke outcomes and other
              metrics vary across the repertoire.
            </p>
            <ul className="with-spacing">
              <li>Check which strokes you use the most.</li>
              <li>
                Check for critical gaps in the stroke map, e.g., do you have high-intensity top spin
                strokes?
              </li>
              <li>Use the Stroke Map to navigate to each stroke’s detailed metric assessment. </li>
            </ul>
          </Col>
          <Col sm="6" className="d-flex align-items-center">
            <img
              src={playList}
              className="d-block mx-auto img-fluid img-border"
              alt="Playmap metrics"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function StrokeMapContents() {
  return (
    <Container>
      <Row className="padding-top-25">
        <Col sm="12">
          <h3>Core Strokes</h3>
          <p>
            The top three strokes are the Core Strokes. These define your game and performance,
            therefore, you will have the biggest impact on your game by defining and improving these
            strokes.
          </p>
          <p>
            To These define your game and performance, therefore, yYou will have the biggest impact
            on your game by defining and improving these strokes.
          </p>

          <h3 className="padding-top-15">
            Use the Stroke Map to Determine Gaps in Your Stroke Repertoire{' '}
          </h3>
          <p>
            The best tennis players have a broad repertoire of strokes and can respond to any shot
            (strike conditions). Use CueMate to identify gaps in your repertoire, or vocabulary, of
            strokes. You can become a more versatile player by challenging yourself to form a new
            stroke class or consolidate an existing stroke to make it a Core Stroke.
          </p>

          <h3 className="padding-top-15">Stroke Map Trend Indicators</h3>
          <p>
            As you play, the Stroke Map populates based on the stroke counts you are using. The cell
            color highlights provide a general overview of shot quality for a given stroke.
          </p>
          <p>
            The trend indicators are based on the selected review criteria (stroke count, spin,
            speed, sweet point percentage, stroke quality score).
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 3, size: 6 }}>
          <img
            src={strokeMapTrain}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Map Train"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="padding-top-15">Use the Stroke Map to Navigate to a Stroke Class </h3>
          <p>
            You can learn more about each shot’s outcomes — as well as stroke performance and
            technique — by tapping on a stroke type. This takes you to the Class Report Screen,
            where you can analyze stroke and shot metrics in detail to determine which aspect(s) of
            your stroke should be improved.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default function StrokeMap() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <StrokeMapTitle />
      <UsingTheStrokeMap />
      <StrokeMapContents />
      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

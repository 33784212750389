import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import evolutionaryTree from '../../../../images/concepts/evolutionary-tree.png';
import repertoireEvolution from '../../../../images/concepts/repertoire-evolution.png';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm="12">
          {' '}
          <h2>Learning Model</h2>{' '}
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm="12">
          <p>
            CueMate’s skill rating is the first that formalizes skill assessment. The patented
            approach is based on how humans acquire their stroke repertoire. New strokes are
            acquired either by learning the technique from scratch, or through modifying an existing
            stroke.
          </p>
          <p>This process can be described as a tree shown here for forehand groundstrokes:</p>
        </Col>
      </Row>

      <Row className="padding-top-25 justify-content-center">
        <Col sm="6">
          <img
            src={evolutionaryTree}
            className="d-block mx-auto img-fluid img-border"
            alt="Evolutionary Tree"
          />{' '}
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="12">
          <p>
            <ul>
              <li>Novice players typically start with flat and slice strokes.</li>
              <li>
                As they gain experience they acquire top spin, however, typically only at low
                intensity. Notice that each stroke class goes through the learning stages
                (formation, consolidation, optimization).
              </li>
              <li>
                This means that a new stroke class will take repetition and some validation to get
                acquired at a stage where it can be performed automatically and reliably.
              </li>
            </ul>
          </p>

          <p>
            As a result of this process, a player will require and exposed to conditions that
            stimulate the formation of new stroke classes with higher intensity and spin type.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function RepertoireEvolution() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm="12">
            <p>
              This process also implies that the stroke map will have patterns in the distribution
              of strokes and their learning stage, which can be used for skill assessment. The
              illustration bellow shows the learning stage of the strokes across the repertoire
              following the acquisition sequence given by the tree.
            </p>
          </Col>
        </Row>
        <Row className="padding-top-25 justify-content-center">
          <Col sm="6">
            <img
              src={repertoireEvolution}
              className="d-block mx-auto img-fluid img-border"
              alt="Repertoire Evolution"
            />{' '}
          </Col>
        </Row>

        <Row className="padding-top-25">
          <Col>
            <p>
              Note that the progression shown above is idealized and used here for illustration.
              Players will take different amounts of time to acquire stroke classes and will
              emphasize different stroke classes. For example, a defensive player will not acquire
              high-intensity top spin stroke (see the game type). This model can capture a very wide
              variety of player types and skill levels . The characteristics in stroke distribution
              and learning stage forms the basis for how CueMate assesses the players’ skills.
            </p>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </Container>
  );
}

export default function LearningModel() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <RepertoireEvolution />
    </>
  );
}

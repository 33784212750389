import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';
import { cuematePOST, cuematePUT } from '../../../../../libs/dataAccess';
import LoaderButton from '../../../../../components/LoaderButton';
import './CVCarouselFeedback.css';

const CVFeedbackType = {
  CLASSIFICATION: 'classification',
  FRAME_DETECTION: 'frameDetection',
  OTHERS: 'others',
};

function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

function CVCarouselFeedback({ strokeId, values }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [classificationFeedback, setClassificationFeedback] = useState({});
  const [impactFrameFeedback, setImpactFrameFeedback] = useState({});
  const [otherFeedback, setOtherFeedback] = useState({});
  const [showSubmissionText, setShowSubmissionText] = useState(false);

  useEffect(() => {
    values.forEach((value) => {
      const { type, comment } = value;
      switch (type) {
        case CVFeedbackType.CLASSIFICATION:
          setClassificationFeedback((prevValue) => ({
            ...value,
            ...prevValue,
            type: CVFeedbackType.CLASSIFICATION,
            strokeId,
            comment,
          }));
          break;
        case CVFeedbackType.FRAME_DETECTION:
          setImpactFrameFeedback((prevValue) => ({
            ...value,
            ...prevValue,
            type: CVFeedbackType.FRAME_DETECTION,
            strokeId,
            comment,
          }));
          break;
        case CVFeedbackType.OTHERS:
          setOtherFeedback((prevValue) => ({
            ...value,
            ...prevValue,
            type: CVFeedbackType.OTHERS,
            strokeId,
            comment,
          }));
          break;
        default:
          break;
      }
    });

    setShowSubmissionText(false);
  }, [values]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const currentSession = await Auth.currentSession();
      const authToken = currentSession.accessToken.jwtToken;
      const promises = [];
      if (
        !isObjectEmpty(classificationFeedback) &&
        classificationFeedback.id === undefined &&
        classificationFeedback.comment.length > 0
      ) {
        promises.push(cuematePOST('/cv/feedback', authToken, classificationFeedback));
      } else if (
        !isObjectEmpty(classificationFeedback) &&
        classificationFeedback.id !== undefined
      ) {
        promises.push(
          cuematePUT(`/cv/feedback/${classificationFeedback.id}`, authToken, {
            comment: classificationFeedback.comment,
          })
        );
      }

      if (
        !isObjectEmpty(impactFrameFeedback) &&
        impactFrameFeedback.id === undefined &&
        impactFrameFeedback.comment.length > 0
      ) {
        promises.push(cuematePOST('/cv/feedback', authToken, impactFrameFeedback));
      } else if (!isObjectEmpty(impactFrameFeedback) && impactFrameFeedback.id !== undefined) {
        promises.push(
          cuematePUT(`/cv/feedback/${impactFrameFeedback.id}`, authToken, {
            comment: impactFrameFeedback.comment,
          })
        );
      }

      if (
        !isObjectEmpty(otherFeedback) &&
        otherFeedback.id === undefined &&
        otherFeedback.comment.length > 0
      ) {
        promises.push(cuematePOST('/cv/feedback', authToken, otherFeedback));
      } else if (!isObjectEmpty(otherFeedback) && otherFeedback.id !== undefined) {
        promises.push(
          cuematePUT(`/cv/feedback/${otherFeedback.id}`, authToken, {
            comment: otherFeedback.comment,
          })
        );
      }

      const results = await Promise.allSettled(promises);
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          if (result.value.type === CVFeedbackType.CLASSIFICATION) {
            setClassificationFeedback(result.value);
          } else if (result.value.type === CVFeedbackType.FRAME_DETECTION) {
            setImpactFrameFeedback(result.value);
          } else if (result.value.type === CVFeedbackType.OTHERS) {
            setOtherFeedback(result.value);
          }
        } else {
          console.error(result.reason);
        }
      });
      setIsSubmitting(false);
      setShowSubmissionText(true);
    } catch (error) {
      console.error(error);
    }
  };

  const shouldDisableSubmit = () => {
    let disableSubmit = true;
    values.forEach((value) => {
      const { comment } = value;
      if (comment !== undefined && comment.trim().length > 0) {
        disableSubmit = false;
      }
    });
    return disableSubmit;
  };

  return (
    <>
      <LoaderButton
        text="Submit"
        color="primary"
        isLoading={isSubmitting}
        onClick={handleSubmit}
        disabled={shouldDisableSubmit()}
        block
      />
      {showSubmissionText && <FormText>Feedback submitted successfully</FormText>}
    </>
  );
}

CVCarouselFeedback.propTypes = {
  strokeId: PropTypes.string,
  values: PropTypes.array,
};

export default CVCarouselFeedback;

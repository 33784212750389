import React, { Component, useState, useCallback, useEffect } from 'react';
import {
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Country, State, City } from 'country-state-city';
import Select from 'react-select';
import Pdf from './CueMate-Terms-and-Conditions.pdf';
import { cuematePUT } from '../../../libs/dataAccess';
import LoaderButton from '../../../components/LoaderButton';

const COUNTRIES = Country.getAllCountries().map((country) => ({
  label: country.name,
  value: country.isoCode,
  ...country,
}));

const DEFAULT_COUNTRY_NAME = 'United States';

const DELIVERY_METHODS = Object.freeze({
  HAS_SENSOR: 'I already have a CueMate sensor',
  STORE_PICKUP: 'Store Pickup',
  SHIPPING: 'Ship to Me',
});

const PASSWORD_OPTIONS = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 0,
  returnScore: false,
};

function TermsAndConditionsModal({ isOpen, onModalChange }) {
  const [modal, setModal] = useState(isOpen);

  useEffect(() => {
    setModal(isOpen);
  }, [isOpen]);

  const toggle = useCallback(
    (didAcceptTerms = false) => {
      const update = !modal;
      setModal(update);
      onModalChange(update, didAcceptTerms);
    },
    [isOpen, modal]
  );

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>Terms and Conditions</ModalHeader>
        <ModalBody>
          <embed src={Pdf} frameBorder="0" width="100%" height="100%" />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle(true)}>
            I Accept
          </Button>{' '}
          <Button color="secondary" onClick={() => toggle(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

TermsAndConditionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalChange: PropTypes.func,
};

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address1: '',
      address2: '',
      cognitoUser: props.cognitoUser,
      city: '',
      confirmPassword: '',
      country: COUNTRIES.find((country) => country.name === DEFAULT_COUNTRY_NAME),
      deliveryMethod: '',
      firstName: '',
      hasUserAgreed: false,
      isLoading: false,
      lastName: '',
      telephoneNumber: '',
      password: '',
      shouldRequestNewPassword: props.isNewPasswordRequired,
      shouldShowThankNote: false,
      state: '',
      zip: '',
      isModalOpen: false,
    };

    this.checkMatch = this.checkMatch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateAddress = this.validateAddress.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.onModalChange = this.onModalChange.bind(this);
  }

  componentDidMount() {
    const { cognitoUser } = this.props;
    const challengeParam = cognitoUser && cognitoUser.challengeParam;
    const userAttributes = challengeParam && challengeParam.userAttributes;

    if (userAttributes !== undefined) {
      const { name: firstName, family_name: lastName, phone_number: phoneNumber } = userAttributes;
      this.setState({
        firstName: firstName || '',
        lastName: lastName || '',
        telephoneNumber: phoneNumber || '',
      });
    }
  }

  handleChange(event) {
    if (event.target.id === 'hasUserAgreed') {
      this.setState({ hasUserAgreed: event.target.checked });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  }

  handleOptionChange(event) {
    const stateObject = {};
    stateObject[event.target.name] = event.target.value;
    if (event.target.value !== DELIVERY_METHODS.SHIPPING) {
      stateObject.address1 = '';
      stateObject.address2 = '';
      stateObject.city = '';
      stateObject.state = '';
      stateObject.zip = '';
    }
    this.setState(stateObject);
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });

    const { firstName, lastName, hasUserAgreed, deliveryMethod, telephoneNumber } = this.state;
    const attributes = {
      name: firstName,
      family_name: lastName,
      'custom:hasAcceptedAgreement': hasUserAgreed ? '1' : '0',
      'custom:hasCompletedSignup': '1',
      'custom:hasConfBeenLogged': '0',
      'custom:acceptedAgreemntOn': new Date().toString(),
      phone_number: telephoneNumber,
    };

    let userProfileObject = {
      properties: {
        deliveryMethod,
      },
    };
    if (deliveryMethod === DELIVERY_METHODS.SHIPPING) {
      const { address1, address2, city, country, state, zip } = this.state;
      const address = {
        street_address: address1,
        extended_address: address2,
        locality: city.name,
        region: state.name,
        postal_code: zip,
        country_name: country.name,
      };
      userProfileObject = { ...userProfileObject, ...address };
    }

    const zapierURL = `https://hook.us1.make.com/6cp5mdxxt6cudixktv1acrvpioafqwk7`;
    const email =
      ((((this.props || '').cognitoUser || '').challengeParam || '').userAttributes || '').email ||
      '';
    const body = {
      email,
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    };

    const { shouldRequestNewPassword, cognitoUser, password } = this.state;
    const { onSignupCompletion } = this.props;
    try {
      if (shouldRequestNewPassword) {
        await Auth.completeNewPassword(cognitoUser, password.trim(), attributes);
      } else {
        await Auth.updateUserAttributes(cognitoUser, attributes);
      }
      if (email !== '') {
        await fetch(zapierURL, requestOptions);
      }
      const currentSession = await Auth.currentSession();
      await cuematePUT('/userprofile', currentSession.accessToken.jwtToken, userProfileObject);
      await onSignupCompletion();
      this.setState({ shouldShowThankNote: true });
    } catch (e) {
      this.setState({ isLoading: false });
      // eslint-disable-next-line no-undef
      alert(e.message);
    }
  }

  onModalChange(update, didAccept = false) {
    this.setState({ isModalOpen: update, hasUserAgreed: didAccept });
  }

  validatePassword() {
    const { password, confirmPassword } = this.state;
    return isStrongPassword(password, PASSWORD_OPTIONS) && password === confirmPassword;
  }

  checkMatch(event) {
    const { password } = this.state;
    if (event.target.value !== password) {
      event.target.setCustomValidity('Password does not match the confirm password.');
    } else {
      // input is valid -- reset the error message
      event.target.setCustomValidity('');
    }
  }

  validateForm() {
    const {
      firstName,
      lastName,
      telephoneNumber,
      deliveryMethod,
      hasUserAgreed,
      shouldRequestNewPassword,
    } = this.state;

    return (
      firstName.length > 0 &&
      lastName.length > 0 &&
      (telephoneNumber && isPossiblePhoneNumber(telephoneNumber) ? 'true' : 'false') &&
      (deliveryMethod === DELIVERY_METHODS.SHIPPING ? this.validateAddress() : true) &&
      hasUserAgreed &&
      (shouldRequestNewPassword ? this.validatePassword() : true)
    );
  }

  validateAddress() {
    const { address1, city, country, state, zip } = this.state;
    return (
      address1.length > 0 &&
      typeof country === 'object' &&
      typeof state === 'object' &&
      typeof city === 'object' &&
      zip.length > 0
    );
  }

  renderThankNote() {
    const { firstName } = this.state;
    return (
      <Container className="padding-bottom-25 animated fadeIn">
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
            <h1>Thanks {firstName}!</h1>
            <hr className="colorgraph" />
            <h3 className="padding-top-25">Welcome to CueMate Tennis!</h3>
            <h4>
              You will now be redirected to the My Settings page where you can add important
              information about yourself.
            </h4>
          </Col>
        </Row>
      </Container>
    );
  }

  renderFullSignupForm() {
    const {
      address1,
      address2,
      city,
      confirmPassword,
      country,
      deliveryMethod,
      firstName,
      lastName,
      password,
      state,
      telephoneNumber,
      zip,
      isModalOpen,
    } = this.state;

    const isStrongPwd = password.length > 0 && isStrongPassword(password, PASSWORD_OPTIONS);
    const isMatchingPwd =
      password.length > 0 && confirmPassword.length > 0 && password === confirmPassword;

    let name;
    let familyName;
    let phoneNumber;
    const { cognitoUser } = this.props;
    const challengeParam = cognitoUser && cognitoUser.challengeParam;
    const userAttributes = challengeParam && challengeParam.userAttributes;
    if (userAttributes !== undefined) {
      name = userAttributes && userAttributes.name;
      familyName = userAttributes && userAttributes.family_name;
      phoneNumber = userAttributes && userAttributes.phone_number;
    }

    const firstNameInput = (
      <FormGroup>
        <Input
          type="text"
          name="firstName"
          id="firstName"
          className="form-control"
          placeholder="First Name"
          value={firstName}
          onChange={this.handleChange}
          autoFocus
          required
          disabled={typeof name !== 'undefined'}
        />
      </FormGroup>
    );

    const lastNameInput = (
      <FormGroup>
        <Input
          type="text"
          name="lastName"
          id="lastName"
          className="form-control"
          placeholder="Last Name"
          value={lastName}
          onChange={this.handleChange}
          required
          disabled={typeof familyName !== 'undefined'}
        />
      </FormGroup>
    );

    function getPhoneNumberError(phoneNo) {
      if (phoneNo === undefined) {
        return 'Phone number required';
      }
      if (!isPossiblePhoneNumber(phoneNo)) {
        return 'Invalid phone number';
      }
      return '';
    }

    const phoneNumberInput = (
      <FormGroup>
        <PhoneInput
          defaultCountry="US"
          readOnly={typeof phoneNumber !== 'undefined'}
          error={getPhoneNumberError(telephoneNumber)}
          international
          // eslint-disable-next-line no-shadow
          onChange={(telephoneNumber) => this.setState({ telephoneNumber })}
          placeholder="Enter phone number"
          type="tel"
          value={telephoneNumber}
          required
        />
      </FormGroup>
    );

    const deliveryMethodInput = (
      <FormGroup>
        <h3>Delivery Method</h3>
        <div>
          {Object.entries(DELIVERY_METHODS).map((item) => (
            <FormGroup check key={item[0]}>
              <Input
                type="radio"
                name="deliveryMethod"
                value={item[1]}
                onChange={this.handleOptionChange}
              />{' '}
              <Label check>{item[1]}</Label>
            </FormGroup>
          ))}
        </div>
      </FormGroup>
    );

    const updatedStates = (isoCode) =>
      // eslint-disable-next-line no-shadow
      State.getStatesOfCountry(isoCode).map((state) => ({
        label: state.name,
        value: state.isoCode,
        ...state,
      }));
    const updatedCities = (countryIsoCode, stateIsoCode) =>
      // eslint-disable-next-line no-shadow
      City.getCitiesOfState(countryIsoCode, stateIsoCode).map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }));

    const countryInput = (
      <FormGroup>
        <Label for="country">Country/region</Label>
        <Select
          id="country"
          label="country"
          name="country"
          options={COUNTRIES}
          value={country}
          // eslint-disable-next-line no-shadow
          onChange={(country) => this.setState({ country, state: {}, city: {} })}
          required
        />
      </FormGroup>
    );

    const addressInput1 = (
      <FormGroup>
        <Label for="address1">Address</Label>
        <Input
          id="address1"
          name="address1"
          onChange={this.handleChange}
          placeholder="1234 Main St"
          required
          value={address1}
        />
      </FormGroup>
    );

    const addressInput2 = (
      <FormGroup>
        <Label for="address2">Apartment, suite, etc. (optional)</Label>
        <Input
          id="address2"
          name="address2"
          onChange={this.handleChange}
          placeholder="Apt. 101"
          value={address2}
        />
      </FormGroup>
    );

    const cityInput = (
      <FormGroup>
        <Label for="city">City</Label>
        <Select
          id="city"
          name="city"
          // eslint-disable-next-line no-shadow
          onChange={(city) => this.setState({ city })}
          options={country && state ? updatedCities(country.value, state.value) : []}
          required
          value={city}
        />
      </FormGroup>
    );

    const stateInput = (
      <FormGroup>
        <Label for="state">State</Label>
        <Select
          id="state"
          name="state"
          // eslint-disable-next-line no-shadow
          onChange={(state) => {
            this.setState({ state, city: '' });
          }}
          options={country ? updatedStates(country.value) : []}
          required
          value={state}
        />
      </FormGroup>
    );

    const zipInput = (
      <FormGroup>
        <Label for="zip">ZIP code</Label>
        <Input id="zip" name="zip" onChange={this.handleChange} type="text" required value={zip} />
      </FormGroup>
    );

    const cityStateZipInput = (
      <Row>
        <Col md={4}>{cityInput}</Col>
        <Col md={4}>{stateInput}</Col>
        <Col md={4}>{zipInput}</Col>
      </Row>
    );
    const { shouldRequestNewPassword, isLoading } = this.state;
    return (
      <Col
        xs="12"
        sm={{ size: 8, offset: 2 }}
        md={{ size: 6, offset: 3 }}
        className="animated fadeIn"
      >
        <Form onSubmit={this.handleSubmit}>
          <h2>Complete Sign up</h2>
          <hr className="colorgraph" />
          <Row>
            <Col xs="12" sm="6" md="6">
              {firstNameInput}
            </Col>
            <Col xs="12" sm="6" md="6">
              {lastNameInput}
            </Col>
          </Row>
          {phoneNumberInput}
          <hr />

          {shouldRequestNewPassword ? (
            <>
              <h3>Change Password</h3>
              <Row>
                <Col xs="12" sm="6" md="6">
                  <FormGroup>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={this.handleChange}
                      minLength="8"
                      required
                      valid={isStrongPwd}
                      invalid={!isStrongPwd}
                    />
                    <FormFeedback valid>Sweet! that password is strong</FormFeedback>
                    {password.length > 0 && (
                      <FormFeedback invalid={+!isStrongPwd}>
                        Oh noes! That password is weak
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12" sm="6" md="6">
                  <FormGroup>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={this.handleChange}
                      onInput={this.checkMatch}
                      required
                      valid={isMatchingPwd}
                      invalid={!isMatchingPwd}
                    />
                    {confirmPassword.length > 0 && (
                      <FormFeedback valid>Sweet! that password matches</FormFeedback>
                    )}
                    {confirmPassword.length > 0 && (
                      <FormFeedback invalid={+!isMatchingPwd}>
                        Oh noes! That password does not match
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="12" md="12">
                  <FormText>
                    Your password must have: 8 or more characters, upper and lowercase letters and
                    at least one number
                  </FormText>
                </Col>
              </Row>
            </>
          ) : null}
          <hr />

          {deliveryMethodInput}

          {deliveryMethod === DELIVERY_METHODS.SHIPPING ? (
            <FormGroup>
              {countryInput}
              {addressInput1}
              {addressInput2}
              {cityStateZipInput}
            </FormGroup>
          ) : null}
          <hr />
          <Row>
            <Col xs="12" sm="12" md="12">
              Read and accept the CueMate&apos;s{' '}
              <Button
                className="px-0"
                color="link"
                onClick={() => this.onModalChange(!isModalOpen)}
              >
                Terms and Conditions
              </Button>{' '}
              to continue.
            </Col>
          </Row>
          <hr className="colorgraph" />

          <Row className="text-center">
            <Col xs="12" className="padding-bottom-25">
              <LoaderButton
                type="submit"
                text="Register"
                color="primary"
                size="lg"
                loadingText="Submitting..."
                isLoading={isLoading}
                disabled={!this.validateForm()}
                block
              />
            </Col>
          </Row>
        </Form>
      </Col>
    );
  }

  render() {
    const { shouldShowThankNote, isModalOpen } = this.state;
    return (
      <Container className="Signup padding-bottom-25 animated fadeIn">
        <TermsAndConditionsModal isOpen={isModalOpen} onModalChange={this.onModalChange} />
        <Row>{shouldShowThankNote ? this.renderThankNote() : this.renderFullSignupForm()}</Row>
      </Container>
    );
  }
}

Signup.propTypes = {
  isNewPasswordRequired: PropTypes.bool,
  cognitoUser: PropTypes.object,
  onSignupCompletion: PropTypes.func,
};

import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Row } from 'reactstrap';

function Level2Description() {
  return (
    <Row>
      <Col sm="12">
        Learn how to interpret your data
        <ul>
          <li>Review your stroke map</li>
          <li>Review your stroke classes</li>
          <li>Review your session report</li>
          <li>Review your overall skill and activity profile</li>
        </ul>
      </Col>
    </Row>
  );
}

function Level2KeyConcepts() {
  return (
    <Row>
      <Col>
        <b>Key Concepts</b>
        <ul>
          <li>
            <Link to="/key-concepts/reference-ranges">Reference Ranges</Link>
          </li>
          <li>
            <Link to="/key-concepts/stroke-class-scores">Stroke Class Scores</Link>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

function Level2Features() {
  return (
    <Row>
      <Col>
        Features
        <ul>
          <li>Session Reports</li>
          <li>Class Reports</li>
          <li>Session Review </li>
          <li>Player Profile</li>
        </ul>
      </Col>
    </Row>
  );
}

export { Level2Description, Level2KeyConcepts, Level2Features };

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import spinCue from '../../../../images/concepts/spin-cue.png';

function Title() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm="12">
          {' '}
          <h2>Feedback Cueing</h2>{' '}
          <h4>The brain learns faster and better with immediate feedback.</h4>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="12">
          <h2 className="grey">Why Cueing?</h2>
          <p>
            When you are learning new movements or training to improve an existing movement, your
            brain is easily overwhelmed by the amount of information it has to keep track with.
          </p>
          <p>
            {' '}
            A key information to make learning more efficient is knowing if what you are doing is
            right.
          </p>
          <p>The literature on movement learning distinguishes between two types of feedback:</p>
          <ul>
            <li>The knowledge of performance: did my movement use the right technique or form.</li>
            <li>
              The knowledge of result: did my movement achieve the right outcome, e.g., the amount
              of spin or speed, the shot quality, etc.
            </li>
          </ul>
          <p>CueMate provides this type of feedback using different cue forms and types.</p>
        </Col>
      </Row>
    </Container>
  );
}

function CueForms() {
  return (
    <Container fluid className="padding-top-25 padding-bottom-25 light-grey-bg">
      <Container>
        <Row>
          <Col sm="7">
            <h2 className="grey">Cue Forms</h2>
            <p>Cueing uses sound and verbal feedback generated during your stroke execution.</p>
            <ul>
              <li>
                Sound cues are based on tones that are designed to communicate information about
                your stroke performance in an intuitive way.
              </li>
              <li>
                Verbal cues use speech to communicate specific information about your stroke
                performance.
              </li>
            </ul>
            <p>
              Sound cues are based on five tones to indicate the magnitude from very low to high.
              The tones are based on the reference levels, they simply map the five levels to five
              tones as shown here for spin:
            </p>

            <p>
              Verbal cues are straight spoken words. For example, your speed “46 MPH” or spin
              outcomes “1870 RPM”.
            </p>
          </Col>
          <Col sm="5" className="padding-top-100">
            <img src={spinCue} className="d-block mx-auto img-fluid img-border" alt="Spin Cue" />{' '}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function CueTypes() {
  return (
    <Container>
      <Row className="padding-top-25">
        <Col sm="12">
          <h2 className="grey">Cue Types</h2>
          <p>The cueing features are organized in general cues, training cues, and instructions.</p>
          <h3>General Cues</h3>
          <p>
            General cues are designed to provide general feedback focusing on the stroke outcomes
            independent of the stroke class. You can select to get feedback on spin, speed, or sweet
            spot.
          </p>
          <p>General cues can be communicated both in verbal and sound forms.</p>

          <h3>Training Cues</h3>
          <p>
            Training cues are associated with training goals and therefore focus on specific aspects
            of the stroke technique.
          </p>
          <p>Training cues can be communicated both in verbal and sound forms.</p>

          <h3>Instructions</h3>
          <p>
            Future cueing will also include cue words to remind you of the critical aspects to focus
            on. The cue words will be attached to training goals.
          </p>
          <p>Instruction cues are communicated in verbal form.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default function FeedbackCueing() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Title />
      <CueForms />
      <CueTypes />

      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

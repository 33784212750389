import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import '../AppFeatures.css';

function StrokeMetricsContent() {
  return (
    <Container>
      <Row className="mb-4">
        <Col sm="12">
          {' '}
          <h1>Stroke Metrics</h1>
          <h3>
            CueMate generates metrics for a wide range of attributes characterizing a player’s
            strokes and how they use these in a game.
          </h3>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h3>What Determines the Quality of your Strokes and Shots?</h3>
          <p>
            Ultimately, the purpose of your strokes is to produce outcomes that create shots for the
            game. The quality of these outcomes is determined by many factors, including:
          </p>
          <ul className="with-spacing">
            <li>Anticipation of the incoming ball speed, spin, its ground impact.</li>
            <li>Anticipation of the incoming ball speed, spin, its ground impact.</li>
            <li>
              Movement and stroke preparation, including the positioning, and stance that influence
              the impact point and strike zone.{' '}
            </li>
            <li>
              Stroke technique, including the shape of your stroke, the acceleration of your racket.{' '}
            </li>
          </ul>
          <p>As you can see the stroke technique only represents one part of the whole process.</p>
          <p>
            CueMate computes metrics to evaluate your skills and performance. With a racket sensor
            not all of these variables can be precisely measured but with the right models it is
            possible to estimate a sufficient set of features to diagnose and train some fundamental
            aspects.
          </p>
          <p>
            Recall, that the strokes are organized into classes [link to Stroke Classes]. This makes
            it possible to use statistics, i.e., combine many strokes of the same type, to get a
            more precise picture of your skills for a particular class of strokes.
          </p>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm={12}>
          <h2 className="blueGrey">Metric Types</h2>
          <p>
            The metrics are combined under Class Scores that help with the assessment. Before
            describing those, lets first describe the scope of class metrics and the five categories
            used to organize them.{' '}
          </p>
          <h3>Stroke Primary Outcomes</h3>
          <p>The results produced by a stroke class in the repertoire:</p>
          <ul className="with-spacing">
            <li>Ball Speed</li>
            <li>Ball Spin</li>
          </ul>

          <h3>Strike Quality</h3>
          <p>The quality of your ball strike:</p>
          <ul className="with-spacing">
            <li>
              Sweet spot percentage: what fraction or your strikes are in the racket sweet spot.{' '}
            </li>
            <li>
              Impact Timing: the timing of the racket forward swing relative to the ball strike.{' '}
            </li>
            <li>Impact precision: the variability of your impact point on the racket. </li>
          </ul>
          <p>
            The quality of your ball strike is primarily influenced by your ability to anticipate
            the ball strike and your stroke preparation.
          </p>
          <h3>Stroke Technique</h3>
          <p>
            The stroke technique metrics measures attributes of the movement and racket used for a
            stroke class to produce primary outcomes and control the ball on the court. Example,
            include:
          </p>
          <ul className="with-spacing">
            <li>Smoothness: the continuity of the stroke movement</li>
            <li>Racket Head Speed</li>
            <li>Forward-swing Type: Racket</li>
            <li>Racket Drop (angle of attack)</li>
            <li>Racket face openness</li>
          </ul>
          <p>
            Metrics provide objective ways to assess the key features needed for good technique, and
            therefore, provide the foundation to deliberate training.
          </p>
          <p>Note that CueMate is continuously developing new metrics based on its research.</p>
          <h3>Stroke Physical Performance</h3>
          <p>The physical characteristics of the stroke, including:</p>
          <ul className="with-spacing">
            <li>Power</li>
            <li>Energy</li>
          </ul>
          <h3>Stroke Coefficients</h3>
          <p>
            Coefficients measure the efficiency of your strokes in producing outcomes. Including:
          </p>
          <ul className="with-spacing">
            <li>Spin coefficients measures the efficiency of your technique in producing spin. </li>
            <li>
              Speed coefficient measures the efficiency of your technique in imparting speed on the
              ball.
            </li>
            <li>Spin-speed coefficient measures the ability to produce both spin and speed.</li>
          </ul>
          <h4 className="padding-top-25">
            <Link to="/cuemate-ios/metrics">
              Learn More About Metrics <i className="fa fa-angle-right" />{' '}
            </Link>
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

export default function StrokeMetrics() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <StrokeMetricsContent />
      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

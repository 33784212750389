import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import sessionScore from '../../../../images/web-features/session-score.png';
import sessionOverview from '../../../../images/web-features/session-overview.png';
import strokeTypeNavbar from '../../../../images/web-features/stroke-type-navbar.png';
import strokeMapDigit from '../../../../images/web-features/stroke-map-digit.png';
import strokeMapNoDigit from '../../../../images/web-features/stroke-map-no-digit.png';
import classList from '../../../../images/web-features/class-list.png';
import cadencePlot from '../../../../images/web-features/cadence-plot.png';
import strokeActivity from '../../../../images/web-features/stroke-activity.png';
import impactDistribution from '../../../../images/web-features/impact-distribution.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import './WebFeatures.css';

export default function WebSessionReports() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Session Reports</h2>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Session Score</h2>
          <p>
            The session score is a numerical description of the quality of a session that takes into
            account the range of strokes employed by the player and their quality.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <img
            src={sessionScore}
            className="d-block mx-auto img-fluid img-border"
            alt="Session Score"
          />
          <figcaption className="figure-caption">Session Score</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Session Overview</h2>
          <p>
            The session overview presents the in-play duration, stroke count, impact success rate,
            and court location of the session.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={sessionOverview}
            className="d-block mx-auto img-fluid img-border"
            alt="Session Overview"
          />
          <figcaption className="figure-caption">Session Overview</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Type Navigation Bar</h2>
          <p>
            The stroke type navigation bar lists the stroke counts of the three stroke types
            (groundstroke, serve, and volley) in the session. The content below the navigation bar
            presents the statistics of the selected stroke type (e.g. groundstroke in this case).
            The stroke types with insufficient stroke count are disabled for selection.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={strokeTypeNavbar}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Type Navigation Bar"
          />
          <figcaption className="figure-caption">Stroke Type Navigation Bar</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Map</h2>
          <p>
            The Stroke Map provides the distribution of classes for the strokes of selected type in
            the session. The red dot emphasizes the concentration of session. The chart can switch
            between the view of digits and of circle size.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <img
            src={strokeMapDigit}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Map: Digit View"
          />
          <figcaption className="figure-caption">Stroke Map: Digit View</figcaption>
        </Col>
        <Col sm="6">
          <img
            src={strokeMapNoDigit}
            className="d-block mx-auto img-fluid img-border"
            alt="Stroke Map: Circle View"
          />
          <figcaption className="figure-caption">Stroke Map: Circle View</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Class List</h2>
          <p>
            This section provides statistics and trend for the five most frequently used stroke
            classes for the selected stroke type in the recorded session. Statistics included for
            each class are % of session, impact success rate, pace, and spin. The statistics of
            these classes in the session can be compared with previous session, previous 3 sessions,
            previous 100 strokes, and previous 200 strokes of the class.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img src={classList} className="d-block mx-auto img-fluid img-border" alt="Class List" />
          <figcaption className="figure-caption">Class List</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Cadence</h2>
          <p>
            Cadence plot shows the physical performance in terms of the average number of strokes
            per min.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={cadencePlot}
            className="d-block mx-auto img-fluid img-border"
            alt="Cadence Plot"
          />
          <figcaption className="figure-caption">Cadence Plot</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Session Trends</h2>
          <h3 className="blueGrey">Stroke Activity</h3>
          <p>
            Stroke Class Trend plot is presented as an event chart. It shows the frequency of stroke
            type used over the set/session.
          </p>
          <h3 className="blueGrey">Pace and Spin Trend</h3>
          <p>
            Pace and Spin Trends shows the spin and pace outcomes over the entire session. Pace is
            shown in blue, and spin is shown in red.
          </p>
          <h3 className="blueGrey">Pace Coeff. and Spin Coeff. Trend</h3>
          <h3 className="blueGrey">Impact Success Rate and Impact Variability Trend</h3>
          <p>
            The Impact Success Rate (ISR) Trend Plot on the bottom and Impact Variability on the
            bottom are measures of quality of your strokes. They only consider the stroke and impact
            characteristics and therefore don’t measure the quality of the shots. However, they
            represent necessary conditions for a good shot.
          </p>
          <p>
            The ISR measures if you are hitting the ball in the central area of the racket head.
            Doing so is necessary for proper control of the ball and power. A dashed line provides a
            reference for 85% success rate trend, which is a good target to maintain.
          </p>
          <p>
            The impact variability measures the variability of your impact location on the racket.
            It is a more sensitive measure of your impact consistency.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={strokeActivity}
            className="d-block mx-auto img-fluid img-border"
            alt="Session Trends"
          />
          <figcaption className="figure-caption">Session Trends</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Impact Distribution</h2>
          <p>
            This section presents the impact distribution of the strokes for the selected stroke
            type in the session by group.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={impactDistribution}
            className="d-block mx-auto img-fluid img-border"
            alt="Impact Distribution"
          />
          <figcaption className="figure-caption">Impact Distribution</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-web">
            <h3>
              Back to: CueMate for Web <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

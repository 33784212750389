import * as d3 from 'd3';
import { tennisUtilities } from 'cuemate-charts';

const { PERFORMANCE_COLORS, SKILL_STATUS, TREND_STATUS } = tennisUtilities;

export default function repertoire() {
  let blockSize = 80;
  let margin = {
    left: 30,
    right: 5,
    top: 60,
    bottom: 50,
    spacing: 10,
  };

  function chart(selection) {
    selection.each(function render(data) {
      // Parse data
      const {
        groups,
        groupValues,
        refValues,
        groupMeans,
        groupStds,
        groupSkillStatus,
        groupStats,
        toggleDigit,
        format,
        metricName,
      } = data;

      const fixed = data.fixed !== undefined ? data.fixed : true;

      // Chart spacing parameters
      const sectionSpacing = blockSize / 2;
      const sectionHeight = groups[2].length * (blockSize + margin.spacing) - margin.spacing;
      const sectionWidth = groups[1].length * (blockSize + margin.spacing) - margin.spacing;
      const chartWidth = groups[0].length * (sectionWidth + sectionSpacing) - sectionSpacing;
      const width = fixed ? 955 : chartWidth + margin.left + margin.right;
      const height = sectionHeight + margin.top + margin.bottom;
      const marginLeft = fixed ? (width - chartWidth) / 2 + 10 : margin.left;
      const marginRight = width - chartWidth - marginLeft;

      const legendFontSize = fixed ? 14 : Math.round(12 * (width / 595));
      const legendShift = 10;

      const maxGroupValue = d3.max(groupValues.flat(Infinity));
      const maxRefValue = (refValues && d3.max(refValues.flat(Infinity))) || 0;
      const maxValue = Math.sqrt(Math.max(maxGroupValue, maxRefValue));

      // const statusColors = ['red', 'orange', 'blue', 'green'];
      // const statusColors = ['#dc3545', '#ffc107', '#007bff', '#28a745'];
      const getBlockColor = (skillStatus) => {
        const index = SKILL_STATUS.indexOf(skillStatus);
        if (!skillStatus || index < 0) return 'gray';
        return PERFORMANCE_COLORS[index];
      };

      // Create svg and chart area
      const svg = d3.select(this).attr('viewBox', `0 0 ${width} ${height}`);
      const area = svg.append('g');

      // Create tooltip
      const tooltip = svg
        .append('g')
        .attr('class', 'tp')
        .attr('pointer-events', 'none')
        .style('display', 'none');

      tooltip.append('rect').attr('opacity', 0.9).attr('stroke', 'black').attr('stroke-width', 1);

      const tooltext = tooltip.append('text').attr('text-anchor', 'left');
      const legendFormat = format || d3.format(maxGroupValue <= 1 ? '.2f' : '.0f');
      let digitOn = 'digit' in data ? data.digit : false;

      const rScale = d3
        .scaleLinear()
        .range([0, blockSize / 2])
        .domain([0, 1.2 * maxValue]);

      const def = svg.append('defs');
      const clipPathID = Math.random().toString(36).substring(6);
      def
        .append('marker')
        .attr('id', `triangle-${clipPathID}`)
        .attr('refX', 6)
        .attr('refY', 6)
        .attr('markerWidth', 30)
        .attr('markerHeight', 30)
        .attr('markerUnits', 'userSpaceOnUse')
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M 0 0 12 6 0 12 3 6')
        .style('fill', 'black');

      // Left legends
      groups[2].forEach((g3, i3) => {
        const xPos = marginLeft - 10;
        const yPos = margin.top + (blockSize + margin.spacing) * i3 + blockSize / 2;
        area
          .append('text')
          .attr('transform', `translate(${xPos},${yPos})rotate(-90)`)
          .attr('text-anchor', 'middle')
          .attr('font-size', 17)
          // .attr('dy', '0.35em')
          .text(g3.toUpperCase());
      });

      // Tooltip
      function mouseovered(d) {
        if (digitOn) return;
        tooltip.style('display', null);

        tooltext.text(d.text).attr('x', d.pos[0] + d3.mouse(this)[0] + 15);
        let textbb = tooltext.node().getBBox();
        tooltext.attr('y', d.pos[1] + d3.mouse(this)[1] + 1.5 * textbb.height);

        if (textbb.x + textbb.width >= chartWidth) {
          tooltip.select('text').attr('x', d.pos[0] + d3.mouse(this)[0] - textbb.width - 10);
          textbb = tooltip.select('text').node().getBBox();
        }

        tooltip
          .select('rect')
          .attr('x', textbb.x - 3)
          .attr('y', textbb.y - 3)
          .attr('width', textbb.width + 6)
          .attr('height', textbb.height + 6)
          .attr('fill', 'white');
      }

      groups[0].forEach((g1, i1) => {
        const left = marginLeft + (sectionWidth + sectionSpacing) * i1;
        // Top legends
        area
          .append('text')
          .attr('x', left + sectionWidth / 2)
          .attr('y', margin.top / 3)
          .attr('text-anchor', 'middle')
          .attr('dy', '0.35em')
          .attr('font-size', `${width / 595}rem`)
          .text(g1.toUpperCase());

        if (groupMeans) {
          area
            .append('ellipse')
            .attr('cx', left + blockSize / 2 + (sectionWidth - blockSize) * groupMeans[i1][0])
            .attr(
              'cy',
              margin.top + blockSize / 2 + (sectionHeight - blockSize) * groupMeans[i1][1]
            )
            .attr('rx', (sectionWidth - blockSize) * groupStds[i1][0] * 0.5)
            .attr('ry', (sectionHeight - blockSize) * groupStds[i1][1] * 0.5)
            .style('fill', 'red')
            .style('opacity', digitOn ? 0.5 : 0);
        }

        // Spin differentiation and intensity level
        if (groupStats) {
          const { spinDifferentiation, intensityLevel } = groupStats[i1];
          area
            .append('text')
            .attr('cursor', 'pointer')
            .attr('x', left + sectionWidth / 2)
            .attr('y', height - margin.bottom * 0.7)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .attr('font-szie', legendFontSize)
            .text(
              `SD: ${d3.format('.2f')(spinDifferentiation)}, IL: ${d3.format('.2f')(
                intensityLevel
              )}`
            );
        }

        // Content for each class
        groups[1].forEach((g2, i2) => {
          area
            .append('text')
            .attr('x', left + (blockSize + margin.spacing) * i2 + blockSize / 2)
            .attr('y', margin.top * (2 / 3))
            .attr('text-anchor', 'middle')
            .attr('dy', '0.35em')
            .attr('font-size', 17)
            .text(g2.toUpperCase());

          groups[2].forEach((_, i3) => {
            const currentValue = groupValues[i1][i2][i3];
            const refValue = (refValues && refValues[i1][i2][i3]) || 0;
            const skillStatus = (groupSkillStatus && groupSkillStatus[i1][i2][i3]) || 0;

            const pos = [
              left + (blockSize + margin.spacing) * i2,
              margin.top + (blockSize + margin.spacing) * i3,
            ];
            const focus = area
              .append('g')
              .datum({
                pos,
                text: `${legendFormat(currentValue)}${
                  refValues ? `/${legendFormat(refValue)}` : ''
                }`,
              })
              .attr('transform', `translate(${pos[0]},${pos[1]})`);

            focus
              .append('rect')
              .attr('width', blockSize)
              .attr('height', blockSize)
              .attr('fill', 'none')
              .attr('stroke', getBlockColor(skillStatus))
              .attr('stroke-width', 3);

            // Digit Rendering
            focus
              .append('text')
              .attr('class', 'cur')
              .attr('x', blockSize / 2)
              .attr('y', blockSize / 2)
              .attr('text-anchor', 'middle')
              .attr('dy', '0.35em')
              .attr('font-size', 24)
              .attr('fill', () => {
                if (!refValues) return 'black';
                return currentValue >= refValue ? 'green' : 'red';
              })
              .attr('opacity', digitOn ? 1 : 0)
              .text(legendFormat(currentValue));

            if (refValues) {
              focus
                .append('text')
                .attr('class', 'ref')
                .attr('x', blockSize - 5)
                .attr('y', blockSize - 10)
                .attr('text-anchor', 'end')
                .attr('dy', '0.35em')
                .attr('fill', 'black')
                .attr('font-size', 12)
                .attr('opacity', digitOn ? 1 : 0)
                .text(legendFormat(refValue));
            }

            // Circle Rendering
            focus
              .append('circle')
              .attr('class', 'outer')
              .attr('cx', blockSize / 2)
              .attr('cy', blockSize / 2)
              .attr('r', rScale(Math.sqrt(Math.max(currentValue, refValue))))
              .attr('fill', currentValue >= refValue ? 'green' : 'red')
              .attr('stroke', 'none')
              .attr('opacity', digitOn ? 0 : 0.8)
              .on('mousemove', mouseovered)
              .on('mouseout', () => {
                tooltip.style('display', 'none');
              });

            focus
              .append('circle')
              .attr('class', 'inner')
              .attr('cx', blockSize / 2)
              .attr('cy', blockSize / 2)
              .attr('r', rScale(Math.sqrt(Math.min(currentValue, refValue))))
              .attr('fill', 'blue')
              .attr('stroke', 'white')
              .attr('stroke-width', 0.5)
              .attr('opacity', digitOn ? 0 : 1)
              .on('mousemove', mouseovered)
              .on('mouseout', () => {
                tooltip.style('display', 'none');
              });
          });
        });
      });

      /* Legend */
      // Usually forehand topspin medium will be the class with the largest proportion
      const pv = maxGroupValue;
      const maxIndex = groupValues.flat(Infinity).indexOf(maxGroupValue);
      const pg = Math.floor(maxIndex / (groups[1].length * groups[2].length));
      const pi = maxIndex % groups[2].length;
      const px =
        marginLeft +
        sectionWidth +
        (pg === 0 ? 1.5 * margin.spacing : sectionSpacing - 1.5 * margin.spacing);
      const py = margin.top + 0.5 * blockSize + (blockSize + margin.spacing) * pi;

      const legend = area
        .append('g')
        .attr('transform', `translate(${px},${py})`)
        .style('opacity', digitOn ? 0 : 1);

      legend
        .append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', rScale(Math.sqrt(pv)))
        .attr('y2', -rScale(Math.sqrt(pv)))
        .style('stroke', 'black')
        .style('stroke-width', 1);

      [1, -1].forEach((v) => {
        legend
          .append('line')
          .attr('x1', -margin.spacing / 3)
          .attr('x2', margin.spacing / 3)
          .attr('y1', v * rScale(Math.sqrt(pv)))
          .attr('y2', v * rScale(Math.sqrt(pv)))
          .style('stroke', 'black')
          .style('stroke-width', 1);

        legend
          .append('line')
          .attr('x1', 0)
          .attr('y1', v * (rScale(Math.sqrt(pv)) + 20))
          .attr('x2', 0)
          .attr('y2', v * (rScale(Math.sqrt(pv)) + 6))
          .attr('stroke', 'black')
          .attr('stroke-width', 2)
          .attr('marker-end', `url(#triangle-${clipPathID})`);
      });

      legend
        .append('text')
        .attr('transform', `translate(${(pg ? -1 : 1) * margin.spacing},0)rotate(-90)`)
        .attr('text-anchor', 'middle')
        .attr('dy', '0.35em')
        .text(`${legendFormat(pv)}`);

      // Digit ON/OFF Option
      const options = svg.append('g').attr('cursor', 'pointer');
      const circleSize = Math.round(5 * (width / 595));

      options
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('fill', '#fff')
        .attr('stroke', 'black')
        .attr('r', circleSize);

      const innerCircle = options
        .append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('fill', 'black')
        .attr('stroke', '#fff')
        .style('opacity', digitOn ? 1 : 0)
        .attr('r', circleSize - 2);

      options
        .append('text')
        .attr('x', 10)
        .attr('y', 0)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'start')
        .style('fill', 'black')
        .text('Digit')
        .style('font-size', fixed ? 14 : Math.round(14 * (width / 595)));

      const optionsX = width - marginRight + 5 - (options.node().getBBox().width || 60);
      const optionsY = height - margin.bottom / 4;
      options.attr('transform', `translate(${optionsX}, ${optionsY})`);

      options.on('click', () => {
        digitOn = !digitOn;
        if (toggleDigit) toggleDigit(digitOn);
        innerCircle.style('opacity', digitOn ? 1 : 0);
        legend.style('opacity', digitOn ? 0 : 1);
        area.selectAll('circle.outer').style('opacity', digitOn ? 0 : 0.8);
        area.selectAll('circle.inner').style('opacity', digitOn ? 0 : 1);
        area.selectAll('text.cur').style('opacity', digitOn ? 1 : 0);
        area.selectAll('ellipse').style('opacity', digitOn ? 0.5 : 0);
        if (refValues) area.selectAll('text.ref').style('opacity', digitOn ? 1 : 0);
      });
      if (groupSkillStatus) {
        const rectSize = Math.round(3 * (width / 595));
        const legendStatus = svg
          .append('g')
          .attr(
            'transform',
            `translate(${marginLeft - legendShift}, ${height - margin.bottom / 4})`
          )
          .attr('cursor', 'default');

        const status = metricName === 'skillStatus' ? SKILL_STATUS : TREND_STATUS;
        const legendTitle = metricName === 'skillStatus' ? 'SkillStatus:' : 'ComparingStatus:';
        const legendColor =
          metricName === 'skillStatus' ? PERFORMANCE_COLORS : ['gray', 'green', 'red'];
        const title = legendStatus
          .append('text')
          .attr('dy', '0.35em')
          .attr('text-anchor', 'start')
          // .attr('text-align: center')
          .style('fill', 'black')
          .text(legendTitle)
          .style('font-size', legendFontSize)
          .style('font-style', 'italic')
          .style('text-decoration', 'underline');

        let shift = (title.node().getBBox().width || 80) + rectSize + 2;
        d3.range(1, status.length).forEach((i) => {
          const item = legendStatus.append('g').attr('transform', `translate(${shift})`);

          item
            .append('rect')
            .attr('x', 0)
            .attr('y', -rectSize)
            .attr('width', 2 * rectSize)
            .attr('height', 2 * rectSize)
            .attr('fill', '#fff')
            .attr('stroke', legendColor[i]);

          item
            .append('text')
            .attr('x', rectSize * 2 + 2)
            .attr('y', 0)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'start')
            .style('fill', legendColor[i])
            .text(status[i].toUpperCase())
            .style('font-size', legendFontSize);

          shift += (item.node().getBBox().width || 80) + rectSize + 2;
        });
      }
    });
  }

  chart.blockSize = function changeBlockSize(value) {
    if (!arguments.length) return blockSize;
    blockSize = value;
    return chart;
  };

  chart.margin = function changeMargin(value) {
    if (!arguments.length) return margin;
    margin = value;
    return chart;
  };

  return chart;
}

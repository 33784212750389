import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row, Nav, NavItem, TabContent, TabPane, Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames';
import { NoReportMessage, LoadingState } from 'cuemate-charts';
import * as actions from '../../../../../actions';
import useCheckMobileScreen from '../../../../../libs/mobileUtilities';
import { cuemateGET } from '../../../../../libs/dataAccess';
import { FriendshipStatus } from '../../../social/SocialConstants';
import { sortArrObjWithStringPropertyAsc } from '../../../../../libs/utilities';

const TabNames = {
  SOCIAL: 'SOCIAL',
};

export default function ReportFramework({ stage }) {
  const dispatch = useDispatch();
  const { activeContent, config, reportType, classData, sessionData, elements } = useSelector(
    (state) => ({
      activeContent: state.activeContent,
      config: state.config,
      reportType: state.reportType,
      classData: state.classData,
      sessionData: state.sessionData,
      elements: state.elements,
    })
  );
  const { tabName = 'home' } = useParams();

  const [visitedTabs, updateTabs] = useState([]);
  const [currentContent, setContent] = useState(activeContent);
  const [friendRequests, setFriendRequests] = useState([]);

  const onFriendRequestsUpdate = useCallback((newList) => {
    setFriendRequests(newList);
  });

  const onTransition = (context) => {
    let content = config.getTransitionID(context);
    updateTabs((tabs) => {
      let newTabs = [...tabs];
      const tabId = newTabs.findIndex((t) => t.name.includes(content));
      if (tabId < 0) {
        newTabs = newTabs.filter((t) => !t.name.includes(content.split(',')[0]));
        // eslint-disable-next-line no-use-before-define
        newTabs.push({ name: content, content: renderTab(content) });
      } else {
        content = tabs[tabId].name;
      }
      return newTabs;
    });
    dispatch(actions.updateActiveContent(content));
    setContent(content);
  };

  const renderTab = (content) => {
    const { reportTabs } = config;
    const reportTab = reportTabs.find((t) => content.includes(t.name));
    const TagName = reportTab.component;
    return (
      <TabPane tabId={reportTab.name} key={reportTab.name}>
        {reportTab.name.includes(TabNames.SOCIAL) ? (
          <TagName
            onTransition={onTransition}
            onFriendRequestsUpdate={onFriendRequestsUpdate}
            friendRequests={friendRequests}
          />
        ) : (
          <TagName onTransition={onTransition} />
        )}
      </TabPane>
    );
  };

  const getFriendRequests = async () => {
    try {
      const url = `/users/social/friendships`;
      const currentSession = await Auth.currentSession();
      const response = await cuemateGET(url, currentSession.accessToken.jwtToken);
      const friends = response.data;
      const requests = friends.filter((el) => el.status === FriendshipStatus.PENDING);

      setFriendRequests(sortArrObjWithStringPropertyAsc(requests, 'username'));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getFriendRequests();
  }, []);

  useEffect(() => {
    if (Object.keys(config).length > 0 && visitedTabs.length === 0) {
      onTransition(tabName.replace('-', '_').toUpperCase());
    }
  }, [config]);

  useEffect(() => {
    updateTabs([]);
    if (Object.keys(config).length > 0) {
      onTransition(currentContent || tabName.replace('-', '_').toUpperCase());
    }
  }, [reportType]);

  const isMobile = useCheckMobileScreen();

  if (stage === 0 || (stage !== 10 && Object.keys(config).length === 0)) {
    return <LoadingState />;
  }
  return stage === 10 ||
    elements.length === 0 ||
    classData.length === 0 ||
    sessionData.sessions.length === 0 ? (
    <NoReportMessage />
  ) : (
    <>
      <Container fluid={isMobile}>
        <Row>
          <Col sm="12">
            <Nav pills className="flex-row">
              {config.reportTabs
                .filter((tab) => !tab.experiment || reportType === 'experiment')
                .map((tab) => (
                  <NavItem key={tab.name} style={{ flex: 1, textAlign: 'center' }}>
                    <Link
                      className={classnames('nav-link', {
                        active: currentContent && currentContent.includes(tab.name),
                      })}
                      onClick={() => onTransition(tab.name)}
                      to={`/dashboard/${reportType}/${tab.name.split('_').join('-').toLowerCase()}`}
                    >
                      {!tab.name.includes(TabNames.SOCIAL) ? (
                        tab.name.split('_').join(' ')
                      ) : (
                        <>
                          SOCIAL{' '}
                          {friendRequests.length > 0 && <Badge pill>{friendRequests.length}</Badge>}
                        </>
                      )}
                    </Link>
                  </NavItem>
                ))}
            </Nav>
          </Col>
        </Row>
      </Container>

      <Container fluid={isMobile}>
        <Row className="padding-top-25">
          <Col sm="12">
            <TabContent
              activeTab={currentContent && currentContent.split(',')[0]}
              className="padding-bottom-25"
            >
              {visitedTabs.map((t) => t.content)}
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
}

ReportFramework.propTypes = {
  stage: PropTypes.number,
};

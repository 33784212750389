import React, { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { Input, Container, FormGroup, Form, Label } from 'reactstrap';
import { cuemateGET, cuematePUT } from '../../../../libs/dataAccess';
import onError from '../../../../libs/errorLib';

const readUserNotificationGroups = async () => {
  const currentSession = await Auth.currentSession();
  const response = await cuemateGET(
    '/users/notifications/groups',
    currentSession.accessToken.jwtToken
  );
  if (!response) {
    return {};
  }
  return response;
};

const updateUserNotificationGroups = async (notificationGroups) => {
  const currentSession = await Auth.currentSession();
  const response = await cuematePUT(
    '/users/notifications/groups',
    currentSession.accessToken.jwtToken,
    notificationGroups
  );
  return !response ? {} : response;
};

export default function UserNotificationGroups() {
  const [userNotificationGroups, setUserNotificationGroups] = useState({});

  useEffect(() => {
    async function onLoad() {
      try {
        const userNotificationGroupsResponse = await readUserNotificationGroups();
        setUserNotificationGroups(userNotificationGroupsResponse);
      } catch (error) {
        onError(error, true);
      }
    }

    onLoad();
  }, []);

  const handleOnChange = useCallback(async (event) => {
    event.preventDefault();
    try {
      const notificationGroup = { [event.target.id]: event.target.checked };
      const updatedUserNotificationGroups = await updateUserNotificationGroups(notificationGroup);
      setUserNotificationGroups(updatedUserNotificationGroups);
    } catch (error) {
      onError(error, true);
    }
  });

  function renderUpdateForm(userNotificationGroupsParam) {
    const {
      canReceiveActivityReport,
      canRecieveGoalActive,
      canReceiveGoalSuccess,
      canReceiveRatingProgress,
      canReceiveComments,
      canReceiveLikes,
      canReceiveFriendshipAccept,
      canReceiveFriendshipRequest,
      canReceiveShareActivity,
      canReceiveAppFeatures,
      canReceiveAppUpdates,
      notificationLanguage,
    } = userNotificationGroupsParam;

    return (
      <Container>
        <h2 className="blue">Mobile Notifications</h2>
        <Form>
          <FormGroup>
            <Label for="activityReport">
              <h3>Activity Report</h3>
            </Label>
            <div>
              {' '}
              <Input
                type="checkbox"
                id="canReceiveActivityReport"
                checked={canReceiveActivityReport || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When my activity report is available on the website</Label>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="trainingGoals">
              <h3>Training Goals</h3>
            </Label>
            <div>
              <Input
                type="checkbox"
                id="canReceiveGoalActive"
                checked={canRecieveGoalActive || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When my training goal becomes active</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="canReceiveGoalSuccess"
                checked={canReceiveGoalSuccess || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When I achieve my training goal</Label>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="rating">
              <h3>Rating</h3>
            </Label>
            <div>
              <Input
                type="checkbox"
                id="canReceiveRatingProgress"
                checked={canReceiveRatingProgress || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>{`When I've improved my rating`}</Label>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="social">
              <h3>Social</h3>
            </Label>
            <div>
              <Input
                type="checkbox"
                id="canReceiveLikes"
                checked={canReceiveLikes || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When someone likes one of my activities</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="canReceiveComments"
                checked={canReceiveComments || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When someone comments on one of my activities</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="canReceiveFriendshipRequest"
                checked={canReceiveFriendshipRequest || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When someone sends me a connection request</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="canReceiveFriendshipAccept"
                checked={canReceiveFriendshipAccept || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When someone accepts my connection request</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="canReceiveShareActivity"
                checked={canReceiveShareActivity || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>When my connections post activities</Label>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="cuemate">
              <h3>CueMate</h3>
            </Label>
            <div>
              <Input
                type="checkbox"
                id="canReceiveAppFeatures"
                checked={canReceiveAppFeatures || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>Receive notifications about new features</Label>
            </div>
            <div>
              <Input
                type="checkbox"
                id="canReceiveAppUpdates"
                checked={canReceiveAppUpdates || ''}
                onChange={handleOnChange}
              />{' '}
              <Label check>Receive notification about new mobile app release</Label>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="notificationLanguage">
              <h3>Notification Language</h3>
            </Label>
            <div>
              <Input
                type="text"
                id="notificationLanguage"
                label="Notification Language"
                value={notificationLanguage || ''}
                disabled
              />
            </div>
          </FormGroup>
        </Form>
      </Container>
    );
  }

  const startSessionForm = renderUpdateForm(userNotificationGroups);

  return <div>{startSessionForm}</div>;
}

import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import homeoverview from '../../../../images/screens/home/Home-StartSession-ABCD.png';
import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';
import labelC from '../../../../images/screens/labels/C.png';
import labelD from '../../../../images/screens/labels/D.png';
import homeA from '../../../../images/screens/home/Home-StartSession-A.png';
import homeB from '../../../../images/screens/home/Home-StartSession-B.png';
import homeC from '../../../../images/screens/home/Home-StartSession-C.png';
import homeD from '../../../../images/screens/home/Home-StartSession-D.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';

import './AppFeatures.css';

export default function HomeScreen() {
  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row>
        <Col sm="12">
          <h1>Home Screen</h1>
        </Col>
      </Row>

      <Row>
        <Col sm="8" className="padding-top-50">
          <img src={homeoverview} className="d-block mx-auto img-fluid" alt="Home Overview" />
        </Col>
        <Col sm="4" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/home-screen#overview">
                  Overview
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/home-screen#manage-sensors">
                  Manage Sensors
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/home-screen#start-session">
                  Start Session
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/home-screen#review-prior-sessions">
                  Review Prior Sessions
                </Link>
              </h3>
            </Media>
          </Media>
        </Col>
      </Row>

      <Row className="padding-top-100" id="overview">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-header-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h2>Overview</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            {' '}
            View total strokes hit, total time and total sessions played since using CueMate in the
            overview.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={homeA} className="d-block mx-auto img-fluid" alt="Overview" />
        </Col>
      </Row>

      <Row className="padding-top-100 padding-bottom-100" id="manage-sensors">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-header-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h2>Manage Sensors</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            View a list of CueMate sensors around you and be able to connect and disconnect from
            them.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={homeB} className="d-block mx-auto img-fluid" alt="Manage Sensors" />
        </Col>
      </Row>

      <Row className="padding-top-100 padding-bottom-100" id="start-session">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-header-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h2>Start Session</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Select START SESSION to begin your session which will take you to the{' '}
            <Link to="/cuemate-ios/tagging">tagging</Link> screen.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={homeC} className="d-block mx-auto img-fluid" alt="Start Session" />
        </Col>
      </Row>

      <Row className="padding-top-100" id="start-session">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-header-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h2>Review Prior Sessions</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Prior sessions are listed out with the date and location, session type (Warmup,
            Freeplay, Game or Train), number of sets, duration, sweet spot %, number of strokes and
            partner name and type if tagged so.
          </p>
          <p>
            Selecting a prior session will give you access to the{' '}
            <Link to="/cuemate-ios/review-screen">review</Link> screen for that session whereas
            clicking the document icon will take you to the session stroke and training{' '}
            <Link to="/cuemate-ios/reports-screen">report</Link>.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={homeD} className="d-block mx-auto img-fluid" alt="Start Session" />
        </Col>
      </Row>

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

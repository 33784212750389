import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import corestrokes1 from '../../../../images/cuemate101/level2/key-concepts/core-strokes/core-strokes-1.png';
import corestrokes2 from '../../../../images/cuemate101/level2/key-concepts/core-strokes/core-strokes-2.png';
import corestrokes3 from '../../../../images/cuemate101/level2/key-concepts/core-strokes/core-strokes-3.png';
import corestrokes4 from '../../../../images/cuemate101/level2/key-concepts/core-strokes/core-strokes-4.png';
import swipe from '../../../../images/cuemate101/level2/key-concepts/core-strokes/swipe.png';
import '../AppFeatures.css';

export default function CoreStrokes() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={5}>
          <h2>Core Strokes</h2>
          <p>
            CueMate records all strokes and sorts them into 18 stroke classes on the Stroke Map.
          </p>
          <p>There are three separate Stroke Maps views – Groundstrokes, Volleys, and Serves.</p>
          <p>Stroke classes with the most counts are considered core strokes. </p>
          <p>
            Tapping on a stroke class will display the{' '}
            <span className="blue">Metrics Overview</span> for that stroke.
          </p>
        </Col>
        <Col sm={7}>
          <img
            src={corestrokes1}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Stroke Map"
          />
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={{ offset: 3, size: 4 }}>
          <img src={corestrokes2} className="d-block mx-auto img-fluid" alt="Stroke Report" />
        </Col>
        <Col sm={2}>
          <br />
          <br />
          <br />
          Tap “Metrics” to explore the 12 <span className="blue">Stroke Metrics.</span>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={4}>
          <p />
          <p>
            <span className="blue">Metric Scales</span> show performance compared to personal goals
            as well as to the average player, based on data from other players.
          </p>
          <p>
            View player performance for the most recent session, or rolling averages over the
            previous 5, 10, or 15 sessions.
          </p>
          <p>Swipe left to see all 12 metrics.</p>
          <img src={swipe} className="d-block mx-auto img-fluid" alt="Swipe" />
        </Col>
        <Col sm={8}>
          <figure className="figure">
            <img src={corestrokes3} className="d-block mx-auto img-fluid" alt="Technique Metrics" />
            <figcaption>Tap on an individual metric to see the graph view.</figcaption>
          </figure>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={7}>
          <img
            src={corestrokes4}
            className="d-block mx-auto img-fluid img-border"
            alt="Metric Trends: Spin"
          />
        </Col>
        <Col sm={5}>
          <br />
          <br />
          <p>
            <span className="blue bold">Metric Trends</span> show individual player performance
            plotted over time.
          </p>
          <p>
            View performance during single sets to evaluate effectiveness of drills and cueing. In
            addition, see overall performance trends over past sessions.
          </p>
          <p>
            Red lines display the average for each Set or Session. The blue line represents the
            Training Goal.
          </p>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/training-goals">
            <h3>
              Next: Training Goals <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

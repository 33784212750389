import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import playerSummaryOverview from '../../../../images/web-features/player-summary-overview.png';
import strokeTypeNavbar from '../../../../images/web-features/stroke-type-navbar.png';
import strokeMapNoDigit from '../../../../images/web-features/stroke-map-no-digit.png';
import skillProfile from '../../../../images/web-features/skill-profile.png';
import skillStatus from '../../../../images/web-features/skill-status.png';
import strokeRepertoire from '../../../../images/web-features/stroke-repertoire.png';
import skillMapOverview from '../../../../images/web-features/skill-map-overview.png';
import spinEnvelope from '../../../../images/web-features/spin-envelope.png';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import './WebFeatures.css';

export default function WebPlayerReports() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Player Reports</h2>
          <h3>
            Player Report provides a summary of the player’s skills and performance combining the
            information across all the stroke classes.
          </h3>
        </Col>
      </Row>

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Player Summary Overview</h2>
          <p>
            The player summary overview shows the total activity data, including total number of
            sessions and strokes, as well as global skill metrics, including the impact success rate
            and the global score.
          </p>
          <p>
            The player report includes a plot that shows how the system is building up of data for
            the CueMate motion modeling and analysis.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={playerSummaryOverview}
            className="d-block mx-auto
          img-fluid img-fluid img-border"
            alt="Player Summary Overview"
          />
          <figcaption className="figure-caption">Player Summary Overview</figcaption>
        </Col>
      </Row>
      <Row>
        <p>
          Use this plot during the first sessions to learn about amount of data and the variety in
          strokes.The usage and variety in strokes are used to determine user categories:
          <ul>
            <li>Setup</li>
            <li>Intermediate</li>
            <li>Advanced</li>
            <li>Expert</li>
          </ul>
          These features are used to guide your future activity and in particular make sure you
          provide sufficient data, both quantity and variety for the system to model your skills.
          The user category is also used to determine access to advance features which will be
          released in the future.
        </p>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Type Navigation Bar</h2>
          <p>The following sections are determined by the selected stroke type.</p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={strokeTypeNavbar}
            className="d-block mx-auto img-fluid
          img-fluid img-border"
            alt="Stroke Type Navigation Bar"
          />
          <figcaption className="figure-caption">Stroke Type Navigation Bar</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Map</h2>
          <p>
            Similar as in <Link to="/cuemate-web/session-reports">Session Reports</Link>, the Player
            Report also provides the stroke map of the classes, but for strokes in all sessions.
            Moreover, the stroke map in the Player Report provides the reference ranges from the
            population. The green donut means the player has more proportion of the class than the
            population average, and red the opposite.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={strokeMapNoDigit}
            className="d-block mx-auto img-fluid
          img-fluid img-border"
            alt="Stroke Map: Circle View"
          />
          <figcaption className="figure-caption">Stroke Map: Circle View</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Skill Profile</h2>
          <p>
            The Skill Profile shows the stroke classes ranked based on each class composite score.
            The composite score combines key movement metrics. The individual class scores are then
            added to produce the Total Score. The same elements are used to determine the Session
            Score.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={skillProfile}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Skill Profile"
          />
          <figcaption className="figure-caption">Skill Profile</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Skill Status</h2>
          <p>
            The stroke classes are categorized into four development stages from low to high:
            formation, consolidation, and optimal.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={skillStatus}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Skill Status"
          />
          <figcaption className="figure-caption">Skill Status</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Stroke Repertoire Chart</h2>
          <p>The stroke repertoire chart shows the stroke classes based on Use Frequency.</p>
          <p>
            The strokes are arranged hierarchically, starting from the stroke categories
            (groundstrokes forehand/backhand), and then each subclass dividing up the classes based
            on spin (topspin, flat, backspin), and finally based on strength (low, medium, high).
          </p>
          <p>[Red color indicates a class without a statistically significant stroke count.]</p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={strokeRepertoire}
            className="d-block mx-auto img-fluid
          img-fluid img-border"
            alt="Stroke Repertoire"
          />
          <figcaption className="figure-caption">Stroke Repertoire</figcaption>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="blueGrey padding-top-25">Metrics Chart Overview</h3>
          <p>
            Metric Charts provide summaries of player performance through a selection of key
            metrics. The user can select attributes for Technique and Outcomes.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="blueGrey">Skill Map Chart Overview</h3>
          <p>
            Skill map chart enables the user to access the distribution across the spaces of
            different metrics, including outcomes, technique attributes, and coefficients, for the
            classes selected in the donut chart.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 4, offset: 4 }}>
          <img
            src={skillMapOverview}
            className="d-block mx-auto img-fluid
          img-fluid img-border"
            alt="Skill Map Chart Overview"
          />
          <figcaption className="figure-caption">Skill Map Chart Overview</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-50">
        <Col sm={12}>
          <h2 className="blueGrey">Spin Envelope</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }}>
          <img
            src={spinEnvelope}
            className="d-block mx-auto img-fluid
          img-fluid img-border"
            alt="Spin Envelope"
          />
          <figcaption className="figure-caption">Spin Envelope</figcaption>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-web">
            <h3>
              Back to: CueMate for Web <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

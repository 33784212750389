import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Col, Row, UncontrolledAlert } from 'reactstrap';
import { useAsyncV2, cuemateGET, cuematePUT } from '../../../../libs/dataAccess';
import LoadingStateComponent from '../../../../components/LoadingStateComponent';
import onError from '../../../../libs/errorLib';
import AddActivityGoalProfile from './AddActivityGoalProfile';

export default function MyActivityProfile() {
  const [profileName, setProfileName] = useState('');
  const getActivityGoalProfilesAndSettings = async () => {
    const currentSession = await Auth.currentSession();
    const response = await cuemateGET(
      '/goals/activity/profiles',
      currentSession.accessToken.jwtToken
    );
    if (!response) {
      return [];
    }

    const userSettings = await cuemateGET('/userprofile', currentSession.accessToken.jwtToken);
    const { activityGoalProfile } = userSettings;
    const { data: activityGoalProfileOptions } = response;
    if (activityGoalProfile && activityGoalProfile.length > 0) {
      // The activityGoalProfile key exists and contains at least one object with an id property
      const activityGoalProfileId = activityGoalProfile[0].id;
      const matchingActivityGoalProfile = activityGoalProfileOptions.find(
        (profile) => profile.id === activityGoalProfileId
      );
      if (matchingActivityGoalProfile) {
        // The matching activity goal profile object was found
        setProfileName(matchingActivityGoalProfile.name);
      }
    }
    return activityGoalProfileOptions;
  };

  const handleOptionSelect = async (option) => {
    if (Object.keys(option).length !== 0) {
      const activityGoalProfileObject = {
        id: option.id,
        type: option.type,
      };
      const activityGoalProfile = [activityGoalProfileObject];
      const userProfileUpdate = { activityGoalProfile };
      const currentSession = await Auth.currentSession();
      cuematePUT('/userprofile', currentSession.accessToken.jwtToken, userProfileUpdate);
      setProfileName(option.name);
    }
  };

  const {
    value: activityGoalProfileOptions,
    error,
    loading,
  } = useAsyncV2(getActivityGoalProfilesAndSettings, []);

  if (loading) {
    return <LoadingStateComponent loadingText="Loading clubs.." />;
  }

  if (error) {
    onError(error);
    return <LoadingStateComponent loadingText="Error loading clubs. Refresh to try again." />;
  }

  return (
    <>
      {' '}
      <h2 className="blue">My Activity Profile</h2>
      <Row>
        <Col xs="6" sm="6" md="6">
          {profileName === '' ? (
            <UncontrolledAlert color="warning" size="sm">
              Please Select
            </UncontrolledAlert>
          ) : null}
          {profileName === '' ? 'Not Selected' : profileName}{' '}
          <AddActivityGoalProfile
            profileOptions={activityGoalProfileOptions}
            profileName={profileName}
            handleChange={handleOptionSelect}
          />
        </Col>
      </Row>{' '}
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './ModalVideoPlayer.css';

function ModalVideoPlayer({ open, toggleModal, videoLink }) {
  const closeBtn = (
    <button className="close" onClick={toggleModal} type="button">
      &times;
    </button>
  );
  return (
    <Modal
      isOpen={open}
      toggle={toggleModal}
      size="lg"
      backdrop
      centered
      className="modalVideoPlayer"
    >
      <ModalHeader toggle={toggleModal} close={closeBtn} />
      <ModalBody>
        <ReactPlayer url={videoLink} width="100%" playing controls />
      </ModalBody>
    </Modal>
  );
}

ModalVideoPlayer.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,
  videoLink: PropTypes.string,
};

export default ModalVideoPlayer;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level2/on-court/training-goal-status/TrainingGoalStatus-1.png';
import view2 from '../../../../images/cuemate101/level2/on-court/training-goal-status/TrainingGoalStatus-2.png';
import view3 from '../../../../images/cuemate101/level2/on-court/training-goal-status/TrainingGoalStatus-3.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Training Goal Status</h1>
          <p>
            The Rating System generates training goals for players to improve specific elements of
            their Core Strokes. The Training List shows a player’s active Training Goals. Achieving
            these training goals will help you get your stroke map intended for the right stroke
            consistency and quality
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5} className="padding-top-25">
          <h3 className="padding-top-25 blue">TRAINING GOAL STATUS</h3>
          <p>
            The Status View lets players see the date a goal was created, how many strokes have been
            logged, and their progress toward consistently meeting the goal.
          </p>
          <p>
            It also shows a plot of the relative to the goal value. This tells you how much of the
            right strokes you are training.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Training Goal Status 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row>
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Training Goal Status 2" />
          </Col>
          <Col sm={5}>
            <p>You can use training cues with these training goals.</p>
            <p>
              The statistics for Training Goals are calculated based on individual player data and
              appear as a blue line on the metrics graph for that Training Element. (Calculation
              incorporates averages, rate of improvement, and top percentiles to set a goal that is
              challenging yet achievable for that player.)
            </p>
          </Col>
        </Row>
        <br />
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row>
        <Col sm={5}>
          <h3 className="padding-top-25 blue">ACHIEVEMENTS</h3>
          <p>
            Achievements display progress toward consistently meeting the goal and thus mastering
            the new skill. Each block represents 100 strokes. Once a player meets the goal for 3 out
            of 5 blocks, the goal is marked as success.
          </p>
          <p>
            The screen also shows a list of training tips that will help you achieve a training
            goal.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view3} className="d-block mx-auto img-fluid" alt="Training Goal Status 3" />
        </Col>
      </Row>
    </Container>
  );
}

export default function TrainingGoalStatus() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />

      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

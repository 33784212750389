import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import metricList from '../../../../images/screens/ClassReport-Metrics.PNG';

import metricsreference from '../../../../images/screens/ClassReport-Overview-Radar.PNG';

import bluebands from '../../../../images/processes/bluebands.jpg';
import blackdot from '../../../../images/processes/blackdot.jpg';

import './AppFeatures.css';
import MetricsScreen from './MetricsScreen';

export default function Metrics() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Metrics</h1>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h4 className="padding-top-25">
            CueMate’s performance metrics provide quantitative assessments of a player’s skills over
            a holistic range of attributes, from detailed movement dynamics to activity structure.
            This is achieved by tracking one’s skill elements over time, then combining them to
            produce a comprehensive yet individual skill profile.
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-50 padding-bottom-25">
        <Col sm={6}>
          <p>
            CueMate also incorporates data from other players to help interpret and identify a
            single player’s specific weaknesses, as well as set realistic expectations for skill
            levels attainable through deliberate training.
          </p>
          <p>
            A player can learn more about their stroke outcomes — as well as stroke performance and
            technique — by tapping on a stroke type in the Stroke Map, which takes them to the
            Metrics screen.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={metricList}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Metric List"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm={12}>
          <h2 className="blueGrey">Metric Types</h2>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm={4}>
          <h3>Outcome Measurements</h3>
          <p>The primary results produced by strokes, measured by:</p>
          <ul>
            <li>Ball Speed</li>
            <li>Ball Spin</li>
            <li>Sweet Spot</li>
          </ul>
        </Col>
        <Col sm={4}>
          <h3>Movement Technique</h3>
          <p>
            Stroke form characteristics, designed to help understand technique deficiencies and
            provide actionable knowledge to enable improvement.
          </p>
          <ul>
            <li>Arc Length</li>
            <li>BL Openness</li>
            <li>FS Lag</li>
            <li>FS Type</li>
            <li>Head Speed</li>
            <li>Impact Timing</li>
            <li>Peak Acceleration</li>
            <li>Peak Acceleration Timing</li>
            <li>Peak Swing Rate</li>
            <li>Peak Swing Rate Timing</li>
            <li>Racket Drop</li>
            <li>Smoothness</li>
          </ul>
        </Col>
        <Col sm={4}>
          <h3>Physical Performance</h3>
          <p>The stroke’s physical performance can be used to improve movement efficiency.</p>
          <ul>
            <li>Energy</li>
            <li>Power</li>
          </ul>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={4}>
          <h3>Coefficients</h3>
          <p>
            The coefficients (cE) are designed to capture efficiency of the stroke in producing
            outcomes:
          </p>
          <ul>
            <li>Spin cE</li>
            <li>Speed cE</li>
            <li>Spin-Speed cE</li>
          </ul>
        </Col>
      </Row>

      <hr />

      <Row className="padding-top-25">
        <Col sm={12}>
          <h2 className="blueGrey">Metrics Composite View</h2>
        </Col>
      </Row>
      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <p>
            The metrics composite view combines the key metrics in a so-called radar chart. This
            screen make it possible to get a picture of the overall stroke characteristics for a
            class in a single glance.
          </p>
          <p>
            Similar as for the metrics screen, the results are overlaid on the reference values.
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={metricsreference}
            className="d-block mx-auto img-fluid img-border"
            alt="Metrics outcome screen"
          />
        </Col>
      </Row>
      <hr />

      <Row>
        <Col sm="12">
          <h2 className="blueGrey padding-bottom-25">Assessing Your Stroke Metrics</h2>
          <h4 className="padding-bottom-25">
            CueMate metrics offer a detailed stroke analysis to determine which stroke type and what
            specific attributes should be further developed.
          </h4>
          <h4 className="padding-bottom-25">
            Each metric is displayed in terms of its statistics (mean and standard deviation). These
            quantities are superposed on a blue scale (low, medium, high, very high) that makes it
            possible to understand where the player’s attributes fall relative to the larger player
            population.
          </h4>
          <h4 className="padding-bottom-25">
            Metrics can be displayed for various time scales: current session, previous session,
            previous 5 sessions, and previous 10 sessions.
          </h4>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <p>
            CueMate computes metrics for the stroke outcomes such as the amount of spin and speed,
            the sweet spot, as well as the stroke technique (timing), performance (energy, power)
            and coefficients (spin cE, speed cE, spin-speed cE)
          </p>
        </Col>
        <Col sm={6}>
          <img
            src={metricList}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Metric technique"
          />
        </Col>
      </Row>

      <Row className="padding-top-50 padding-bottom-25">
        <Col sm={6}>
          <h3>Metric Scale for Skill Tiers</h3>
          <img
            src={bluebands}
            className="d-block mx-auto img-fluid img-fluid"
            alt="Scale for Skill Tiers"
          />
          <p>
            A blue gradient scale for each metric shown as color bands that represent different
            skill. Low represents metric values for less skilled players; Super High represents
            metric values for highly skilled players. These tiers are determined based on data from
            a population of players.
          </p>
        </Col>
        <Col sm={6}>
          <h3>Your Metrics</h3>
          <img
            src={blackdot}
            className="d-block mx-auto img-fluid img-fluid"
            alt="Scale for Skill Tiers"
          />
          <p>
            The black dot represents your statistical average for each metric within the selected
            time scale (e.g., last 5 sessions). The range bars indicate that sometimes represents
            the standard deviation, which indicates the variability of your metrics.
          </p>
        </Col>
      </Row>
      <hr />

      <MetricsScreen />

      <Row className="padding-top-100">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

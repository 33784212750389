import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import icon1 from '../../../images/concepts/icons/assessmentlevels.png';
import icon2 from '../../../images/concepts/icons/repertoire.png';
import icon3 from '../../../images/concepts/icons/setsandsessions.png';
import icon4 from '../../../images/concepts/icons/strokesandshots.png';
import icon5 from '../../../images/concepts/icons/trainingloop.png';

import './Background.css';

function BackgroundTitle() {
  return (
    <Container className="padding-25">
      <Row>
        <Col sm={12}>
          <h1>CueMate in-depth Background</h1>
          <h4 className="padding-top-10">
            {' '}
            CueMate provides the first <i>scientific data-driven approach</i> to movement training.
          </h4>
          <h4>
            {' '}
            CueMate Tennis was designed to help tennis players train and improve their skills faster
            by augmenting the natural human learning processes. This background section is designed
            to give you more in-depth knowledge about how CueMate works.
          </h4>
          <h4>The section is organized into the following parts:</h4>
        </Col>
      </Row>
    </Container>
  );
}

function LearnMoreAbout() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-15">
          <Col sm="2">
            <Link to="technology-and-movement-training">
              <img
                src={icon5}
                className="d-block mx-auto img-fluid concept-icon"
                alt="Technology and Movement Training"
              />
            </Link>
          </Col>
          <Col sm="6">
            <h4 className="tracking"> #1 </h4>
            <Link to="technology-and-movement-training">
              <h3>Technology and Movement Training </h3>
            </Link>
            <p>
              What role can technology play in assisting training? What are the main challenges and
              why hasn’t there been more products in this space.
            </p>
          </Col>
        </Row>

        <Row className="padding-top-15">
          <Col sm="2">
            <Link to="how-cuemate-works">
              <img
                src={icon1}
                className="d-block mx-auto img-fluid concept-icon"
                alt="How CueMate Works"
              />
            </Link>
          </Col>
          <Col sm="6">
            <h4 className="tracking"> #2 </h4>
            <Link to="how-cuemate-works">
              <h3>How CueMate Works: The Big Picture</h3>
            </Link>
            <p>
              A brief overview of the uniquely challenges of activities like tennis and how CueMate
              addresses these from movement modeling, movement learning, and training augmentation.
            </p>
          </Col>
        </Row>

        <Row className="padding-top-15">
          <Col sm="2">
            <Link to="hierarchical-movement-skill-model">
              <img
                src={icon2}
                className="d-block mx-auto img-fluid concept-icon"
                alt="Hierarchical Movement Skill Model"
              />
            </Link>
          </Col>
          <Col sm="6">
            <h4 className="tracking"> #3 </h4>
            <Link to="hierarchical-movement-skill-model">
              <h3>Movement and Skill Model</h3>
            </Link>
            <p>
              Get a better understanding of how CueMate models movements and the relationship of the
              modeling approach to the organization of the human nervous system.
            </p>
          </Col>
        </Row>

        <Row className="padding-top-15">
          <Col sm="2">
            <Link to="learning-model">
              <img
                src={icon3}
                className="d-block mx-auto img-fluid concept-icon"
                alt="Learning Model"
              />
            </Link>
          </Col>
          <Col sm="6">
            <h4 className="tracking"> #4 </h4>
            <Link to="learning-model">
              <h3>CueMate&apos;s Learning Model</h3>
            </Link>
            <p>
              It would be challenging to build technology to augment learning without a model of how
              the brain learns movements. CueMate’s learning model makes it possible to get you on
              the path to becoming a better player taking into account your unique attributes and
              goals.{' '}
            </p>
          </Col>
        </Row>

        <Row className="padding-top-15">
          <Col sm="2">
            <Link to="hierarchical-skill-assessment-and-training">
              <img
                src={icon4}
                className="d-block mx-auto img-fluid concept-icon"
                alt="Hierarchical Skill Assessment and Training"
              />
            </Link>
          </Col>
          <Col sm="6">
            <h4 className="tracking"> #5 </h4>
            <Link to="hierarchical-skill-assessment-and-training">
              <h3>Skill Assessment and Training</h3>
            </Link>
            <p>
              The movement and learning models enable diagnostics across different levels of
              performance and organization of the nervous system.{' '}
            </p>
          </Col>
        </Row>

        <Row className="padding-top-15">
          <Col sm="2">
            <Link to="cuemate-ecosystem">
              <img
                src={icon5}
                className="d-block mx-auto img-fluid concept-icon"
                alt="CueMate Ecosystem"
              />
            </Link>
          </Col>
          <Col sm="6">
            <h4 className="tracking"> #6 </h4>
            <Link to="cuemate-ecosystem">
              <h3>CueMate Ecosystem</h3>
            </Link>
            <p>
              CueMate was designed to be a complete ecosystem, which provides different dimensions
              of user interactions and integrates the data from population to create values for
              users with different needs, skills levels, and individual characteristics.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function Background() {
  return (
    <>
      <BackgroundTitle />
      <LearnMoreAbout />
    </>
  );
}

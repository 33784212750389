import React from 'react';
import { Col, Container, Row, UncontrolledCarousel } from 'reactstrap';

import Breadcrumbs from '../../../components/Breadcrumbs';

import repertoireTree from '../../../images/background/repertoire-tree.png';
import strokePatternPhases from '../../../images/background/stroke-pattern-phases.png';
import strokeCategoriesTypes from '../../../images/background/stroke-pattern-repertoire-categories-01.png';
import intensityClass from '../../../images/background/stroke-pattern-repertoire-categories-02.png';
import spinClass from '../../../images/background/stroke-pattern-repertoire-categories-03.png';
import hierarchicalSkillModelAssessment from '../../../images/background/hierarchical-skill-model-and-assessment.png';
import './Background.css';

export function Title() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>Movement and Skill Model</h1>
          <h4 className="padding-top-10">
            Activities like tennis rely on a wide repertoire of movement skills, including those
            needed to move on the court, and those supporting the ball strike. To enable effective
            data-driven training, a central problem is extracting and decomposing these movement
            into forms that enable skill assessment.
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

function MovementSkillModelOverview() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h2 className="blueGrey">Movement Skill Model Overview</h2>
          </Col>
        </Row>

        <Row>
          <Col sm={12} className="padding-top-10">
            <p>
              CueMate uses a hierarchical movement skill model. This model is based on how the brain
              learns and organizes movement.
            </p>
            <p>This type of model is often illustrated as a tree.</p>
            <ul className="with-spacing">
              <li>
                The top level breaks down the stroke patterns in stroke categories (ground strokes,
                overheads, volleys, serves).
              </li>
              <li>
                And then, each category is divided into stroke classes such as forehand or backhand,
                which can further be subdivided based on spin (top spin, flat, and slice) and
                intensity (low, medium, high).
              </li>
              <li>
                Finally, each stroke class can be divided into phases (backswing, back loop, forward
                swing, etc.).{' '}
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm="6">
            <img
              src={repertoireTree}
              className="d-block mx-auto img-fluid img-border"
              alt="Repertoire Tree"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function StrokeCategoriesClasses() {
  return (
    <Container className="padding-top-25 padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h3 className="blueGrey">Stroke Categories and Classes</h3>
          <p>
            The illustrations bellow show the distinction between stroke categories, and the spin
            and intensity classes.
          </p>
        </Col>
      </Row>
      <Row className="padding-top-15 justify-content-center">
        <Col sm="6">
          <UncontrolledCarousel
            dark
            items={[
              {
                key: 1,
                src: strokeCategoriesTypes,
              },
              {
                key: 2,
                src: intensityClass,
              },
              {
                key: 3,
                src: spinClass,
              },
            ]}
            className="img-border"
          />
        </Col>
      </Row>
    </Container>
  );
}

function StrokeArchitecture() {
  return (
    <Container fluid className="light-grey-bg padding-top-25 padding-bottom-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h3 className="blueGrey">Stroke Architecture</h3>
            <p>
              Within a specific stroke class, the stroke is decomposed into phases. Movement phases
              are important because they reflect the movement technique and the underlying movement
              biomechanics. Movement phases enable to extract details of the movement performance
              needed to model and analyze stroke technique.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={4}>
            <img
              src={strokePatternPhases}
              className="d-block mx-auto img-fluid img-border"
              alt="Stroke Pattern witth Phases"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function MovementSkillModel() {
  return (
    <Container>
      <Row className="padding-top-25">
        <Col sm={12}>
          <h3 className="blueGrey">Hierarchical Movement Skill Model</h3>
          <p>
            Now that you understand the key elements and dimensions of the tennis stroke, the
            following diagram summarizes CueMate’s movement skill model components.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center padding-15">
        <Col sm={7}>
          <img
            src={hierarchicalSkillModelAssessment}
            className="d-block mx-auto img-fluid img-border"
            alt="Hierarchical Skill Model and Assessment"
          />
        </Col>
      </Row>
      <Row>
        <p>
          {' '}
          The hierarchical skill model provides information for assessment and training. The
          assessment can focus on different levels, the repertoire or stroke map, the stroke
          classes, stroke phase profiles, and the specific stroke features.
        </p>
        <p>
          {' '}
          This organization makes it possible to account how the many elements combine to create the
          player’s overall skill. It is also essential to manage and organize the movement data in
          complex activities like tennis.
        </p>
        <p>
          Finally, the wide range of characteristics involved in movement performance also explain
          why becoming proficient in a sport like tennis can take years of dedicated training.
        </p>
      </Row>
    </Container>
  );
}
export default function HierarchicalMovementModel() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Title />
      <MovementSkillModelOverview />
      <StrokeCategoriesClasses />
      <StrokeArchitecture />
      <MovementSkillModel />
      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

export default function processLeaderboardData(data = []) {
  const arrayOfMetrics = [];
  const output = {};
  // transforms the data to what the caller is expecting
  // this is because of how the previous data was formatted
  if (data && data.length) {
    data.forEach((entry) => {
      if (entry.metrics) {
        const reduceResult = entry.metrics.reduce((map, obj) => {
          // eslint-disable-next-line no-param-reassign
          map[obj.name] = obj.value;
          return map;
        }, {});
        reduceResult.user = entry.user;
        arrayOfMetrics.push(reduceResult);
      }
    });
  }

  arrayOfMetrics.forEach((entry, index) => {
    const metrics = Object.keys(entry);
    metrics.forEach((metric) => {
      if (output[metric] === undefined) {
        output[metric] = {};
      }
      output[metric] = { [index]: entry[metric], ...output[metric] };
    });
  });
  return output;
}

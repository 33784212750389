import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs';
import './Setup.css';
import connect from '../../../images/guides/connect.jpg';

import bluetooth from '../../../images/getstarted/bluetooth.png';

export default function SetupSensor() {
  return (
    <Container className="padding-bottom-50 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm={8}>
          <h2>Attach the CueMate Sensor</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <ol>
            <li>
              Using a flat-head screwdriver, pry off and remove the existing trap door from racket
              butt.
            </li>
            <li>
              Align the notch on the edge of the sensor with the notch on the butt of the racket.
            </li>
            <li>
              Insert the sensor into the butt of the racket by pressing firmly on its outer edges
              until you hear and feel a click.
            </li>
          </ol>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm={8}>
          <h2>Charge the CueMate Sensor</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <ol>
            <li>
              Attach a Micro USB cable to the sensor and to the wall using a standard USB charger.
              (The CueMate sensor can be charged while in the racket.)
            </li>
            <li>
              The charge indicator LED glows amber while charging, and green when fully charged. (~2
              hours for full charge)
            </li>
            <li>
              When finished, hold the sensor in place with your thumb, and unplug the micro USB
              cable.
            </li>
          </ol>
          The sensor automatically sleeps when no movement is detected. It can remain on racket
          during play with and without using CueMate.
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Col sm={8}>
          <h2>Pair the CueMate Sensor to the App</h2>
          Note: You must open the app and sign in, before attempting to pair the sensor.
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col sm={6}>
          <ol>
            <li>Check that Bluetooth is turned ON (in iOS Settings {'>'} Bluetooth).</li>
            <li>
              Holding both the racket and your phone, begin the pairing sequence:
              <ol>
                <li>
                  Hold the racket out in front of you so that it is aligned horizontally. The sensor
                  should be blinking fast with a purple hue.
                </li>
                <li>
                  Rotate the racket head upward, aligning it vertically toward the sky with the butt
                  pointing downwards. The sensor should now be blinking fast with a blue hue, and
                  will now attempt to connect.
                </li>
                <li>If successful, you will hear a confirmation tone</li>
              </ol>
            </li>
          </ol>
          Note: If you’re finding it difficult to establish a connection, try disabling and
          re-enabling Bluetooth.
        </Col>
        <Col sm={5}>
          <img src={connect} className="d-block mx-auto img-fluid" alt="Connect your sensor" />
          <br />
          When paired, the Bluetooth icon will be displayed in the in the top right corner of the
          app home screen.
          <img src={bluetooth} className="d-block mx-auto img-fluid" alt="Connect bluetooth icon" />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/">
            <h3>
              Back to: CueMate 101 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

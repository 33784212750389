import React from 'react';
import { Col, Row } from 'reactstrap';
import * as d3 from 'd3';

import { BasicChartWrapper, scoreTrend, strokeCounterSlider, utilities } from 'cuemate-charts';

/* Functions */
const { getHoursAndMinutes } = utilities;

export default function SessionOverview(data) {
  const { sessions, overallStats } = data.sessionData;
  const { onTransition } = data;

  const rows = [
    [
      {
        content: (
          <a href="#PLAYER_REPORT" onClick={() => onTransition('PLAYER_REPORT')}>
            <Row className="black grey-bg padding-top-25">
              <Col sm="3">
                <p className="grey tracking">TOTAL STROKES</p>
                <h3>{overallStats.count || 'Loading...'}</h3>
              </Col>
              <Col sm="3">
                <p className="grey tracking">TOTAL TIME</p>
                <h3>{getHoursAndMinutes(overallStats.playDuration) || 'Loading...'}</h3>
              </Col>
              <Col sm="3">
                <p className="grey tracking">SINCE LAST SESSION</p>
                <h3>{overallStats.daysSinceLastSession || 'Loading...'}</h3>
              </Col>
              <Col sm="3">
                <p className="grey tracking">GLOBAL SCORE</p>
                <h3>
                  {overallStats.globalScore
                    ? d3.format('.1f')(overallStats.globalScore * 100)
                    : '-'}
                </h3>
              </Col>
            </Row>
          </a>
        ),
      },
    ],
    [
      {
        content: (
          <BasicChartWrapper
            className="padding-top-25"
            chart={strokeCounterSlider}
            paras={{
              sessions: sessions.sort((a, b) => (a.datetime < b.datetime ? 1 : -1)),
            }}
          />
        ),
      },
    ],
    [
      {
        content: (
          <BasicChartWrapper
            className="padding-top-25"
            chart={scoreTrend}
            paras={{
              sessions: sessions.sort((a, b) => (a.datetime < b.datetime ? 1 : -1)),
              defaults: ['technique', 'activity'],
              onTransition,
            }}
          />
        ),
      },
    ],
  ];

  sessions.forEach((session) => {
    const { datetime, location, dateText, playDuration, count, impactSuccessRate } = session;
    const sessionLocation = location ? location.match(/\w.*[A-Za-z]/g)[0] : '-';
    const score =
      (typeof session.score === 'number' ? session.score : session.score.technique) * 10;
    rows.push([
      {
        content: (
          <a href={`#${datetime}`} key={datetime} onClick={() => onTransition(datetime)}>
            <Row className="black grey-bg padding-top-25 margin-bottom-25">
              <Col sm="2">
                <h4> {dateText} </h4>
                <p className="grey tracking">DATE</p>
              </Col>
              <Col sm="2">
                <h4> {getHoursAndMinutes(playDuration)} </h4>
                <p className="grey tracking">PLAY DURATION</p>
              </Col>
              <Col sm="2">
                <h4> {count} </h4>
                <p className="grey tracking">STROKES</p>
              </Col>
              <Col sm="2">
                <h4> {`${d3.format('.1f')(impactSuccessRate * 100)}%`} </h4>
                <p className="grey tracking">SWEET SPOT</p>
              </Col>
              <Col sm="1">
                <h4> {d3.format('.1f')(score)} </h4>
                <p className="grey tracking">SCORE</p>
              </Col>
              <Col sm="2">
                <h4> {sessionLocation} </h4>
                <p className="grey tracking">LOCATION</p>
              </Col>
              <Col sm="1">
                <h4 className="text-right pull-right">
                  <i className="fa fa-chevron-right blue" aria-hidden="true" />
                </h4>
              </Col>
            </Row>
          </a>
        ),
      },
    ]);
  });

  return rows;
}

import { useState, useEffect } from 'react';

import config from '../config';

const API_ENDPOINT = config.api.ENDPOINT;

const Method = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};

async function makeCall(method, url, token, data = undefined) {
  const params = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  if (data !== undefined) {
    params.body = JSON.stringify(data);
  }

  const response = await fetch(`${API_ENDPOINT}${url}`, params);
  return response;
}

export async function cuematePOST(url, token, data) {
  const response = await makeCall(Method.POST, url, token, data);
  const asJson = await response.json();
  return asJson;
}

export async function cuemateGET(url, token) {
  const response = await makeCall(Method.GET, url, token);
  const asJson = await response.json();
  return asJson;
}

export async function cuematePUT(url, token, data) {
  const response = await makeCall(Method.PUT, url, token, data);
  const asJson = await response.json();
  return asJson;
}

export async function cuemateDELETE(url, token) {
  const response = await makeCall(Method.DELETE, url, token);
  const asJson = await response.json();
  return asJson;
}

export function useAsync(getMethod, params) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function get() {
      try {
        setLoading(true);
        const reponseData = await getMethod(...params);
        setData(reponseData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    get();
    // eslint-disable-next-line
  }, []);
  return [isLoading, error, data, setData];
}

export function useAsyncV2(getMethod, params) {
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  async function getResource() {
    try {
      setLoading(true);
      const result = await getMethod(...params);
      setValue(result);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getResource();
  }, params);

  return { value, error, loading, setValue };
}

import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './LoadingStateComponent.css';
import PropTypes from 'prop-types';

export default function LoadingStateComponent({ loadingText = '' }) {
  return (
    <Container className="LoagindContent align-items-center justify-content-center">
      <Row>
        <Col className="text-center">
          <i className="fa fa-spinner fa-pulse fa-4x fa-fw" aria-hidden="true" />
          <span className="sr-only">Loading...</span>
          <p className="mt-5 text-center animated flash">{loadingText}</p>
        </Col>
      </Row>
    </Container>
  );
}

LoadingStateComponent.propTypes = {
  loadingText: PropTypes.string,
};

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Breadcrumbs';

import trainingLoop from '../../../images/home/trainingloop.png';
import humanEcosystem from '../../../images/background/cuemate-ecosystem-diagram.png';

import './Background.css';

function Title() {
  return (
    <Container className="padding-bottom-25">
      <Row>
        <Col sm={12}>
          <h1>CueMate Ecosystem</h1>
          <h4 className="padding-top-10">
            The CueMate Training Agent leverages the latest sensor technologies combined with
            machine learning algorithms, and population data, to enable advanced diagnostics and
            data-driven training. The system supports individualized training for any skill level
            and is designed to enhance training independently or with a coach.
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

function EcosystemOverview() {
  return (
    <Container fluid className="light-grey-bg padding-bottom-25 padding-top-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h2 className="blueGrey">Ecosystem Overview</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="padding-top-10">
            <p>
              The CueMate is set up as a complete training ecosystem that combines the system
              components, including mobile computing and feedback, to create an augmented training
              experience. The CueMate ecosystem is designed from the ground up to enhance natural
              movement learning.
            </p>
          </Col>
        </Row>
        <Row className="padding-25 justify-content-center">
          <Col sm={6}>
            <img src={humanEcosystem} className="d-block mx-auto img-fluid" alt="Training Loop" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function Components() {
  return (
    <Container className="padding-bottom-25 padding-top-25">
      <Row>
        <Col sm={12} className="padding-top-15">
          <h3>Components</h3>
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="padding-top-10">
          <p>The key components of the ecosystem are:</p>
          <ol className="with-spacing">
            <li>
              CueMate captures a user’s racket movements using a sensor inserted into the racket
              handle. The measurements are sent to a mobile app for processing.
            </li>
            <li>
              CueMate then provides assessments of key movement attributes as well as feedbacks
              (i.e., real-time cueing and instructions) that augment the brain’s natural movement
              execution and learning mechanisms.
            </li>
            <li>
              In addition, the movement data is processed in a cloud system where it is more
              comprehensively assessed and compared with other players’ data to provide larger
              perspective on the strengths and weaknesses.
            </li>
          </ol>
          <p>
            We have also been extensively using computer vision as part of the research and
            validation. We plan to release a set of computer vision tools for our most motivated
            users.{' '}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function AugmentedTraining() {
  return (
    <Container fluid className="light-grey-bg padding-bottom-25 padding-top-25">
      <Container>
        <Row>
          <Col sm={12}>
            <h3>Augmented Training</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="padding-top-10">
            <p>
              The CueMate mobile app is designed as a training agent that acts as a “digital coach,”
              enabling users to review their stroke repertoire, outcomes, and performance metrics,
              and subsequently identify training goals using a rating system.
            </p>
            <p>
              As described above, the stroke vocabulary is the foundation to being a proficient
              player. CueMate’s analyzes the stroke repertoire to determine a skill rating for each
              stroke categories (groundstrokes, volleys, and serves). The rating is a number from 1
              to 5: novice (1), beginner (2), intermediate (3), advanced (4), expert (5).
            </p>
          </Col>
        </Row>
        <Row className="padding-top-25 justify-content-center">
          <Col sm={5}>
            <img src={trainingLoop} className="d-block mx-auto img-fluid" alt="Training Loop" />
          </Col>
          <Col sm={7}>
            <p>
              Training goals target a variety of attributes needed to take one’s tennis skill rating
              to the next level, including the quality and range of strokes and specific features of
              movement technique.
            </p>
            <p>
              As the player progresses, CueMate continually updates their skill assessments,
              diagnostics, and training interventions to help sustain progress. Every move a player
              makes contributes to building a precise understanding of their skills and performance.
            </p>
            <p>
              CueMate is designed to create a virtuous cycle of insights and training interventions
              that builds skills more efficiently and helps break past training plateaus by focusing
              on areas of weakness.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default function CueMateEcosystem() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <Title />
      <EcosystemOverview />
      <Components />
      <AugmentedTraining />
      <Container className="padding-bottom-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

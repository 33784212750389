import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Col, Container, Row, Table } from 'reactstrap';
import ModalVideoPlayer from '../../../components/ModalVideoPlayer';

import cuematetrainingloop from '../../../images/home/trainingloop.png';
import assessmentlevels from '../../../images/concepts/icons/assessmentlevels.png';
import repertoire from '../../../images/concepts/icons/repertoire.png';
import strokesandshots from '../../../images/concepts/icons/strokesandshots.png';
import trainingloop from '../../../images/concepts/icons/trainingloop.png';

import { Level1Description, Level1KeyConcepts, Level1Features } from './level1/L1ToC';
import { Level2Description, Level2KeyConcepts, Level2Features } from './level2/L2ToC';
import { Level3Description, Level3KeyConcepts, Level3Features } from './level3/L3ToC';

import connectingASensor from '../../../images/cuemate101/thumbnails/connecting-a-sensor.png';
import recordingASession from '../../../images/cuemate101/thumbnails/recording-a-session.png';
import readingTheStrokeMap from '../../../images/cuemate101/thumbnails/reading-the-stroke-map.png';
import extendedTagging from '../../../images/cuemate101/thumbnails/extending-tagging.png';

import reviewStrokeMap from '../../../images/cuemate101/thumbnails/review-stroke-map.png';
import reviewSessionReport from '../../../images/cuemate101/thumbnails/review-session-report.png';
import reviewClassReport from '../../../images/cuemate101/thumbnails/review-class-report.png';
import playerProfile from '../../../images/cuemate101/thumbnails/player-profile.png';
import generalCues from '../../../images/cuemate101/thumbnails/general-cues.png';
import trainingGoals from '../../../images/cuemate101/thumbnails/training-goal.png';
import customTraining from '../../../images/cuemate101/thumbnails/custom-training.png';

import PdfLink from '../home/guides/PdfLink';

import './CueMate101.css';

const setupPDFURL = 'docs/CueMateSetUp.pdf';
const setupLinkText = 'CueMate Set-up';

export default function CueMate101() {
  const [isOpen, setOpen] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const toggle = (link) => {
    setVideoLink(link);
    setOpen(!isOpen);
  };

  return (
    <Container className="padding-bottom-25 animated fadeIn">
      <ModalVideoPlayer open={isOpen} toggleModal={() => toggle('')} videoLink={videoLink} />
      <Row>
        <Col sm="12">
          <h1>CueMate 101</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <p>
            CueMate 101 is designed to help you get up-and running with CueMate, and show you how
            augmented training can accelerate your progress.
          </p>
          <p>The material is organized in four sections:</p>
          <ul>
            <li>Essentials on the Court</li>
            <li>Review your Sessions</li>
            <li>Train with CueMate</li>
            <li>Advanced Training Features</li>
          </ul>
          <PdfLink pdfURL={setupPDFURL} linkText={setupLinkText} show />
          <Link to="/download-app/XD"> Download the App </Link>
        </Col>
        <Col sm="4">
          <img
            src={cuematetrainingloop}
            className="d-block mx-auto img-fluid"
            alt="CueMate Training Loop"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25">
        <Table striped responsive>
          <thead>
            <tr>
              <th className="table-header">
                <img
                  src={repertoire}
                  className="d-block mx-auto img-fluid concept-icon-sm pb-2"
                  alt="Level 1: Essentials on the Court"
                />
                <h3 className="bold">
                  <Link to="/cuemate101/level1">1. Essentials on the Court</Link>{' '}
                </h3>
              </th>
              <th className="table-header">
                <img
                  src={strokesandshots}
                  className="d-block mx-auto img-fluid concept-icon-sm pb-2"
                  alt="Level 2: Review your Sessions"
                />
                <h3 className="bold">
                  <Link to="/cuemate101/level2">2: Review your Sessions</Link>{' '}
                </h3>
              </th>
              <th className="table-header">
                <img
                  src={assessmentlevels}
                  className="d-block mx-auto img-fluid concept-icon-sm pb-2"
                  alt="Level 3: Train with CueMate"
                />
                <h3 className="bold">
                  <Link to="/cuemate101/level3">3: Train with CueMate</Link>{' '}
                </h3>
              </th>
              <th>
                <img
                  src={trainingloop}
                  className="d-block mx-auto img-fluid concept-icon-sm pb-2"
                  alt="Level 4: Advanced Training Features"
                />
                <h3 className="bold">
                  <Link to="/cuemate101/level4">4: Advanced Training Features</Link>{' '}
                </h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Level1Description />
              </td>
              <td>
                <Level2Description />
              </td>
              <td>
                <Level3Description />
              </td>
              <td>
                Learn the specialized and advanced features
                <ul>
                  <li>Learn to use more technical metrics to optimize your strokes</li>
                  <li>Learn how to set custom training goals</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Videos
                <ul>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781991396')}
                          className="button-none"
                        >
                          <img src={connectingASensor} alt="Connecting a Sensor" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Connecting a Sensor</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781991880')}
                          className="button-none"
                        >
                          <img src={recordingASession} alt="Recording a Session" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Recording a Session</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781991742')}
                          className="button-none"
                        >
                          <img src={readingTheStrokeMap} alt="Play - Stroke Map" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Play - Stroke Map</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781996127')}
                          className="button-none"
                        >
                          <img src={extendedTagging} alt="Extended Tagging" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Sets and Extended Tagging</div>
                    </div>
                  </li>
                </ul>
              </td>
              <td>
                Videos
                <ul>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781995176')}
                          className="button-none"
                        >
                          <img src={reviewStrokeMap} alt="Review - Stroke Map" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Review - Stroke Map</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781995207')}
                          className="button-none"
                        >
                          <img src={reviewClassReport} alt="Class Report - 1" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Class Report - 1</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781995199')}
                          className="button-none"
                        >
                          <img src={reviewClassReport} alt="Class Report - 2" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Class Report - 2</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/781995226')}
                          className="button-none"
                        >
                          <img src={reviewSessionReport} alt="Session Report" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Session Report</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/784038920')}
                          className="button-none"
                        >
                          <img src={playerProfile} alt="Player Profile" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">Player Profile</div>
                    </div>
                  </li>
                </ul>
              </td>
              <td>
                Videos
                <ul>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => toggle('https://vimeo.com/784039082')}
                          className="button-none"
                        >
                          <img src={generalCues} alt="General Cues" width="128px" />
                        </button>
                      </div>
                      <div className="flex-grow-1 ms-1">General Cues</div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img src={trainingGoals} alt="Training Goals" width="128px" />
                      </div>
                      <div className="flex-grow-1 ms-1">Training Goals</div>
                    </div>
                  </li>
                </ul>
              </td>
              <td>
                Videos
                <ul>
                  <li>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img src={customTraining} alt="..." width="128px" />
                      </div>
                      <div className="flex-grow-1 ms-1">Train - Custom Training</div>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <Level1KeyConcepts />
              </td>
              <td>
                <Level2KeyConcepts />
              </td>
              <td>
                <Level3KeyConcepts />
              </td>
              <td>
                <b>Key Concepts</b>
                <ul>
                  <li>Technique Metrics</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <Level1Features />
              </td>
              <td>
                <Level2Features />
              </td>
              <td>
                <Level3Features />
              </td>
              <td>
                Features
                <ul>
                  <li>Custom Training Goals</li>
                  <li>Personalized Reference Ranges</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review5 from '../../../../images/cuemate101/level2/review/review-5.png';
import review6 from '../../../../images/cuemate101/level2/review/review-6.png';
import review7 from '../../../../images/cuemate101/level2/review/review-7.png';
import review8 from '../../../../images/cuemate101/level2/review/review-8.png';
import review9 from '../../../../images/cuemate101/level2/review/review-9.png';

import '../AppFeatures.css';

export default function L2OutcomeMetrics() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>SESSION REVIEW: OUTCOME METRICS</h2>
          <h3>BACKHAND TOPSPIN STROKE</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <ol>
            <li>Tap the core Backhand Topspin stroke</li>
            <img src={review5} className="d-block mx-auto img-fluid" alt="Stroke Map" />
          </ol>
        </Col>
        <Col sm="6">
          <ol start="2">
            <li>View outcome metrics for that stroke class</li>
            <img src={review6} className="d-block mx-auto img-fluid" alt="Outcomes" />
            <span className="blue">Tap on a Metric to view ‘Metric Trends’</span>
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="9">
          <ol start="3">
            <li>View graphs for each Outcome Metric: Spin, Pace, Success</li>
            <figure className="figure">
              <img
                src={review7}
                className="d-block mx-auto img-fluid"
                alt="GS Backhand Topspin L Spin"
              />
              <figcaption className="figure-caption blue">Spin increases in Set 3</figcaption>
            </figure>
          </ol>
        </Col>
        <Col sm="3">
          <br />
          <div className="overlay">
            <h4>Set Descriptions</h4>
            <p> Set 1: Warm-up</p>
            <p>Set 2: Baseline</p>
            <p>Set 3: Backhand Warm-up + Cue</p>
            <p>Set 4: Backhand Rally + Cue</p>
            <p>Set 5: Backhand Rally</p>
            <p>Set 6: BH/FH Rally + Cue</p>
            <p>Set 7: Performance Evaluation</p>
          </div>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="6">
          <img
            src={review8}
            className="d-block mx-auto img-fluid"
            alt="GS Backhand Topspin L Pace"
          />
          <span className="blue">
            Pace was mostly maintained despite the focus on generating more spin
          </span>
        </Col>
        <Col sm="6">
          <img
            src={review9}
            className="d-block mx-auto img-fluid"
            alt="GS Backhand Topspin L Pace Success"
          />
          <span className="blue">
            Impact Success Rate (hitting the racket “Sweet-Spot”) was significantly higher during
            the final evaluation set
          </span>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/finish">
            <h3>
              Next: End Session <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { cuemateGET, cuematePOST } from '../../../../libs/dataAccess';
import onError from '../../../../libs/errorLib';

const RACKET_GRIP_SIZE = [
  { key: '4(#0)', value: '4 (#0)' },
  { key: '41/8(#1)', value: '4 1/8 (#1)' },
  { key: '41/4(#2)', value: '4 1/4 (#2)' },
  { key: '43/8(#3)', value: '4 3/8 (#3)' },
  { key: '41/2(#4)', value: '4 1/2 (#4)' },
  { key: '45/8(#5)', value: '4 5/8 (#5)' },
  { key: '43/4(#6)', value: '4 3/4 (#6)' },
];

const getTennisRacketBrands = async () => {
  let response;
  try {
    const currentSession = await Auth.currentSession();
    response = await cuemateGET('/gear/rackets/brands', currentSession.accessToken.jwtToken);
    const { data = [] } = response;
    return data;
  } catch (error) {
    onError(error, true);
  }
  return [];
};

const getTennisRacketsByBrand = async (brand) => {
  let response;
  try {
    const currentSession = await Auth.currentSession();
    response = await cuemateGET(
      `/gear/rackets?brand=${brand}`,
      currentSession.accessToken.jwtToken
    );
    const { data = [] } = response;
    return data;
  } catch (error) {
    onError(error, true);
  }
  return [];
};

const createUserRacket = async (racket) => {
  let response = {};
  try {
    const currentSession = await Auth.currentSession();
    response = await cuematePOST(
      `/users/gear/rackets`,
      currentSession.accessToken.jwtToken,
      racket
    );
  } catch (error) {
    onError(error, true);
  }
  return response;
};

export default function AddRacket({ racketAdded, onRacketAdded }) {
  const [modal, setModal] = useState(false);
  const [tennisRacketBrands, setTennisRacketBrands] = useState([]);
  const [tennisRacketsByBrand, setTennisRacketsByBrand] = useState([]);
  const [selectedRacketBrand, setSelectedRacketBrand] = useState('');
  const [selectedRacketModel, setSelectedRacketModel] = useState('');
  const [selectedRacketGripSize, setSelectedGripSize] = useState('');
  const [selectedRacketNickname, setSelectedRacketNickname] = useState('');
  const [selectedRacketNotes, setSelectedRacketNotes] = useState('');

  const toggle = async () => {
    if (!modal) {
      setTennisRacketBrands(await getTennisRacketBrands());
    }
    setModal(!modal);
  };
  useEffect(() => {
    async function onLoad() {
      try {
        const tennisRacketsByBrandsResponse = await getTennisRacketsByBrand(selectedRacketBrand);
        setTennisRacketsByBrand(tennisRacketsByBrandsResponse);
      } catch (error) {
        onError(error, true);
      }
    }
    if (selectedRacketBrand.length > 0 && selectedRacketBrand !== '-1') {
      onLoad();
    } else {
      setTennisRacketsByBrand([]);
    }
  }, [selectedRacketBrand]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let racket = {
        brand: selectedRacketBrand,
        model: selectedRacketModel,
        gripSize: selectedRacketGripSize,
        nickname: selectedRacketNickname,
        notes: selectedRacketNotes,
      };
      racket = Object.fromEntries(Object.entries(racket).filter(([, v]) => v !== '' && v !== '-1'));
      if (Object.keys(racket).length > 0) {
        racket.remoteId = Date.now() * 1000;
        const response = await createUserRacket(racket);
        if (Object.keys(response).length > 0) onRacketAdded(racketAdded + 1);
      }
    } catch (error) {
      onError(error, true);
    }
    toggle();
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={() => {
          toggle();
        }}
      >
        Add Racket
      </Button>
      <Modal
        isOpen={modal}
        toggle={() => {
          toggle();
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          Add Racket
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col sm="4">
                <FormGroup>
                  <Label for="brand">Brand</Label>
                  <Input
                    id="brand"
                    name="brand"
                    type="select"
                    onChange={(e) => setSelectedRacketBrand(e.target.value)}
                  >
                    <option value="-1">Select an option</option>
                    {tennisRacketBrands.length > 0
                      ? tennisRacketBrands.map((option) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <option key={option.key} value={option.value}>
                            {option.value}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="model">Model</Label>
                  <Input
                    id="model"
                    name="model"
                    type="select"
                    onChange={(e) => setSelectedRacketModel(e.target.value)}
                  >
                    <option value="-1">Select an option</option>
                    {tennisRacketsByBrand.length > 0
                      ? tennisRacketsByBrand.map((option) => (
                          <option key={option.key} value={option.value}>
                            {option.value}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="4">
                <FormGroup>
                  <Label for="gripSize">Grip Size</Label>
                  <Input
                    id="gripSize"
                    name="gripSize"
                    type="select"
                    onChange={(e) => setSelectedGripSize(e.target.value)}
                  >
                    <option value="-1">Select an option</option>
                    {RACKET_GRIP_SIZE.map((option) => (
                      <option key={option.key} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="nickname">Nickname</Label>
                  <Input
                    id="nickname"
                    name="nickname"
                    onChange={(e) => setSelectedRacketNickname(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="notes">Notes</Label>
                  <Input
                    id="notes"
                    name="notes"
                    type="textarea"
                    onChange={(e) => setSelectedRacketNotes(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={selectedRacketBrand === '' || selectedRacketBrand === '-1'}
            onClick={(event) => {
              handleSubmit(event);
            }}
          >
            Add Racket
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => {
              toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

AddRacket.propTypes = {
  racketAdded: PropTypes.number,
  onRacketAdded: PropTypes.func,
};

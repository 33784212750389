import React from 'react';
import { Col, Container, Media, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import playoverview from '../../../../images/screens/play/Play-ABCDE.gif';
import playA from '../../../../images/screens/play/Play-A.png';
import playB from '../../../../images/screens/play/Play-B.png';
import playC from '../../../../images/screens/play/Play-C.png';
import playD from '../../../../images/screens/play/Play-D.png';
import playE from '../../../../images/screens/play/Play-E.png';
import playmappopulated from '../../../../images/screens/Play-StrokeMap.PNG';
import playlive from '../../../../images/screens/Play-Live.PNG';
import playlist from '../../../../images/screens/Play-ListofStrokes.PNG';
import labelA from '../../../../images/screens/labels/A.png';
import labelB from '../../../../images/screens/labels/B.png';
import labelC from '../../../../images/screens/labels/C.png';
import labelD from '../../../../images/screens/labels/D.png';
import labelE from '../../../../images/screens/labels/E.png';
import './AppFeatures.css';

export default function HomeScreen() {
  const strokeMapContent = (
    <Row className="padding-top-100" id="stroke-map">
      <Col sm="7" className="padding-top-50">
        <Media>
          <Media tag="span" left>
            <img src={labelA} className="label-header-max-height" alt="Label A" />
          </Media>
          <Media body>
            <h2>Stroke Map</h2>
          </Media>
        </Media>
        <p className="padding-top-50">
          CueMate tracks and organizes your repertoire of groundstrokes and volleys onto a 3x6 table
          called the Stroke Map. Serves are mapped onto 3x3 table. You can reference the Stroke Map
          to discern shot quality for each stroke type.
        </p>
      </Col>
      <Col sm="5" className="padding-top-50">
        <img src={playA} className="d-block mx-auto img-fluid" alt="Stroke Map" />
      </Col>
    </Row>
  );

  return (
    <Container className="padding-top-50 padding-bottom-100 animated fadeIn">
      <Row>
        <Col sm="12">
          <h1>Play Screen</h1>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="padding-top-50">
          <img src={playoverview} className="d-block mx-auto img-fluid" alt="Play screen" />
        </Col>
        <Col sm="4" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelA} className="label-max-height" alt="Label A" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/play-screen#stroke-map">
                  Stroke Map
                </Link>
              </h3>
            </Media>
          </Media>

          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/play-screen#session-set-information">
                  Session/Set Information
                </Link>
              </h3>
            </Media>
          </Media>

          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/play-screen#session-set-controls">
                  Session/Set Controls
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/play-screen#live-view">
                  Live View
                </Link>
              </h3>
            </Media>
          </Media>
          <Media>
            <Media tag="span" left>
              <img src={labelE} className="label-max-height" alt="Label E" />
            </Media>
            <Media body>
              <h3>
                <Link smooth to="/cuemate-ios/play-screen#play-list">
                  Play List
                </Link>
              </h3>
            </Media>
          </Media>
        </Col>
      </Row>

      {strokeMapContent}

      <Row className="padding-top-100" id="session-set-information">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelB} className="label-header-max-height" alt="Label B" />
            </Media>
            <Media body>
              <h2>Session Set Information</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The Session/Set Information panel is located on the left-hand side of the screen. The
            top section has time and outcome-based comparators for the current set versus previous
            sessions. The rest of the panel shows whether a cue is turned on or off for the current
            set, time elapsed, and total stroke count for the current set as well as the full
            session.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={playB} className="d-block mx-auto img-fluid" alt="Session Set Information" />
        </Col>
      </Row>

      <Row className="padding-top-100" id="session-set-controls">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelC} className="label-header-max-height" alt="Label C" />
            </Media>
            <Media body>
              <h2>Session/Set Controls</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            The Session/Set Controls remains constant on the right-hand side of the Play screen.
          </p>
          <p>There are four possible actions within the Session/Set Controls, described below:</p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={playC} className="d-block mx-auto img-fluid" alt="Session/Set Controls" />
        </Col>
      </Row>
      <Row>
        <Col sm="3" className="padding-top-50">
          <h3>Tag Set</h3>
          <p>
            {' '}
            Select the tag icon to manually tag elements of your current set: opponent, type, emoji,
            and general notes.
          </p>
        </Col>
        <Col sm="3" className="padding-top-50">
          <h3>Pause Set</h3>
          <p> Select the pause icon to pause time for your current set and session.</p>
        </Col>
        <Col sm="3" className="padding-top-50">
          <h3>Add New Set</h3>
          <p> Select the add set icon to add a new set to your session. </p>
        </Col>
        <Col sm="3" className="padding-top-50">
          <h3>End Session</h3>
          <p>
            {' '}
            To complete your session, select the END button at the bottom of the Session/Set
            Organizer. After completing your session, you will be taken to the{' '}
            <Link to="/cuemate-ios/reports-screen">Reports</Link> screen to view your session
            report.{' '}
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <h3>Organize Your Strokes</h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <p>
            The Stroke Map organizes forehand and backhand groundstrokes, serves, and volleys by
            spin (topspin, flat, and slice) and by intensity (hard, medium, and light).
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25">
          <figure className="figure">
            <img
              src={playmappopulated}
              className="d-block mx-auto img-fluid img-border"
              alt="Playmap populated"
            />
            <figcaption className="figure-caption">
              The 18-cell Stoke Map organizes all possible types of groundstrokes, serves and
              volleys
            </figcaption>
          </figure>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="padding-top-50">
          <h3>Determine stroke quality</h3>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <p>
            The quality of each stroke and its outcomes are conveyed on the Stroke Map. Each stroke
            composite metric quality is compared to historic stroke composite metric quality. The
            specific timescale being compared (e.g., current set v. prev 3 sessions) is determined
            by the time assessment selected in the time filter on the top right of the screen.
          </p>
        </Col>
        <Col sm="6">
          <p>
            Decline is marked in <span className="badRed">RED</span> and improvement is marked in{' '}
            <span className="goodGreen">GREEN</span>.
          </p>
          <p>
            The minimum amount of data needed for CueMate to sufficiently be able to assess and
            diagnose a stroke type is 20 strokes. Note that strokes within the Stroke Map remain
            empty — not filled in with grey — until you hit 20 strokes of that stroke type.
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm="6" className="padding-top-50">
          <h3>Navigate to Metric Assessment</h3>
          <p>
            The <span className="badRed">RED</span> and <span className="goodGreen">GREEN</span>{' '}
            outlines on the Stroke Map provide a general overview of stroke and stroke outcome
            quality. You can dive deeper into outcomes — as well as stroke performance and technique
            — by selecting a stroke type. This takes you to the metric screen, where you can analyze
            metrics in detail to determine which element(s) of your stroke should be developed.
          </p>
        </Col>
        <Col sm="6" className="padding-top-50">
          <h3>Determine Gaps in your Repertoire</h3>
          <p>
            You can also use CueMate to identify gaps in your repertoire, or vocabulary, of strokes.
            As you play, the Stroke Map is populated with unique stroke counts. The best tennis
            players have a broad repertoire of strokes and can respond to any shot. You can become a
            more versatile player by challenging yourself to populate the entire stroke map.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-100" id="live-view">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelD} className="label-header-max-height" alt="Label D" />
            </Media>
            <Media body>
              <h2>Live View</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Click VIEW LIVE button to access Live View, a helpful tool for your coach or friend to
            see characteristics of your last stroke used — in real time. Live View provides the
            current outcome, technique, and performance metrics for the last stroke used, impact
            location on the racket, and the stroke path.
          </p>
          <p>
            The bottom of the screen provides a quick navigation for the last 5 strokes hit in case
            you want to view back in recent history.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={playD} className="d-block mx-auto img-fluid" alt="Live View" />
        </Col>
      </Row>

      <Row>
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-50">
          <figure className="figure">
            <img
              src={playlive}
              className="d-block mx-auto img-fluid img-border"
              alt="Play live screen"
            />
            <figcaption className="figure-caption">
              Example Live Mode with Forehand Topspin L as last stroke used.
            </figcaption>
          </figure>
        </Col>
      </Row>

      <Row className="padding-top-100" id="play-list">
        <Col sm="7" className="padding-top-50">
          <Media>
            <Media tag="span" left>
              <img src={labelE} className="label-header-max-height" alt="Label E" />
            </Media>
            <Media body>
              <h2>Play List</h2>
            </Media>
          </Media>
          <p className="padding-top-50">
            Stroke types are listed out in order of usage as a percentage of your entire repertoire.
            Each stroke type includes usage as a percentage of repertoire, sweet spot, spin, and
            speed for the set.
          </p>
        </Col>
        <Col sm="5" className="padding-top-50">
          <img src={playE} className="d-block mx-auto img-fluid" alt="List of Strokes" />
        </Col>
      </Row>
      <Row className="padding-bottom-100">
        <Col sm={{ size: 8, offset: 2 }} className="padding-top-25">
          <figure className="figure">
            <img
              src={playlist}
              className="d-block mx-auto img-fluid img-border"
              alt="Playlist screen"
            />
            <figcaption className="figure-caption">
              The most used stroke type in Set 2 is Forehand Topspin Hard (FTH).
            </figcaption>
          </figure>
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import '../AppFeatures.css';

export default function CourtSession2() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Court Session 2</h1>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <h4 className="bold">TRAINING CUES EXERCISE</h4>
          <p>
            Training Cues allow players to focus on improving a single stroke element. In this
            example the player is training to increase spin on their Backhand stroke. Upon
            completion of this exercise, you can repeat these same steps to improve other stroke
            elements.
          </p>
        </Col>
      </Row>
      <hr />

      <h4 className="blue">TRAINING TOOLS</h4>
      <Row className="padding-bottom-25">
        <Col sm={6}>
          <span className="bold">Training Elements</span>
          <br />
          Stroke Class: Backhand Topspin Light
          <br />
          +
          <br />
          Stroke Metric: Spin
          <br />= ‘BTL Spin’
        </Col>
        <Col sm={6}>
          <span className="bold">Training Goal</span>
          <br />
          BTL Spin of 1,264 rpm
          <br />
          Player’s baseline BTL Spin = 1,066 rpm
          <br />
          <span className="bold">Custom Cue</span>
          <br />
          BTL Spin (Secondary Cue FTM Spin)
        </Col>
      </Row>

      <h4 className="blue">COURT SESSION OVERVIEW</h4>
      <Row className="padding-bottom-25">
        <Col sm={6}>
          <p>
            <span className="bold">Set 1: Warmup</span>
            <br />- Rally to ‘Warm up
          </p>
          <p>
            <span className="bold">Set 2: Performance Baseline</span>
            <br />- Rally to record stroke counts and stroke metrics
          </p>
          <p>
            <span className="bold">&emsp;&emsp;Review Set</span>
            <br />
            &emsp;&emsp;- Use Stroke Map to identify two Core Strokes
            <br />
            &emsp;&emsp;(1 Backhand Topspin and 1 Forehand Topspin)
            <br />
            &emsp;&emsp;- For each, add a Training Goal for the Spin metric
          </p>
          <p>
            <span className="bold">Set 3: Backhand Warmup - w/ Cue</span>
            <br />- Turn on Spin Cues
            <br />- Racket feed, return using only the Backhand stroke
            <br />- Use cue feedback to get a feel for how stroke adjustments impact spin
          </p>
          <p>
            <span className="bold">Set 4: Backhand Rally - w/ Cue</span>
            <br />- Rally to practice Backhand stroke under more challenging conditions
            <br />- Use cues to retain increased spin
          </p>
          <p>
            <span className="bold">Set 4: Backhand Rally - No Cue</span>
            <br />- Rally again using only Backhand stroke
            <br />- Try to maintain spin without spin cue
          </p>
          <p>
            <span className="bold">Set 6: Backhand + Forehand Rally - w/ Cue</span>
            <br />- Rally using BH and FH, with spin cues
            <br />- Pay attention to the stroke adjustments that reliably increase spin
          </p>
          <p>
            <span className="bold">Set 7: Progress Evaluation – No Cue</span>
            <br />- Rally a final set to measure improvement
            <br />- Use both the Backhand and Forehand strokes
            <br />- Focus on generating spin while maintaining stroke success and keeping ball in
            play
          </p>
        </Col>
        <Col sm={6}>
          <div className="overlay">
            <h4 className="blue">SESSION REVIEW</h4>
            <p>
              <span className="bold">Stroke Maps</span>
              <br />- Was there a difference in stroke class distribution on Stroke Maps 1 – 7?
              <br />- Were there more flats during rallying vs warmup sets?
              <br />- How did your Backhand/Forehand ratio change? Did you perform more Backhand
              strokes after training?
            </p>
            <p>
              <span className="bold">Stroke Metrics</span>
              <br />- How did spin change over the course of the session?
              <br />- Did an increase in spin result in a decrease in performance on any other
              metrics?
              <br />- Was increase in spin maintained after cueing was turned off?
            </p>
            <p>
              <span className="bold">Next Session</span>
              <br />- Train against this goal using different drills
              <br />- Try using Training Cues on other training elements (e.g. ‘Forehand Flat Hard’
              stroke with Pace metric)
            </p>
          </div>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2/court-exercise/set-1">
            <h3>
              Next: Set 1 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import hierarchicalSkillModelAndAssessment from '../../../../images/concepts/hierarchical_skill_model_and_assessment.png';
// import outcomeReferenceRangesGraph2 from '../../../../images/concepts/outcomes-reference-ranges-graph2.png';

function SAOverview() {
  return (
    <Container className="padding-bottom-25">
      <Row className="mb-3">
        <Col sm="12">
          <h1>Skill Assessments </h1>
          <h3>
            The amount of data generated from measurements can easily be overwhelming. The core of
            CueMate’s skill assessment is the hierarchical skill model which organizes the
            information following how the brain performs and learns movement behavior.
          </h3>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <p>
            Recall that your skill level and general performance on the court depend on a wide range
            of different factors and dimensions. For example, your stroke performance alone depends
            on your preparation, setup, stroke execution.
          </p>
          <p>
            The current emphasis of the skill assessment is the stroke performance, encompassing
            aspects from strike quality, consistency, technique, and overall quality in terms of
            generating key outcomes such as spin and ball spin.
          </p>
          <Link to="/background">
            <p>
              See background page for more details about CueMate’s current emphasis{' '}
              <i className="fa fa-angle-right" />{' '}
            </p>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

function MovementAndSkillModel() {
  return (
    <Container>
      <Row className="mb-3">
        <Col sm={12}>
          <h2>Movement and Skill Model</h2>
          <p>
            CueMate analytics and scores are organized following the hierarchical skill model as
            shown in the diagram bellow.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={{ offset: 2, size: 8 }}>
          <img
            src={hierarchicalSkillModelAndAssessment}
            className="d-block mx-auto img-fluid img-border"
            alt="Hierarchical Skill Model and Assessment"
          />{' '}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Stroke Class Metrics</h3>
          <p>
            Each stroke class aggregates hundreds or more strokes that share similar characteristics
            defining the class. With this number of strokes, it is possible to use statistics to
            describe your performance and technique across a range of characteristics.
          </p>
          <Link to="/key-concepts/stroke-metrics">
            <p>
              See metrics section for more detailed information <i className="fa fa-angle-right" />{' '}
            </p>
          </Link>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Stroke Class Scores</h3>
          <p>
            The stroke class scores are computed by combining the various stroke metrics and are
            designed to assess how you are doing across these different aspects of performance.
          </p>
          <p>CueMate uses the following Class Scores:</p>
          <p className="fst-italic">Strike Quality Score</p>
          <p>
            Captures the quality of the ball strike (impact timing, sweet spot, and precision).
            Strike quality is necessary for a good outcome but not sufficient.
          </p>
          <p>
            A good score is achieved by anticipating the incoming shot, preparing and setting up for
            the stroke, and finally executing the stroke.
          </p>
          <p className="fst-italic">Outcome Efficiency Score</p>
          <p>
            As its name implies, captures the efficiency of your technique in producing the stroke
            outcomes. It is a good measure of your technique, i.e., how well you use your body
            biomechanics to generate spin and speed.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Outcome Level Rating</h3>
          <p>
            Captures the level of the stroke’s spin and ball speed outcomes relative to the player
            population across all groups. CueMate considers three levels from low, medium, and high.{' '}
          </p>
          <p>
            This rating captures the quality of your strokes in achieving the spin and ball speed
            outcomes and makes it possible to understand where your different classes fall across
            the repertoire.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Skill Rating</h3>
          <p>
            CueMate’s Skill Rating was developed to address the difficulty of rating skills
            reliably. The Skill Rating considers the learning process, i.e., how you acquire the
            repertoire of strokes.
          </p>
          <Link to="/key-concepts/learning-model">
            <p>
              See learning model for more details <i className="fa fa-angle-right" />{' '}
            </p>
          </Link>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Global Score and Leaderboard</h3>
          <p>
            Given the multi-dimensional nature of skills, it is difficult to reliably reduce your
            skills to a single score. Therefore, the global score and leaderboard ranking is only
            indicative. In particular skills relies on the ability of building and winning points
            and other aspect of game strategies and mental game.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <h3 className="blueGrey">Activity Profile</h3>
          <p>
            The most fundamental aspect of training is to get enough repetitions of a particular
            stroke under a range of conditions—your brain cannot learn without going out on the
            court and performing. As biological systems we cannot be programmed like a machine.
          </p>
          <p>
            The activity profile tracks you level of activity across the different stroke categories
            and classes. If you aren’t doing well in a particular stroke class it is often because
            you are not practicing that stroke enough.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <Table bordered className="column-same-width">
            <thead>
              <tr>
                <th>Stroke Class Assessment Types</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Stroke Class Metrics</td>
                <td>
                  {' '}
                  Strokes in the same class focuses on attributes of the strokes in a class,
                  different aspects:{' '}
                  <ul className="with-spacing">
                    <li>Outcomes: Spin and Speed</li>
                    <li>Technique</li>
                    <li>Physical performance</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Stroke Class Scores</td>
                <td>
                  Combines the metrics to capture more macroscopic aspects of behavior:
                  <ul className="with-spacing">
                    <li>Strike Quality</li>
                    <li>Stroke Quality</li>
                  </ul>{' '}
                </td>
              </tr>
              <tr>
                <td>Stroke Class Ratings</td>
                <td>
                  More comprehensive aspects such as:
                  <ul className="with-spacing">
                    <li>Outcome levels</li>
                    <li>Learning stage: memory formation</li>
                  </ul>{' '}
                  These ratings capture macroscopic associated with the learning process
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <Table bordered className="column-same-width">
            <thead>
              <tr>
                <th>Stroke Class Scores</th>
                <th>Interpretation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Strike Quality Score</td>
                <td>
                  Shot anticipation <br /> Stroke preparation
                </td>
              </tr>
              <tr>
                <td>Stroke Outcome Level</td>
                <td>Stroke level of outcomes (spin and speed).</td>
              </tr>
              <tr>
                <td>Stroke Outcome Efficiency</td>
                <td>
                  Efficiency in achieving the outcomes. Low values indicate deficiencies in stroke
                  technique including use of the body (kinematic chain).
                </td>
              </tr>
              <tr>
                <td>Stroke Composite Score</td>
                <td>Combines all the stroke metrics to create a composite score.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <Table bordered className="column-same-width">
            <thead>
              <tr>
                <th>Repertoire Metrics</th>
                <th>Interpretation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Repertoire Distributions </td>
                <td>
                  Distribution of the stroke classes how you specialize your strokes to best respond
                  to different conditions and outcomes.
                </td>
              </tr>
              <tr>
                <td>Forehand/backhand ratio</td>
                <td>Are you using enough backhands?</td>
              </tr>
              <tr>
                <td>Intensity Level</td>
                <td>The physical intensity of your strokes across the repertoire.</td>
              </tr>
              <tr>
                <td>Spin Differentiation</td>
                <td>How well can you generate spin across different intensity levels</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <Table bordered className="column-same-width">
            <thead>
              <tr>
                <th>Player Skill Ratings</th>
                <th>Interpretation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Skill Status</td>
                <td>Distribution of the learning stage across the repertoire.</td>
              </tr>
              <tr>
                <td>Skill Profile</td>
                <td>Distribution of the stroke composite score across the repertoire.</td>
              </tr>
              <tr>
                <td>Skill Rating</td>
                <td>
                  Rating between 1-5 for each stroke category (Ground strokes, volleys, and serves)
                  tells you how far along you are in the learning journey.
                </td>
              </tr>
              <tr>
                <td>Player total Score</td>
                <td>
                  Combines everything to provide an overall rating that is used for the leaderboard
                  ranking.
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={12}>
          <Table bordered className="column-same-width">
            <thead>
              <tr>
                <th>Player Activity Profile</th>
                <th>Interpretation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Player overall activity in terms of strokes, duration of play, per week, month.
                </td>
                <td>
                  Are you showing up on the court enough and regularly to make the desired progress
                  and sustain the desired level of performance.
                </td>
              </tr>
              <tr>
                <td>Activity types including games, training, free play</td>
                <td>
                  Are you playing enough games, training, etc. given your aspired player type.
                </td>
              </tr>
              <tr>
                <td>Activity across stroke categories and classes</td>
                <td>
                  Are you performing enough of the stroke categories and types to achieve the
                  desired level of progress and performance.
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

function Interpretation() {
  return (
    <Container className="padding-bottom-25">
      <Row className="mb-3">
        <Col sm="12">
          <h2>Interpretation</h2>
          <p>
            To help with the interpretation of the range of metrics, CueMate also provides features
            to
          </p>
          <ul className="with-spacing">
            <li>
              Class scores metric highlights: which metric leads changes in a particular class score
            </li>
            <li>Trends: tracks the evolution of a metric or score over time a given time period</li>
            <li>Reference Values: provide distribution of a metric or score value</li>
            <li>
              Reference Groups: defines the player subgroup used to derive the reference values
              (skill level, social groups, etc.)
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h3 className="blueGrey">Class Scores Interpretation</h3>
          <p>
            Since score are made by combining metrics, they can be used to determine which aspects
            that have the highest impact on your skills for a specific stroke class.
          </p>
          <p>
            For example, using the Stroke Quality Score it is possible to determine which metric is
            the leading contributor to a trend.
          </p>
          <p>
            This information is useful to determine what aspect to focus on to achieve the highest
            impact on stroke quality and performance.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h3 className="blueGrey">Trends</h3>
          <p>Another aspect of interpretation is by assessing changes over time.</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h3 className="blueGrey">Reference Values</h3>
          <p>
            The most central in assessment is using{' '}
            <Link to="/key-concepts/reference-ranges">reference ranges</Link>. The reference values
            make it possible to understand how metrics are typically distributed. To make these
            references most relevant, CueMate defines reference groups that reflect your level and
            style of play.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h3 className="blueGrey">Session Scores</h3>
          <p>
            Session scores provide information about how well you are doing overall across your
            sessions or sets.
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={12}>
          <h3 className="blueGrey">Global Score and Leaderboard</h3>
          <p>
            The Global Score combines the different scores to give you an overall picture of your
            skill level. Note that CueMate also has skill ratings that are used more specifically
            for training. The global score determines your rank in the leaderboard.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default function SkillAssessments() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>

      <SAOverview />
      <MovementAndSkillModel />
      <Interpretation />
      <Container className="padding-25">
        <Breadcrumbs />
      </Container>
    </>
  );
}

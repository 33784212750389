/* eslint no-undef: "error" */
/* eslint-env browser */
import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { AppContext } from '../../../libs/contextLib';
import LoaderButton from '../../../components/LoaderButton';
import CompleteSignupForm from '../signup/Signup';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: '',
      password: '',
      shouldShowCompleteSignupForm: false,
      isNewPasswordRequired: false,
      cognitoUser: null,
    };

    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSignupCompletion = this.onSignupCompletion.bind(this);
  }

  async componentDidMount() {
    const { location } = window;
    const parsed = qs.parse(location.search);
    const username = parsed.username || '';
    const password = parsed.password || '';
    this.setState({
      username,
      password,
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    let { username, password } = this.state;
    username = username.trim();
    password = password.trim();

    try {
      const user = await Auth.signIn(username, password);
      const hasCompletedSignup =
        user.attributes &&
        user.attributes['custom:hasCompletedSignup'] &&
        user.attributes['custom:hasCompletedSignup'] === '1';
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED' && !hasCompletedSignup) {
        this.setState({
          cognitoUser: user,
          shouldShowCompleteSignupForm: true,
          isNewPasswordRequired: true,
        });
      } else if (!hasCompletedSignup) {
        this.setState({
          cognitoUser: user,
          shouldShowCompleteSignupForm: true,
          isNewPasswordRequired: false,
        });
      } else {
        const { userHasAuthenticated } = this.context;
        userHasAuthenticated(true);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      // eslint-disable-next-line no-undef
      alert(`Error login: - ${e.message}`);
    }
  }

  async onSignupCompletion() {
    try {
      await Auth.currentSession();
      const { userHasAuthenticated } = this.context;
      userHasAuthenticated(true);
    } catch (e) {
      this.setState({ isLoading: false });
      // eslint-disable-next-line no-undef
      alert(e.message);
    }
  }

  validateForm() {
    const { username, password } = this.state;
    return username.trim().length >= 1 && password.trim().length >= 5;
  }

  render() {
    const {
      shouldShowCompleteSignupForm,
      cognitoUser,
      isNewPasswordRequired,
      username,
      password,
      isLoading,
    } = this.state;
    return shouldShowCompleteSignupForm ? (
      <CompleteSignupForm
        cognitoUser={cognitoUser}
        isNewPasswordRequired={isNewPasswordRequired}
        onSignupCompletion={this.onSignupCompletion}
      />
    ) : (
      <Container className="Login padding-bottom-25 animated fadeIn">
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
            <Form onSubmit={this.handleSubmit}>
              <h2>CueMate Beta Log In</h2>
              <hr className="colorgraph" />
              <FormGroup>
                <Input
                  type="text"
                  name="usernameInput"
                  id="username"
                  className="form-control"
                  placeholder="Username"
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                  autoCapitalize="off"
                  value={username}
                  onChange={this.handleChange}
                  autoFocus
                  required
                />
              </FormGroup>
              <FormGroup className="form-group">
                <Input
                  type="password"
                  name="passwordInput"
                  id="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  onChange={this.handleChange}
                  minLength="8"
                  required
                />
              </FormGroup>
              <Row>
                <Col xs="12" md="12" className="padding-bottom-25">
                  <LoaderButton
                    type="submit"
                    text="Login"
                    color="primary"
                    size="lg"
                    loadingText="Logging in..."
                    isLoading={isLoading}
                    disabled={!this.validateForm()}
                    block
                  />
                  <p className="margin-top-25">
                    <Link to="/password-reset">I forgot my password</Link>
                  </p>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

Login.contextType = AppContext;

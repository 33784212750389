import React from 'react';
import './FeedbackButton.css';

export default function FeedbackButton() {
  return (
    <div id="feedbackbutton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://cuemate-tennis.typeform.com/g-feedback"
        className="feedback btn-primary feedback-bttn-display"
      >
        Give Beta Feedback
      </a>
    </div>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level1/on-court/class-report/ClassReport-1.png';
import view2 from '../../../../images/cuemate101/level1/on-court/class-report/ClassReport-2.png';
import view3 from '../../../../images/cuemate101/level1/on-court/class-report/ClassReport-3.png';
import view4 from '../../../../images/cuemate101/level1/on-court/class-report/ClassReport-4.png';
import view5 from '../../../../images/cuemate101/level1/on-court/class-report/ClassReport-5.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Class Reports</h1>
          <p>
            Selecting a stroke class on a Stroke Map while playing or after play (in Review) will
            display the report for that stroke. Understanding what these reports offer is essential
            in improving the outcomes for that stroke class.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5}>
          <h3 className="blue">COMPARE W. REFERENCE GROUPS</h3>
          <p>
            You can put your stroke metrics in perspective with other players in your group and
            across all CueMate players.
          </p>
          <p>
            Knowing where you are lacking will help you to focus your training to those areas and
            help you become better than your peers.
          </p>
        </Col>
        <Col sm={7} className="padding-top-25">
          <img src={view1} className="d-block mx-auto img-fluid" alt="Class Report 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Class Report 2" />
          </Col>
          <Col sm={5}>
            <h3 className="padding-top-25 blue">RADAR PLOT</h3>
            <p>
              Compare your key stroke metrics (sweet spot, ball spin, ball speed, spin-speed Coeff
              and timing) with other players using the radar chart by selecting the reference groups
              in the dropdown as well as the session count toggle.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row className="padding-top-25">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">BAR PLOT</h3>
          <p>
            Toggling to the bar plot shows your metric averages and ranges indicated by four shades
            of blue from low to high.
          </p>
          <h3 className="blue">STROKE PROFILE</h3>
          <p>(in development)</p>
        </Col>
        <Col sm={7}>
          <img src={view3} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen4() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row className="padding-top-25">
          <Col sm={7}>
            <img src={view4} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
          </Col>
          <Col sm={5}>
            <h3 className="blue">LINE PLOT</h3>
            <p>The line plot shows the general trend of the metrics over time.</p>
            <h3 className="blue">IMPACT MAP</h3>
            <p>
              The impact map in these reports help you visualize where you have been hitting on the
              racket. Dots marked in red show strokes outside the sweet spot whereas green dots are
              in the sweet spot.
            </p>
            <p>
              If you have mishits or frequently hit the ball out of the court observing the impact
              map will help diagnose the problem and make you focus on hitting the sweet spot more
              often
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

function Screen5() {
  return (
    <Container className="padding-25">
      <Row className="padding-top-25">
        <Col sm={5}>
          {' '}
          <h3 className="blue padding-top-25">OUTCOME METRICS</h3>
          <p>Select ‘Metrics’ to explore stroke metrics and their trends.</p>
          <p>
            The scales show performance compared to the average player as well as a training goal if
            active.
          </p>
          <p>
            View player performance for the most recent session, or rolling averages over the
            previous 5, 10, or 15 sessions. These metrics are useful in focusing training on
            different aspects of your stroke technique.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view5} className="d-block mx-auto img-fluid" alt="Stroke Map Review" />
        </Col>
      </Row>
    </Container>
  );
}

export default function ClassReport() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />
      <Screen4 />
      <Screen5 />
      <Container className="padding-25">
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

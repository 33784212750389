import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Col, Row } from 'reactstrap';

/*

  Breadcrumbs: https://github.com/icd2k3/react-router-breadcrumbs-hoc
  // breadcrumbs can be any type of component or string
  const UserBreadcrumb = ({ match }) =>
    <span>{match.params.userId}</span>; // use match param userId to fetch/display user name
*/
// define some custom breadcrumbs for certain routes (optional)
const routes = [
  { path: '/home', breadcrumb: null },
  { path: '/home/session-syllabus', breadcrumb: 'Session Syllabus' },

  { path: '/background', breadcrumb: 'Background' },
  { path: '/background/how-cuemate-works', breadcrumb: 'How CueMate Works' },
  {
    path: '/background/hierarchical-movement-skill-model',
    breadcrumb: 'Movement and Skill Model',
  },
  {
    path: '/background/learning-model',
    breadcrumb: `CueMate's Learning Model`,
  },
  {
    path: '/background/hierarchical-skill-assessment-and-training',
    breadcrumb: 'Skill Assessment and Training',
  },
  { path: '/background/cuemate-ecosystem', breadcrumb: 'CueMate Ecosystem' },

  { path: '/key-concepts', breadcrumb: 'Key Concepts' },
  { path: '/key-concepts/strokes-and-shots', breadcrumb: 'Strokes and Shots' },
  { path: '/key-concepts/stroke-map', breadcrumb: 'Stroke Map' },
  { path: '/key-concepts/stroke-metrics', breadcrumb: 'Stroke Metrics' },
  { path: '/key-concepts/stroke-class-scores', breadcrumb: 'Stroke Class Scores' },
  { path: '/key-concepts/skill-assesments', breadcrumb: 'Skill Assessments' },
  { path: '/key-concepts/sessions-and-sets', breadcrumb: 'Sessions, Sets and Tagging' },
  { path: '/key-concepts/training-goals', breadcrumb: 'Training Goals' },
  { path: '/key-concepts/feedback-cueing', breadcrumb: 'Feedback Cueing' },
  { path: '/key-concepts/skill-rating', breadcrumb: 'Skill Rating and Game Styles' },
  { path: '/key-concepts/rating-based-training', breadcrumb: 'Rating-based Training' },
  { path: '/key-concepts/learning-model', breadcrumb: 'Learning Model' },
  { path: '/key-concepts/reference-ranges', breadcrumb: 'Reference Ranges' },

  { path: '/cuemate101/download-app', breadcrumb: 'Download App' },
  { path: '/cuemate101/install-app', breadcrumb: 'Install App' },
  { path: '/cuemate101/setup-sensor', breadcrumb: 'Setup Sensor' },

  { path: '/cuemate101', breadcrumb: 'CueMate 101' },
  { path: '/cuemate101/level1', breadcrumb: 'Level 1' },
  { path: '/cuemate101/level1/stroke-outcomes', breadcrumb: 'Stroke Outcomes' },
  { path: '/cuemate101/level1/stroke-map', breadcrumb: 'Stroke Map' },
  { path: '/cuemate101/level1/court-exercise', breadcrumb: 'Court Exercise' },
  { path: '/cuemate101/level1/court-exercise/set-1', breadcrumb: 'Set 1' },
  { path: '/cuemate101/level1/court-exercise/set-2', breadcrumb: 'Set 2' },
  { path: '/cuemate101/level1/court-exercise/set-3', breadcrumb: 'Set 3' },
  { path: '/cuemate101/level1/court-exercise/set-4', breadcrumb: 'Set 4' },
  { path: '/cuemate101/level1/court-exercise/set-5', breadcrumb: 'Set 5' },
  { path: '/cuemate101/level1/court-exercise/set-6', breadcrumb: 'Set 6' },
  {
    path: '/cuemate101/level1/court-exercise/review-stroke-map',
    breadcrumb: 'Review: Stroke Map',
  },
  {
    path: '/cuemate101/level1/court-exercise/review-outcome-metrics',
    breadcrumb: 'Review: Outcome Metrics',
  },
  { path: '/cuemate101/level1/court-exercise/finish', breadcrumb: 'Finish' },
  { path: '/cuemate101/level1/on-court', breadcrumb: null },
  { path: '/cuemate101/level1/on-court/recording-sessions', breadcrumb: 'Recording Sessions' },
  { path: '/cuemate101/level1/on-court/stroke-map', breadcrumb: 'Stroke Map' },
  { path: '/cuemate101/level1/on-court/session-reports', breadcrumb: 'Session Reports' },
  { path: '/cuemate101/level1/on-court/class-reports', breadcrumb: 'Class Reports' },
  { path: '/cuemate101/level1/on-court/session-review', breadcrumb: 'Session Review' },

  { path: '/cuemate101/level2', breadcrumb: 'Level 2' },
  { path: '/cuemate101/level2/core-strokes', breadcrumb: 'Core Strokes' },
  { path: '/cuemate101/level2/training-goals', breadcrumb: 'Training Goals' },
  { path: '/cuemate101/level2/training-cues', breadcrumb: 'Training Cues' },
  { path: '/cuemate101/level2/court-exercise', breadcrumb: 'Court Session 2' },
  { path: '/cuemate101/level2/court-exercise/set-1', breadcrumb: 'Set 1' },
  { path: '/cuemate101/level2/court-exercise/set-2', breadcrumb: 'Set 2' },
  { path: '/cuemate101/level2/court-exercise/set-3', breadcrumb: 'Set 3' },
  { path: '/cuemate101/level2/court-exercise/set-4', breadcrumb: 'Set 4' },
  { path: '/cuemate101/level2/court-exercise/set-5', breadcrumb: 'Set 5' },
  { path: '/cuemate101/level2/court-exercise/set-6', breadcrumb: 'Set 6' },
  { path: '/cuemate101/level2/court-exercise/set-7', breadcrumb: 'Set 7' },
  {
    path: '/cuemate101/level2/court-exercise/review-stroke-map',
    breadcrumb: 'Review: Stroke Maps',
  },
  {
    path: '/cuemate101/level2/court-exercise/review-outcome-metrics',
    breadcrumb: 'Review: Outcome Metrics',
  },
  { path: '/cuemate101/level2/court-exercise/finish', breadcrumb: 'Finish' },
  { path: '/cuemate101/level2/on-court', breadcrumb: null },
  { path: '/cuemate101/level2/on-court/rating-system', breadcrumb: 'Rating System' },
  { path: '/cuemate101/level2/on-court/training-goal-status', breadcrumb: 'Training Goal Status' },
  { path: '/cuemate101/level2/on-court/training-report', breadcrumb: 'Training Report' },

  { path: '/cuemate101/level3', breadcrumb: 'Level 3' },
  { path: '/cuemate101/level3/review', breadcrumb: 'Review' },
  { path: '/cuemate101/level3/reports', breadcrumb: 'Reports' },
  { path: '/cuemate101/level3/reports', breadcrumb: 'Browse' },
  { path: '/cuemate101/level3/metric-views', breadcrumb: 'Metric Views' },
  { path: '/cuemate101/level3/on-court', breadcrumb: null },
  { path: '/cuemate101/level3/on-court/advanced-metrics', breadcrumb: 'Advanced Metrics' },
  {
    path: '/cuemate101/level3/on-court/custom-training-goals',
    breadcrumb: 'Custom Training Goals',
  },

  { path: '/features', breadcrumb: null },
  { path: '/cuemate-ios/metrics/stroke-class', breadcrumb: 'Stroke Class' },
  { path: '/cuemate-ios/metrics/repertoire', breadcrumb: 'Repertoire' },
  { path: '/cuemate-ios/metrics/class-ensemble', breadcrumb: 'Class Ensemble' },

  { path: '/cuemate-ios/training', breadcrumb: 'Training' },
  { path: '/cuemate-ios/training/skill-assessment', breadcrumb: 'Skill Assessment' },
  { path: '/cuemate-ios/training/stroke-metrics', breadcrumb: 'Stroke Metrics' },
  { path: '/cuemate-ios/training/rating', breadcrumb: 'Rating-based' },
  { path: '/cuemate-ios/training/self-directed', breadcrumb: 'Self-directed' },
  { path: '/cuemate-ios/training/programs', breadcrumb: 'Programs' },
  {
    path: '/cuemate-ios/training/training-loop',
    breadcrumb: 'Training Loop',
  },
  {
    path: '/cuemate-ios/training/training-element',
    breadcrumb: 'Training Elements',
  },
  {
    path: '/cuemate-ios/training/training-list',
    breadcrumb: 'Training Lists',
  },
  {
    path: '/cuemate-ios/training/training-goals',
    breadcrumb: 'Training Goals',
  },
  {
    path: '/cuemate-ios/training/learning-process-assessment',
    breadcrumb: 'Learning Process Assessment and Skill Rating',
  },
  { path: '/cuemate-ios/metrics', breadcrumb: 'Metrics' },

  { path: '/cuemate-ios', breadcrumb: 'CueMate for iOS' },
  { path: '/cuemate-ios/home-screen', breadcrumb: 'Home' },
  { path: '/cuemate-ios/player-profile', breadcrumb: 'Player Profile' },
  { path: '/cuemate-ios/play-screen', breadcrumb: 'Play' },
  { path: '/cuemate-ios/train', breadcrumb: 'Train' },
  { path: '/cuemate-ios/reports-screen', breadcrumb: 'Reports' },
  { path: '/cuemate-ios/review-screen', breadcrumb: 'Review' },
  { path: '/cuemate-ios/metrics-screen', breadcrumb: 'Metrics' },
  { path: '/cuemate-ios/trends-screen', breadcrumb: 'Trends' },
  { path: '/cuemate-ios/status-screen', breadcrumb: 'Status' },
  { path: '/cuemate-ios/settings', breadcrumb: 'Settings' },

  { path: '/cuemate-web', breadcrumb: 'CueMate for Web' },
  { path: '/cuemate-web/home-screen', breadcrumb: 'Home' },
  { path: '/cuemate-web/session-reports', breadcrumb: 'Session Reports' },
  { path: '/cuemate-web/player-reports', breadcrumb: 'Player Reports' },
  {
    path: '/cuemate-web/stroke-class-reports',
    breadcrumb: 'Stroke Class Reports',
  },
  { path: '/cuemate-web/training', breadcrumb: 'Training' },
  { path: '/cuemate-web/leaderboard', breadcrumb: 'Leaderboard' },
];

// map & render your breadcrumb components however you want.
// each `breadcrumb` has the props `key`, `location`, and `match` included!
function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/', '/home'] });
  return (
    <Row className="padding-bottom-25 padding-top-25">
      <Col sm="12">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={breadcrumb.match.pathname}>
            <NavLink to={breadcrumb.match.pathname}>{breadcrumb.breadcrumb}</NavLink>
            {index < breadcrumbs.length - 1 && <i> / </i>}
          </span>
        ))}
      </Col>
    </Row>
  );
}

export default Breadcrumbs;

import React, { Fragment, useState } from 'react';
import {
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  NavbarBrand,
  TabPane,
  Navbar,
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { connect } from 'react-redux';

/* Charts */
import { Diagnostics, tennisUtilities } from 'cuemate-charts';

/* Functions */
import { getVisionImage } from '../util/dataQuery';

const { getMetrics, CV_METRICS } = tennisUtilities;

const PLAY_TYPES = ['play', 'train', 'game'];

/*
  Returns a tab pane, a tabId and a sessionsList are required
*/
function ClassDiagnostics({
  activeContent,
  config,
  onTransition,
  activityData,
  classData,
  elements,
  refRanges,
}) {
  const sortedClassData = classData.filter((c) => c.show).sort((a, b) => b.score - a.score);
  const activeClassId = Math.max(
    0,
    sortedClassData.findIndex((c) => activeContent.includes(c.name))
  );

  const parseTime = d3.timeParse('%Y-%m-%dT%H:%M:%S.%LZ');
  const formatTime = d3.timeFormat('%Y-%m-%d--%H-%M-%S');
  const setTypes = {};
  Object.keys(activityData)
    .map((s) => activityData[s].sets)
    .flat()
    .forEach((d) => {
      const setId = formatTime(parseTime(d.startTS));
      setTypes[setId] = d.typeRaw || 'play';
    });
  const filteredElements = elements.map((d) => ({
    ...d,
    playType: setTypes[d.set] || 'play',
  }));
  return (
    <Row>
      <Col sm="3" className="text-sm-left text-center d-flex flex-column">
        <ClassList
          {...{
            classData: sortedClassData,
            activeClassId,
            onTransition,
            config,
          }}
        />
      </Col>
      <Col sm="9" className="text-sm-left text-center">
        <ClassContent
          {...{
            refRanges,
            elements: filteredElements,
            classData: sortedClassData,
            activeClassId,
          }}
        />
      </Col>
    </Row>
  );
}

ClassDiagnostics.propTypes = {
  elements: PropTypes.array,
  activeContent: PropTypes.string,
  config: PropTypes.object,
  onTransition: PropTypes.func,
  classData: PropTypes.array,
  refRanges: PropTypes.array,
  activityData: PropTypes.object,
};

function ClassList({ classData, activeClassId, config, onTransition }) {
  const [classesListOpen, setOpen] = useState(-1);
  const toggleClassesList = (type) => {
    setOpen(classesListOpen === type ? -1 : type);
  };
  const activeClass = classData[activeClassId];
  return (
    <>
      {config.parameters.types.map((type, j) => {
        const typeClasses = classData.filter((cls) => cls.name.startsWith(type) && cls.show);
        return (
          <Fragment key={`frag-${type}`}>
            <NavbarBrand
              className="btn mr-auto"
              style={{
                cursor: typeClasses.length > 0 ? 'pointer' : 'not-allowed',
                color: activeClass.name.includes(type) ? '#2196F3' : null,
              }}
              onClick={typeClasses.length > 0 ? () => toggleClassesList(j) : null}
            >
              <span className="pr-2">{type.toUpperCase()}</span>
              {typeClasses.length > 0 &&
                (classesListOpen === j ? (
                  <i className="fa fa-caret-down" aria-hidden="true" />
                ) : (
                  <i className="fa fa-caret-right" aria-hidden="true" />
                ))}
            </NavbarBrand>
            {typeClasses.length > 0 && (
              <Collapse isOpen={classesListOpen === j}>
                <Nav vertical>
                  {typeClasses
                    .sort((a, b) => b.score - a.score)
                    .map((cls, index) => (
                      <NavItem key={`nav-${type}-${cls.name}`}>
                        <NavLink
                          className={classnames({ active: cls.name === activeClass.name })}
                          onClick={() => onTransition(`_${cls.name}`)}
                        >
                          {`${index + 1}. ${cls.abrName}`}
                        </NavLink>
                      </NavItem>
                    ))}
                </Nav>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </>
  );
}

ClassList.propTypes = {
  config: PropTypes.object,
  onTransition: PropTypes.func,
  classData: PropTypes.array,
  activeClassId: PropTypes.number,
};

function ClassContent({ classData, activeClassId, refRanges, elements }) {
  // const [referenceId, toggleReference] = useState(0);
  const referenceId = 0;
  const [playTypes, setPlayTypes] = useState(PLAY_TYPES.map(() => true));

  const togglePlayType = (id) => {
    setPlayTypes(playTypes.map((p, i) => (i === id ? !p : p)));
  };

  const activeClass = classData[activeClassId];
  const classId = classData
    .filter((t) => t.name.startsWith(activeClass.name[0]))
    .findIndex((t) => t.name === activeClass.name);

  const ranges = (refRanges && refRanges[referenceId]) || {};
  const classRanges =
    ranges.value && ranges.value.find((d) => d.classification === activeClass.name);
  const metrics = getMetrics(classRanges);

  const selectedTypes = PLAY_TYPES.filter((_, i) => playTypes[i]);
  const selectedElements = elements.filter((d) => selectedTypes.indexOf(d.playType) > -1);
  return (
    <TabPane className="animated fadeIn">
      <Row className="align-items-center justify-content-between">
        <h2 className="blue">{`${classId + 1}. ${activeClass.abrName}`}</h2>
      </Row>
      <Navbar color="light" light expand="md">
        <NavbarBrand>Play Types</NavbarBrand>
      </Navbar>
      <Form>
        {PLAY_TYPES.map((d, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup check inline key={i}>
            <Label check>
              <Input type="checkbox" onChange={() => togglePlayType(i)} checked={playTypes[i]} />
              {d[0].toUpperCase() + d.slice(1)}
            </Label>
          </FormGroup>
        ))}
      </Form>
      <hr />
      <Diagnostics
        elements={selectedElements}
        activeClass={activeClass.name}
        metrics={metrics}
        getImage={getVisionImage}
        cvMetrics={CV_METRICS}
        modal={false}
      />
    </TabPane>
  );
}

ClassContent.propTypes = {
  elements: PropTypes.array,
  refRanges: PropTypes.array,
  classData: PropTypes.array,
  activeClassId: PropTypes.number,
};

export default connect((state) => ({
  activeContent: state.activeContent,
  config: state.config,
  elements: state.elements,
  classData: state.classData,
  refRanges: state.refRanges,
  activityData: state.activityData,
}))(ClassDiagnostics);

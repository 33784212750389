import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import review7 from '../../../../images/cuemate101/level1/fundamentals/review/review-7.png';
import review8 from '../../../../images/cuemate101/level1/fundamentals/review/review-8.png';

import '../AppFeatures.css';

export default function FinishEndSession() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h2>FINISH: END SESSION</h2>
          <h3>UPLOAD DATA &#38; LOG OUT</h3>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={{ size: 8 }}>
          <ol>
            <li>
              <img src={review7} className="d-block mx-auto img-fluid" alt="Review Stroke Map" />
            </li>
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="5">
          <ol start="2">
            <li>Log Out</li>
            <img
              src={review8}
              className="d-block mx-auto img-fluid img-border"
              alt="‘Browse’ to view Stroke Maps for each set"
            />
          </ol>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/">
            <h3>
              Back to: CueMate 101 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';

export default function RatingBasedTraining() {
  return (
    <Container className="padding-bottom-25 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-bottom-15">
        <Col sm="12">
          {' '}
          <h1>Rating-Based Training</h1>{' '}
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h2 className="blueGrey">Learning or Training Journey</h2>
          <p>
            Tennis is a high dimensional movement skill (see the movement model for more details).
            As a result, tennis training presents many challenges:
            <ul>
              <li>
                What aspects should you focus training on to give you the tools for a better game?
              </li>
              <li>
                What path will take you from your current skill level to the desired skill level?
              </li>
              <li>Finally, every player has different needs, individual factors, and goals.</li>
            </ul>
          </p>
          <p>
            A central aspect of training is determining your current skill level so that training
            can proceed in a way that responds to your needs and puts you on the right path.
          </p>
          <p>
            The stroke repertoire is the keystone of CueMate’s skill assessment, learning
            assessment, and training. The following briefly describes how it is used to assess
            learning and generate training goals.
          </p>
          <p>
            Your specific stroke map and learning stage distribution determines your skill level
            (skill rating) and game style. With this knowledge CueMate computes training goals that
            will take you to your desired game style and skill level.
          </p>
          <p>
            Check out the <Link to="/background/learning-model">CueMate’s Learning Model</Link> to
            understand how CueMate assesses where you are along the learning journey.{' '}
          </p>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import cues1 from '../../../../images/cuemate101/level2/key-concepts/training-cues/cues-1.png';
import cues2 from '../../../../images/cuemate101/level2/key-concepts/training-cues/cues-2.png';
import cues3 from '../../../../images/cuemate101/level2/key-concepts/training-cues/cues-3.png';
import cues4 from '../../../../images/cuemate101/level2/key-concepts/training-cues/cues-4.png';

import '../AppFeatures.css';

export default function TrainingCues() {
  return (
    <Container className="padding-top-25 padding-bottom-100 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Key Concepts</h1>
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={8}>
          <h2>Training Cues</h2>
          <p>
            CueMate gives real-time audio feedback to help players reach training goals faster.
            Training Cues are set relative to individual goals and are easy to interpret using cue
            sounds based on standard musical scales.
          </p>
          <p>
            The lowest Cue is a B flat, with the “minor” key giving it an obvious negative sound
            indicating below baseline performance. The other cues move stepwise up the musical
            scale, and the peak performance cue is a full octave higher, making it stand out from
            the rest.
          </p>
        </Col>
        <Col sm={{ size: 4 }}>
          <img
            src={cues1}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Player at the end of forward-swing"
          />
        </Col>
      </Row>

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={{ offset: 1, size: 9 }}>
          <img
            src={cues2}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Player at the end of forward-swing"
          />
          <br />
          <p>
            Once a Training Goal is added for a Training Element, the Custom Cue scale will be
            adjusted to the player’s baseline performance and the new Training Goal. The cue will
            only be active during that specific stroke and in response to a single metric (example:
            Forehand Topspin Medium - Spin)
          </p>
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">PACE</h4>
          <p>Peak racket head speed during stroke</p>
        </Col>
        <Col sm={{ size: 6 }}>
          <figcaption className="figure-caption">Audio Cue Tone</figcaption>
          <img
            src={cues3}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Audio Cue Tone"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">SPIN</h4>
          <p>Rotation speed of ball leaving the racket</p>
        </Col>
        <Col sm={{ size: 6 }}>
          <figcaption className="figure-caption">Audio Cue Tone</figcaption>
          <img
            src={cues3}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Audio Cue Tone"
          />
        </Col>
      </Row>
      <hr />

      <Row className="padding-top-25 padding-bottom-25">
        <Col sm={6}>
          <h4 className="blue">ISR</h4>
          <p>Impact Success Rate: Ball contact with racket “sweet-spot”</p>
        </Col>
        <Col sm={{ size: 6 }}>
          <figcaption className="figure-caption">Audio Cue Tone</figcaption>
          <img
            src={cues4}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Audio Cue Tone"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate101/level2">
            <h3>
              Back to: Level 2 <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import view1 from '../../../../images/cuemate101/level1/on-court/stroke-map/StrokeMap-1.png';
import view2 from '../../../../images/cuemate101/level1/on-court/stroke-map/StrokeMap-2.png';
import view3 from '../../../../images/cuemate101/level1/on-court/stroke-map/StrokeMap-3.png';

import '../AppFeatures.css';

function TitleContent() {
  return (
    <Container className="padding-bottom-25">
      <Row className="padding-top-2">
        <Col sm="12">
          <h1>Using the Stroke Map and Outcome Metrics</h1>
          <p>
            CueMate records all strokes and sorts them into 18 stroke classes on the Stroke Map.
            This grid is the Stroke Map, and it reveals a player’s ‘stroke repertoire’ during a set.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-15">
        <Col sm={5}>
          <h3 className="padding-top-25 blue">SESSION INFORMATION</h3>
          <p>
            The information panel on the left of the stroke map tells you about the amount of time
            and number of strokes you have played in each of your sets as well as the totals across
            the session.
          </p>
          <p>
            If you have multiple sets, swiping up or down will update the stroke map to the details
            in that set.
          </p>
          <p>The panel shows you if your current set has cueing turned on or off.</p>
        </Col>
        <Col sm={7}>
          <img src={view1} className="d-block mx-auto img-fluid" alt="Stroke Map Review 1" />
        </Col>
      </Row>
    </Container>
  );
}

function Screen2() {
  return (
    <Container fluid className="light-grey-bg padding-25">
      <Container>
        <Row>
          <Col sm={7}>
            <img src={view2} className="d-block mx-auto img-fluid" alt="Stroke Map Review 2" />
          </Col>
          <Col sm={5}>
            <h3 className="padding-top-25 blue">STROKE DISTRIBUTION</h3>
            <p>
              The Stroke Map tells you about which strokes you are playing in terms of the spin
              type, intensity level, their counts, and outcomes.
              <ul>
                <li>
                  If your stroke map is skewed towards a few certain stroke classes or types (maybe
                  you have more flats than topspin), it shows that you have gaps in your repertoire.
                  This lack of strokes in other classes encourages you to hit more of them, thereby
                  increasing your stroke repertoire
                </li>
              </ul>
            </p>
          </Col>
        </Row>
        <br />
      </Container>
    </Container>
  );
}

function Screen3() {
  return (
    <Container className="padding-25">
      <Row>
        <Col sm={5} className="padding-top-25">
          <p>
            If your stroke classes are highlighted in green, it means that you are currently hitting
            strokes with better outcomes compared to your previous sessions. Red shows that your
            outcomes are lower than in the past. This will help you focus your hitting on these
            certain classes and push you to improve your outcomes and technique.
          </p>
          <p>
            Stroke classes with the three most counts are considered core strokes. These classes can
            identify as your strengths, and you can further optimize your performance in these
            classes.
          </p>
        </Col>
        <Col sm={7}>
          <img src={view3} className="d-block mx-auto img-fluid" alt="Stroke Map Review 3" />
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default function StrokeMapReview() {
  return (
    <>
      <Container>
        <Breadcrumbs />
      </Container>
      <TitleContent />
      <Screen2 />
      <Screen3 />
      <Container>
        <Row>
          <Col sm={12}>
            <Link to="/cuemate101/">
              <h3>
                Back to: CueMate 101 <i className="fa fa-angle-right" />
              </h3>
            </Link>
          </Col>
        </Row>
        <Breadcrumbs />
      </Container>
    </>
  );
}

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

export default function StrokeClassScores() {
  return (
    <Container className="padding-bottom-25 animated fadeIn">
      <Breadcrumbs />

      <Row className="padding-bottom-25">
        <Col sm="12">
          {' '}
          <h2>Stroke Class Scores</h2>{' '}
          <h4>
            Stroke class metrics are very useful to determine how well you are doing for a wide
            range of characteristics of your stroke performance. These metrics can be combined to
            provide scores{' '}
          </h4>
          <h4>
            Tennis performance depends on different aspects coming together. For example,
            preparation, setup, stroke execution.
          </h4>
          <h4>
            Stroke class scores are designed to assess how you are doing across these aspects of
            performance.
          </h4>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h2 className="blueGrey">Stroke Class Scores</h2>
          <h4>CueMate provides several Scores to help you assess your performance, including:</h4>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <h3>Strike Quality Score</h3>
          <p>
            Captures the quality of the ball strike (impact timing, sweet spot, and precision).
            Strike quality is necessary for a good outcome but not sufficient.
          </p>
          <p>
            A good score is achieved by anticipating, preparing, and setting up and executing the
            stroke.
          </p>

          <h3>Outcome Level Score</h3>
          <p>
            Captures the level of the stroke outcomes relative to the player population across all
            groups. This score makes it possible to understand where you fall relative to other
            players.
          </p>

          <h3>Outcome Efficiency Score</h3>
          <p>
            As its name implies, captures the efficiency of your technique in producing the stroke
            outcomes. It is a good measure of your technique, i.e., how well you use your body
            biomechanics to generate spin and speed.{' '}
          </p>

          <h3>Stroke Quality Score</h3>
          <p>
            Captures the overall quality of the stroke, including its execution and preparation.
          </p>
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm="12">
          <h2 className="blueGrey">Session and Global Scores</h2>
          <h4>
            Session and total scores provide information about how well you are doing overall across
            your sessions or sets. These scores also provide the basis for the computation of
            leaderboard.{' '}
          </h4>
        </Col>
      </Row>

      <Breadcrumbs />
    </Container>
  );
}

/* eslint-disable default-param-last */
import { combineReducers } from 'redux';
import * as actions from '../actions';

const leaderboards = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_LEADERBOARD:
      return action.payload;
    default:
      return state;
  }
};

const username = (state = '', action) => {
  switch (action.type) {
    case actions.ADD_USERNAME:
      return action.username;
    default:
      return state;
  }
};

const userGroups = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_USER_GROUPS:
      return action.payload;
    default:
      return state;
  }
};

const reportType = (state = 'basic', action) => {
  switch (action.type) {
    case actions.ADD_REPORT_TYPE:
      return action.reportType;
    default:
      return state;
  }
};

const activityData = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ACTIVITY_DATA:
      return action.payload;
    default:
      return state;
  }
};

const activeSets = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_ACTIVE_SETS:
      return action.payload;
    default:
      return state;
  }
};

const realmClient = (state = null, action) => {
  switch (action.type) {
    case actions.ADD_REALM_CLIENT:
      return action.payload;
    default:
      return state;
  }
};

const elements = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_ELEMENTS:
      return action.payload;
    default:
      return state;
  }
};

const refRanges = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_REF_RANGES:
      return action.payload;
    default:
      return state;
  }
};

const classStats = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_CLASS_STATS:
      return action.payload;
    default:
      return state;
  }
};

const sessionData = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_SESSION_DATA:
      return action.payload;
    default:
      return state;
  }
};

const classData = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_CLASS_DATA:
      return action.payload;
    default:
      return state;
  }
};

const visionData = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_VISION_DATA:
      return action.payload;
    default:
      return state;
  }
};

const config = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_CONFIG:
      return action.payload;
    default:
      return state;
  }
};

const activeContent = (state = null, action) => {
  switch (action.type) {
    case actions.UPDATE_ACTIVE_CONTENT:
      return action.content;
    default:
      return state;
  }
};

const modes = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_MODES:
      return action.modes;
    default:
      return state;
  }
};

const units = (state = [], action) => {
  switch (action.type) {
    case actions.UPDATE_UNITS:
      return action.payload;
    default:
      return state;
  }
};

const cmvThresholds = (state = [], action) => {
  switch (action.type) {
    case actions.ADD_CMV_THRESHOLDS:
      return action.payload;
    default:
      return state;
  }
};

const rating = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_RATING:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  leaderboards,
  username,
  userGroups,
  reportType,
  activityData,
  activeSets,
  realmClient,
  elements,
  sessionData,
  classData,
  visionData,
  refRanges,
  classStats,
  config,
  activeContent,
  modes,
  units,
  cmvThresholds,
  rating,
});

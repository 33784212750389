import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../components/Breadcrumbs';

import { Level3Description, Level3KeyConcepts, Level3Features } from './L3ToC';

export default function Level3() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row>
        <Col sm="12">
          <h2>3: Train with CueMate</h2>
        </Col>
      </Row>
      <Level3Description />
      <Level3KeyConcepts />
      <Level3Features />
    </Container>
  );
}

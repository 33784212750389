import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { HashLink as Link } from 'react-router-hash-link';

import settings from '../../../../images/screens/Settings.PNG';

import Breadcrumbs from '../../../../components/Breadcrumbs';

import './AppFeatures.css';

export default function SettingsScreen() {
  return (
    <Container className="padding-bottom-100 animated fadeIn">
      <Breadcrumbs />
      <Row className="padding-top-25">
        <Col sm="12">
          <h2>Settings</h2>
        </Col>
      </Row>
      <Row className="padding-top-25">
        <Col>
          <p>The Settings screen allows you to do the following:</p>
          <ul>
            <li>Version Number: The version of the CueMate Tennis App currently installed</li>
            <li>
              BLE Status: Check the connectedness of the CueMate sensor and disconnect from it
            </li>
            <li>Bluetooth Info: Display the signal strength on the Play Screen </li>
            <li>Units: Switch between the units in the app</li>
            <li>
              Cueing Features
              <ul>
                <li>Cueing: Switch for turning on sound during cueing</li>
                <li>Signal Strength: Switch for turning on sound when signal strength is weak</li>
                <li>Stroke: Switch for turning on sound for every stroke detected</li>
                <li>Cueing Sound: Toggle for switching sounds between speaker and earphone</li>
              </ul>
            </li>
          </ul>
        </Col>
        <Col sm={6}>
          <img
            src={settings}
            className="d-block mx-auto img-fluid img-fluid img-border"
            alt="Settings"
          />
        </Col>
      </Row>

      <Row className="padding-top-25">
        <Col sm={12}>
          <Link to="/cuemate-ios">
            <h3>
              Back to: CueMate iOS <i className="fa fa-angle-right" />
            </h3>
          </Link>
        </Col>
      </Row>
      <Breadcrumbs />
    </Container>
  );
}
